import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SiteDto } from '../../dtos';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetSiteByIdQuery, useUpdateSiteMutation } from '../../store/generated/generatedApi';
import { SiteForm } from '../../Components/Forms/SiteForm';

export const EditSiteView: FC = () => {
    const { siteId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetSiteByIdQuery({ id: siteId! });
    const [updateSite, { isSuccess, isError, reset }] = useUpdateSiteMutation();

    useSuccessfulUpdateSnackbar('Site', isSuccess, reset);
    useFailedUpdateSnackbar('site', isError, reset);

    const handleSave = (values: SiteDto) => {
        updateSite(values);
    };

    const handleCancel = () => {
        navigate('/dataManagement/sites');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <SiteForm save={handleSave} cancel={handleCancel} initValues={data} />;
};
