import { IRoute } from './types';
import {
    UserListView,
    AddUserView,
    EditUserView,
    Home,
    AddMaterialView,
    EditMaterialView,
    MaterialListView,
    DataManagementView,
    JobListView,
    EditJobView,
    BrokerListView,
    AddBrokerView,
    EditBrokerView,
    DriverMobileView,
    DispatchWrapper,
} from '../Views';
import { TrashListView } from '../Views/Trash';
import { AddCustomerView, CustomerListView, EditCustomerView } from '../Views/Customer';
import { CustomerDashboard } from '../Views/Customer/CustomerDashboard';
import { AddQuoteView, EditQuoteView, QuoteListView } from '../Views/Quote';
import { AddDumpRateView, AddSiteView, EditDumpRateView, EditSiteView, SiteListView } from '../Views/Site';
import { AddMaterialPriceView, EditMaterialPriceView, InputPriceListView, MaterialPriceListView } from '../Views/MaterialPrice';
import { AddContactView, EditContactView } from '../Views/Contact';
import { AddSiteHaulingLineItemView, EditSiteHaulingLineItemView } from '../Views/Quote/LineItems/SiteHauling';
import { AddMaterialLineItemView, EditMaterialLineItemView } from '../Views/Quote/LineItems/Material';
import { AddHourlyLineItemView, EditHourlyLineItemView } from '../Views/Quote/LineItems/Hourly';
import { AddMiscLineItemView, EditMiscLineItemView } from '../Views/Quote/LineItems/Misc';
import { AddDumpLineItemView, EditDumpLineItemView } from '../Views/Quote/LineItems/Dump';
import { MiscSettings } from '../Views/MiscSettings';
import { EditOrderView } from '../Views/Orders';
import { AddEquipmentView, EditEquipmentView, EquipmentListView } from '../Views/Equipment';
import { AddOrderReviewView, EditOrderReviewView } from '../Views/OrderReview';
import { OrderReviewPage } from '../Views/OrderReview/OrderReviewPage';
import { HomePageNavigator } from './HomePageNavigator';
import { CreateTicketView } from '../Views/OrderReview/CreateTicketView';

export const routes: IRoute[] = [
    {
        component: <HomePageNavigator />,
        path: '/',
        requiredPermissions: [],
    },
    {
        component: <Home />,
        path: '/home',
        requiredPermissions: [],
    },
    {
        component: <CustomerListView />,
        path: '/customers',
        requiredPermissions: ['read:customer'],
    },
    {
        component: <AddCustomerView />,
        path: '/customer/create',
        requiredPermissions: ['create:customer'],
    },
    {
        component: <EditCustomerView />,
        path: '/customer/:customerId/:tab?',
        requiredPermissions: ['edit:customer'],
    },
    {
        component: <AddQuoteView />,
        path: '/customer/:customerId/quote/create/:type?/:appraisalType?',
        requiredPermissions: ['create:quote'],
    },
    {
        component: <EditQuoteView />,
        path: '/customer/:customerId/quote/:quoteId/:tab?',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <CustomerDashboard />,
        path: '/customer/:customerId/dashboard',
        requiredPermissions: ['read:customer'],
    },
    {
        component: <AddContactView />,
        path: '/customer/:customerId/contact/create',
        requiredPermissions: ['create:contact'],
    },
    {
        component: <EditContactView />,
        path: '/customer/:customerId/contact/:id',
        requiredPermissions: ['edit:contact'],
    },
    {
        component: <AddContactView />,
        path: '/dataManagement/site/:siteId/contact/create',
        requiredPermissions: ['create:contact'],
    },
    {
        component: <EditContactView />,
        path: '/dataManagement/site/:siteId/contact/:id',
        requiredPermissions: ['edit:contact'],
    },
    {
        component: <UserListView />,
        path: '/users',
        requiredPermissions: ['read:users'],
    },
    {
        component: <AddUserView />,
        path: '/user/create',
        requiredPermissions: ['create:users'],
    },
    {
        component: <EditUserView />,
        path: '/user/:id',
        requiredPermissions: ['edit:users'],
    },
    {
        component: <DataManagementView />,
        path: '/dataManagement',
    },
    {
        component: <MaterialListView />,
        path: '/dataManagement/materials',
        requiredPermissions: ['read:material'],
    },
    {
        component: <AddMaterialView />,
        path: '/dataManagement/material/create',
        requiredPermissions: ['create:material'],
    },
    {
        component: <EditMaterialView />,
        path: '/dataManagement/material/:id',
        requiredPermissions: ['edit:material'],
    },
    {
        component: <EquipmentListView />,
        path: '/dataManagement/equipment',
        requiredPermissions: ['read:equipment'],
    },
    {
        component: <AddEquipmentView />,
        path: '/dataManagement/equipment/create',
        requiredPermissions: ['create:equipment'],
    },
    {
        component: <EditEquipmentView />,
        path: '/dataManagement/equipment/:equipmentId',
        requiredPermissions: ['edit:equipment'],
    },
    {
        component: <JobListView />,
        path: '/jobs',
        requiredPermissions: ['read:job'],
    },
    {
        component: <EditJobView />,
        path: '/job/:id',
        requiredPermissions: ['edit:job'],
    },
    {
        component: <SiteListView />,
        path: '/dataManagement/sites',
        requiredPermissions: ['read:site'],
    },
    {
        component: <BrokerListView />,
        path: '/dataManagement/brokers',
        requiredPermissions: ['read:broker'],
    },
    {
        component: <AddBrokerView />,
        path: '/dataManagement/broker/create',
        requiredPermissions: ['create:broker'],
    },
    {
        component: <EditBrokerView />,
        path: '/dataManagement/broker/:brokerId',
        requiredPermissions: ['edit:broker'],
    },
    {
        component: <AddSiteView />,
        path: '/dataManagement/site/create',
        requiredPermissions: ['create:site'],
    },
    {
        component: <EditSiteView />,
        path: '/dataManagement/site/:siteId/:tab?',
        requiredPermissions: ['edit:site'],
    },
    {
        component: <AddDumpRateView />,
        path: '/dataManagement/site/:siteId/dumpRate/create',
        requiredPermissions: ['create:dumpRate'],
    },
    {
        component: <EditDumpRateView />,
        path: '/dataManagement/site/:siteId/dumpRate/:dumpRateId',
        requiredPermissions: ['edit:dumpRate'],
    },
    {
        component: <MaterialPriceListView />,
        path: '/dataManagement/materialPrices',
        requiredPermissions: ['read:materialPrice'],
    },
    {
        component: <AddMaterialPriceView />,
        path: '/dataManagement/materialPrice/create',
        requiredPermissions: ['create:materialPrice'],
    },
    {
        component: <EditMaterialPriceView />,
        path: '/dataManagement/materialPrice/:id',
        requiredPermissions: ['edit:materialPrice'],
    },
    {
        component: <InputPriceListView />,
        path: '/dataManagement/materialPrice/inputPriceList',
        requiredPermissions: ['create:materialPrice'],
    },
    {
        component: <TrashListView />,
        path: '/trash',
        requiredPermissions: ['read:trash'],
    },
    {
        component: <QuoteListView />,
        path: '/quotes',
        requiredPermissions: ['read:quote'],
    },
    {
        component: <AddQuoteView />,
        path: '/quote/create/:type?/:appraisalType?',
        requiredPermissions: ['create:quote'],
    },
    {
        component: <EditQuoteView />,
        path: '/quote/:quoteId/:tab?/:appraisalType?',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <EditOrderView />,
        path: '/job/:jobId/order/:orderId',
        requiredPermissions: ['edit:order'],
    },
    {
        component: <OrderReviewPage />,
        path: 'orderReview',
        requiredPermissions: ['read:review', 'read:billing'],
        permissionLogic: 'or'
    },
    {
        component: <AddOrderReviewView />,
        path: '/job/:jobId/order/:orderId/orderReview/create',
        requiredPermissions: ['create:review', 'create:billing'],
        permissionLogic: 'or'
    },
    {
        component: <EditOrderReviewView />,
        path: '/job/:jobId/order/:orderId/orderReview/:orderReviewId',
        requiredPermissions: ['edit:review', 'edit:billing'],
        permissionLogic: 'or'
    },
    {
        component: <AddMaterialLineItemView />,
        path: '/quote/:quoteId/lineItem/material/create/:jobId?/:orderId?',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <EditMaterialLineItemView />,
        path: '/quote/:quoteId/lineItem/material/:lineItemId',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <AddSiteHaulingLineItemView />,
        path: '/quote/:quoteId/lineItem/siteHauling/create/:jobId?/:orderId?',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <EditSiteHaulingLineItemView />,
        path: '/quote/:quoteId/lineItem/siteHauling/:lineItemId',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <AddHourlyLineItemView />,
        path: '/quote/:quoteId/lineItem/hourly/create/:jobId?/:orderId?',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <EditHourlyLineItemView />,
        path: '/quote/:quoteId/lineItem/hourly/:lineItemId',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <AddDumpLineItemView />,
        path: '/quote/:quoteId/lineItem/dump/create/:jobId?/:orderId?',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <EditDumpLineItemView />,
        path: '/quote/:quoteId/lineItem/dump/:lineItemId',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <AddMiscLineItemView />,
        path: '/quote/:quoteId/lineItem/misc/create/:jobId?/:orderId?',
        requiredPermissions: ['edit:quote'],
    },
    {
        component: <EditMiscLineItemView />,
        path: '/quote/:quoteId/lineItem/misc/:lineItemId',
        requiredPermissions: ['edit:quote'],
    },

    {
        component: <MiscSettings />,
        path: '/settings',
        requiredPermissions: ['read:miscSettings', 'edit:miscSettings'],
    },
    {
        component: <DispatchWrapper />,
        path: '/dispatch',
        requiredPermissions: ['read:dispatch'],
    },
    {
        component: <CreateTicketView />,
        path: '/orderReview/enter-ticket',
        requiredPermissions: ['read:billing'],
    }
];

export const mobileRoutes: IRoute[] = [
    {
        component: <DriverMobileView />,
        path: '/driver/:activeTab?',
        requiredPermissions: ['is:hauler'],
    }
];

export const publicRoutes: IRoute[] = [];
