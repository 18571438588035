import { useCallback, useEffect, useMemo, useState } from 'react';
import { AssignmentType } from '../../../dtos/generated/AssignmentType';
import { BrokerDto, DriverDto, EquipmentDto, RegionDto } from '../../../dtos';
import {
    IFormFieldValidationConfig,
    IFormProps,
    isNotBlank,
    isShorterThanMaxLength,
    runFieldValidation,
    runFormValidation,
} from '../../../Components/CoreLib/library';
import _ from 'lodash';
import { EventModel } from '@bryntum/scheduler';
import { areDateTimesEqual, countries } from '../../../util';

export interface IUseEventEditorFormProps extends IFormProps<EventModel> {
    isHaulerView: boolean;
}

export interface IEventEditorFormValues {
    startTime?: Date | null;
    endTime?: Date | null;
    onsiteTime?: Date | null;
    yardTime?: Date | null;
    assignmentType?: AssignmentType;
    driver?: DriverDto;
    driverId?: string;
    broker?: BrokerDto;
    brokerId?: string;
    equipment?: EquipmentDto;
    equipmentId?: string;
    haulToAddressLine1?: string;
    haulToAddressLine2?: string;
    haulToCity?: string;
    haulToCountry?: string;
    haulToState?: string;
    haulToZipCode?: string;
    haulFromAddressLine1?: string;
    haulFromAddressLine2?: string;
    haulFromCity?: string;
    haulFromCountry?: string;
    haulFromState?: string;
    haulFromZipCode?: string;
    haulFromSiteName?: string;
    haulToSiteName?: string;
    haulToRegion?: RegionDto;
    haulFromRegion?: RegionDto;
    haulToRegionId?: string;
    haulFromRegionId?: string;
    driverMemo?: string;
}

export function useEventEditorForm(props: IUseEventEditorFormProps) {
    const { save, cancel, initValues, isHaulerView } = props;
    const [startTime, setStartTime] = useState<Date | undefined | null>(initValues ? new Date((initValues as any).data.startDate) : null);
    const [endTime, setEndTime] = useState<Date | undefined | null>(initValues ? new Date((initValues as any).data.endDate) : null);
    const [onsiteTime, setOnsiteTime] = useState<Date | undefined | null>(initValues ? new Date((initValues as any).data.onsiteTime) : null);
    const [yardTime, setYardTime] = useState<Date | undefined | null>(
        initValues && (initValues as any).data.yardTime ? new Date((initValues as any).data.yardTime) : null
    );
    const [assignmentType, setAssignmentType] = useState<AssignmentType>();
    const [driver, setDriver] = useState<DriverDto>();
    const [broker, setBroker] = useState<BrokerDto>();
    const [equipment, setEquipment] = useState<EquipmentDto>();
    const [formHaulToAddressLine1, setFormHaulToAddressLine1] = useState('');
    const [formHaulToAddressLine2, setFormHaulToAddressLine2] = useState('');
    const [formHaulToCity, setFormHaulToCity] = useState('');
    const [formHaulToCountry, setFormHaulToCountry] = useState(countries[2]);
    const [formHaulToState, setFormHaulToState] = useState('');
    const [formHaulToZipCode, setFormHaulToZipCode] = useState('');
    const [formHaulFromAddressLine1, setFormHaulFromAddressLine1] = useState('');
    const [formHaulFromAddressLine2, setFormHaulFromAddressLine2] = useState('');
    const [formHaulFromCity, setFormHaulFromCity] = useState('');
    const [formHaulFromCountry, setFormHaulFromCountry] = useState(countries[2]);
    const [formHaulFromState, setFormHaulFromState] = useState('');
    const [formHaulFromZipCode, setFormHaulFromZipCode] = useState('');
    const [formDriverMemo, setFormDriverMemo] = useState('');
    const [formHaulToSiteName, setFormHaulToSiteName] = useState('');
    const [formHaulFromSiteName, setFormHaulFromSiteName] = useState('');
    const [formHaulToRegion, setFormHaulToRegion] = useState<RegionDto>();
    const [formHaulFromRegion, setFormHaulFromRegion] = useState<RegionDto>();

    useEffect(() => {
        if (initValues) {
            const event = (initValues as any).data;
            setStartTime(event.startDate ? new Date(event.startDate) : null);
            setEndTime(event.endDate ? new Date(event.endDate) : null);
            setOnsiteTime(event.onsiteTime ? new Date(event.onsiteTime) : null);
            setYardTime(event.yardTime !== null ? new Date(event.yardTime) : null);
            setAssignmentType(event.assignmentType);
            setDriver(event.driver);
            setBroker(event.broker);
            setEquipment(event.equipment ?? event.driver?.equipment);
            setFormHaulToAddressLine1(event?.haulToAddressLine1 ?? '');
            setFormHaulToAddressLine2(event?.haulToAddressLine2 ?? '');
            setFormHaulToCity(event?.haulToCity ?? '');
            setFormHaulToState(event?.haulToState ?? '');
            setFormHaulToZipCode(event?.haulToZipCode ?? '');
            setFormHaulToCountry(event?.haulToCountry ?? countries[2]);
            setFormHaulFromAddressLine1(event?.haulFromAddressLine1 ?? '');
            setFormHaulFromAddressLine2(event?.haulFromAddressLine2 ?? '');
            setFormHaulFromCity(event?.haulFromCity ?? '');
            setFormHaulFromState(event?.haulFromState ?? '');
            setFormHaulFromZipCode(event?.haulFromZipCode ?? '');
            setFormHaulFromCountry(event?.haulFromCountry ?? countries[2]);
            setFormDriverMemo(event?.driverMemo);
            setFormHaulToSiteName(event?.haulToSiteName ?? '');
            setFormHaulFromSiteName(event?.haulFromSiteName ?? '');
            setFormHaulToRegion(event?.haulToRegion);
            setFormHaulFromRegion(event?.haulFromRegion);
        }
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IEventEditorFormValues, string>>(
        new Map([
            ['startTime', ''],
            ['endTime', ''],
            ['assignmentType', ''],
            ['brokerId', ''],
            ['driverId', ''],
            ['equipmentId', ''],
            ['haulToAddressLine1', ''],
            ['haulToCity', ''],
            ['haulToState', ''],
            ['haulToZipCode', ''],
            ['haulToCountry', ''],
            ['haulFromAddressLine1', ''],
            ['haulFromCity', ''],
            ['haulFromState', ''],
            ['haulFromZipCode', ''],
            ['haulFromCountry', ''],
            ['haulFromSiteName', ''],
            ['haulToSiteName', ''],
            ['haulToRegion', ''],
            ['haulFromRegion', ''],
            ['haulToRegionId', ''],
            ['haulFromRegionId', ''],
        ])
    );

    const minutesOfDay = useCallback((date: Date) => {
        return date.getMinutes() + date.getHours() * 60;
    }, []);

    const isTimeValid = useCallback(() => {
        return (value: any) => {
            if (startTime && endTime) {
                let isValid = !(minutesOfDay(new Date(startTime)) >= minutesOfDay(new Date(endTime)));

                const errorMessageBuilder = (_fieldName: string) => `Start time must be before end time.`;
                return {
                    isValid,
                    errorMessageBuilder,
                };
            } else {
                return {
                    isValid: false,
                    errorMessageBuilder: (_fieldName: string) => `Start time and end time are required.`,
                };
            }
        };
    }, [endTime, minutesOfDay, startTime]);

    const formFieldValidators = useMemo(() => {
        return new Map<keyof IEventEditorFormValues, IFormFieldValidationConfig>([
            [
                'startTime',
                {
                    validators: [isNotBlank, isTimeValid()],
                    errorMessageEntityName: 'Start Time',
                },
            ],
            [
                'endTime',
                {
                    validators: [isNotBlank, isTimeValid()],
                    errorMessageEntityName: 'End Time',
                },
            ],
            [
                'assignmentType',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Assignment Type',
                },
            ],
            [
                'brokerId',
                {
                    validators: assignmentType === AssignmentType.Broker ? [isNotBlank] : [],
                    errorMessageEntityName: 'Broker',
                },
            ],
            [
                'driverId',
                {
                    validators: assignmentType === AssignmentType.Driver ? [isNotBlank] : [],
                    errorMessageEntityName: 'Driver',
                },
            ],
            [
                'equipmentId',
                {
                    validators: assignmentType === AssignmentType.Driver ? [isNotBlank] : [],
                    errorMessageEntityName: 'Equipment',
                },
            ],
            [
                'haulToAddressLine1',
                {
                    validators: [isShorterThanMaxLength(100)],
                    errorMessageEntityName: 'Address Line 1',
                },
            ],
            [
                'haulToCity',
                {
                    validators: [isShorterThanMaxLength(60)],
                    errorMessageEntityName: 'City',
                },
            ],
            [
                'haulToState',
                {
                    validators: [isShorterThanMaxLength(25)],
                    errorMessageEntityName: 'State',
                },
            ],
            [
                'haulToZipCode',
                {
                    validators: [isShorterThanMaxLength(12)],
                    errorMessageEntityName: 'Zip Code',
                },
            ],
            [
                'haulToCountry',
                {
                    validators: [isShorterThanMaxLength(60)],
                    errorMessageEntityName: 'Country',
                },
            ],
            [
                'haulFromAddressLine1',
                {
                    validators: [isShorterThanMaxLength(100)],
                    errorMessageEntityName: 'Address Line 1',
                },
            ],
            [
                'haulFromCity',
                {
                    validators: [isShorterThanMaxLength(60)],
                    errorMessageEntityName: 'City',
                },
            ],
            [
                'haulFromState',
                {
                    validators: [isShorterThanMaxLength(25)],
                    errorMessageEntityName: 'State',
                },
            ],
            [
                'haulFromZipCode',
                {
                    validators: [isShorterThanMaxLength(12)],
                    errorMessageEntityName: 'Zip Code',
                },
            ],
            [
                'haulFromCountry',
                {
                    validators: [isShorterThanMaxLength(60)],
                    errorMessageEntityName: 'Country',
                },
            ],
        ]);
    }, [assignmentType, isTimeValid]);

    const getCurrentFormValues = useCallback((): IEventEditorFormValues => {
        return {
            startTime,
            endTime,
            onsiteTime,
            yardTime,
            assignmentType,
            driver,
            driverId: driver?.id,
            broker,
            brokerId: broker?.id,
            equipment,
            equipmentId: equipment?.id,
            haulToAddressLine1: formHaulToAddressLine1,
            haulToAddressLine2: formHaulToAddressLine2,
            haulToCity: formHaulToCity,
            haulToState: formHaulToState,
            haulToZipCode: formHaulToZipCode,
            haulToCountry: formHaulToCountry,
            haulFromAddressLine1: formHaulFromAddressLine1,
            haulFromAddressLine2: formHaulFromAddressLine2,
            haulFromCity: formHaulFromCity,
            haulFromState: formHaulFromState,
            haulFromZipCode: formHaulFromZipCode,
            haulFromCountry: formHaulFromCountry,
            haulFromRegion: formHaulFromRegion,
            haulFromSiteName: formHaulFromSiteName,
            haulToSiteName: formHaulToSiteName,
            haulToRegion: formHaulToRegion,
            haulToRegionId: formHaulToRegion?.id,
            haulFromRegionId: formHaulToRegion?.id,
            driverMemo: formDriverMemo,
        };
    }, [
        assignmentType,
        broker,
        driver,
        endTime,
        equipment,
        formHaulToAddressLine1,
        formHaulToAddressLine2,
        formHaulToCity,
        formHaulToCountry,
        formHaulToState,
        formHaulToZipCode,
        formHaulFromAddressLine1,
        formHaulFromAddressLine2,
        formHaulFromCity,
        formHaulFromCountry,
        formHaulFromState,
        formHaulFromZipCode,
        formHaulFromRegion,
        formHaulFromSiteName,
        formHaulToSiteName,
        formHaulToRegion,
        onsiteTime,
        startTime,
        yardTime,
        formDriverMemo,
    ]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IEventEditorFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IEventEditorFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    const isFormDirty = useMemo(() => {
        const data = (initValues as any).data;
        var isDirty = false;
        if (startTime) {
            isDirty = isDirty || !areDateTimesEqual(startTime, data.startDate);
        }
        if (endTime) {
            isDirty = isDirty || !areDateTimesEqual(endTime, data.endDate);
        }
        if (onsiteTime) {
            isDirty = isDirty || !areDateTimesEqual(onsiteTime, data.onsiteTime);
        }
        if (yardTime) {
            isDirty = isDirty || !areDateTimesEqual(yardTime, data.yardTime);
        }
        isDirty = isDirty || assignmentType !== data.assignmentType;
        isDirty = isDirty || driver?.id !== data.driverId;
        isDirty = isDirty || broker?.id !== data.brokerId;
        isDirty = isDirty || equipment?.id !== data.equipmentId;
        isDirty = isDirty || formHaulToAddressLine1 !== data?.haulToAddressLine1;
        isDirty = isDirty || formHaulToAddressLine2 !== data?.haulToAddressLine2;
        isDirty = isDirty || formHaulToCity !== data?.haulToCity;
        isDirty = isDirty || formHaulToState !== data?.haulToState;
        isDirty = isDirty || formHaulToZipCode !== data?.haulToZipCode;
        isDirty = isDirty || formHaulToCountry !== data?.haulToCountry;
        isDirty = isDirty || formHaulToSiteName !== data?.haulToSiteName;
        isDirty = isDirty || formHaulToRegion !== data?.haulToRegion;
        isDirty = isDirty || formHaulFromAddressLine1 !== data?.haulFromAddressLine1;
        isDirty = isDirty || formHaulFromAddressLine2 !== data?.haulFromAddressLine2;
        isDirty = isDirty || formHaulFromCity !== data?.haulFromCity;
        isDirty = isDirty || formHaulFromState !== data?.haulFromState;
        isDirty = isDirty || formHaulFromZipCode !== data?.haulFromZipCode;
        isDirty = isDirty || formHaulFromCountry !== data?.haulFromCountry;
        isDirty = isDirty || formHaulFromSiteName !== data?.haulFromSiteName;
        isDirty = isDirty || formHaulFromRegion !== data?.haulFromRegion;
        isDirty = isDirty || formDriverMemo !== data?.driverMemo;
        return isDirty;
    }, [
        assignmentType,
        broker?.id,
        driver?.id,
        endTime,
        equipment?.id,
        formHaulToAddressLine1,
        formHaulToAddressLine2,
        formHaulToCity,
        formHaulToCountry,
        formHaulToState,
        formHaulToZipCode,
        formHaulFromAddressLine1,
        formHaulFromAddressLine2,
        formHaulFromCity,
        formHaulFromCountry,
        formHaulFromState,
        formHaulFromZipCode,
        formHaulFromRegion,
        formHaulFromSiteName,
        formHaulToSiteName,
        formHaulToRegion,
        initValues,
        onsiteTime,
        startTime,
        yardTime,
        formDriverMemo,
    ]);

    const handleHaulToAddressChange = useCallback(
        (address: { addressLine1: string; addressLine2?: string; country: string; city: string; state: string; zipCode: string }) => {
            setFormHaulToAddressLine1(address.addressLine1);
            setFormHaulToAddressLine2(address.addressLine2 ?? '');
            setFormHaulToCity(address.city);
            setFormHaulToCountry(address.country);
            setFormHaulToState(address.state);
            setFormHaulToZipCode(address.zipCode);
        },
        []
    );

    const handleHaulFromAddressChange = useCallback(
        (address: { addressLine1: string; addressLine2?: string; country: string; city: string; state: string; zipCode: string }) => {
            setFormHaulFromAddressLine1(address.addressLine1);
            setFormHaulFromAddressLine2(address.addressLine2 ?? '');
            setFormHaulFromCity(address.city);
            setFormHaulFromCountry(address.country);
            setFormHaulFromState(address.state);
            setFormHaulFromZipCode(address.zipCode);
        },
        [setFormHaulFromAddressLine2]
    );

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            if (initValues) {
                let data = {
                    startDate: startTime?.toISOString(),
                    endDate: endTime?.toISOString(),
                    onsiteTime: onsiteTime?.toISOString(),
                    yardTime: yardTime !== null ? yardTime?.toISOString() : undefined,
                    assignmentType,
                    driverId: driver?.id,
                    driver: driver ?? undefined,
                    brokerId: broker?.id,
                    broker: broker ?? undefined,
                    equipmentId: equipment?.id,
                    equipment: equipment ?? undefined,
                    eventColor: broker ? '#285C63' : '#A3271F',
                    resourceId: initValues.getData('resourceId'),
                    haulToAddressLine1: formHaulToAddressLine1,
                    haulToAddressLine2: formHaulToAddressLine2,
                    haulToCity: formHaulToCity,
                    haulToState: formHaulToState,
                    haulToZipCode: formHaulToZipCode,
                    haulToCountry: formHaulToCountry,
                    haulToSiteName: formHaulToSiteName,
                    haulToRegion: formHaulToRegion,
                    haulFromAddressLine1: formHaulFromAddressLine1,
                    haulFromAddressLine2: formHaulFromAddressLine2,
                    haulFromCity: formHaulFromCity,
                    haulFromState: formHaulFromState,
                    haulFromZipCode: formHaulFromZipCode,
                    haulFromCountry: formHaulFromCountry,
                    haulFromSiteName: formHaulFromSiteName,
                    haulFromRegion: formHaulFromRegion,
                    haulFromRegionId: formHaulFromRegion?.id,
                    haulToRegionId: formHaulToRegion?.id,
                    driverMemo: formDriverMemo,
                };

                // When on the driver/broker dispatch view, we need to manually handle the moving of the
                // dispatch between the different stores to properly trigger the onUpdate listeners.
                if (isHaulerView) {
                    if (driver) {
                        data.resourceId = driver.id;
                    } else if (broker) {
                        data.resourceId = broker.id;
                    }
                } else {
                    data.resourceId = initValues.getData('resourceId');
                }
                initValues.set(data);

                save(initValues);
            }
        }
    }, [
        validateForm,
        initValues,
        startTime,
        endTime,
        onsiteTime,
        yardTime,
        assignmentType,
        driver,
        broker,
        equipment,
        formHaulToAddressLine1,
        formHaulToAddressLine2,
        formHaulToCity,
        formHaulToState,
        formHaulToZipCode,
        formHaulToCountry,
        formHaulToSiteName,
        formHaulToRegion,
        formHaulFromAddressLine1,
        formHaulFromAddressLine2,
        formHaulFromCity,
        formHaulFromState,
        formHaulFromZipCode,
        formHaulFromCountry,
        formHaulFromSiteName,
        formHaulFromRegion,
        formDriverMemo,
        isHaulerView,
        save,
    ]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        startTime,
        endTime,
        onsiteTime,
        yardTime,
        assignmentType,
        driver,
        broker,
        equipment,
        handleHaulToAddressChange,
        handleHaulFromAddressChange,
        setFormDriverMemo,
        formDriverMemo,
        formHaulToAddressLine1,
        formHaulToAddressLine2,
        formHaulToCity,
        formHaulToState,
        formHaulToZipCode,
        formHaulToCountry,
        formHaulFromAddressLine1,
        formHaulFromAddressLine2,
        formHaulFromCity,
        formHaulFromState,
        formHaulFromZipCode,
        formHaulFromCountry,
        formHaulToRegion,
        formHaulFromRegion,
        formHaulToSiteName,
        formHaulFromSiteName,
        setFormHaulToSiteName,
        setFormHaulFromSiteName,
        setFormHaulToRegion,
        setFormHaulFromRegion,
        setStartTime,
        setEndTime,
        setOnsiteTime,
        setYardTime,
        setAssignmentType,
        setDriver,
        setBroker,
        setEquipment,
        validateField,
    };
}
