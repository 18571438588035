import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { EquipmentDto, EquipmentTypeDto } from '../../../dtos';
import { emptyGuid } from '../../../util';
import { IFormFieldValidationConfig, IFormProps, isNotBlank, isShorterThanMaxLength, runFieldValidation, runFormValidation } from '../../CoreLib/library';
import { useLazyGetEquipmentNumberByIdQuery } from '../../../store/generated/generatedApi';

export interface IEquipmentFormValues {
    number: string;
    equipmentTypeId: string;
    isActive: boolean;
    brokerId?: string;
    year?: number;
    make?: string;
    model?: string;
    vin?: string;
}

const DEFAULT_EQUIPMENT: EquipmentDto = {
    id: emptyGuid,
    number: '',
    equipmentTypeId: '',
    hasDriver: false,
    isActive: true,
    createdOn: new Date(),
    clientId: emptyGuid,
    make: '',
    model: '',
    vin: '',
};

export function useEquipmentForm(props: IFormProps<EquipmentDto>) {
    const { save, cancel, initValues } = props;
    const [formNumber, setFormNumber] = useState(initValues?.number ?? '');
    const [formEquipmentType, setFormEquipmentType] = useState(initValues?.equipmentType);
    const [formIsActive, setFormIsActive] = useState(initValues?.isActive ?? true);
    const [formBroker, setFormBroker] = useState(initValues?.broker);
    const [formYear, setFormYear] = useState(initValues?.year);
    const [formMake, setFormMake] = useState(initValues?.make);
    const [formModel, setFormModel] = useState(initValues?.model);
    const [formVin, setFormVin] = useState(initValues?.vin);
    const [isDuplicateNumber, setIsDuplicateNumber] = useState<boolean>();
    const [isChanged, setIsChanged] = useState(false);

    const [checkNumberAvailability, { data: unavailableNumber }] = useLazyGetEquipmentNumberByIdQuery({});

    useEffect(() => {
        setFormNumber(initValues?.number ?? '');
        setFormEquipmentType(initValues?.equipmentType);
        setFormIsActive(initValues?.isActive ?? true);
        setFormBroker(initValues?.broker);
        setFormYear(initValues?.year);
        setFormMake(initValues?.make);
        setFormModel(initValues?.model);
        setFormVin(initValues?.vin);
    }, [initValues]);

    const isNotADuplicateNumber = useCallback(() => {
        return (value: any) => {
            const isValid = value === initValues?.number || !isDuplicateNumber;
            const errorMessageBuilder = (_fieldName: string) => `This number is already in use`;
            return {
                isValid,
                errorMessageBuilder,
            };
        };
    }, [initValues?.number, isDuplicateNumber]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IEquipmentFormValues, string>>(
        new Map([
            ['number', ''],
            ['equipmentTypeId', ''],
            ['year', ''],
            ['make', ''],
            ['model', ''],
            ['vin', '']
        ])
    );

    const formFieldValidators = useMemo(
        () =>
            new Map<keyof IEquipmentFormValues, IFormFieldValidationConfig>([
                [
                    'number',
                    {
                        validators: [isNotBlank, isNotADuplicateNumber()],
                        errorMessageEntityName: 'Number',
                    },
                ],
                [
                    'equipmentTypeId',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Equipment Type',
                    },
                ],
                [
                    'make',
                    {
                        validators: [isShorterThanMaxLength(100)],
                        errorMessageEntityName: 'Make',
                    },
                ],
                [
                    'model',
                    {
                        validators: [isShorterThanMaxLength(100)],
                        errorMessageEntityName: 'Model',
                    },
                ],
                [
                    'vin',
                    {
                        validators: [isShorterThanMaxLength(100)],
                        errorMessageEntityName: 'VIN',
                    },
                ],
            ]),
        [isNotADuplicateNumber]
    );

    const getCurrentFormValues = useMemo(() => {
        return (): IEquipmentFormValues => {
            return {
                number: formNumber,
                equipmentTypeId: formEquipmentType?.id ?? emptyGuid,
                brokerId: formBroker?.id,
                isActive: formIsActive,
                year: formYear,
                make: formMake,
                model: formModel,
                vin: formVin,
            };
        };
    }, [formNumber, formEquipmentType?.id, formBroker?.id, formIsActive, formYear, formMake, formModel, formVin]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IEquipmentFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IEquipmentFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    useEffect(() => {
        if (unavailableNumber !== undefined && unavailableNumber !== null && formNumber !== initValues?.number) {
            setIsDuplicateNumber(unavailableNumber);
        } else {
            setIsDuplicateNumber(false);
        }
        validateField('number');
    }, [formNumber, initValues?.number, unavailableNumber, validateField, validateForm]);

    const isFormDirty = useCallback(() => {
        return isChanged;
    }, [isChanged]);

    const handleNumberChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.replaceAll(' ', '');
            setFormNumber(value);
            if (value !== initValues?.number) {
                checkNumberAvailability({ number: value });
            }
            setIsChanged(true);
        },
        [checkNumberAvailability, initValues?.number]
    );

    const handleYearChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        let value = Number(event.target.value);
        if (isNaN(value)) {
            setFormYear(undefined);
        }
        else {
            setFormYear(value);
        }
        setIsChanged(true);
    }, []);

    const handleMakeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormMake(event.target.value);
        setIsChanged(true);
    }, []);

    const handleModelChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormModel(event.target.value);
        setIsChanged(true);
    }, []);

    const handleVinChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormVin(event.target.value);
        setIsChanged(true);
    }, []);

    const handleEquipmentTypeChange = useCallback((value?: EquipmentTypeDto) => {
        setFormEquipmentType(value);
        setIsChanged(true);
    }, []);

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    }, []);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_EQUIPMENT),
                ...formValues,
            };
            if (!updatedUserRecord.year) {
                delete updatedUserRecord.year;
            }
            save(updatedUserRecord);
            setIsChanged(false);
        }
    }, [getCurrentFormValues, initValues, save, validateForm]);

    const handleCancel = useCallback(() => {
        cancel();
        setIsChanged(false);
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleNumberChange,
        handleYearChange,
        handleMakeChange,
        handleModelChange,
        handleVinChange,
        handleEquipmentTypeChange,
        handleIsActiveChange,
        formNumber,
        formYear,
        formMake,
        formModel,
        formVin,
        formEquipmentType,
        formIsActive,
        formBroker,
        setFormBroker,
        validateField,
    };
}
