import { FormControl, FormHelperText, FormLabel, MenuItem, Select, SelectChangeEvent, debounce } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { PricingMethodType } from '../../dtos';

export interface IPricingMethodTypeSelect {
    selectedPricingMethodType?: PricingMethodType;
    onChange: (selectedPricingMethodType?: PricingMethodType) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
}

export const PricingMethodTypeSelect: FC<IPricingMethodTypeSelect> = props => {
    const { selectedPricingMethodType, onChange, required, errorMessage, disabled } = props;
    const [fieldValue, setFieldValue] = useState(selectedPricingMethodType);

    useEffect(() => {
        setFieldValue(selectedPricingMethodType);
    }, [selectedPricingMethodType]);

    const handleSelectChange = useCallback((value: PricingMethodType | null) => {
        onChange(value ?? undefined);
    }, [onChange]);

    const debouncedHandleChange = useMemo(
        () => debounce((value: PricingMethodType | null) => {
            handleSelectChange(value);
        }, 300)
        , [handleSelectChange]);

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>Pricing Method</FormLabel>
            <Select
                value={fieldValue ?? ''}
                onChange={
                    (event: SelectChangeEvent<PricingMethodType>) => {
                        setFieldValue(event.target.value as PricingMethodType);
                        debouncedHandleChange(event.target.value as PricingMethodType);
                    }
                }
                disabled={disabled}
            >
                <MenuItem value={PricingMethodType.MaterialOnly}>
                    Material Only
                </MenuItem>
                <MenuItem value={PricingMethodType.HaulOnly}>
                    Haul Only
                </MenuItem>
                <MenuItem value={PricingMethodType.Standard}>
                    Standard
                </MenuItem>
            </Select>
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}