import {FC} from 'react';
import { LineItemDto, QuoteDto } from '../../../dtos';
import { ListAlt } from '@mui/icons-material';
import { Dialog, DialogTitle, Typography, DialogContent, DialogActions, Button, Divider, Box } from '@mui/material';
import { QuoteLineItemsOrderView } from '../../../Views';

export interface IAddOrderReviewLineItemDialogProps {
    open: boolean;
    onClose: () => void;
    quote: QuoteDto;
    onAddQuoteLineItem: (quoteLineItem: LineItemDto) => void;
    onAddCustomLineItem: () => void;
};

export const AddOrderReviewLineItemDialog: FC<IAddOrderReviewLineItemDialogProps> = ({
    open,
    onClose,
    quote,
    onAddQuoteLineItem,
    onAddCustomLineItem
}) => {
    return (
        <Dialog onClose={onClose} open={open} maxWidth='lg' fullWidth>
            <DialogTitle sx={{ backgroundColor: 'primary', color: '#ffffff' }}>
                <Typography variant='h2'>
                    <ListAlt /> Quote Price List
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <QuoteLineItemsOrderView
                    quote={quote}
                    copyQuoteLineItemToOrder={onAddQuoteLineItem}
                    maxHeight={'50vh'} // should be a string with 'px' or 'vh'
                />
                <Divider>
                    OR
                </Divider>
                <Box px={2}>
                    <Button variant='contained' onClick={onAddCustomLineItem} fullWidth>
                        Add Custom Line Item
                    </Button>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};