import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EquipmentDto } from '../../dtos';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetEquipmentByIdQuery, useUpdateEquipmentMutation } from '../../store/generated/generatedApi';
import { EquipmentForm } from '../../Components/Forms/EquipmentForm';

export const EditEquipmentView: FC = () => {
    const { equipmentId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetEquipmentByIdQuery({ id: equipmentId! });
    const [updateEquipment, { isSuccess, isError, reset }] = useUpdateEquipmentMutation();

    useSuccessfulUpdateSnackbar('Equipment', isSuccess, reset);
    useFailedUpdateSnackbar('equipment', isError, reset);

    const handleSave = (values: EquipmentDto) => {
        updateEquipment(values);
    };

    const handleCancel = () => {
        navigate('/dataManagement/equipment');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <EquipmentForm save={handleSave} cancel={handleCancel} initValues={data} />;
};
