import { FC, useEffect, useState } from "react";
import { ApiError, FormInput, FormSection, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from "../../Components/CoreLib/library";
import { Grid, Typography } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { SlimFormHeader } from "../../Components/Core/SlimFormHeader";
import { useArchiveMiscSettingsByIdMutation, useGetMiscSettingsByIdQuery, useUpdateMiscSettingsMutation } from "../../store/generated/generatedApi";
import { useNavigate } from "react-router-dom";

export const MiscSettings: FC = () => {
    const navigate = useNavigate();
    const DEFAULT_SETTINGS_ID = '86993401-6d3b-4499-bdec-4c86f1404567';
    const { data, isLoading, error, refetch } = useGetMiscSettingsByIdQuery({ id: DEFAULT_SETTINGS_ID });
    const [update, { isSuccess, isError, error: updateError, reset }] = useUpdateMiscSettingsMutation();

    useSuccessfulUpdateSnackbar('Settings', isSuccess, reset);
    useFailedUpdateSnackbar('Settings', isError, reset, updateError);

    const [quotePdfFooter, setQuotePdfFooter] = useState('');

    useEffect(() => {
        if (data) {
            setQuotePdfFooter(data?.quotePdfFooter);
        }
    }, [data]);

    const handleSave = () => {
        if (data) {
            update({ ...data, quotePdfFooter });
        }
    };

    const handleCancel = () => {
        navigate('/');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <SlimFormHeader
                objectName='Settings'
                permissionsTypeName={'miscSettings'}
                icon={<Settings />}
                isActive
                canDelete={false}
                deleteMutation={useArchiveMiscSettingsByIdMutation}
                title={'Settings'}
                breadcrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Admin', navLink: '/', isText: true },
                ]}
                id={undefined}
                isFormDirty={() => {
                    return data?.quotePdfFooter !== quotePdfFooter;
                }}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'miscSettings'}
                hideDividers
            />
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item>
                            <Typography variant='h2'>Quotes</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={12}>
                            <FormInput
                                value={quotePdfFooter}
                                onChange={(e) => {
                                    setQuotePdfFooter(e.target.value);
                                }}
                                label='Quote PDF Footer'
                                name='quotePdfFooter'
                                fullWidth
                                disabled={isLoading}
                                rows={10}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};