import { FC, ReactElement, useCallback, useContext, useMemo } from 'react';
import { StandardCardWithHeader, UserPermissionContext } from '../../../Components/CoreLib/library';
import { Box, Grid, Link, Typography } from '@mui/material';
import { Article } from '@mui/icons-material';
import { JobDto } from '../../../dtos';
import { useNavigate } from 'react-router-dom';

export interface IJobDetailsCardProps {
    job?: JobDto;
}

export const JobDetailsCard: FC<IJobDetailsCardProps> = (props) => {
    const { job } = props;
    const navigate = useNavigate();
    const { permissions } = useContext(UserPermissionContext);

    const currentQuote = useMemo(() => {
        return job?.currentQuote;
    }, [job?.currentQuote]);

    const cityStateZip = useMemo(() => {
        if (currentQuote) {
            return `${currentQuote?.city}, ${currentQuote?.state}, ${currentQuote?.zipCode}`;
        } else {
            return '';
        }
    }, [currentQuote]);

    const quoteTypeLabel = useMemo(() => {
        if (currentQuote) {
            const typeLabel = currentQuote?.type === 'Standard' ? '' : ' (FOB)';

            return `${currentQuote?.appraisalType ? 'Estimate' : 'Quote'}${typeLabel}`;
        } else {
            return '';
        }
    }, [currentQuote]);

    const gridItem = useCallback((content: ReactElement) => {
        return (
            <Grid
                item
                sx={{
                    '--Grid-borderWidth': '1px',
                    border: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                    borderCollapse: 'collapse',
                    flexGrow: '1',
                }}>
                <Box p={2}>{content}</Box>
            </Grid>
        );
    }, []);

    return (
        <StandardCardWithHeader headerTitle='Job' headerIcon={<Article />}>
            <Grid item container direction='row' p={2} alignItems='stretch'>
                <Grid item container direction='column' xs={2} justifyContent='space-evenly'>
                    {gridItem(
                        <Typography>
                            Current Quote #
                            <Link
                                href={`/quote/${currentQuote?.id}`}
                                variant='body2'
                                onClick={() => {
                                    navigate(`/quote/${currentQuote?.id}`);
                                }}>
                                {currentQuote?.quoteNumber}
                            </Link>
                        </Typography>
                    )}
                    {gridItem(<Typography>Type: {quoteTypeLabel}</Typography>)}
                    {gridItem(<Typography>Status: {currentQuote?.status}</Typography>)}
                </Grid>
                <Grid item container direction='column' xs={2} justifyContent='space-evenly'>
                    {gridItem(<Typography>PO Number: {currentQuote?.poNumber}</Typography>)}
                    {gridItem(
                        <Typography>
                            Customer:{' '}
                            {permissions.includes('edit:customer') ? (
                                <Link
                                    href={`/customer/${currentQuote?.customerId}`}
                                    variant='body2'
                                    onClick={() => {
                                        navigate(`/customer/${currentQuote?.customerId}`);
                                    }}>
                                    {currentQuote?.customer?.name}
                                </Link>
                            ) : (
                                <>{currentQuote?.customer?.name}</>
                            )}
                        </Typography>
                    )}
                    {gridItem(
                        <Typography>
                            Contact:{' '}
                            {permissions.includes('edit:contact') ? (
                                <Link
                                    href={`/contact/${currentQuote?.contactId}`}
                                    variant='body2'
                                    onClick={() => {
                                        navigate(`/contact/${currentQuote?.contactId}`);
                                    }}>
                                    {currentQuote?.contact?.contactName}
                                </Link>
                            ) : (
                                <>{currentQuote?.contact?.contactName}</>
                            )}
                        </Typography>
                    )}
                    {gridItem(<Typography>Salesperson: {currentQuote?.salesPerson?.displayName}</Typography>)}
                </Grid>
                <Grid item container direction='column' xs={2} justifyContent='space-evenly'>
                    {gridItem(<Typography>Landline: {currentQuote?.contact?.landlinePhone}</Typography>)}
                    {gridItem(<Typography>Cell: {currentQuote?.contact?.cellPhone}</Typography>)}
                    {gridItem(<Typography>Fax: {currentQuote?.contact?.fax}</Typography>)}
                </Grid>
                <Grid item container direction='column' xs={6}>
                    {gridItem(
                        <>
                            <Typography>Project Name:</Typography>
                            <Typography>{currentQuote?.projectName}</Typography>
                        </>
                    )}
                    {gridItem(
                        <>
                            <Typography>Project Address:</Typography>
                            <Typography>{currentQuote?.addressLine1}</Typography>
                            {currentQuote?.addressLine2 && <Typography>{currentQuote?.addressLine2}</Typography>}
                            <Typography>{cityStateZip}</Typography>
                            <Typography>{currentQuote?.country}</Typography>
                        </>
                    )}
                    {gridItem(
                        <>
                            <Typography>Quote Memo:</Typography>
                            <Typography>{currentQuote?.memo}</Typography>
                        </>
                    )}
                </Grid>
            </Grid>
        </StandardCardWithHeader>
    );
};
