import { FormControl, FormHelperText, FormLabel, MenuItem, Select, SelectChangeEvent, debounce } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { OrderByType } from '../../dtos/generated/OrderByType';

export interface IOrderBySelectProps {
    selectedOrderBy?: OrderByType;
    onChange: (selectedOrderBy?: OrderByType) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
}

export const OrderBySelect: FC<IOrderBySelectProps> = props => {
    const { selectedOrderBy, onChange, required, errorMessage, disabled } = props;
    const [fieldValue, setFieldValue] = useState(selectedOrderBy);

    useEffect(() => {
        setFieldValue(selectedOrderBy);
    }, [selectedOrderBy]);

    const handleSelectChange = useCallback((value: OrderByType | null) => {
        onChange(value ?? undefined);
    }, [onChange]);

    const debouncedHandleChange = useMemo(
        () => debounce((value: OrderByType | null) => {
            handleSelectChange(value);
        }, 300)
        , [handleSelectChange]);

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>Order By</FormLabel>
            <Select
                value={fieldValue ?? ''}
                onChange={
                    (event: SelectChangeEvent<OrderByType>) => {
                        setFieldValue(event.target.value as OrderByType);
                        debouncedHandleChange(event.target.value as OrderByType);
                    }
                }
                disabled={disabled}
            >
                <MenuItem value={OrderByType.Equipment}>
                    Equipment
                </MenuItem>
                <MenuItem value={OrderByType.Quantity}>
                    Quantity
                </MenuItem>
                <MenuItem value={OrderByType.Loads}>
                    Loads
                </MenuItem>
            </Select>
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}