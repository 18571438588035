
type RouteProps = SearchQueryParams;

function createKeys(keyRecord: Record<keyof RouteProps, any>): (keyof RouteProps)[] {
    return Object.keys(keyRecord) as any;
}

export const QueryRouteProps = createKeys({
    searchText: undefined,
    sortKey: undefined,
    sortAsc: undefined,
    page: undefined,
    pageSize: undefined,
    includeInactive: undefined,
});

export interface SearchQueryParams {
    searchText?: string;
    sortKey?: string;
    sortAsc?: boolean;
    page?: number;
    pageSize?: number;
    includeInactive?: boolean;
}
