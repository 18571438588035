import { emptyGuid } from "./constants";

export function getUniqueKey(obj: IdentifiableObject) {
    if (!obj) {
        return undefined;
    }

    if (obj.id === '' || obj.id === emptyGuid) {
        if (typeof obj.createdOn === 'string') {
            return Date.parse(obj.createdOn);
        } else {
            return obj.createdOn.getTime();
        }
    }
    
    return obj.id;
}

export interface IdentifiableObject {
    id: string;
    createdOn: Date | string;
}