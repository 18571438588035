import { DateHelper } from "@bryntum/scheduler";
import { Done } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { FC, useMemo } from "react";

export const DriverManagementEventBar: FC<any> = (props: { key: any, eventRecord: any, resourceRecord: any }) => {
    const { startDate, endDate, data } = props.eventRecord;

    const description = useMemo(() => {
        return `${data?.orderLineItem?.lineItem?.description} (LI #${data?.orderLineItem?.orderLineItemNumber} - Order #${data?.orderLineItem?.orderNumber})`;
    }, [data?.orderLineItem?.lineItem?.description, data?.orderLineItem?.orderLineItemNumber, data?.orderLineItem?.orderNumber]);

    const jobName = useMemo(() => {
        return data?.orderLineItem?.order?.quote?.jobName;
    }, [data?.orderLineItem?.order?.quote?.jobName]);

    const line2 = useMemo(() => {
        let value = '';
        if (jobName) {
            value += `${jobName} - `;
        }
        value += `${data?.orderLineItem?.order?.quote?.customer?.name} (Job #${data?.orderLineItem?.order?.job?.jobNumber ?? '???'})`;
        return value;
    }, [data?.orderLineItem?.order?.job?.jobNumber, data?.orderLineItem?.order?.quote?.customer?.name, jobName]);

    const isCompleted = useMemo(() => {
        return data.isCompleted;
    }, [data.isCompleted])

    const eventBar = useMemo(() => {
        return (
            <div>
                <Grid item container spacing={1} alignItems='center' wrap='nowrap'>
                    {isCompleted && <Grid item xs={2}>
                        <Done />
                    </Grid>}
                    <Grid item container direction='column' xs={isCompleted ? 10 : 12}>
                        <Grid item container alignItems='center' justifyContent='space-between'>
                            <Grid item>
                                <span>
                                    {description}
                                </span>
                            </Grid>
                            <Grid item>
                                <span>{DateHelper.formatRange([new Date(startDate), new Date(endDate)], 'S{LT} - E{LT}')}</span>
                            </Grid>
                        </Grid>
                        {line2 && <Grid item>
                            <span>{line2}</span>
                        </Grid>}
                    </Grid>
                </Grid>
            </div>
        );
    }, [description, endDate, line2, startDate, isCompleted]);

    return (
        <>{eventBar}</>
    );
};