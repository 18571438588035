import { Model } from '@bryntum/scheduler';

export default class HaulerModel extends Model {
    static get $name() {
        return 'HaulerModel';
    }

    static get fields() {
        return ['id', 'code', 'name', 'equipmentId', 'equipment', 'assignedEquipment', 'hireDate', 'isScheduled', 'haulerType'];
    }

    static get defaults() {
        return {
            durationUnit: 'h',

            name: 'New event',
        };
    }
}
