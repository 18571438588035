import { isDateGreaterThanOrEqual } from '../../util';

export const isNotOutOfDate = (date: Date) => {
    return isDateGreaterThanOrEqual(date, new Date());
};

export const getToolTipData = (date: Date, data: any, eventRecord?: any) => {
    let toolTip = `<table class="tooltip-table">
                        `;

    const convertMinsToHrsMins = (mins: number) => {
        let h = Math.floor(mins / 60);
        let m = mins % 60;
        let hours = h < 10 ? '0' + h : h;
        let minutes = m < 10 ? '0' + m : m;
        return `${hours}:${minutes}`;
    };

    let dispatchingIntervalTime = '';
    if (data?.orderLineItem?.dispatchingIntervalTime) {
        dispatchingIntervalTime = convertMinsToHrsMins(data.orderLineItem.dispatchingIntervalTime!);
    }

    if (eventRecord) {
        let status = '';
        if (eventRecord?.isOrderInReview) {
            status = 'In Review';
        } else if (eventRecord?.isCompleted) {
            status = 'Complete';
        } else {
            status = eventRecord?.released ? 'Released' : 'Unreleased';
        }
        toolTip = toolTip.concat(`
            <tr>
                <td class="tooltip-cell" colspan="2">Status: ${status}</td>
            </tr>
            `);
    }
    toolTip = toolTip.concat(`
                            <tr>
                                <td class="tooltip-cell">Customer: ${data?.orderLineItem?.order?.job?.customerName ?? ''}</td>
                                <td class="tooltip-cell">Line Item #: ${data?.orderLineItem?.orderLineItemNumber}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell" colspan="2">Orderer: ${data?.orderLineItem?.order?.orderer ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell" colspan="2">Project Name: ${data?.orderLineItem?.projectName ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell" colspan="2">Job Name: ${data?.orderLineItem?.jobName ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell">Job #: ${data?.orderLineItem?.order?.job?.jobNumber ?? ''}</td>
                                <td class="tooltip-cell">Order #: ${data?.orderLineItem?.order?.orderNumber ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell">Description: ${data?.orderLineItem?.lineItem?.description ?? ''}</td>
                                <td class="tooltip-cell">Equipment Type: ${data?.orderLineItem?.equipmentType?.type ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell">Year: ${data?.equipment?.year ?? ''}</td>
                                <td class="tooltip-cell">Make/Model: ${data?.equipment?.make ?? ''} ${data?.equipment?.model ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell" colspan="2">VIN: ${data?.equipment?.vin ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell" colspan="2">Requirements: ${data?.orderLineItem?.requirements ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell">Onsite Time: ${data?.onsiteTime ? new Date(data?.onsiteTime).toLocaleTimeString() : 'ASAP'}</td>
                                <td class="tooltip-cell">Yard Time: ${data?.yardTime ? new Date(data?.yardTime).toLocaleTimeString() : 'ASAP'}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell">Interval Time: ${dispatchingIntervalTime}</td>
                            </tr>
                            <tr>
                            <td class="tooltip-cell" colspan="2">
                                    Haul To: ${data?.haulToAddressDispatchCard}
                                </td>

                            </tr>
                             <tr>

                                <td class="tooltip-cell" colspan="2">
                                    Haul From: ${data?.haulFromAddressDispatchCard}
                                </td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell" colspan="2">Internal Memo: ${data?.orderLineItem?.memo ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell" colspan="2">Dispatch Memo: ${data?.orderLineItem?.dispatchMemo ?? ''}</td>
                            </tr>
                            <tr>
                                <td class="tooltip-cell" colspan="2">Driver Memo: ${data?.orderLineItem?.driverMemo ?? ''}</td>
                            </tr>
                    </table>`);
    return toolTip;
};
