import { useAuth0 } from "@auth0/auth0-react"
import { FC, useMemo } from "react";
import { Dispatches } from "./Dispatches";
import WebhookConnector from '../../Components/Webhook/WebhookConnector';

export const DispatchWrapper: FC = () => {
    const { user } = useAuth0();

    const webhookConnector = useMemo(() => WebhookConnector(user?.sub ?? ''), [user?.sub]);

    return <Dispatches webhookConnector={webhookConnector} />;
}