import { DateHelper } from "@bryntum/scheduler";
import { Done } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { FC, useMemo } from "react";

export const OrderManagementEventBar: FC<any> = (props: { key: any, eventRecord: any, resourceRecord: any }) => {
    const { startDate, endDate, data } = props.eventRecord;

    const name = useMemo(() => {
        return data.name;
    }, [data]);

    const equipmentType = useMemo(() => {
        return data?.equipment ? `${data?.equipment?.equipmentType?.type ?? ''} #${data?.equipment?.number ?? ''}` : '';
    }, [data?.equipment]);

    const isCompleted = useMemo(() => {
        return data.isCompleted;
    }, [data.isCompleted])

    const eventBar = useMemo(() => {
        return (
            <div>
                <Grid item container spacing={1} alignItems='center' wrap='nowrap'>
                    {isCompleted && <Grid item xs={2}>
                        <Done />
                    </Grid>}
                    <Grid item container direction='column' xs={isCompleted ? 10 : 12}>
                        <Grid item container alignItems='center' justifyContent='space-between'>
                            <Grid item display='flex' alignItems='center'>
                                {name}
                            </Grid>
                            <Grid item>
                                <span>{DateHelper.formatRange([new Date(startDate), new Date(endDate)], 'S{LT} - E{LT}')}</span>
                            </Grid>
                        </Grid>
                        {equipmentType && <Grid item>
                            <span>{equipmentType}</span>
                        </Grid>}
                    </Grid>
                </Grid>
            </div>
        );
    }, [endDate, equipmentType, name, startDate, isCompleted]);

    return (
        <>{eventBar}</>
    );
};