import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { BrokerDto } from '../../../dtos';
import { countries, emptyGuid } from '../../../util';
import { IFormFieldValidationConfig, IFormProps, isNotBlank, isShorterThanMaxLength, runFieldValidation, runFormValidation } from '../../CoreLib/library';
import { useLazyGetBrokerCodeByIdQuery } from '../../../store/generated/generatedApi';

export interface IBrokerFormValues {
    code: string;
    name: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    isActive: boolean;
    payRate?: number;
}

const DEFAULT_BROKER: BrokerDto = {
    id: emptyGuid,
    code: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: countries[2], // US
    isActive: true,
    createdOn: new Date(),
    clientId: emptyGuid,
    payRate: undefined,
};

export function useBrokerForm(props: IFormProps<BrokerDto>) {
    const { save, cancel, initValues } = props;
    const [formCode, setFormCode] = useState('');
    const [formName, setFormName] = useState('');
    const [formAddressLine1, setFormAddressLine1] = useState('');
    const [formAddressLine2, setFormAddressLine2] = useState('');
    const [formCity, setFormCity] = useState('');
    const [formState, setFormState] = useState('');
    const [formZipCode, setFormZipCode] = useState('');
    const [formCountry, setFormCountry] = useState(countries[2]);
    const [formIsActive, setFormIsActive] = useState(true);
    const [isDuplicateCode, setIsDuplicateCode] = useState<boolean>();
    const [formPayRate, setFormPayRate] = useState<number | undefined>(100);
    const [isChanged, setIsChanged] = useState(false);

    const [checkCodeAvailability, { data: unavailableCode }] = useLazyGetBrokerCodeByIdQuery({});

    useEffect(() => {
        setFormCode(initValues?.code ?? '');
        setFormName(initValues?.name ?? '');
        setFormAddressLine1(initValues?.addressLine1 ?? '');
        setFormAddressLine2(initValues?.addressLine2 ?? '');
        setFormCity(initValues?.city ?? '');
        setFormState(initValues?.state ?? '');
        setFormZipCode(initValues?.zipCode ?? '');
        setFormCountry(initValues?.country ?? countries[2]);
        setFormIsActive(initValues?.isActive ?? true);
        setFormPayRate(initValues?.payRate ? initValues.payRate * 100 : 100);
    }, [initValues]);

    const isNotADuplicateCode = useCallback(() => {
        return (value: any) => {
            const isValid = value === initValues?.code || !isDuplicateCode;
            const errorMessageBuilder = (_fieldName: string) => `This code is already in use`;
            return {
                isValid,
                errorMessageBuilder,
            };
        };
    }, [initValues?.code, isDuplicateCode]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IBrokerFormValues, string>>(
        new Map([
            ['code', ''],
            ['name', ''],
            ['addressLine1', ''],
            ['city', ''],
            ['state', ''],
            ['zipCode', ''],
            ['country', ''],
            ['payRate', ''],
        ])
    );

    const formFieldValidators = useMemo(
        () =>
            new Map<keyof IBrokerFormValues, IFormFieldValidationConfig>([
                [
                    'code',
                    {
                        validators: [isNotBlank, isNotADuplicateCode()],
                        errorMessageEntityName: 'Code',
                    },
                ],
                [
                    'name',
                    {
                        validators: [isNotBlank, isShorterThanMaxLength(100)],
                        errorMessageEntityName: 'Name',
                    },
                ],
                [
                    'addressLine1',
                    {
                        validators: [isNotBlank, isShorterThanMaxLength(100)],
                        errorMessageEntityName: 'Address Line 1',
                    },
                ],
                [
                    'city',
                    {
                        validators: [isNotBlank, isShorterThanMaxLength(60)],
                        errorMessageEntityName: 'City',
                    },
                ],
                [
                    'state',
                    {
                        validators: [isNotBlank, isShorterThanMaxLength(25)],
                        errorMessageEntityName: 'State',
                    },
                ],
                [
                    'zipCode',
                    {
                        validators: [isNotBlank, isShorterThanMaxLength(12)],
                        errorMessageEntityName: 'Zip Code',
                    },
                ],
                [
                    'country',
                    {
                        validators: [isNotBlank, isShorterThanMaxLength(60)],
                        errorMessageEntityName: 'Country',
                    },
                ],
                [
                    'payRate',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Pay Rate',
                    },
                ],
            ]),
        [isNotADuplicateCode]
    );

    const getCurrentFormValues = useMemo(() => {
        return (): IBrokerFormValues => {
            return {
                code: formCode,
                name: formName,
                addressLine1: formAddressLine1,
                addressLine2: formAddressLine2,
                city: formCity,
                state: formState,
                zipCode: formZipCode,
                country: formCountry,
                isActive: formIsActive,
                payRate: formPayRate ? formPayRate / 100 : undefined,
            };
        };
    }, [formCode, formName, formAddressLine1, formAddressLine2, formCity, formState, formZipCode, formCountry, formIsActive, formPayRate]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IBrokerFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IBrokerFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    useEffect(() => {
        if (unavailableCode !== undefined && unavailableCode !== null && formCode !== initValues?.code) {
            setIsDuplicateCode(unavailableCode);
        } else {
            setIsDuplicateCode(false);
        }
        validateField('code');
    }, [formCode, initValues?.code, unavailableCode, validateField, validateForm]);

    const isFormDirty = () => {
        return isChanged;
    };

    const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replaceAll(' ', '');
        setFormCode(value);
        if (value !== initValues?.code) {
            checkCodeAvailability({ code: value });
        }
        setIsChanged(true);
    };

    const handleNameChange = (value: string) => {
        setFormName(value);
        setIsChanged(true);
    };

    const handleAddressLine1Change = (value: string) => {
        setFormAddressLine1(value);
        setIsChanged(true);
    };

    const handleAddressLine2Change = (value: string) => {
        setFormAddressLine2(value);
        setIsChanged(true);
    };

    const handleCityChange = (value: string) => {
        setFormCity(value);
        setIsChanged(true);
    };

    const handleStateChange = (value: string) => {
        setFormState(value);
        setIsChanged(true);
    };

    const handleZipCodeChange = (value: string) => {
        setFormZipCode(value);
        setIsChanged(true);
    };

    const handleCountryChange = (value: string) => {
        setFormCountry(value);
        setIsChanged(true);
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    };

    const handlePayRateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber;
        setFormPayRate(value);
        setIsChanged(true);
    }, []);

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_BROKER),
                ...formValues,
            };
            save(updatedUserRecord);
            setIsChanged(false);
        }
    };

    const handleCancel = () => {
        cancel();
        setIsChanged(false);
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleCodeChange,
        handleNameChange,
        handleAddressLine1Change,
        handleAddressLine2Change,
        handleCityChange,
        handleStateChange,
        handleZipCodeChange,
        handleCountryChange,
        handleIsActiveChange,
        handlePayRateChange,
        formCode,
        formName,
        formAddressLine1,
        formAddressLine2,
        formCity,
        formState,
        formZipCode,
        formCountry,
        formIsActive,
        formPayRate,
        validateField,
    };
}
