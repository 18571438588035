import { Grid, debounce } from '@mui/material';
import { FC, useCallback } from 'react';
import { DriverDto } from '../../../dtos';
import { IFormProps, LoadingIndicator, FormSection, FormInput, FormNumberInput } from '../../CoreLib/library';
import { useDriverForm } from './useDriverForm';
import { AddressForm, DatePicker, EquipmentAutocomplete } from '../../CommonInputs';

export interface IDriverFormProps extends IFormProps<DriverDto> {
	setFormDriverValid: (isValid: boolean) => void;
	currentValues?: DriverDto;
}

export const DriverForm: FC<IDriverFormProps> = (props) => {
	const { isLoading, currentValues } = props;
	const {
		fieldErrors,
		handleCodeChange,
		handleHireDateChange,
		handleTerminationDateChange,
		handleEquipmentChange,
		handlePayRateChange,
		formCode,
		formEquipment,
		formHireDate,
		formTerminationDate,
		formPayRate,
		handleAddressChange,
		formAddressLine1,
		formAddressLine2,
		formCity,
		formState,
		formZipCode,
		formCountry
	} = useDriverForm(props);

	const debouncedHandleCodeChange = useCallback(
		() => debounce((event: React.ChangeEvent<HTMLInputElement>) => {
			handleCodeChange(event);
		}, 300)
		, [handleCodeChange]);

	if (isLoading) {
		return <LoadingIndicator />;
	}

	return (
		<Grid container direction='column' spacing={3}>
			<Grid item>
				<FormSection>
					<Grid item container direction='row' spacing={3}>
						<Grid item xs={12} sm={6} md={3}>
							<FormInput
								value={formCode}
								onChange={(event) => {
									debouncedHandleCodeChange()(event);
								}}
								label='Code'
								name='code'
								errorText={fieldErrors.get('code')}
								fullWidth
								required
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<EquipmentAutocomplete
								initialEquipment={currentValues?.equipment}
								formEquipment={formEquipment}
								errorMessage={fieldErrors.get('equipmentId')}
								handleEquipmentChange={handleEquipmentChange}
							/>
						</Grid>
					</Grid>
					<Grid item container direction='row' spacing={3}>
						<Grid item xs={12} sm={6} md={3}>
							<DatePicker
								label='Hire Date'
								value={formHireDate}
								onChange={handleHireDateChange}
								error={!!fieldErrors.get('hireDate')}
								errorText={fieldErrors.get('hireDate')}
								required
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<DatePicker
								label='Termination Date'
								value={formTerminationDate}
								onChange={handleTerminationDateChange}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormNumberInput
								value={formPayRate}
								onChange={handlePayRateChange}
								label='Pay Rate (%)'
								name='payRate'
								fullWidth
								inputProps={{ min: 0, max: 100, step: 1.0 }}
								required
								errorText={fieldErrors.get('payRate')}
								error={!!fieldErrors.get('payRate')}
							/>
						</Grid>
					</Grid>
					<Grid item container direction='row' spacing={3}>
						<AddressForm
							handleAddressChange={handleAddressChange}
							enableLookup
							addressLine1={formAddressLine1}
							addressLine2={formAddressLine2}
							country={formCountry}
							city={formCity}
							state={formState}
							zipCode={formZipCode}
							fieldErrors={fieldErrors}
							allOptional
						/>
					</Grid>
				</FormSection>
			</Grid>
		</Grid>
	);
};
