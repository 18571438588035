import { useSnackbar } from 'notistack';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { InputPriceListForm } from '../../Components/Forms/InputPriceListForm';
import { PriceListDto } from '../../dtos';
import { useCreatePriceListMutation } from '../../store/generated/generatedApi';

export const InputPriceListView: FC = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [inputPriceList, { data: createResponse, isSuccess, isError, error: updateError, reset }] = useCreatePriceListMutation();

    useSuccessfulUpdateSnackbar('Price List', isSuccess, () => {
        reset();
        if ((createResponse?.numFailed ?? 0) > 0) {
            // This is the only reason there SHOULD be failed responses but this is also an assumption that nothing else went wrong.
            enqueueSnackbar(`${createResponse?.numFailed} Material Prices were not entered due to conflicting entries.`, { variant: 'warning' })
        }
        navigate('/dataManagement/materialPrices');
    });
    useFailedUpdateSnackbar('Price List', isError, reset, updateError);

    const handleSave = (values: PriceListDto) => {
        inputPriceList(values);
    };

    const handleCancel = () => {
        navigate('/dataManagement/materialPrices');
    };

    return <InputPriceListForm save={handleSave} cancel={handleCancel} />;
};
