import { BryntumGrid, BryntumGridProps, BryntumScheduler } from '@bryntum/scheduler-react';
import { MutableRefObject, FC, useState, useEffect, useRef } from 'react';
import { isDateGreaterThanOrEqual } from '../../../../util';
import OrderDragElement from '../../Components/OrderDragElement';
import OrderLineItemModel from '../../Stores/Models/OrderLineItemModel';

interface Props {
    schedulerRef: MutableRefObject<BryntumScheduler>;
    config: BryntumGridProps;
    dataList: any[];
    disabled?: boolean;
}

export const OrderLineItemGrid: FC<Props> = (props) => {
    const {
        schedulerRef,
        config,
        dataList,
        disabled
    } = props;

    const gridRef = useRef<BryntumGrid>() as MutableRefObject<BryntumGrid>;

    const [store] = useState({
        modelClass: OrderLineItemModel,
        autoLoad: true,
    });

    useEffect(() => {
        const drag = new OrderDragElement({
            grid: gridRef.current.instance,
            schedule: schedulerRef.current.instance,
            constrain: true,
            outerElement: gridRef.current.instance.element,
            eventColor: '#A3271F',
            listeners: {
                beforeDragStart({ source }) {
                    if (disabled) {
                        return false;
                    }
                    return isDateGreaterThanOrEqual(source.schedule.startDate, new Date());
                },
            },
        });
        return () => {
            drag.destroy();
        };
    }, [schedulerRef, gridRef, disabled]);

    return <BryntumGrid ref={gridRef} store={store} data={dataList} {...config} />;
};
