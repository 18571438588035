import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Box, Button, Divider, Grid, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import logo from '../../../src/logoHorizontal.png';
import { useLazyGetUserAuth0UserIdByIdQuery } from '../../store/generated/generatedApi';
import { NotAuthorizedError } from '../Core/NotAuthorizedError';
import { UserAvatar, LoadingIndicator } from '../CoreLib/library';

export interface IMobileShellProps {
    isProtected?: boolean;
}

const MobileShell: FC<IMobileShellProps> = (props) => {
    const { isProtected } = props;
    const navigate = useNavigate();
    const { user, isAuthenticated, loginWithRedirect, logout, isLoading: isAuthLoading } = useAuth0();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const profileMenuOpen = Boolean(anchorEl);
    const [getUserData, { data: userData, isLoading: isLoadingUser, error: userError }] = useLazyGetUserAuth0UserIdByIdQuery();

    useEffect(() => {
        if (isAuthenticated) {
            if (user?.sub) {
                getUserData({
                    id: user?.sub
                });
            }
        }
    }, [user, getUserData, isAuthenticated, navigate]);

    const handleLogout = useCallback(() => {
        setAnchorEl(null);
        logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        });
    }, [logout]);

    const handleProfileMenuOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleProfileMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    if (isAuthLoading || isLoadingUser) {
        return <LoadingIndicator />;
    }

    if (isProtected) {
        if (!isAuthenticated) {
            localStorage.setItem('redirectUrl', window.location.pathname + window.location.search);
            loginWithRedirect();
            return <LoadingIndicator />;
        } else if (userError) {
            return <NotAuthorizedError
                children={
                    <Grid item>
                        <Button variant='contained' color='secondary' onClick={() => {
                            logout({
                                logoutParams: {
                                    returnTo: window.location.origin
                                }
                            });
                        }}>Logout</Button>
                    </Grid>
                } />;
        }
    }

    return (
        <Box sx={{ height: '100vh' }}>
            <AppBar position='sticky' elevation={0} sx={{ padding: '0px' }}>
                <Toolbar variant='dense'>
                    <Grid item sx={{ marginY: 1, marginX: 0, paddingX: 0 }}>
                        <a href='/'>
                            <img src={logo} alt='TRIAX Logo' height={'100%'} width={'244'} />
                        </a>
                    </Grid>
                    {isAuthenticated && (
                        <Grid container direction='row' justifyContent='end'>
                            <div style={{ cursor: 'pointer' }} onClick={handleProfileMenuOpen}>
                                <UserAvatar name={user?.name} />
                            </div>
                            <Menu
                                anchorEl={anchorEl}
                                open={profileMenuOpen}
                                onClose={handleProfileMenuClose}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                PaperProps={{
                                    sx: {
                                        mt: 1.5,
                                        border: 'solid 1px #AAAAAA',
                                        borderRadius: '10px',
                                        padding: '8px',
                                        minWidth: '240px',
                                    },
                                }}>
                                <Grid container direction='column' alignItems='center'>
                                    <Grid item container direction='row' justifyContent='center'>
                                        <UserAvatar name={user?.name} />
                                    </Grid>
                                    <Grid item container direction='row' justifyContent='center'>
                                        <Typography sx={{ fontSize: '18px', marginTop: '12px' }}>
                                            {userData ? userData?.displayName : user?.email}
                                        </Typography>
                                    </Grid>
                                    <Grid item container direction='row' justifyContent='center'>
                                        <Typography sx={{ fontSize: '14px', marginBottom: '12px', paddingLeft: '8px', paddingRight: '8px' }} noWrap>
                                            {user?.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{ marginBottom: '12px' }} />
                                <MenuItem onClick={handleLogout} sx={{ fontSize: '14px' }}>
                                    Sign Out
                                </MenuItem>
                            </Menu>
                        </Grid>
                    )}
                </Toolbar>
            </AppBar>
            {isAuthLoading || !isAuthenticated ? <LoadingIndicator /> : <Outlet />}
            {/* I am leaving this here so we can use it for testing if needed but it also blocks the navigation tabs so it is commented out by default */}
            {/* <AuthenticatedComponent
                requiredPermissions={['read:permissionToggle']}
                component={<PermissionToggler getPermissionsQuery={useLazyGetUserPermissionsQuery} />}
            /> */}
        </Box>
    );
};

export default MobileShell;
