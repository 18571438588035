import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError, LoadingIndicator, useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../../Components/CoreLib/library';
import { DumpRateForm } from '../../../Components/Forms/DumpRateForm';
import { DumpRateDto } from '../../../dtos';
import { useCreateDumpRateMutation, useGetSiteByIdQuery } from '../../../store/generated/generatedApi';
import { emptyGuid } from '../../../util';

export const AddDumpRateView: FC = () => {
    const navigate = useNavigate();
    const { siteId } = useParams();
    const { data, isLoading, error, refetch } = useGetSiteByIdQuery({ id: siteId! });
    const [createDumpRate, { isError, isSuccess, reset }] = useCreateDumpRateMutation();

    const redirectUrl = useMemo(() => `/dataManagement/site/${siteId}/dumpRates`, [siteId]);

    useSuccessfulCreateSnackbar('Dump Rate', isSuccess, () => navigate(redirectUrl));
    useFailedCreateSnackbar('dump rate', isError, reset)

    const handleSave = (values: DumpRateDto) => {
        createDumpRate(values);
    };

    const handleCancel = () => {
        navigate(redirectUrl);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <DumpRateForm save={handleSave} cancel={handleCancel} siteId={siteId ?? emptyGuid} siteName={data?.name ?? ''} />;
};

