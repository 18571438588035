import { Inventory, Payments } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { MaterialDto } from '../../dtos';
import {
    useArchiveMaterialBulkDeleteMutation,
    useGetMaterialQuery,
    useMaterialBulkArchiveMutation,
    useMaterialBulkUnarchiveMutation,
} from '../../store/generated/generatedApi';
import { usePageTitleSetter } from '../../util';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'CODE',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const MaterialListView: FC = () => {
    const navigate = useNavigate();
    usePageTitleSetter('Materials');

    const navigateToMaterialPricePage = useCallback((material: MaterialDto) => {
		const { id, code } = material;
		window.open(`${window.origin}/dataManagement/materialPrices?materialId=${id}&materialName=${code}`, '_blank');
	}, []);

	const renderViewMaterialPriceButton = useCallback((material: MaterialDto) => {

        const handleClick: MouseEventHandler = (e) => {
            e.stopPropagation();
            navigateToMaterialPricePage(material);
        }

		return (
			<Tooltip title='View Material Prices'>
				<Box className='hover-content'>
					<IconButton onClick={handleClick}>
						<Payments />
					</IconButton>
				</Box>
			</Tooltip>
		)
	}, [navigateToMaterialPricePage]);

    const tableColumns: DataTableColumn<MaterialDto>[] = useMemo(() => [
        { key: 'code', label: 'Product Code', sortKey: 'CODE' },
        { key: 'name', label: 'Name', sortKey: 'NAME' },
        { key: '', label: '', sortKey: '', width: '64px', fieldMapper: renderViewMaterialPriceButton}
    ], [renderViewMaterialPriceButton]);

    const handleCreateNew = () => {
        navigate('/dataManagement/material/create');
    };

    const handleTableEdit = (id: string) => {
        navigate(`/dataManagement/material/${id}`);
    };

    return (
        <StandardListViewV2
            headerIcon={<Inventory />}
            headerTitle='Materials'
            breadCrumbs={[
                { label: 'Home', navLink: '/' },
                { label: 'Data Management', navLink: '/dataManagement' },
            ]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetMaterialQuery}
            isShowActiveToggleVisible
            inactivateMutation={useMaterialBulkArchiveMutation}
            activateMutation={useMaterialBulkUnarchiveMutation}
            deleteMutation={useArchiveMaterialBulkDeleteMutation}
            tableColumns={tableColumns}
            entityNameSingular='Material'
            permissionName='material'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
        />
    );
};
