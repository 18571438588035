import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSuccessfulCreateSnackbar, useFailedCreateSnackbar, LoadingIndicator } from '../../../../Components/CoreLib/library';
import { DumpLineItemDto } from '../../../../dtos';
import { useCreateDumpLineItemMutation, useLazyGetOrderByIdQuery, useLazyGetQuoteByIdQuery } from '../../../../store/generated/generatedApi';
import { DumpLineItemForm } from '../../../../Components/Forms/QuoteForm/LineItemForms/DumpLineItem/DumpLineItemForm';
import { DEFAULT_DUMP_LINE_ITEM } from '../../../../Components/Forms/QuoteForm/LineItemForms/DumpLineItem/useDumpLineItemForm';

export const AddDumpLineItemView: FC = () => {
    const { quoteId, jobId, orderId } = useParams();
    const navigate = useNavigate();
    const [getQuote, { data: quote, isLoading: isQuoteLoading }] = useLazyGetQuoteByIdQuery();
    const [createDumpLineItem, { isError, isSuccess, reset }] = useCreateDumpLineItemMutation();
    const [getOrder, { data: order, isLoading: isOrderLoading }] = useLazyGetOrderByIdQuery();

    useEffect(() => {
        if (quoteId && !quote) {
            getQuote({ id: quoteId! });
        }
    }, [getQuote, quote, quoteId]);

    useEffect(() => {
        if (orderId && !order) {
            getOrder({ id: orderId! });
        }
    }, [getOrder, order, orderId]);

    useSuccessfulCreateSnackbar('Dump Line Item', isSuccess, () => {
        if (orderId || jobId) {
            navigate(`/job/${jobId}/order/${order?.id ?? 'create'}`);
        } else if (quoteId) {
            navigate(`/quote/${quoteId}/lineItems`);
        } else {
            navigate(`/quotes`);
        }
    });
    useFailedCreateSnackbar('dump line item', isError, reset)

    const handleSave = (values: DumpLineItemDto) => {
        createDumpLineItem(values);
    };

    const handleCancel = () => {
        if (orderId || jobId) {
            navigate(`/job/${jobId}/order/${order?.id ?? 'create'}`);
        } else if (quoteId) {
            navigate(`/quote/${quoteId}/lineItems`)
        } else {
            navigate('/quotes');
        }
    };

    if (isQuoteLoading || isOrderLoading) {
        return <LoadingIndicator />;
    }

    return <DumpLineItemForm save={handleSave} cancel={handleCancel} quote={quote} order={order} initValues={DEFAULT_DUMP_LINE_ITEM} />;
};
