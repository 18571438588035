import { Payments } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrompt } from '../../../Views';
import { QuotesAffectedByChangeListViewDialog } from '../../../Views/Quote';
import { MaterialDto, MaterialPriceDto, QuoteAffectingChangeTypeDto, QuoteAffectingChangesDto } from '../../../dtos';
import { ChangeType } from '../../../dtos/generated/ChangeType';
import { useArchiveMaterialPriceByIdMutation, useGetMaterialQuery, useLazyGetQuoteAffectedQuery } from '../../../store/generated/generatedApi';
import { IncomeCodeSelect, SiteSelect } from '../../CommonInputs';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { FormSection, IEntityAutocomplete, IFormProps, LoadingIndicator, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../CoreLib/library';
import { MaterialPriceCostsForm } from './MaterialPriceCostsForm';
import { useMaterialPriceForm } from './useMaterialPriceForm';

export const MaterialPriceForm: FC<IFormProps<MaterialPriceDto>> = (props) => {
    const { isLoading } = props;
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const navigate = useNavigate();
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleMaterialChange,
        handleSiteChange,
        handleIncomeCodeChange,
        handleMaterialPriceCostsChange,
        handleIsActiveChange,
        formMaterial,
        formSite,
        formIncomeCode,
        formMaterialPriceCosts,
        formIsActive,
        getCurrentFormValues,
        validateField,
    } = useMaterialPriceForm(props);

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const { data: materials, isLoading: materialsLoading } = useGetMaterialQuery({
        searchText: '',
        sortKey: 'CODE',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: false,
    });
    const [deleteObject, { isSuccess: isDeleteSuccess, isError: isDeleteError, reset: resetDelete }] = useArchiveMaterialPriceByIdMutation();
    useSuccessfulActionSnackbar('archived', 'Material Price', isDeleteSuccess, () => {
        resetDelete();
        navigate('/dataManagement/materialPrices');
    });
    useFailedActionSnackbar('archive', 'Material Price', isDeleteError, resetDelete);

    const [getAffectedQuotes, { isLoading: isLoadingAffectedQuotes, isError: isErrorLoadingAffectedQuotes }] = useLazyGetQuoteAffectedQuery();
    useFailedActionSnackbar('check for', 'affected quotes', isErrorLoadingAffectedQuotes);

    const currentMaterialPriceId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    const title = useMemo(() => {
        if (formMaterial && formSite) {
            return `${formMaterial.code} - ${formSite.name}`;
        } else {
            return 'New Material Price';
        }
    }, [formMaterial, formSite]);

    const getChanges = useCallback(() => {
        let dto = {
            changes: [
                {
                    id: currentMaterialPriceId,
                    changeType: isDelete ? ChangeType.MaterialPriceDeleted : ChangeType.MaterialPriceChanged,
                    materialPrice: isDelete ? undefined : getCurrentFormValues(),
                },
            ] as QuoteAffectingChangeTypeDto[],
        } as QuoteAffectingChangesDto;

        return dto;
    }, [currentMaterialPriceId, getCurrentFormValues, isDelete]);

    const checkForAffectedQuotes = useCallback((isDeleting?: boolean) => {
        getAffectedQuotes({
            serializedDto: JSON.stringify(getChanges())
        }).unwrap().then(result => {
            if (result.totalQueryResults === 0) {
                if (isDeleting) {
                    deleteObject({ id: currentMaterialPriceId! });
                } else {
                    handleSave();
                }
            } else {
                setIsDelete(true)
                setIsDialogOpen(true);
            }
        });
    }, [currentMaterialPriceId, deleteObject, getAffectedQuotes, getChanges, handleSave]);

    if (isLoading || materialsLoading) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <Grid container direction='column' spacing={3}>
                <QuotesAffectedByChangeListViewDialog
                    onClose={() => {
                        setIsDialogOpen(false);
                        setIsDelete(false);
                    }}
                    onConfirm={() => {
                        if (isDialogOpen) {
                            if (isDelete) {
                                deleteObject({ id: currentMaterialPriceId! });
                            } else {
                                handleSave();
                            }
                        }
                        setIsDialogOpen(false);
                        setIsDelete(false);
                    }}
                    open={isDialogOpen}
                    changes={getChanges()}
                />
                <SlimFormHeader
                    objectName='Material Price'
                    permissionsTypeName={'materialPrice'}
                    icon={<Payments />}
                    title={title}
                    breadcrumbs={[
                        { label: 'Home', navLink: '/' },
                        { label: 'Data Management', navLink: '/dataManagement' },
                        { label: 'Material Prices', navLink: '/dataManagement/materialPrices' },
                    ]}
                    isActive={formIsActive}
                    handleIsActiveChange={handleIsActiveChange}
                    id={currentMaterialPriceId}
                    isFormDirty={isFormDirty}
                    handleCancel={handleCancel}
                    handleSave={() => { checkForAffectedQuotes(false); }}
                    entityNameSingular={'materialPrice'}
                    handleDelete={() => {
                        checkForAffectedQuotes(true);
                    }}
                    isLoading={isLoadingAffectedQuotes}
                    canDelete={!isLoadingAffectedQuotes}
                />
                <Grid item mx={2}>
                    <FormSection>
                        <Grid item container direction='column' spacing={3}>
                            <Grid item container direction='row' spacing={3}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <FormControl error={!!fieldErrors.get('materialId')} fullWidth required>
                                        <FormLabel>Material</FormLabel>
                                        <IEntityAutocomplete
                                            options={materials?.pageResults}
                                            onChange={(e, value) => {
                                                handleMaterialChange(value ?? null);
                                            }}
                                            value={formMaterial}
                                            getOptionLabel={(option: MaterialDto) => `${option.code} - ${option.name}`}
                                            isLoading={materialsLoading}
                                            error={!!fieldErrors.get('materialId')}
                                        />
                                        <FormHelperText>{fieldErrors.get('materialId')}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <SiteSelect
                                        selectedSiteId={formSite?.id ?? ''}
                                        handleSelectedSiteChange={handleSiteChange}
                                        required
                                        errorMessage={fieldErrors.get('siteId')}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <IncomeCodeSelect
                                        selectedIncomeCodeId={formIncomeCode?.id ?? ''}
                                        handleSelectedIncomeCodeChange={handleIncomeCodeChange}
                                        required
                                        errorMessage={fieldErrors.get('incomeCodeId')}
                                        onBlur={() => validateField('incomeCodeId')}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item container direction='row'>
                                <Typography variant='h2'>Prices</Typography>
                            </Grid>
                            <Grid item container direction='row'>
                                <MaterialPriceCostsForm
                                    materialPriceCosts={formMaterialPriceCosts}
                                    handleMaterialPriceCostsChanged={handleMaterialPriceCostsChange}
                                    material={formMaterial}
                                    fieldErrors={fieldErrors}
                                />
                            </Grid>
                        </Grid>
                    </FormSection>
                </Grid>
            </Grid>
        </>
    );
};
