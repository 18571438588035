import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DriverDto, EquipmentDto } from '../../../dtos';
import { countries, emptyGuid } from '../../../util';
import { IFormFieldValidationConfig, isNotBlank, isShorterThanMaxLength, runFieldValidation, runFormValidation } from '../../CoreLib/library';
import { useLazyGetDriverCodeByIdQuery } from '../../../store/generated/generatedApi';
import { IDriverFormProps } from './DriverForm';

export interface IDriverFormValues {
    code: string;
    hireDate: Date;
    terminationDate?: Date;
    userId: string;
    equipmentId?: string;
    payRate?: number;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    isActive: boolean;
    clientId?: string;
    id?: string;
}

export const DEFAULT_DRIVER: DriverDto = {
    id: emptyGuid,
    code: '',
    hireDate: new Date(),
    terminationDate: undefined,
    userId: '',
    equipmentId: undefined,
    payRate: undefined,
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: countries[2], // US
    isActive: true,
    createdOn: new Date(),
    clientId: emptyGuid,
};

export function useDriverForm(props: IDriverFormProps) {
    const { save, cancel, initValues, currentValues, setFormDriverValid } = props;
    const [formCode, setFormCode] = useState(initValues?.code ?? '');
    const [formHireDate, setFormHireDate] = useState<Date | null | undefined>(initValues?.hireDate ?? new Date());
    const [formTerminationDate, setFormTerminationDate] = useState<Date | null | undefined>(initValues?.terminationDate ?? null);
    const [formUser, setFormUser] = useState(initValues?.user);
    const [formEquipment, setFormEquipment] = useState(initValues?.equipment ?? undefined);
    const [formPayRate, setFormPayRate] = useState<number | undefined>(initValues?.payRate ? initValues.payRate * 100 : 100);
    const [formAddressLine1, setFormAddressLine1] = useState(initValues?.addressLine1 ?? '');
    const [formAddressLine2, setFormAddressLine2] = useState(initValues?.addressLine2 ?? '');
    const [formCity, setFormCity] = useState(initValues?.city ?? '');
    const [formState, setFormState] = useState(initValues?.state ?? '');
    const [formZipCode, setFormZipCode] = useState(initValues?.zipCode ?? '');
    const [formCountry, setFormCountry] = useState(initValues?.country ?? countries[2]);
    const [formIsActive, setFormIsActive] = useState(initValues?.isActive ?? true);
    const [isDuplicateCode, setIsDuplicateCode] = useState<boolean>();
    const [isChanged, setIsChanged] = useState(false);

    const [checkCodeAvailability, { data: unavailableCode }] = useLazyGetDriverCodeByIdQuery({});

    useEffect(() => {
        setFormCode(initValues?.code ?? '');
        setFormHireDate(initValues?.hireDate ?? new Date());
        setFormTerminationDate(initValues?.terminationDate ?? null);
        setFormUser(initValues?.user);
        setFormEquipment(initValues?.equipment ?? undefined);
        setFormPayRate(initValues?.payRate ? initValues.payRate * 100 : 100);
        setFormAddressLine1(initValues?.addressLine1 ?? '');
        setFormAddressLine2(initValues?.addressLine2 ?? '');
        setFormCity(initValues?.city ?? '');
        setFormState(initValues?.state ?? '');
        setFormZipCode(initValues?.zipCode ?? '');
        setFormCountry(initValues?.country ?? countries[2]);
        setFormIsActive(initValues?.isActive ?? true);
    }, [
        initValues?.addressLine1,
        initValues?.addressLine2,
        initValues?.city,
        initValues?.code,
        initValues?.country,
        initValues?.equipment,
        initValues?.hireDate,
        initValues?.isActive,
        initValues?.payRate,
        initValues?.state,
        initValues?.terminationDate,
        initValues?.user,
        initValues?.zipCode,
    ]);

    const isNotADuplicateCode = useCallback(
        (data?: any) => {
            return (value: any) => {
                const isValid = !isDuplicateCode || value === initValues?.code;
                const errorMessageBuilder = (_fieldName: string) => (!isValid ? `This code is already in use` : '');
                return {
                    isValid,
                    errorMessageBuilder,
                };
            };
        },
        [initValues?.code, isDuplicateCode]
    );

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IDriverFormValues, string>>(
        new Map([
            ['code', ''],
            ['hireDate', ''],
            ['userId', ''],
            ['equipmentId', ''],
            ['addressLine1', ''],
            ['city', ''],
            ['state', ''],
            ['zipCode', ''],
            ['country', ''],
            ['payRate', ''],
        ])
    );

    const formFieldValidators = useCallback(
        (data?: any) =>
            new Map<keyof IDriverFormValues, IFormFieldValidationConfig>([
                [
                    'code',
                    {
                        validators: [isNotBlank, isNotADuplicateCode(data)],
                        errorMessageEntityName: 'Code',
                    },
                ],
                [
                    'hireDate',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Hire Date',
                    },
                ],
                [
                    'addressLine1',
                    {
                        validators: [isShorterThanMaxLength(100)],
                        errorMessageEntityName: 'Address Line 1',
                    },
                ],
                [
                    'city',
                    {
                        validators: [isShorterThanMaxLength(60)],
                        errorMessageEntityName: 'City',
                    },
                ],
                [
                    'state',
                    {
                        validators: [isShorterThanMaxLength(25)],
                        errorMessageEntityName: 'State',
                    },
                ],
                [
                    'zipCode',
                    {
                        validators: [isShorterThanMaxLength(12)],
                        errorMessageEntityName: 'Zip Code',
                    },
                ],
                [
                    'country',
                    {
                        validators: [isShorterThanMaxLength(60)],
                        errorMessageEntityName: 'Country',
                    },
                ],
                [
                    'payRate',
                    {
                        validators: [isNotBlank],
                        errorMessageEntityName: 'Pay Rate',
                    },
                ],
            ]),
        [isNotADuplicateCode]
    );

    const getCurrentFormValues = useCallback(
        (data?: DriverDto) => {
            if (data) {
                return { ...data };
            }
            return {
                ...(initValues ?? currentValues),
                id: initValues?.id ?? emptyGuid,
                clientId: initValues?.clientId ?? emptyGuid,
                createdOn: initValues?.createdOn ?? new Date(),
                code: formCode,
                hireDate: formHireDate ?? new Date(),
                terminationDate: formTerminationDate ?? undefined,
                userId: formUser?.id ?? emptyGuid,
                equipmentId: formEquipment?.id ?? undefined,
                payRate: formPayRate ? formPayRate / 100 : undefined,
                addressLine1: formAddressLine1,
                addressLine2: formAddressLine2,
                city: formCity,
                state: formState,
                zipCode: formZipCode,
                country: formCountry,
                isActive: formIsActive,
            };
        },
        [
            initValues,
            currentValues,
            formCode,
            formHireDate,
            formTerminationDate,
            formUser?.id,
            formEquipment?.id,
            formPayRate,
            formAddressLine1,
            formAddressLine2,
            formCity,
            formState,
            formZipCode,
            formCountry,
            formIsActive,
        ]
    );

    const validateForm = useCallback(
        (data?: DriverDto) => {
            const formValues = getCurrentFormValues(data);
            const validationResult = runFormValidation<Partial<IDriverFormValues>>(formValues, formFieldValidators(data));
            setFieldErrors(validationResult.errorMessages);
            setFormDriverValid(validationResult.isValid);
        },
        [formFieldValidators, getCurrentFormValues, setFormDriverValid]
    );

    const validateField = useCallback(
        (fieldName: keyof IDriverFormValues) => {
            const validationConfig = formFieldValidators().get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    useEffect(() => {
        if (unavailableCode !== undefined && unavailableCode !== null) {
            setIsDuplicateCode(unavailableCode);
        } else {
            setIsDuplicateCode(false);
        }
        validateField('code');
    }, [formCode, currentValues?.code, unavailableCode, validateField, validateForm]);

    const isFormDirty = useCallback(() => {
        return isChanged;
    }, [isChanged]);

    const currentFormValues = useMemo(() => {
        return {
            id: initValues?.id ?? emptyGuid,
            clientId: initValues?.clientId ?? emptyGuid,
            createdOn: initValues?.createdOn ?? new Date(),
            code: formCode,
            hireDate: formHireDate ?? new Date(),
            terminationDate: formTerminationDate ?? undefined,
            userId: formUser?.id ?? emptyGuid,
            equipmentId: formEquipment?.id ?? undefined,
            payRate: formPayRate ? formPayRate / 100 : undefined,
            addressLine1: formAddressLine1,
            addressLine2: formAddressLine2,
            city: formCity,
            state: formState,
            zipCode: formZipCode,
            country: formCountry,
            isActive: formIsActive,
        };
    }, [
        formAddressLine1,
        formAddressLine2,
        formCity,
        formCode,
        formCountry,
        formEquipment?.id,
        formHireDate,
        formIsActive,
        formPayRate,
        formState,
        formTerminationDate,
        formUser?.id,
        formZipCode,
        initValues?.clientId,
        initValues?.createdOn,
        initValues?.id,
    ]);

    const handleSave = useCallback(() => {
        const updatedDriver = currentFormValues;
        save(updatedDriver);
        validateForm(updatedDriver);
    }, [currentFormValues, save, validateForm]);

    useEffect(() => {
        if (currentFormValues) {
            handleSave();
        }
    }, [currentFormValues, handleSave]);

    const handleCodeChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value.replaceAll(' ', '');
            setFormCode(value);
            if (value !== currentValues?.code) {
                checkCodeAvailability({ code: encodeURIComponent(value) });
            }
            setIsChanged(true);
        },
        [currentValues, checkCodeAvailability]
    );

    const handleEquipmentChange = useCallback((value?: EquipmentDto) => {
        setFormEquipment(value);
        setIsChanged(true);
    }, []);

    const handleHireDateChange = useCallback((date: Date | null | undefined) => {
        setFormHireDate(date);
        setIsChanged(true);
    }, []);

    const handleTerminationDateChange = useCallback((date: Date | null | undefined) => {
        setFormTerminationDate(date);
        setIsChanged(true);
    }, []);

    const handleAddressChange = useCallback(
        (address: { addressLine1: string; addressLine2?: string; country: string; city: string; state: string; zipCode: string }) => {
            setFormAddressLine1(address.addressLine1);
            setFormAddressLine2(address.addressLine2 ?? '');
            setFormCity(address.city);
            setFormCountry(address.country);
            setFormState(address.state);
            setFormZipCode(address.zipCode);
            setIsChanged(true);
        },
        []
    );

    const handlePayRateChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const value = isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber;
        setFormPayRate(value);
        setIsChanged(true);
    }, []);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleCodeChange,
        handleEquipmentChange,
        handleHireDateChange,
        handleTerminationDateChange,
        handlePayRateChange,
        handleAddressChange,
        formCode,
        formHireDate,
        formTerminationDate,
        formUser,
        formEquipment,
        formPayRate: formPayRate,
        formAddressLine1,
        formAddressLine2,
        formCity,
        formState,
        formZipCode,
        formCountry,
        formIsActive,
        validateField,
    };
}
