import { AddCircle, RemoveCircle } from '@mui/icons-material';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { ChangeEvent, FC, useCallback } from 'react';
import { IncomeCodeDto, MaterialDto, PriceListLineItemDto } from '../../../../dtos';
import { emptyGuid } from '../../../../util';
import { IncomeCodeSelect, MaterialSelect, UnitOfMeasureSelect } from '../../../CommonInputs';
import { FormInput } from '../../../CoreLib/library';

export interface IMaterialPriceCostLineItemProps {
    idx: number;
    priceListLineItem: PriceListLineItemDto;
    handleChange: (updatedValue: PriceListLineItemDto, idx: number) => void;
    handleBlur: (fieldName: keyof PriceListLineItemDto, idx: number) => void;
    errors: Map<keyof PriceListLineItemDto, string>;
    handleDelete?: () => void;
    splitMaterialAndIncome?: boolean;
}

export const MaterialPriceCostLineItem: FC<IMaterialPriceCostLineItemProps> = (props) => {
    const { idx, priceListLineItem, handleChange, handleBlur, errors, splitMaterialAndIncome, handleDelete } = props;

    const handleMaterialChange = useCallback(
        (material?: MaterialDto) => {
            const updatedValue: PriceListLineItemDto = { ...priceListLineItem, materialId: material?.id ?? emptyGuid, materialCode: material?.code ?? '' };
            handleChange(updatedValue, idx);
        },
        [priceListLineItem, handleChange, idx]
    );

    const handleIncomeCodeChange = useCallback(
        (incomeCode?: IncomeCodeDto) => {
            const updatedValue: PriceListLineItemDto = {
                ...priceListLineItem,
                incomeCodeId: incomeCode?.id ?? emptyGuid,
                incomeCodeDescription: incomeCode?.accountDescription ?? '',
            };
            handleChange(updatedValue, idx);
        },
        [priceListLineItem, handleChange, idx]
    );

    const handleCostChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            var value: number | undefined = e.target.valueAsNumber;
            if (isNaN(value)) {
                value = undefined;
            }
            const updatedValue: PriceListLineItemDto = { ...priceListLineItem, cost: value };
            handleChange(updatedValue, idx);
        },
        [priceListLineItem, handleChange, idx]
    );

    const handlePriceAChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            var value: number | undefined = e.target.valueAsNumber;
            if (isNaN(value)) {
                value = undefined;
            }
            const updatedValue: PriceListLineItemDto = { ...priceListLineItem, priceA: value };
            handleChange(updatedValue, idx);
        },
        [priceListLineItem, handleChange, idx]
    );

    const handlePriceBChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            var value: number | undefined = e.target.valueAsNumber;
            if (isNaN(value)) {
                value = undefined;
            }
            const updatedValue: PriceListLineItemDto = { ...priceListLineItem, priceB: value };
            handleChange(updatedValue, idx);
        },
        [priceListLineItem, handleChange, idx]
    );

    const handlePriceCChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            var value: number | undefined = e.target.valueAsNumber;
            if (isNaN(value)) {
                value = undefined;
            }
            const updatedValue: PriceListLineItemDto = { ...priceListLineItem, priceC: value };
            handleChange(updatedValue, idx);
        },
        [priceListLineItem, handleChange, idx]
    );

    const handleUnitOfMeasureChange = useCallback(
        (value?: string) => {
            const updatedValue: PriceListLineItemDto = { ...priceListLineItem, unitOfMeasure: value ?? '' };
            handleChange(updatedValue, idx);
        },
        [priceListLineItem, handleChange, idx]
    );

    const handleIsSkippedChange = useCallback(
        (isSkipped: boolean) => {
            const updatedValue: PriceListLineItemDto = { ...priceListLineItem, isSkipped };
            handleChange(updatedValue, idx);
        },
        [priceListLineItem, handleChange, idx]
    );

    return (
        <Grid item container direction='row' spacing={1} alignItems='center'>
            {splitMaterialAndIncome ? (
                <>
                    <Grid item xs={2}>
                        <MaterialSelect
                            selectedMaterialId={priceListLineItem.materialId}
                            handleSelectedMaterialChange={handleMaterialChange}
                            required
                            errorMessage={errors.get('materialId')}
                            onBlur={() => handleBlur('materialId', idx)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IncomeCodeSelect
                            selectedIncomeCodeId={priceListLineItem.incomeCodeId}
                            handleSelectedIncomeCodeChange={handleIncomeCodeChange}
                            required
                            errorMessage={errors.get('incomeCodeId')}
                            onBlur={() => handleBlur('incomeCodeId', idx)}
                        />
                    </Grid>
                </>
            ) : (
                <Grid item xs={2}>
                    <FormInput
                        value={`${priceListLineItem.materialCode} - ${priceListLineItem.incomeCodeDescription}`}
                        onChange={() => { }}
                        label='Material'
                        name='material'
                        fullWidth
                        required
                        disabled
                    />
                </Grid>
            )}
            {priceListLineItem.isSkipped ? (
                <Grid item width={64}>
                    <Tooltip title='Add New Price'>
                        <IconButton color='primary' sx={{ mt: 2 }} onClick={() => handleIsSkippedChange(false)}>
                            <AddCircle fontSize='large' />
                        </IconButton>
                    </Tooltip>
                </Grid>
            ) : (
                <>
                    <Grid item xs={1}>
                        <FormInput
                            value={priceListLineItem.cost?.toString()}
                            onChange={handleCostChange}
                            label='Cost'
                            name='cost'
                            onBlur={() => handleBlur('cost', idx)}
                            errorText={errors.get('cost')}
                            fullWidth
                            required
                            type='number'
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormInput
                            value={priceListLineItem.priceA?.toString()}
                            onChange={handlePriceAChange}
                            label='Price A'
                            name='priceA'
                            onBlur={() => handleBlur('priceA', idx)}
                            errorText={errors.get('priceA')}
                            fullWidth
                            required
                            type='number'
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormInput
                            value={priceListLineItem.priceB?.toString()}
                            onChange={handlePriceBChange}
                            label='Price B'
                            name='priceB'
                            onBlur={() => handleBlur('priceB', idx)}
                            errorText={errors.get('priceB')}
                            fullWidth
                            required
                            type='number'
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <FormInput
                            value={priceListLineItem.priceC?.toString()}
                            onChange={handlePriceCChange}
                            label='Price C'
                            name='priceC'
                            onBlur={() => handleBlur('priceC', idx)}
                            errorText={errors.get('priceC')}
                            fullWidth
                            required
                            type='number'
                        />
                    </Grid>
                    <Grid item width={138}>
                        <UnitOfMeasureSelect
                            selectedUnit={priceListLineItem.unitOfMeasure ?? ''}
                            handleSelectedUnitChange={handleUnitOfMeasureChange}
                            required
                            errorMessage={errors.get('unitOfMeasure')}
                            onBlur={() => handleBlur('unitOfMeasure', idx)}
                        />
                    </Grid>
                    <Grid item width={64}>
                        <Tooltip title='Remove New Price'>
                            <IconButton color='primary' sx={{ mt: 2 }} onClick={handleDelete ? handleDelete : () => handleIsSkippedChange(true)}>
                                <RemoveCircle fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
