import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { EquipmentDto } from '../../dtos';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateEquipmentMutation } from '../../store/generated/generatedApi';
import { EquipmentForm } from '../../Components/Forms/EquipmentForm';

export const AddEquipmentView: FC = () => {
    const navigate = useNavigate();
    const [createEquipment, { data, isError, isSuccess, reset }] = useCreateEquipmentMutation();

    useSuccessfulCreateSnackbar('Equipment', isSuccess, () => navigate(`/dataManagement/equipment/${data?.id}`));
    useFailedCreateSnackbar('equipment', isError, reset)

    const handleSave = (values: EquipmentDto) => {
        createEquipment(values);
    };

    const handleCancel = () => {
        navigate('/dataManagement/equipment');
    };

    return <EquipmentForm save={handleSave} cancel={handleCancel} />;
};
