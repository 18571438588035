import { FilterList } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, FormControlLabel, Grid, Switch, Typography
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { DatePicker, JobSelect } from '../../../Components/CommonInputs';
import { JobDto } from '../../../dtos';
import { IQuoteFilterOptions } from '../Utils';

export interface IQuoteFilterDialogProps {
    isVisible: boolean;
    onClose: () => void;
    filterProps: IQuoteFilterOptions;
    setFilterProps: (updatedFilters: IQuoteFilterOptions) => void;
}

export const QuoteFilterDialog: FC<IQuoteFilterDialogProps> = (props) => {
    const { isVisible, onClose, filterProps, setFilterProps } = props;
    const [quoteFilters, setQuoteFilters] = useState<IQuoteFilterOptions>(filterProps);

    useEffect(() => {
        if (isVisible) {
            setQuoteFilters(filterProps);
        }
    }, [filterProps, isVisible]);

    const isFormClean =
        filterProps.job === quoteFilters.job &&
        filterProps.quoteDateAfter === quoteFilters.quoteDateAfter &&
        filterProps.quoteDateBefore === quoteFilters.quoteDateBefore &&
        filterProps.expirationDateBefore === quoteFilters.expirationDateBefore &&
        filterProps.expirationDateAfter === quoteFilters.expirationDateAfter &&
        filterProps.showHistorical === quoteFilters.showHistorical;

    const handleClose = () => {
        onClose();
    };

    const handleFilter = () => {
        setFilterProps({ ...quoteFilters });
        handleClose();
    };

    const handleClear = () => {
        setQuoteFilters({
            job: undefined,
            quoteDateBefore: undefined,
            quoteDateAfter: undefined,
            expirationDateBefore: undefined,
            expirationDateAfter: undefined,
            showHistorical: false,
        });
    };

    const handleJobChange = (selectedJob?: JobDto) => {
        setQuoteFilters({
            ...quoteFilters,
            job: selectedJob
        });
    };

    const handleShowHistoricalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuoteFilters({
            ...quoteFilters,
            showHistorical: event.target.checked
        });
    }

    return (
        <Dialog open={isVisible} onClose={handleClose} fullWidth maxWidth='md' PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography variant='h2'>
                    {<FilterList />} {'Filter Quotes'}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='column' spacing={2}>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={8}>
                            <JobSelect selectedJobId={quoteFilters.job?.id ?? ''} handleSelectedJobChange={handleJobChange} />
                        </Grid>
                        <Grid item container direction={'column'} xs={4} alignSelf={'center'}>
                            <Grid item>
                                <FormControlLabel
                                    control={<Switch checked={quoteFilters.showHistorical !== undefined ? quoteFilters.showHistorical : false} onChange={handleShowHistoricalChange} />}
                                    label={'Show Historical'}
                                    labelPlacement='start'
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label={'Quote Date After'}
                                value={quoteFilters.quoteDateAfter}
                                onChange={(date: Date | null | undefined) => {
                                    if (date) {
                                        if (!isNaN(Date.parse(date.toString()))) {
                                            setQuoteFilters({
                                                ...quoteFilters,
                                                quoteDateAfter: date ? new Date(date) : undefined
                                            });
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label={'Quote Date Before'}
                                value={quoteFilters.quoteDateBefore}
                                onChange={(date: Date | null | undefined) => {
                                    if (date) {
                                        if (!isNaN(Date.parse(date.toString()))) {
                                            setQuoteFilters({
                                                ...quoteFilters,
                                                quoteDateBefore: date ? new Date(date) : undefined
                                            });
                                        }
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label={'Expiration Date After'}
                                value={quoteFilters.expirationDateAfter}
                                onChange={(date: Date | null | undefined) => {
                                    if (date) {
                                        if (!isNaN(Date.parse(date.toString()))) {
                                            setQuoteFilters({
                                                ...quoteFilters,
                                                expirationDateAfter: date ? new Date(date) : undefined
                                            });
                                        }
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DatePicker
                                label={'Expiration Date Before'}
                                value={quoteFilters.expirationDateBefore}
                                onChange={(date: Date | null | undefined) => {
                                    if (date) {
                                        if (!isNaN(Date.parse(date.toString()))) {
                                            setQuoteFilters({
                                                ...quoteFilters,
                                                expirationDateBefore: date ? new Date(date) : undefined
                                            });
                                        }
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'space-between'}>
                    <Grid item>
                        <Button onClick={handleClear} size='large'>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            {isFormClean ? 'Close' : 'Cancel'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleFilter} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
