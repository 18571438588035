import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderReviewDto } from '../../dtos';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetOrderReviewByIdQuery, useUpdateOrderReviewMutation } from '../../store/generated/generatedApi';
import { OrderReviewForm } from '../../Components/Forms/OrderReviewForm';
import { format } from 'date-fns';

export const EditOrderReviewView: FC = () => {
    const { orderId, jobId, orderReviewId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetOrderReviewByIdQuery({ id: orderReviewId! });
    const [updateOrderReview, { isSuccess, isError, isLoading: updating, reset, error: updateError }] = useUpdateOrderReviewMutation();

    useSuccessfulUpdateSnackbar('Order Review', isSuccess, () => {
    });
    useFailedUpdateSnackbar('order Review', isError, reset, updateError);

    const handleSave = (values: OrderReviewDto) => {
        updateOrderReview(values);
    };

    const handleCancel = () => {
        if (data) {
            navigate(`/orderReview?date=${format(data.orderDate, 'yyyy-MM-dd')}& view=${data.readyForBilling ? 'BILLING' : 'CUSTOMER-SERVICE'} `);
        }
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <OrderReviewForm save={handleSave} cancel={handleCancel} initValues={data} jobId={jobId ?? ''} orderId={orderId ?? ''} isLoading={updating} />;
};
