import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BrokerDto } from '../../dtos';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateBrokerMutation } from '../../store/generated/generatedApi';
import { BrokerForm } from '../../Components/Forms/BrokerForm';

export const AddBrokerView: FC = () => {
    const navigate = useNavigate();
    const [createBroker, { data, isError, isSuccess, reset }] = useCreateBrokerMutation();

    useSuccessfulCreateSnackbar('Broker', isSuccess, () => navigate(`/dataManagement/broker/${data?.id}`));
    useFailedCreateSnackbar('broker', isError, reset)

    const handleSave = (values: BrokerDto) => {
        createBroker(values);
    };

    const handleCancel = () => {
        navigate('/dataManagement/brokers');
    };

    return <BrokerForm save={handleSave} cancel={handleCancel} />;
};
