import { CrudManagerConfig } from '@bryntum/scheduler';
import { BryntumScheduler, BryntumSchedulerProps } from '@bryntum/scheduler-react';
import { FC, MutableRefObject, useEffect } from 'react';

interface Props {
    schedulerRef: MutableRefObject<BryntumScheduler>;
    config: BryntumSchedulerProps;
    resources?: any[];
    events?: any[];
    columns: any;
    crudManager: CrudManagerConfig;
    onEventStoreUpdate: ({ source, record, changes }: { source: any; record: any; changes: any }) => void;
}
export const HaulerManagementDispatchingViewScheduler: FC<Props> = (props) => {
    const { schedulerRef, config, resources, events, columns, crudManager, onEventStoreUpdate } = props;

    useEffect(() => {
        if (!schedulerRef?.current?.instance?.eventStore) {
            return;
        }
        const eventStore = schedulerRef.current.instance.eventStore;
        Object.assign(eventStore, {
            onUpdate: onEventStoreUpdate,
        });
    }, [onEventStoreUpdate, schedulerRef, schedulerRef?.current?.instance?.eventStore]);

    useEffect(() => {
        if (!schedulerRef?.current?.instance) {
            return;
        }
        const eventStore = schedulerRef?.current?.instance?.eventStore;
        if (events) {
            eventStore.data = events;
        }

        async function commit() {
            await eventStore.project.commitAsync();
        }

        commit().then(() => {
            schedulerRef?.current?.instance?.refreshRows();
        });
    }, [events, schedulerRef, schedulerRef?.current?.instance]);

    useEffect(() => {
        if (!schedulerRef?.current?.instance) {
            return;
        }
        const resourceStore = schedulerRef.current.instance.resourceStore;

        if (resources) {
            resourceStore.data = resources;
        }

        async function commit() {
            await resourceStore.project.commitAsync();
        }

        commit().then(() => {
            schedulerRef?.current?.instance?.refreshRows();
        });
    }, [resources, schedulerRef, schedulerRef?.current?.instance]);

    return (
        <>
            <BryntumScheduler ref={schedulerRef} eventDragFeature={{ showTooltip: true }} columns={columns} crudManager={crudManager} {...config} />
        </>
    );
};
