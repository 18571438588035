import { SelectChangeEvent } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    ContactDto,
    DispatchReviewDto,
    FreightBillingLineItemDto,
    JobDto,
    LineItemBillingRatesDto,
    LineItemDto,
    OrderByType,
    OrderDto,
    OrderReviewDto,
    OrderReviewLineItemDto,
} from '../../../dtos';
import { AssignmentType } from '../../../dtos/generated/AssignmentType';
import { ReviewStatus } from '../../../dtos/generated/ReviewStatus';
import { useCreateBillingRatesFreightLineItemBillingRatesMutation, useGetOrderOrderReviewQuery } from '../../../store/generated/generatedApi';
import { emptyGuid, LineItemTypes } from '../../../util';
import { IFormFieldValidationConfig, IFormProps, createValidatorConfig, isNotBlank, runFormValidation } from '../../CoreLib/library';
import { useLineItemConverter } from '../../../util/useLineItemConverter';

export interface IOrderReviewFormValues {
    orderNumber?: number;
    orderDate: Date;
    clientId?: string;
    jobId: string;
    job?: JobDto;
    orderId?: string;
    order?: OrderDto;
    orderLineItems: OrderReviewLineItemDto[];
    isActive: boolean;
    memo: string;
    poNumber: string;
    isTaxable?: boolean;
    orderedByContact?: ContactDto;
    contact?: ContactDto;
}

export const DEFAULT_ORDER_REVIEW: OrderReviewDto = {
    id: emptyGuid,
    clientId: emptyGuid,
    jobId: emptyGuid,
    orderId: emptyGuid,
    orderNumber: undefined,
    orderDate: new Date(),
    isActive: true,
    createdOn: new Date(),
    orderLineItems: [],
    memo: '',
    status: ReviewStatus.InReview,
    statusName: 'In Review',
    readyForBilling: false,
    sentToQuickBooks: false,
    poNumber: '',
    isTaxable: undefined,
};

const ORDER_REVIEW_FORM_VALIDATION_CONFIG = new Map<keyof IOrderReviewFormValues, IFormFieldValidationConfig>([
    ['orderDate', createValidatorConfig([isNotBlank], 'Order Date')],
]);

function buildOrderReviewLineItemConfig(lineItem: OrderReviewLineItemDto): Map<keyof OrderReviewLineItemDto, IFormFieldValidationConfig> {
    var validationConfig = new Map<keyof OrderReviewLineItemDto, IFormFieldValidationConfig>();
    if (lineItem.orderBy) {
        if (lineItem.orderBy === OrderByType.Loads) {
            validationConfig.set('loadOrQuantityReq', createValidatorConfig([isNotBlank], 'Load/Quantity Req'));
        }
    } else {
        validationConfig.set('equipmentRequired', createValidatorConfig([isNotBlank], 'Equipment Required'));
    }
    return validationConfig;
}

function buildDispatchValidationConfig(dispatch: DispatchReviewDto): Map<keyof DispatchReviewDto, IFormFieldValidationConfig> {
    var validationConfig = new Map<keyof DispatchReviewDto, IFormFieldValidationConfig>();
    if (dispatch.assignmentType === AssignmentType.Broker) {
        validationConfig.set('brokerId', createValidatorConfig([isNotBlank], 'Broker'));
    } else {
        validationConfig.set('driverId', createValidatorConfig([isNotBlank], 'Driver'));
        validationConfig.set('equipmentId', createValidatorConfig([isNotBlank], 'Equipment'));
    }
    validationConfig.set('startDate', createValidatorConfig([isNotBlank], 'Start Date'));
    validationConfig.set('endDate', createValidatorConfig([isNotBlank], 'End Date'));
    return validationConfig;
}

function buildFreightBillingValidationConfig(freightBilling: FreightBillingLineItemDto): Map<keyof FreightBillingLineItemDto, IFormFieldValidationConfig> {
    var validationConfig = new Map<keyof FreightBillingLineItemDto, IFormFieldValidationConfig>();
    validationConfig.set('freightBillNumber', createValidatorConfig([isNotBlank], 'Freight Bill #'));
    if (freightBilling.lineItemType === LineItemTypes.Material || freightBilling.lineItemType === LineItemTypes.Dump) {
        validationConfig.set('quantity', createValidatorConfig([isNotBlank], 'Quantity'));
    }
    return validationConfig;
}

export function useOrderReviewForm(props: IFormProps<OrderReviewDto>, jobId: string, orderId: string) {
    const navigate = useNavigate();
    const { save, cancel, initValues } = props;
    const { convertQuoteLineItemToOrderReviewLineItem } = useLineItemConverter(initValues?.quote);
    const { data: order } = useGetOrderOrderReviewQuery({ id: orderId! });
    const [getBillingRates] = useCreateBillingRatesFreightLineItemBillingRatesMutation();

    const [formOrderNumber, setFormOrderNumber] = useState(initValues?.orderNumber ?? order?.orderNumber);
    const [formIsActive, setFormIsActive] = useState(initValues?.isActive ?? true);
    const [formOrderDate, setFormOrderDate] = useState<Date | null | undefined>(
        initValues?.orderDate ? new Date(initValues?.orderDate) : new Date(order?.orderDate ?? new Date())
    );
    const [formOrderLineItems, setFormOrderLineItems] = useState<OrderReviewLineItemDto[]>([]);
    const [formMemo, setFormMemo] = useState<string>(initValues?.memo ?? order?.memo ?? '');
    const [formStatus, setFormStatus] = useState<ReviewStatus>(initValues?.status ?? ReviewStatus.InReview);
    const [formPoNumber, setFormPoNumber] = useState(initValues?.poNumber ?? order?.poNumber ?? '');
    const [formOrderByContact, setFormOrderByContact] = useState(initValues?.order?.orderedByContact);
    const [formContact, setFormContact] = useState(initValues?.order?.contact);
    const [isChanged, setIsChanged] = useState(false);
    const [formIsTaxable, setFormIsTaxable] = useState(initValues?.isTaxable ?? order?.isTaxable ?? false);

    useEffect(() => {
        if (!initValues?.id && order?.orderReview) {
            navigate(`/job/${jobId}/order/${orderId}/orderReview/${order?.orderReview.id}`);
        }
    }, [initValues?.id, jobId, navigate, order, orderId]);

    useEffect(() => {
        setFormOrderNumber(initValues?.orderNumber ?? order?.orderNumber);
        setFormIsActive(initValues?.isActive ?? true);
        setFormOrderDate(initValues?.orderDate ? new Date(initValues?.orderDate) : new Date(order?.orderDate ?? new Date()));
        setFormOrderByContact(initValues?.orderedByContact ?? order?.orderedByContact);
        setFormContact(initValues?.contact ?? order?.contact);
        setFormIsTaxable(initValues?.isTaxable ?? order?.isTaxable ?? false);
        setFormOrderLineItems(
            initValues?.orderLineItems.map((x) => {
                return {
                    ...x,
                } as OrderReviewLineItemDto;
            }) ??
                order?.orderLineItems.map((x) => {
                    return {
                        ...x,
                        status: ReviewStatus.InReview,
                        yardTime: x.yardTime,
                        onsiteTime: x.onsiteTime,
                        description: x.lineItem?.description,
                        unitOfMeasure: x.lineItem?.unitOfMeasure,
                        id: emptyGuid,
                        orderReviewLineItemId: x.id,
                        billingRates: {
                            ...x.billingRates,
                            id: emptyGuid,
                            isActive: true,
                            createdOn: new Date(),
                        } as LineItemBillingRatesDto,
                        dispatches:
                            x.dispatches?.map((y) => {
                                return {
                                    ...y,
                                    assignmentType: y.brokerId ? AssignmentType.Broker : AssignmentType.Driver,
                                    id: emptyGuid,
                                    orderLineItemId: undefined,
                                    equipment: y.equipment,
                                    equipmentId: y.equipmentId,
                                    yardTime: y.yardTime,
                                    haulToAddressLine1: y.haulToAddressLine1 ?? '',
                                    haulToAddressLine2: y.haulToAddressLine2 ?? '',
                                    haulToCity: y.haulToCity ?? '',
                                    haulToCountry: y.haulToCountry ?? '',
                                    haulToState: y.haulToState ?? '',
                                    haulToZipCode: y.haulToZipCode ?? '',
                                    haulToRegion: y.haulToRegion,
                                    haulToRegionId: y.haulToRegionId,
                                    haulToSiteName: y.haulToSiteName ?? '',
                                    haulFromAddressLine1: y.haulFromAddressLine1 ?? '',
                                    haulFromAddressLine2: y.haulFromAddressLine2 ?? '',
                                    haulFromCity: y.haulFromCity ?? '',
                                    haulFromCountry: y.haulFromCountry ?? '',
                                    haulFromState: y.haulFromState ?? '',
                                    haulFromZipCode: y.haulFromZipCode ?? '',
                                    haulFromRegion: y.haulFromRegion,
                                    haulFromRegionId: y.haulFromRegionId,
                                    haulFromSiteName: y.haulFromSiteName,
                                    freightBillingLineItems: [],
                                };
                            }) ?? [],
                        isDispatched: false,
                        isInvoiced: false,
                        siteId: x.lineItem?.siteId,
                        site: x.lineItem?.site,
                        zone: x.lineItem?.zone
                    } as OrderReviewLineItemDto;
                }) ??
                []
        );
        setFormMemo(initValues?.memo ?? order?.memo ?? '');
        setFormStatus(initValues?.status ?? ReviewStatus.InReview);
        setFormPoNumber(initValues?.poNumber ?? order?.poNumber ?? '');
    }, [initValues, order]);

    const currentFormValues = useMemo((): IOrderReviewFormValues => {
        return {
            jobId: jobId,
            orderId: orderId,
            isActive: formIsActive,
            orderNumber: formOrderNumber,
            orderDate: formOrderDate ?? new Date(),
            orderLineItems: formOrderLineItems,
            memo: formMemo,
            poNumber: formPoNumber,
            isTaxable: formIsTaxable,
            orderedByContact: formOrderByContact,
            contact: formContact,
        };
    }, [formIsActive, formIsTaxable, formMemo, formOrderDate, formOrderLineItems, formOrderNumber, formPoNumber, jobId, orderId, formOrderByContact, formContact]);

    const [baseFormErrors, setBaseFormErrors] = useState<Map<keyof IOrderReviewFormValues, string>>(new Map());
    const [lineItemErrors, setLineItemErrors] = useState<Map<number, Map<keyof OrderReviewLineItemDto, string>>>(new Map());
    const [dispatchErrors, setDispatchErrors] = useState<Map<number, Map<number, Map<keyof DispatchReviewDto, string>>>>(new Map());
    const [freightErrors, setFreightErrors] = useState<Map<number, Map<number, Map<number, Map<keyof FreightBillingLineItemDto, string>>>>>(new Map());

    const validateForm = useCallback(() => {
        var isValid = true;
        const { isValid: isBaseValid, errorMessages: baseErrorMessages } = runFormValidation<IOrderReviewFormValues>(
            currentFormValues,
            ORDER_REVIEW_FORM_VALIDATION_CONFIG
        );
        !isBaseValid && (isValid = false);
        setBaseFormErrors(baseErrorMessages);

        var lineItemValidationErrors: Map<number, Map<keyof OrderReviewLineItemDto, string>> = new Map();
        var dispatchValidationErrors: Map<number, Map<number, Map<keyof DispatchReviewDto, string>>> = new Map();
        var freightBillingValidationErrors: Map<number, Map<number, Map<number, Map<keyof FreightBillingLineItemDto, string>>>> = new Map();

        currentFormValues.orderLineItems.forEach((lineItem, lineItemIdx) => {
            const validationConfig = buildOrderReviewLineItemConfig(lineItem);
            const { isValid: isLineItemValid, errorMessages: lineItemErrorMessages } = runFormValidation<OrderReviewLineItemDto>(lineItem, validationConfig);
            !isLineItemValid && (isValid = false);
            lineItemValidationErrors.set(lineItemIdx, lineItemErrorMessages);

            var lineItemDispatchErrors: Map<number, Map<keyof DispatchReviewDto, string>> = new Map();
            var lineItemFreightBillingValidationErrors: Map<number, Map<number, Map<keyof FreightBillingLineItemDto, string>>> = new Map();
            lineItem.dispatches.forEach((dispatch, dispatchIdx) => {
                const dispatchValidationConfig = buildDispatchValidationConfig(dispatch);
                const { isValid: isDispatchValid, errorMessages: dispatchErrorMessages } = runFormValidation<DispatchReviewDto>(
                    dispatch,
                    dispatchValidationConfig
                );
                !isDispatchValid && (isValid = false);
                lineItemDispatchErrors.set(dispatchIdx, dispatchErrorMessages);

                var dispatchFreightBillingValidationErrors: Map<number, Map<keyof FreightBillingLineItemDto, string>> = new Map();
                dispatch.freightBillingLineItems?.forEach((freightBillingLineItem, freightBillingIdx) => {
                    const freightBillingValidationConfig = buildFreightBillingValidationConfig(freightBillingLineItem);
                    const { isValid: isFreightBillingValid, errorMessages: freightBillingErrorMessages } = runFormValidation<FreightBillingLineItemDto>(
                        freightBillingLineItem,
                        freightBillingValidationConfig
                    );
                    !isFreightBillingValid && (isValid = false);
                    dispatchFreightBillingValidationErrors.set(freightBillingIdx, freightBillingErrorMessages);
                });
                lineItemFreightBillingValidationErrors.set(dispatchIdx, dispatchFreightBillingValidationErrors);
            });
            dispatchValidationErrors.set(lineItemIdx, lineItemDispatchErrors);
            freightBillingValidationErrors.set(lineItemIdx, lineItemFreightBillingValidationErrors);
        });
        setLineItemErrors(lineItemValidationErrors);
        setDispatchErrors(dispatchValidationErrors);
        setFreightErrors(freightBillingValidationErrors);

        return isValid;
    }, [currentFormValues]);

    const isFormDirty = useCallback(() => {
        return isChanged;
    }, [isChanged]);

    const handleOrderDateChange = useCallback((value: Date | null | undefined) => {
        setFormOrderDate(value);
        setIsChanged(true);
    }, []);

    const handleContactChange = useCallback((value?: ContactDto) => {
        setFormContact(value);
        setIsChanged(true);
    }, []);

    const handleOrderedByContactChange = useCallback((value?: ContactDto) => {
        setFormOrderByContact(value);
        setIsChanged(true);
    }, []);

    const handleOrderLineItemsChange = useCallback((lineItems: OrderReviewLineItemDto[]) => {
        setFormOrderLineItems(lineItems);
        setIsChanged(true);
    }, []);

    const handleFormStatusChange = useCallback((event: SelectChangeEvent) => {
        setFormStatus(parseInt(event.target.value));
        setIsChanged(true);
    }, []);

    const handleRemoveDispatchReview = useCallback(
        (lineItemIndex: number, dispatchIndex: number) => {
            var updatedLineItems = _.cloneDeep(formOrderLineItems);
            updatedLineItems[lineItemIndex].dispatches.splice(dispatchIndex, 1);
            setFormOrderLineItems(updatedLineItems);
            setIsChanged(true);
        },
        [formOrderLineItems]
    );

    const handleMemoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormMemo(event.target.value);
        setIsChanged(true);
    }, []);

    const handleIsTaxableChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsTaxable(isChecked);
        setIsChanged(true);
    }, []);

    const createNewOrderReviewLineItem = useCallback(
        async (quoteLineItem?: LineItemDto): Promise<OrderReviewLineItemDto> => {
            if (quoteLineItem) {
                return (await convertQuoteLineItemToOrderReviewLineItem(quoteLineItem, formOrderLineItems.length + 1)) as OrderReviewLineItemDto;
            } else {
                return {
                    id: emptyGuid,
                    isActive: true,
                    createdOn: new Date(),
                    orderReviewId: undefined,
                    lineItemId: undefined,
                    equipmentTypeId: undefined,
                    description: '',
                    orderBy: OrderByType.Quantity,
                    loadOrQuantityReq: undefined,
                    equipmentRequired: undefined,
                    memo: '',
                    driverMemo: '',
                    dispatchMemo: '',
                    yardTime: undefined,
                    onsiteTime: undefined,
                    orderLineItemNumber: formOrderLineItems.length + 1,
                    dispatches: [],
                    poNumber: '',
                    haulToAddressLine1: '',
                    haulToAddressLine2: '',
                    haulToCity: '',
                    haulToState: '',
                    haulToZipCode: '',
                    haulToCountry: '',
                    haulFromAddressLine1: '',
                    haulFromAddressLine2: '',
                    haulFromCity: '',
                    haulFromState: '',
                    haulFromZipCode: '',
                    haulFromCountry: '',
                    haulToRegionId: undefined,
                    haulToRegion: undefined,
                    haulFromRegionId: undefined,
                    haulFromRegion: undefined,
                    haulToSiteName: '',
                    haulFromSiteName: '',
                    billingRates: {
                        id: emptyGuid,
                        isActive: true,
                        createdOn: new Date(),
                        lineItemRate: undefined,
                    },
                    isDispatched: false,
                    isInvoiced: false,
                } as OrderReviewLineItemDto;
            }
        },
        [formOrderLineItems.length, convertQuoteLineItemToOrderReviewLineItem]
    );

    const createNewDispatchReview = useCallback(
        (lineItemIndex: number) => {
            return {
                assignmentType: AssignmentType.Broker,
                id: emptyGuid,
                isActive: true,
                createdOn: new Date(),
                orderLineItemId: undefined,
                driverId: undefined,
                brokerId: undefined,
                startDate: formOrderLineItems[lineItemIndex].onsiteTime,
                endDate: undefined,
                yardTime: formOrderLineItems[lineItemIndex].yardTime,
                equipmentId: undefined,
                haulToAddressLine1: formOrderLineItems[lineItemIndex].haulToAddressLine1 ?? '',
                haulToAddressLine2: formOrderLineItems[lineItemIndex].haulToAddressLine2 ?? '',
                haulToCity: formOrderLineItems[lineItemIndex].haulToCity ?? '',
                haulToCountry: formOrderLineItems[lineItemIndex].haulToCountry ?? '',
                haulToState: formOrderLineItems[lineItemIndex].haulToState ?? '',
                haulToZipCode: formOrderLineItems[lineItemIndex].haulToZipCode ?? '',
                haulToRegion: formOrderLineItems[lineItemIndex].haulToRegion,
                haulToRegionId: formOrderLineItems[lineItemIndex].haulToRegionId,
                haulToSiteName: formOrderLineItems[lineItemIndex].haulToSiteName,
                haulFromAddressLine1: formOrderLineItems[lineItemIndex].haulFromAddressLine1 ?? '',
                haulFromAddressLine2: formOrderLineItems[lineItemIndex].haulFromAddressLine2 ?? '',
                haulFromCity: formOrderLineItems[lineItemIndex].haulFromCity ?? '',
                haulFromCountry: formOrderLineItems[lineItemIndex].haulFromCountry ?? '',
                haulFromState: formOrderLineItems[lineItemIndex].haulFromState ?? '',
                haulFromZipCode: formOrderLineItems[lineItemIndex].haulFromZipCode ?? '',
                haulFromRegion: formOrderLineItems[lineItemIndex].haulFromRegion,
                haulFromRegionId: formOrderLineItems[lineItemIndex].haulFromRegionId,
                haulFromSiteName: formOrderLineItems[lineItemIndex].haulFromSiteName,
                freightBillingLineItems: [],
            } as DispatchReviewDto;
        },
        [formOrderLineItems]
    );

    const calculateBillingRates = useCallback(
        (billingRates: FreightBillingLineItemDto): Promise<FreightBillingLineItemDto> => {
            return getBillingRates(billingRates).then((response) => {
                return (response as any).data as FreightBillingLineItemDto;
            });
        },
        [getBillingRates]
    );

    const handleAddNewFreightBillingLineItem = useCallback(
        async (lineItemIndex: number, dispatchIndex: number) => {
            var updatedLineItems = _.cloneDeep(formOrderLineItems);
            let freightBillingLineItem = {
                ...updatedLineItems[lineItemIndex].billingRates,
                id: emptyGuid,
                isActive: true,
                createdOn: new Date(),
            };

            if (updatedLineItems[lineItemIndex].orderBy === OrderByType.Quantity) {
                freightBillingLineItem.quantity = updatedLineItems[lineItemIndex].loadOrQuantityReq;
            }

            freightBillingLineItem = await calculateBillingRates(freightBillingLineItem);

            updatedLineItems[lineItemIndex].dispatches[dispatchIndex].freightBillingLineItems!.push(freightBillingLineItem);
            setFormOrderLineItems(updatedLineItems);
            setIsChanged(true);
        },
        [calculateBillingRates, formOrderLineItems]
    );

    const handleRemoveFreightBillingLineItem = useCallback(
        (lineItemIndex: number, dispatchIndex: number, freightBillingLineItemIndex: number) => {
            var updatedLineItems = _.cloneDeep(formOrderLineItems);
            updatedLineItems[lineItemIndex].dispatches[dispatchIndex].freightBillingLineItems!.splice(freightBillingLineItemIndex, 1);
            setFormOrderLineItems(updatedLineItems);
            setIsChanged(true);
        },
        [formOrderLineItems]
    );

    const handleAddNewOrderReviewLineItem = useCallback(
        async (quoteLineItem?: LineItemDto) => {
            var newOrderReviewLineItem = await createNewOrderReviewLineItem(quoteLineItem);
            setFormOrderLineItems((current) => [...current, newOrderReviewLineItem]);
            setIsChanged(true);
        },
        [createNewOrderReviewLineItem]
    );

    const handleRemoveOrderReviewLineItem = useCallback(
        (lineItemIndex: number) => {
            var updatedLineItems = _.cloneDeep(formOrderLineItems);
            updatedLineItems.splice(lineItemIndex, 1);
            // Reorder the line items that were added adhoc
            updatedLineItems.forEach((lineItem, index) => {
                if (lineItem.lineItemId === undefined) {
                    lineItem.orderLineItemNumber = index + 1;
                }
            });
            setFormOrderLineItems(updatedLineItems);
            setIsChanged(true);
        },
        [formOrderLineItems]
    );

    const handleAddNewDispatchReview = useCallback(
        (lineItemIndex: number) => {
            var updatedLineItems = _.cloneDeep(formOrderLineItems);
            updatedLineItems[lineItemIndex].dispatches.push(createNewDispatchReview(lineItemIndex));
            setFormOrderLineItems(updatedLineItems);
            setIsChanged(true);
        },
        [createNewDispatchReview, formOrderLineItems]
    );

    const isFormValid = useMemo(() => {
        return validateForm();
    }, [validateForm]);

    const handleSave = useCallback(() => {
        if (isFormValid) {
            const updatedOrderReviewRecord = {
                ...(initValues ?? DEFAULT_ORDER_REVIEW),
                ...currentFormValues,
                contactId: currentFormValues.contact?.id,
                orderedByContactId: currentFormValues.orderedByContact?.id,
                sentToQuickBooks: undefined,
            };
            save(updatedOrderReviewRecord);
            setIsChanged(false);
        }
    }, [currentFormValues, initValues, save, isFormValid]);

    const handleSubmit = useCallback(
        (sentToQuickBooks: boolean) => {
            if (isFormValid) {
                const updatedUserRecord = {
                    ...(initValues ?? DEFAULT_ORDER_REVIEW),
                    ...currentFormValues,
                    contactId: currentFormValues.contact?.id,
                    orderedByContactId: currentFormValues.orderedByContact?.id,
                    readyForBilling: true,
                    sentToQuickBooks,
                };
                save(updatedUserRecord);
                setIsChanged(false);
            }
        },
        [currentFormValues, initValues, isFormValid, save]
    );

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        isFormValid,
        handleSave,
        handleSubmit,
        handleCancel,
        baseFormErrors,
        lineItemErrors,
        dispatchErrors,
        freightErrors,
        handleOrderDateChange,
        handleContactChange,
        handleOrderedByContactChange,
        handleOrderLineItemsChange,
        handleAddNewDispatchReview,
        handleAddNewOrderReviewLineItem,
        handleAddNewFreightBillingLineItem,
        handleRemoveDispatchReview,
        handleRemoveOrderReviewLineItem,
        handleRemoveFreightBillingLineItem,
        handleMemoChange,
        handleFormStatusChange,
        handleIsTaxableChange,
        setFormPoNumber,
        formIsActive,
        formOrderNumber,
        formOrderDate,
        formOrderByContact,
        formContact,
        formOrderLineItems,
        formMemo,
        formStatus,
        formPoNumber,
        formIsTaxable,
        order,
    };
}

export function isErrorInMap(errors: Map<string, string> | undefined): boolean {
    if (!errors) {
        return false;
    }
    return Array.from(errors.values()).some((x) => x !== '');
}
