import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderReviewDto } from '../../dtos';
import { useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useUpdateOrderReviewMutation } from '../../store/generated/generatedApi';
import { format, parseISO } from 'date-fns';
import { TicketForm } from '../../Components/Forms/OrderReviewForm/TicketForm';

export const CreateTicketView: FC = () => {
    const { date } = useParams<{ date?: string }>();
    const navigate = useNavigate();
    const [updateOrderReview, { isSuccess, isError, isLoading: updating, reset }] = useUpdateOrderReviewMutation();
    const [updatedDate, setUpdatedDate] = useState(date ?? '');

    useSuccessfulUpdateSnackbar('Ticket', isSuccess, () => {
    });
    useFailedUpdateSnackbar('Ticket', isError, reset)

    const handleSave = (values: OrderReviewDto) => {
        updateOrderReview(values);
    };

    const handleCancel = () => {
        if (updatedDate) {
            navigate(`/orderReview?date=${format(parseISO(updatedDate), 'yyyy-MM-dd')}`);
        } else {
            navigate('/orderReview');
        }
    };

    return <TicketForm save={handleSave} cancel={handleCancel} isLoading={updating} dateGiven={new Date(date ?? '')} setUpdatedDate={setUpdatedDate} />;
};
