import { EventStore } from '@bryntum/scheduler';
import DispatchEventModel from './DispatchEventModel';

export default class DispatchEventStore extends EventStore {
    static get $name() {
        return 'DispatchEventStore';
    }

    static get defaultConfig() {
        return {
            modelClass: DispatchEventModel,
        };
    }
}
