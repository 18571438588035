export enum SchedulingStatus {
    Overbooked = 'Overbooked',
    Scheduled = 'Scheduled',
    Unscheduled = 'Unscheduled',
}

export function getSchedulingStatus(isScheduled: boolean, isOverbooked: boolean) {
    if (isScheduled && isOverbooked) {
        return SchedulingStatus.Overbooked;
    }
    if (isScheduled) {
        return SchedulingStatus.Scheduled;
    }
    return SchedulingStatus.Unscheduled;
}