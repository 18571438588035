import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useArchiveContactBulkDeleteMutation, useContactBulkArchiveMutation, useContactBulkUnarchiveMutation, useGetContactQuery } from '../../store/generated/generatedApi';
import { ContactDto } from '../../dtos/generated/ContactDto';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'CONTACT_NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const ContactsListView: FC = () => {
    const navigate = useNavigate();
    const { customerId, siteId } = useParams();

    const tableColumns: DataTableColumn<ContactDto>[] = [
        { key: 'contactName', label: 'Contact Name', sortKey: 'CONTACT_NAME' },
        { key: 'title', label: 'Title', sortKey: 'TITLE' },
        { key: 'landlinePhone', label: 'Landline', sortKey: 'LAND_PHONE' },
        { key: 'cellPhone', label: 'Cell', sortKey: 'CELL_PHONE' },
        { key: 'email', label: 'Email', sortKey: 'EMAIL' },
    ];

    const getRoute = () => {
        return customerId ? `/customer/${customerId}` : `/dataManagement/site/${siteId}`;
    }

    const handleCreateNew = () => {
        navigate(`${getRoute()}/contact/create`);
    };

    const handleTableEdit = (contactId: string) => {
        navigate(`${getRoute()}/contact/${contactId}`);
    };

    return (
        <StandardListViewV2
            headerIcon={<></>}
            headerTitle=''
            breadCrumbs={[]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetContactQuery}
            isShowActiveToggleVisible
            inactivateMutation={useContactBulkArchiveMutation}
            activateMutation={useContactBulkUnarchiveMutation}
            deleteMutation={useArchiveContactBulkDeleteMutation}
            tableColumns={tableColumns}
            entityNameSingular='Contact'
            permissionName='contact'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
            additionalQueryParameters={customerId ? { customerId } : siteId ? { siteId } : undefined}
        />
    );
};
