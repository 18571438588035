import { Model } from '@bryntum/scheduler';

export default class OrderLineItemModel extends Model {
    static get $name() {
        return 'OrderLineItemModel';
    }

    static get fields() {
        return [
            'id',
            'orderId',
            'order',
            'lineItemId',
            'lineItem',
            'equipmentTypeId',
            'pricingMethodType',
            'orderBy',
            'loadOrQuantityReq',
            'price',
            'equipmentRequired',
            'memo',
            'driverMemo',
            'dispatchMemo',
            'arrivalTime',
            'yardTime',
            'onsiteTime',
            'orderNumber',
            'jobNumber',
            'name',
            'orderLineItemNumber',
            'jobId',
            'isScheduled',
            'requirements',
            'poNumber',
            'haulToAddressLine1',
            'haulToAddressLine2',
            'haulToCountry',
            'haulToCity',
            'haulToState',
            'haulToZipCode',
            'haulFromAddressLine1',
            'haulFromAddressLine2',
            'haulFromCountry',
            'haulFromCity',
            'haulFromState',
            'haulFromZipCode',
            'haulToSiteName',
            'haulToRegion',
            'haulToRegionId',
            'haulFromSiteName',
            'haulFromRegion',
            'haulFromRegionId',
            'canceledOn',
            'cancellationReason',
            'canceledByUserId',
            'isCancelable',
            'isOrderInReview',
            'customerName',
            'siteName',
            'regionName',
            'orderer',
            'dispatchingIntervalTimeFormatted',
            'projectName',
            'jobName',
            'yardTimeFormatted',
            'onsiteTimeFormatted',
        ];
    }

    static get defaults() {
        return {
            durationUnit: 'h',

            name: 'New event',
        };
    }
}
