import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BrokerDto } from '../../dtos';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetBrokerByIdQuery, useUpdateBrokerMutation } from '../../store/generated/generatedApi';
import { BrokerForm } from '../../Components/Forms/BrokerForm';

export const EditBrokerView: FC = () => {
    const { brokerId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetBrokerByIdQuery({ id: brokerId! });
    const [updateBroker, { isSuccess, isError, reset }] = useUpdateBrokerMutation();

    useSuccessfulUpdateSnackbar('Broker', isSuccess, reset);
    useFailedUpdateSnackbar('broker', isError, reset);

    const handleSave = (values: BrokerDto) => {
        updateBroker(values);
    };

    const handleCancel = () => {
        navigate('/dataManagement/brokers');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <BrokerForm save={handleSave} cancel={handleCancel} initValues={data} />;
};
