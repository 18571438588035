import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent, useCallback } from 'react';
import { JobDto } from '../../dtos';
import { useGetJobQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface IJobSelectProps {
    selectedJobId: string;
    handleSelectedJobChange: (selectedJob?: JobDto) => void;
    required?: boolean;
    errorMessage?: string;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    disabled?: boolean;
}

export const JobSelect: FC<IJobSelectProps> = props => {
    const { selectedJobId, handleSelectedJobChange, required, errorMessage, onBlur, disabled } = props;
    const { data: jobs, isLoading: isLoadingJobs } = useGetJobQuery({
        sortKey: 'JOB_NUMBER',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false
    });

    const handleSelectChange = useCallback((_: SyntheticEvent<Element, Event>, value: JobDto | null) => {
        handleSelectedJobChange(value ?? undefined);
    }, [handleSelectedJobChange]);

    const getSelectedJob = useCallback(() => {
        if (selectedJobId === '' || selectedJobId === emptyGuid) {
            return null;
        }
        return jobs?.pageResults.find(x => x.id === selectedJobId) ?? null;
    }, [jobs?.pageResults, selectedJobId]);

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>Job</FormLabel>
            <Autocomplete
                value={getSelectedJob()}
                onChange={handleSelectChange}
                loading={isLoadingJobs}
                options={jobs?.pageResults ?? []}
                getOptionLabel={x => x.jobName ? x.jobName + ' - ' + x.customerName + ' (Job #' + x.jobNumber + ')' : x.customerName + ' (Job #' + x.jobNumber + ')'}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}