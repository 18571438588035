import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { EquipmentTypeDto, QuoteDto, RateToggle, SiteDto, SiteHaulingLineItemDto } from '../../../../../dtos';
import { UnitOfMeasure, emptyGuid, isValidUnitOfMeasure } from '../../../../../util';
import { IFormFieldValidationConfig, IFormProps, isNotBlank, isShorterThanMaxLength, runFieldValidation, runFormValidation } from '../../../../CoreLib/library';

export interface ISiteHaulingLineItemFormValues {
    quoteId: string;
    siteId: string;
    equipmentTypeId: string;
    driverRate: number;
    brokerRate: number;
    haulingRate: number;
    unitOfMeasure: string;
    isActive: boolean;
    haulingFlatRate?: number;
    driverFlatRate?: number;
    brokerFlatRate?: number;
    rateToggle?: RateToggle;
    zone?: string;
}

export const DEFAULT_SITE_HAULING_LINE_ITEM: SiteHaulingLineItemDto = {
    id: emptyGuid,
    quoteId: '',
    siteId: '',
    equipmentTypeId: '',
    description: '',
    quantity: 0,
    driverRate: 0,
    brokerRate: 0,
    haulingRate: 0,
    unitOfMeasure: 'Tons',
    isActive: true,
    createdOn: new Date(),
    haulingFlatRate: undefined,
    driverFlatRate: undefined,
    brokerFlatRate: undefined,
    rateToggle: RateToggle.Standard,
    zone: '',
};

export function useSiteHaulingLineItemForm(props: IFormProps<SiteHaulingLineItemDto>) {
    const { save, cancel, initValues } = props;
    const [formQuote, setFormQuote] = useState(initValues?.quote);
    const [formEquipmentType, setFormEquipmentType] = useState(initValues?.equipmentType);
    const [formSite, setFormSite] = useState(initValues?.site);
    const [formUnitOfMeasure, setFormUnitOfMeasure] = useState(initValues?.unitOfMeasure ?? 'Tons');
    const [formHaulingRate, setFormHaulingRate] = useState(initValues?.haulingRate);
    const [formDriverRate, setFormDriverRate] = useState(initValues?.driverRate);
    const [formBrokerRate, setFormBrokerRate] = useState(initValues?.brokerRate);
    const [formIsActive, setFormIsActive] = useState(initValues?.isActive ?? true);
    const [formMinimumFlatRate, setFormMinimumFlatRate] = useState(initValues?.haulingFlatRate);
    const [formDriverMinimumFlatRate, setFormDriverMinimumFlatRate] = useState(initValues?.driverFlatRate);
    const [formBrokerMinimumFlatRate, setFormBrokerMinimumFlatRate] = useState(initValues?.brokerFlatRate);
    const [formRateToggle, setFormRateToggle] = useState<RateToggle>(initValues?.rateToggle ?? RateToggle.Standard);
    const [formZone, setFormZone] = useState<string>(initValues?.zone ?? '');

    useEffect(() => {
        setFormQuote(initValues?.quote);
        setFormEquipmentType(initValues?.equipmentType);
        setFormSite(initValues?.site);
        setFormHaulingRate(initValues?.haulingRate ?? 0);
        setFormDriverRate(initValues?.driverRate ?? 0);
        setFormBrokerRate(initValues?.brokerRate ?? 0);
        setFormUnitOfMeasure((initValues?.unitOfMeasure as UnitOfMeasure) ?? 'Tons');
        setFormIsActive(initValues?.isActive ?? true);
        setFormMinimumFlatRate(initValues?.haulingFlatRate);
        setFormDriverMinimumFlatRate(initValues?.driverFlatRate);
        setFormBrokerMinimumFlatRate(initValues?.brokerFlatRate);
        setFormRateToggle(initValues?.rateToggle ?? RateToggle.Standard);
        setFormZone(initValues?.zone ?? '');
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof ISiteHaulingLineItemFormValues, string>>(
        new Map([
            ['quoteId', ''],
            ['equipmentTypeId', ''],
            ['siteId', ''],
            ['haulingRate', ''],
            ['driverRate', ''],
            ['brokerRate', ''],
            ['unitOfMeasure', ''],
            ['haulingFlatRate', ''],
            ['driverFlatRate', ''],
            ['brokerFlatRate', ''],
        ])
    );

    const formFieldValidators = useMemo(() => {
        return new Map<keyof ISiteHaulingLineItemFormValues, IFormFieldValidationConfig>([
            [
                'quoteId',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Quote',
                },
            ],
            [
                'equipmentTypeId',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Equipment Type',
                },
            ],
            [
                'siteId',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Site',
                },
            ],
            [
                'haulingRate',
                {
                    validators: formRateToggle !== RateToggle.Flat ? [isNotBlank] : [],
                    errorMessageEntityName: 'Hauling Rate',
                },
            ],
            [
                'driverRate',
                {
                    validators: formRateToggle !== RateToggle.Flat ? [isNotBlank] : [],
                    errorMessageEntityName: 'Driver Rate',
                },
            ],
            [
                'brokerRate',
                {
                    validators: formRateToggle !== RateToggle.Flat ? [isNotBlank] : [],
                    errorMessageEntityName: 'Broker Rate',
                },
            ],
            [
                'haulingFlatRate',
                {
                    validators: formRateToggle !== RateToggle.Standard ? [isNotBlank] : [],
                    errorMessageEntityName: 'Minimum Flat Rate',
                },
            ],
            [
                'driverFlatRate',
                {
                    validators: formRateToggle !== RateToggle.Standard ? [isNotBlank] : [],
                    errorMessageEntityName: 'Driver Minimum Flat Rate',
                },
            ],
            [
                'brokerFlatRate',
                {
                    validators: formRateToggle !== RateToggle.Standard ? [isNotBlank] : [],
                    errorMessageEntityName: 'Broker Minimum Flat Rate',
                },
            ],
            [
                'unitOfMeasure',
                {
                    validators: [isNotBlank, isShorterThanMaxLength(20)],
                    errorMessageEntityName: 'Unit Of Measure',
                },
            ],
        ]);
    }, [formRateToggle]);

    const getCurrentFormValues = useCallback((): ISiteHaulingLineItemFormValues => {
        return {
            quoteId: formQuote?.id ?? emptyGuid,
            siteId: formSite?.id ?? emptyGuid,
            equipmentTypeId: formEquipmentType?.id ?? emptyGuid,
            haulingRate: formHaulingRate ?? 0,
            driverRate: formDriverRate ?? 0,
            brokerRate: formBrokerRate ?? 0,
            unitOfMeasure: formUnitOfMeasure ?? '',
            isActive: formIsActive,
            haulingFlatRate: formMinimumFlatRate,
            driverFlatRate: formDriverMinimumFlatRate,
            brokerFlatRate: formBrokerMinimumFlatRate,
            rateToggle: formRateToggle,
            zone: formZone,
        };
    }, [
        formDriverMinimumFlatRate,
        formDriverRate,
        formEquipmentType?.id,
        formIsActive,
        formMinimumFlatRate,
        formQuote?.id,
        formHaulingRate,
        formSite?.id,
        formBrokerMinimumFlatRate,
        formBrokerRate,
        formUnitOfMeasure,
        formRateToggle,
        formZone,
    ]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<ISiteHaulingLineItemFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof ISiteHaulingLineItemFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    const isFormDirty = useCallback(() => {
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formQuote?.id !== initValues?.quote?.id;
        isDirty = isDirty || formSite !== initValues?.site;
        isDirty = isDirty || formEquipmentType !== initValues?.equipmentType;
        isDirty = isDirty || formUnitOfMeasure !== initValues?.unitOfMeasure;
        isDirty = isDirty || formHaulingRate !== initValues?.haulingRate;
        isDirty = isDirty || formDriverRate !== initValues?.driverRate;
        isDirty = isDirty || formBrokerRate !== initValues?.brokerRate;
        isDirty = isDirty || formMinimumFlatRate !== initValues?.haulingFlatRate;
        isDirty = isDirty || formDriverMinimumFlatRate !== initValues?.driverFlatRate;
        isDirty = isDirty || formBrokerMinimumFlatRate !== initValues?.brokerFlatRate;
        isDirty = isDirty || formZone !== initValues?.zone;

        return isDirty;
    }, [
        formDriverMinimumFlatRate,
        formDriverRate,
        formEquipmentType,
        formIsActive,
        formMinimumFlatRate,
        formQuote?.id,
        formHaulingRate,
        formSite,
        formBrokerMinimumFlatRate,
        formBrokerRate,
        formUnitOfMeasure,
        formZone,
        initValues?.driverFlatRate,
        initValues?.driverRate,
        initValues?.equipmentType,
        initValues?.isActive,
        initValues?.haulingFlatRate,
        initValues?.quote?.id,
        initValues?.haulingRate,
        initValues?.site,
        initValues?.brokerFlatRate,
        initValues?.brokerRate,
        initValues?.unitOfMeasure,
        initValues?.zone,
    ]);

    const handleQuoteChange = useCallback((quote: QuoteDto) => {
        setFormQuote(quote);
    }, []);

    const handleSiteChange = useCallback((site?: SiteDto) => {
        setFormSite(site);
    }, []);

    const handleEquipmentTypeChange = useCallback((equipmentType?: EquipmentTypeDto) => {
        setFormEquipmentType(equipmentType);
    }, []);

    const handleRateChange = useCallback((value?: number) => {
        setFormHaulingRate(value);
    }, []);

    const handleDriverRateChange = useCallback((value?: number) => {
        setFormDriverRate(value);
    }, []);

    const handleBrokerRateChange = useCallback((value?: number) => {
        setFormBrokerRate(value);
    }, []);

    const handleMinimumFlatRateChange = useCallback((value?: number) => {
        setFormMinimumFlatRate(value);
    }, []);

    const handleDriverMinimumFlatRateChange = useCallback((value?: number) => {
        setFormDriverMinimumFlatRate(value);
    }, []);

    const handleBrokerMinimumFlatRateChange = useCallback((value?: number) => {
        setFormBrokerMinimumFlatRate(value);
    }, []);

    const handleUnitOfMeasureChange = useCallback((value?: string) => {
        if (isValidUnitOfMeasure(value ?? '')) {
            setFormUnitOfMeasure(value as UnitOfMeasure);
        } else {
            console.error('Attempted to set unit of measure to invalid value.');
        }
    }, []);

    const handleRateToggleChange = useCallback((rateToggle: RateToggle) => {
        setFormRateToggle(rateToggle);
    }, []);

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
    }, []);

    const handleZoneChange = useCallback((value?: string) => {
        setFormZone(value ?? '');
    }, []);

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_SITE_HAULING_LINE_ITEM),
                ...formValues,
            };
            save(updatedUserRecord);
        }
    };

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleSiteChange,
        handleEquipmentTypeChange,
        handleRateChange,
        handleDriverRateChange,
        handleBrokerRateChange,
        handleUnitOfMeasureChange,
        handleIsActiveChange,
        handleMinimumFlatRateChange,
        handleDriverMinimumFlatRateChange,
        handleBrokerMinimumFlatRateChange,
        handleRateToggleChange,
        handleZoneChange,
        formQuote,
        formSite,
        formEquipmentType,
        formHaulingRate,
        formDriverRate,
        formBrokerRate,
        formUnitOfMeasure,
        formIsActive,
        formMinimumFlatRate,
        formDriverMinimumFlatRate,
        formBrokerMinimumFlatRate,
        formRateToggle,
        formZone,
        validateField,
        initValues,
    };
}
