import { useCallback, useEffect, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { IFilterChip } from "../../../Components/CoreLib/library";
import { emptyGuid } from "../../../util";

export interface IMaterialPriceFilterOptions {
    siteId: string,
    siteName: string,
    materialId: string,
    materialName: string
}

export const CHIP_KEYS = {
    site: 'Site',
    material: 'Material',
};

export function useMaterialPriceFilters() {
    const [searchParams, setSearchParams] = useSearchParams();
    const siteId = searchParams.get('siteId');
    const siteName = searchParams.get('siteName');
    const materialId = searchParams.get('materialId');
    const materialName = searchParams.get('materialName');
    const [materialPriceFilters, setMaterialPriceFilters] = useState<IMaterialPriceFilterOptions>({
        siteId: siteId && siteName ? siteId : emptyGuid,
        siteName: siteId && siteName ? siteName : '',
        materialId: materialId && materialName ? materialId : emptyGuid,
        materialName: materialId && materialName ? materialName : '',
    });

    useEffect(() => {
        const { siteId, siteName, materialId, materialName } = materialPriceFilters;
        let updatedSearchParams = {};
        if (siteId !== emptyGuid && siteName !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                siteId,
                siteName,
            };
        }

        if (materialId !== emptyGuid && materialName !== '') {
            updatedSearchParams = {
                ...updatedSearchParams,
                materialId,
                materialName,
            };
        }

        setSearchParams(createSearchParams(updatedSearchParams));
    }, [materialPriceFilters, setSearchParams]);

    const handleSearchTermFilterChange = (updatedFilters: IMaterialPriceFilterOptions) => {
        setMaterialPriceFilters(updatedFilters);
    };

    const getFilterChips = useCallback(() => {
        const filterChips: IFilterChip[] = [];
        if (materialPriceFilters.siteId !== emptyGuid) {
            filterChips.push({
                key: CHIP_KEYS.site,
                text: materialPriceFilters.siteName,
            });
        }
        if (materialPriceFilters.materialId !== emptyGuid) {
            filterChips.push({
                key: CHIP_KEYS.material,
                text: materialPriceFilters.materialName,
            });
        }
        return filterChips;
    }, [materialPriceFilters]);

    const handleRemoveFilterChip = useCallback((filterChipKey: string) => {
        switch (filterChipKey) {
            case CHIP_KEYS.site:
                setMaterialPriceFilters({
                    ...materialPriceFilters,
                    siteId: emptyGuid,
                    siteName: '',
                });
                break;
            case CHIP_KEYS.material:
                setMaterialPriceFilters({
                    ...materialPriceFilters,
                    materialId: emptyGuid,
                    materialName: '',
                });
                break;
            default:
                console.warn('Invalid filter chip key provided: ', filterChipKey);
                break;
        }
    }, [materialPriceFilters]);

    return {
        materialPriceFilters,
        handleSearchTermFilterChange,
        getFilterChips,
        handleRemoveFilterChip
    }
}