import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialDto } from '../../dtos';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateMaterialMutation } from '../../store/generated/generatedApi';
import { MaterialForm } from '../../Components/Forms/MaterialForm';

export const AddMaterialView: FC = () => {
    const navigate = useNavigate();
    const [createMaterial, { data, isError, isSuccess, reset }] = useCreateMaterialMutation();

    useSuccessfulCreateSnackbar('Material', isSuccess, () => navigate(`/dataManagement/material/${data?.id}`));
    useFailedCreateSnackbar('material', isError, reset)

    const handleSave = (values: MaterialDto) => {
        createMaterial(values);
    };

    const handleCancel = () => {
        navigate('/dataManagement/materials');
    };

    return <MaterialForm save={handleSave} cancel={handleCancel} />;
};
