import { AccountBox } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FC, useMemo } from 'react';
import { useArchiveContactDeleteByIdMutation, useGetCustomerByIdQuery, useGetSiteByIdQuery } from '../../../store/generated/generatedApi';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { FormSection, IFormProps, LoadingIndicator } from '../../CoreLib/library';
import { ContactDto } from '../../../dtos';
import { useContactForm } from './useContactForm';
import { ContactForm } from './ContactForm';

export interface IContactFullPageFormProps extends IFormProps<ContactDto> {
    redirectUrl: string;
}

export const ContactFullPageForm: FC<IContactFullPageFormProps> = (props) => {
    const { isLoading, redirectUrl } = props;

    const contactFormManager = useContactForm(props);
    const { handleSave, handleCancel, isFormDirty, formFirstName, formLastName, formIsActive, handleIsActiveChange, customerId, siteId } = contactFormManager;

    const { data: site, isLoading: siteLoading } = useGetSiteByIdQuery({ id: siteId! });
    const { data: customer, isLoading: customerLoading } = useGetCustomerByIdQuery({ id: siteId! });

    const contactDisplayName = useMemo(() => {
        return (formFirstName + ' ' + formLastName).trim();
    }, [formFirstName, formLastName]);

    const currentContactId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    const getBreadcrumbs = useMemo(() => {
        return customerId
            ? [
                { label: 'Home', navLink: '/' },
                { label: 'Customers', navLink: '/customers' },
                // TODO: this made sense at the time but now that we are not loading all customers here this should really look up the customer by ID or find the name some other way.
                { label: `${customer?.name ?? 'Customer'}`, navLink: `/customer/${customerId}` },
            ]
            : [
                { label: 'Home', navLink: '/' },
                { label: 'Data Management', navLink: '/dataManagement' },
                { label: 'Sites', navLink: '/dataManagement/sites' },
                // TODO: this made sense at the time but now that we are not loading all sites here this should really look up the site by ID or find the name some other way.
                { label: `${site?.name ?? 'Site'}`, navLink: `/dataManagement/site/${siteId}` },
            ];
    }, [customer?.name, customerId, site?.name, siteId]);

    if (isLoading || customerLoading || siteLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <SlimFormHeader
                objectName='Contact'
                permissionsTypeName={'contact'}
                icon={<AccountBox />}
                title={contactDisplayName}
                breadcrumbs={getBreadcrumbs}
                isActive={formIsActive}
                handleIsActiveChange={handleIsActiveChange}
                id={currentContactId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'contact'}
                deleteMutation={useArchiveContactDeleteByIdMutation}
                redirectUrl={redirectUrl}
            />
            <Grid item mx={2}>
                <FormSection>
                    <ContactForm contactFormManager={contactFormManager} />
                </FormSection>
            </Grid>
        </Grid>
    );
};
