import { AccountBox } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, MenuItem, OutlinedInput, Select, SelectChangeEvent, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { UserDto } from '../../../dtos';
import { useArchiveUserDeleteByIdMutation, useGetUserRolesQuery } from '../../../store/generated/generatedApi';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { AuthenticatedComponent, BasicTransferList, FormSection, IFormProps, ITransferListItem, LoadingIndicator } from '../../CoreLib/library';
import { convertRolesToTransferListItems, convertTransferListItemsToRoles } from './mappers';
import { useUserForm } from './useUserForm';
import { BrokerSelect } from '../../CommonInputs';
import { DriverForm } from '../DriverForm';
import { usePrompt } from '../../../Views';

export const UserForm: FC<IFormProps<UserDto>> = (props) => {
    const { isLoading: isLoadingUserRecord } = props;
    const { data: allUserRoles, isLoading: isLoadingUserRoles } = useGetUserRolesQuery();
    const {
        handleSave,
        handleCancel,
        fieldErrors,
        validateField,
        isFormDirty,
        formFirstName,
        handleFirstNameChange,
        formLastName,
        handleLastNameChange,
        formIsActive,
        handleIsActiveChange,
        formEmail,
        handleEmailChange,
        formPhoneNumber,
        handlePhoneNumberChange,
        formRoles,
        handleAssignedRolesChange,
        formTitle,
        handleTitleChange,
        formBrokerId,
        handleBrokerChange,
        haulerType,
        formDriver,
        handleFormHaulerTypeChange,
        handleDriverChange,
        setFormDriverValid
    } = useUserForm(props);

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const userDisplayName = useMemo(() => {
        return (formFirstName + ' ' + formLastName).trim();
    }, [formFirstName, formLastName]);

    const currentUserId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    const transferListCurrentItems: ITransferListItem[] = useMemo(() => {
        return convertRolesToTransferListItems(formRoles);
    }, [formRoles]);

    const transferListAllItems: ITransferListItem[] = useMemo(() => {
        return convertRolesToTransferListItems(allUserRoles ?? []);
    }, [allUserRoles]);

    const handleTransferListUpdate = useCallback(
        (updatedItems: ITransferListItem[]) => {
            handleAssignedRolesChange(convertTransferListItemsToRoles(updatedItems));
        },
        [handleAssignedRolesChange]
    );

    if (isLoadingUserRecord || isLoadingUserRoles) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <SlimFormHeader
                objectName='User'
                permissionsTypeName={'users'}
                icon={<AccountBox />}
                title={userDisplayName}
                breadcrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Admin', navLink: '', isText: true },
                    { label: 'Users', navLink: '/users' },
                ]}
                isActive={formIsActive}
                handleIsActiveChange={handleIsActiveChange}
                id={currentUserId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'user'}
                deleteMutation={useArchiveUserDeleteByIdMutation}
            />
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={4}>
                            <FormControl error={!!fieldErrors.get('firstName')} fullWidth required>
                                <FormLabel>First Name</FormLabel>
                                <OutlinedInput value={formFirstName} onChange={handleFirstNameChange} onBlur={() => validateField('firstName')} />
                                <FormHelperText>{fieldErrors.get('firstName')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl error={!!fieldErrors.get('lastName')} fullWidth required>
                                <FormLabel>Last Name</FormLabel>
                                <OutlinedInput value={formLastName} onChange={handleLastNameChange} onBlur={() => validateField('lastName')} />
                                <FormHelperText>{fieldErrors.get('lastName')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <FormLabel>Title</FormLabel>
                                <OutlinedInput value={formTitle} onChange={handleTitleChange} />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={4}>
                            <FormControl error={!!fieldErrors.get('email')} fullWidth required>
                                <FormLabel>Email</FormLabel>
                                <OutlinedInput value={formEmail} onChange={handleEmailChange} onBlur={() => validateField('email')} />
                                <FormHelperText>{fieldErrors.get('email')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl error={!!fieldErrors.get('phoneNumber')} fullWidth>
                                <FormLabel>Phone</FormLabel>
                                <OutlinedInput value={formPhoneNumber} onChange={handlePhoneNumberChange} onBlur={() => validateField('phoneNumber')} />
                                <FormHelperText>{fieldErrors.get('phoneNumber')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <AuthenticatedComponent
                            requiredPermissions={['assign:userRoles']}
                            children={(
                                <Grid item xs={12}>
                                    <BasicTransferList
                                        targetColumnName='Active Roles'
                                        sourceColumnName='Available Roles'
                                        currentItems={transferListCurrentItems}
                                        allItems={transferListAllItems}
                                        onCurrentItemsChanged={handleTransferListUpdate}
                                    />
                                </Grid>
                            )}
                        />
                    </Grid>
                </FormSection>
            </Grid>
            <Grid item mx={2}>
                {haulerType !== undefined &&
                    <FormSection>
                        <Grid item>
                            <Typography variant='h2'>
                                Hauler Configuration
                            </Typography>
                        </Grid>
                        <Grid item container direction='row'>
                            <Grid item xs={2}>
                                <FormControl fullWidth>
                                    <FormLabel>
                                        Hauler Type
                                    </FormLabel>
                                    <Select
                                        value={haulerType}
                                        onChange={(event: SelectChangeEvent) => {
                                            handleFormHaulerTypeChange(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'broker'}>
                                            Broker
                                        </MenuItem>
                                        <MenuItem value={'driver'}>
                                            Driver
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item container direction='row'>
                            {haulerType === 'broker' &&
                                <Grid item xs={2}>
                                    <BrokerSelect
                                        selectedBrokerId={formBrokerId}
                                        handleSelectedBrokerChange={handleBrokerChange}
                                        onBlur={() => validateField('brokerId')}
                                        required
                                        errorMessage={fieldErrors.get('brokerId')}
                                    />
                                </Grid>
                            }
                            {haulerType === 'driver' &&
                                <Grid item container direction='row' spacing={3}>
                                    <Grid item xs={12}>
                                        <DriverForm save={handleDriverChange} setFormDriverValid={setFormDriverValid} cancel={() => { }} initValues={props.initValues?.driver} currentValues={formDriver} />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </FormSection>
                }
            </Grid>
        </Grid>
    );
};
