import { SwapVert } from "@mui/icons-material";
import { Button, FormControl, FormLabel, Grid, Typography } from "@mui/material";
import { FC, useState } from "react";
import { RegionDto } from "../../dtos";
import { FormInput, IEntityAutocomplete } from "../CoreLib/library";
import { AddressForm } from "./AddressForm";
import { AddressLookup } from "./AddressLookup";
import { SiteSelect } from "./SiteSelect";

export interface IHaulToFromFormProps {
    handleHaulToAddressChange?: (address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }) => void;
    handleHaulFromAddressChange?: (address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }) => void;
    handleSwap?: (address1: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string, siteName?: string, region?: RegionDto }, address2: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string, siteName?: string, region?: RegionDto }) => void;
    enableLookup?: boolean;
    disabled?: boolean;
    haulToAddressLine1: string;
    haulToAddressLine2?: string;
    haulToCountry: string;
    haulToCity: string;
    haulToState: string;
    haulToZipCode: string;
    haulFromAddressLine1: string;
    haulFromAddressLine2?: string;
    haulFromCountry: string;
    haulFromCity: string;
    haulFromState: string;
    haulFromZipCode: string;
    fieldErrors?: any;
    formDirection?: 'row' | 'column';
    haulToSiteName?: string;
    haulFromSiteName?: string;
    haulToRegion?: RegionDto;
    haulFromRegion?: RegionDto;
    handleHaulToSiteNameChange?: (value: string) => void;
    handleHaulFromSiteNameChange?: (value: string) => void;
    handleHaulToRegionChange?: (value: RegionDto) => void;
    handleHaulFromRegionChange?: (value: RegionDto) => void;
    handleHaulFromSiteLookup?: (address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }, siteName: string, region?: RegionDto) => void;
    handleHaulToSiteLookup?: (address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }, siteName: string, region?: RegionDto) => void;
    regions: RegionDto[];
};


export const HaulToFromForm: FC<IHaulToFromFormProps> = props => {
    const {
        handleHaulToAddressChange,
        handleHaulFromAddressChange,
        enableLookup = false,
        regions,
        disabled,
        haulToAddressLine1,
        haulToAddressLine2,
        haulToCountry,
        haulToCity,
        haulToState,
        haulToZipCode,
        haulFromAddressLine1,
        haulFromAddressLine2,
        haulFromCountry,
        haulFromCity,
        haulFromState,
        haulFromZipCode,
        fieldErrors,
        formDirection = 'row',
        haulToRegion,
        haulFromRegion,
        haulFromSiteName,
        haulToSiteName,
        handleHaulFromSiteLookup,
        handleHaulToSiteLookup,
        handleHaulFromRegionChange,
        handleHaulFromSiteNameChange,
        handleHaulToRegionChange,
        handleHaulToSiteNameChange,
        handleSwap,
    } = props;

    const [haulToKey, setHaulToKey] = useState(0);
    const [haulFromKey, setHaulFromKey] = useState(0);

    const [fromSelectedSiteId, setFromSelectedSiteId] = useState('');
    const [toSelectedSiteId, setToSelectedSiteId] = useState('');


    return (
        <Grid item container direction='column'>
            <Grid item>
                <Typography> <strong>Haul From</strong></Typography>
            </Grid>
            <Grid item container direction='row' spacing={2}>
                {enableLookup && <Grid item xs={12} sm={4} md={3}>
                    <AddressLookup
                        handleAddressChange={handleHaulFromAddressChange}
                        key={haulFromKey}
                        disabled={disabled}
                    />
                </Grid>}
                {enableLookup && <Grid item xs={3}>
                    <SiteSelect
                        label="Site Lookup"
                        selectedSiteId={fromSelectedSiteId}
                        handleSelectedSiteChange={(site) => {
                            if (site) {
                                handleHaulFromSiteLookup?.({ addressLine1: site.addressLine1, addressLine2: site.addressLine2, country: site.country, city: site.city, state: site.state, zipCode: site.zipCode }, site.name, site.region);
                            }
                            setFromSelectedSiteId(site?.id ?? '');
                        }}
                        disabled={disabled}
                    />
                </Grid>}
                <Grid item xs={3}>
                    <FormInput
                        value={haulFromSiteName}
                        onChange={(event) => {
                            handleHaulFromSiteNameChange?.(event.target.value);
                        }}
                        label='Site Name'
                        name='site'
                        errorText={fieldErrors?.get('site')}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl
                        fullWidth
                        disabled={disabled}>
                        <FormLabel>Region</FormLabel>
                        <IEntityAutocomplete
                            options={regions ?? []}
                            onChange={(_e, value) => { handleHaulFromRegionChange?.(value); }}
                            value={haulFromRegion}
                            getOptionLabel={(option: RegionDto) => option.name}
                            disabled={disabled}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item container direction={formDirection} spacing={formDirection === 'row' ? 2 : 1} wrap="nowrap">
                <AddressForm
                    handleAddressChange={handleHaulFromAddressChange}
                    addressLine1={haulFromAddressLine1}
                    addressLine2={haulFromAddressLine2}
                    country={haulFromCountry}
                    city={haulFromCity}
                    state={haulFromState}
                    zipCode={haulFromZipCode}
                    fieldErrors={fieldErrors}
                    disabled={disabled}
                    enableLookup={false}
                    allOptional
                    enableAddressLine2={!disabled}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    onClick={() => {
                        handleSwap?.({ addressLine1: haulFromAddressLine1, addressLine2: haulFromAddressLine2, country: haulFromCountry, city: haulFromCity, state: haulFromState, zipCode: haulFromZipCode, siteName: haulFromSiteName, region: haulFromRegion }, { addressLine1: haulToAddressLine1, addressLine2: haulToAddressLine2, country: haulToCountry, city: haulToCity, state: haulToState, zipCode: haulToZipCode, siteName: haulToSiteName, region: haulToRegion });
                        setHaulToKey(key => key + 1);
                        setHaulFromKey(key => key + 1);
                        setFromSelectedSiteId('');
                        setToSelectedSiteId('');
                    }}
                    startIcon={<SwapVert />}
                    color='primary'
                    fullWidth
                    variant='contained'
                    disabled={disabled}
                    size='large'
                >
                    SWAP
                </Button>
            </Grid>
            <Grid item>
                <Typography><strong>Haul To</strong></Typography>
            </Grid>
            <Grid item container direction='row' spacing={2}>
                {enableLookup && <Grid item xs={12} sm={4} md={3}>
                    <AddressLookup
                        handleAddressChange={handleHaulToAddressChange}
                        key={haulToKey}
                        disabled={disabled}
                    />
                </Grid>}
                {enableLookup && <Grid item xs={3}>
                    <SiteSelect
                        label="Site Lookup"
                        selectedSiteId={toSelectedSiteId}
                        handleSelectedSiteChange={(site) => {
                            if (site) {
                                handleHaulToSiteLookup?.({ addressLine1: site.addressLine1, addressLine2: site.addressLine2, country: site.country, city: site.city, state: site.state, zipCode: site.zipCode }, site.name, site.region);
                            }
                            setToSelectedSiteId(site?.id ?? '');
                        }}
                        disabled={disabled}
                    />
                </Grid>}
                <Grid item xs={3}>
                    <FormInput
                        value={haulToSiteName}
                        onChange={(event) => {
                            handleHaulToSiteNameChange?.(event.target.value);
                        }}
                        label='Site Name'
                        name='site'
                        errorText={fieldErrors?.get('site')}
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl
                        fullWidth
                        disabled={disabled}>
                        <FormLabel>Region</FormLabel>
                        <IEntityAutocomplete
                            options={regions ?? []}
                            onChange={(_e, value) => { handleHaulToRegionChange?.(value); }}
                            value={haulToRegion}
                            getOptionLabel={(option: RegionDto) => option.name}
                            disabled={disabled}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item container direction={formDirection} spacing={formDirection === 'row' ? 2 : 1} wrap="nowrap">
                <AddressForm
                    handleAddressChange={handleHaulToAddressChange}
                    addressLine1={haulToAddressLine1}
                    addressLine2={haulToAddressLine2}
                    country={haulToCountry}
                    city={haulToCity}
                    state={haulToState}
                    zipCode={haulToZipCode}
                    fieldErrors={fieldErrors}
                    disabled={disabled}
                    enableLookup={false}
                    allOptional
                    enableAddressLine2={!disabled}
                />
            </Grid>
        </Grid>
    );
};