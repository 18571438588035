import { CopyAll } from '@mui/icons-material';
import { Autocomplete, FormControl, FormHelperText, FormLabel, IconButton, TextField, Tooltip } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent, useMemo } from 'react';
import { SiteDto } from '../../dtos';
import { useGetSiteQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface ISiteSelectProps {
    label?: string;
    selectedSiteId: string;
    handleSelectedSiteChange: (selectedSite?: SiteDto) => void;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    hideCopyAddressButton?: boolean;
}

export const SiteSelect: FC<ISiteSelectProps> = (props) => {
    const { label = 'Site', selectedSiteId, handleSelectedSiteChange, required, errorMessage, disabled, onBlur, hideCopyAddressButton } = props;
    const {
        data: sites,
        isLoading: isLoadingSites,
        isError: isErrorLoadingSites,
    } = useGetSiteQuery({
        sortKey: 'CODE',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false,
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: SiteDto | null) => {
        handleSelectedSiteChange(value ?? undefined);
    };

    const selectedSite = useMemo(() => {
        if (selectedSiteId === '' || selectedSiteId === emptyGuid) {
            return null;
        }
        return sites?.pageResults.find((x) => x.id === selectedSiteId) ?? null;
    }, [selectedSiteId, sites]);

    const selectedSiteAddressString = useMemo(() => {
        if (!selectedSite) {
            return '';
        }
        let addressString = '';
        if (selectedSite.addressLine1) {
            addressString += selectedSite.addressLine1;
        }
        if (selectedSite.addressLine2) {
            if (addressString.length > 0) {
                addressString += ' ';
            }
            addressString += selectedSite.addressLine2;
        }
        if (selectedSite.city) {
            if (addressString.length > 0) {
                addressString += ', ';
            }
            addressString += selectedSite.city;
        }
        if (selectedSite.state) {
            if (addressString.length > 0) {
                addressString += ', ';
            }
            addressString += selectedSite.state;
        }
        if (selectedSite.zipCode) {
            if (addressString.length > 0) {
                addressString += ', ';
            }
            addressString += selectedSite.zipCode;
        }
        return addressString;
    }, [selectedSite]);

    return (
        <FormControl error={isErrorLoadingSites || !!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>{label}</FormLabel>
            <Autocomplete
                value={selectedSite}
                onChange={handleSelectChange}
                loading={isLoadingSites}
                options={sites?.pageResults ?? []}
                getOptionLabel={(x) => `${x.code} - ${x.name}`}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {params.InputProps.endAdornment}
                                    {selectedSiteAddressString && !hideCopyAddressButton && (
                                        <Tooltip title={selectedSiteAddressString} placement='top'>
                                            <IconButton
                                                aria-label='copy'
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    navigator.clipboard.writeText(selectedSiteAddressString);
                                                }}>
                                                <CopyAll />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </>
                            ),
                        }}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
            />
            <FormHelperText>{isErrorLoadingSites ? 'Failed to load site options' : errorMessage ?? ''}</FormHelperText>
        </FormControl>
    );
};
