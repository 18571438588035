import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import logo from '../../../src/logoHorizontal.png';
import { usePageTitleSetter } from '../../util';

export const Home: FC = () => {
    usePageTitleSetter('TriTon');

    return (
        <Box display='flex' flexDirection='column' gap={3} py={2} pt='calc(50vh - 176px)'>
            <Box sx={{ marginY: 1, marginX: 0, paddingX: 0 }} alignItems={'center'} alignSelf={'center'}>
                <a href='/'>
                    <img src={logo} alt='TRIAX Logo' height={'100%'} width={'400'} />
                </a>
            </Box>
            <Box alignSelf={'center'}>
                <Typography>Welcome to TriTon, a hauling service application by Laura Kopetsky Tri-Ax</Typography>
            </Box>
        </Box>
    );
};
