import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { JobDto } from '../../dtos';
import { ApiError, FormSection, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetJobByIdQuery, useUpdateJobMutation } from '../../store/generated/generatedApi';
import { JobForm } from '../../Components/Forms/JobForm';
import { Grid, Tab, Tabs } from '@mui/material';
import { JobDetailsCard, JobNotesCard, JobOrdersCard } from './Components';
import { TabContext, TabPanel } from '@mui/lab';
import { Box } from '@mui/system';

export const EditJobView: FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { tab } = useParams();
    const [selectedTab, setSelectedTab] = useState(tab ?? '');
    const { data, isLoading, error, refetch } = useGetJobByIdQuery({ id: id! });
    const [updateJob, { isSuccess, isError, reset }] = useUpdateJobMutation();

    useSuccessfulUpdateSnackbar('Job', isSuccess, reset);
    useFailedUpdateSnackbar('Job', isError, reset);

    const handleSave = (values: JobDto) => {
        updateJob(values);
    };

    const handleCancel = () => {
        navigate('/jobs');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error || !id) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column' gap={2}>
            <Grid item container direction='row' xs={12}>
                <JobForm save={handleSave} cancel={handleCancel} initValues={data} refetch={refetch} />
            </Grid>
            <Grid item mx={2}>
                <FormSection>
                    <TabContext value={selectedTab}>
                        <Box sx={{borderBottom: '1px solid black'}}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, value) => setSelectedTab(value)}
                                variant='fullWidth'
                                textColor='primary'
                                indicatorColor='primary'
                            >
                                <Tab label='GENERAL' key={0} value={''}/>
                                <Tab label='ORDERS' key={1} value={'orders'}/>
                            </Tabs>
                        </Box>
                        <TabPanel value={''}>
                            <Grid item container direction='column' spacing={2}>
                                <Grid item container direction='row' px={2}>
                                    <Grid item xs={12}>
                                        <JobDetailsCard job={data} />
                                    </Grid>
                                </Grid>
                                <Grid item container direction='row' px={2} pb={2}>
                                    <Grid item xs={12}>
                                        <JobNotesCard jobId={id} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value={'orders'}>
                            <Grid item container direction='column' spacing={2}>
                                <Grid item container direction='row' px={2}>
                                    <Grid item xs={12}>
                                        {data && <JobOrdersCard job={data} />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </FormSection>
            </Grid>
        </Grid>
    );
};
