import { FC } from 'react';
import { StandardCardWithHeader } from '../../../Components/CoreLib/library';
import { ListAlt } from '@mui/icons-material';
import { JobDto } from '../../../dtos';
import { OrderListView } from '../../Orders/OrderListView';

export interface IJobOrdersCardProps {
    job: JobDto;
}

export const JobOrdersCard: FC<IJobOrdersCardProps> = props => {
    const { job } = props;

    return (
        <StandardCardWithHeader headerTitle='Orders' headerIcon={<ListAlt />}>
            <OrderListView job={job} />
        </StandardCardWithHeader>
    );
}