import { LineItemDto, PricingMethodType, MaterialLineItemDto } from '../dtos';
import { LineItemTypes } from './constants';

export const getDefaultPricingMethod = (lineItem?: LineItemDto): PricingMethodType => {
    return lineItem?.type === LineItemTypes.Material
        ? (lineItem as MaterialLineItemDto).isBilledToCustomer
            ? PricingMethodType.HaulOnly
            : PricingMethodType.Standard
        : PricingMethodType.Standard;
};
