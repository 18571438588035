import { EmojiTransportation } from '@mui/icons-material';
import { Grid, debounce } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { BrokerDto } from '../../../dtos';
import { useArchiveBrokerDeleteByIdMutation } from '../../../store/generated/generatedApi';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { IFormProps, LoadingIndicator, FormSection, FormInput, FormNumberInput } from '../../CoreLib/library';
import { useBrokerForm } from './useBrokerForm';
import { AddressForm } from '../../CommonInputs';
import { usePrompt } from '../../../Views';

export const BrokerForm: FC<IFormProps<BrokerDto>> = (props) => {
	const { isLoading } = props;
	const {
		isFormDirty,
		handleSave,
		handleCancel,
		fieldErrors,
		handleCodeChange,
		handleNameChange,
		handleAddressLine1Change,
		handleAddressLine2Change,
		handleCityChange,
		handleStateChange,
		handleZipCodeChange,
		handleCountryChange,
		handleIsActiveChange,
		handlePayRateChange,
		formCode,
		formName,
		formAddressLine1,
		formAddressLine2,
		formCity,
		formState,
		formZipCode,
		formCountry,
		formIsActive,
		formPayRate
	} = useBrokerForm(props);

	usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

	const currentBrokerId = useMemo(() => {
		return props.initValues?.id;
	}, [props.initValues?.id]);

	const debouncedHandleCodeChange = useCallback(
		() => debounce((event: React.ChangeEvent<HTMLInputElement>) => {
			handleCodeChange(event);
		}, 300)
		, [handleCodeChange]);

	if (isLoading) {
		return <LoadingIndicator />;
	}

	return (
		<Grid container direction='column' spacing={3}>
			<SlimFormHeader
				objectName='Broker'
				permissionsTypeName={'broker'}
				icon={<EmojiTransportation />}
				title={formName ? formName : 'New Broker'}
				breadcrumbs={[
					{ label: 'Home', navLink: '/' },
					{ label: 'Data Management', navLink: '/dataManagement' },
					{ label: 'Brokers', navLink: '/dataManagement/brokers' },
				]}
				isActive={formIsActive}
				handleIsActiveChange={handleIsActiveChange}
				id={currentBrokerId}
				isFormDirty={isFormDirty}
				handleCancel={handleCancel}
				handleSave={handleSave}
				entityNameSingular={'broker'}
				deleteMutation={useArchiveBrokerDeleteByIdMutation}
			/>
			<Grid item mx={2}>
				<FormSection>
					<Grid item container direction='row' spacing={3}>
						<Grid item xs={12} sm={6} md={3}>
							<FormInput
								value={formCode}
								onChange={(event) => {
									debouncedHandleCodeChange()(event);
								}}
								label='Code'
								name='code'
								errorText={fieldErrors.get('code')}
								fullWidth
								required
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormInput
								value={formName}
								onChange={(event) => {
									handleNameChange(event.target.value ?? '');
								}}
								label='Name'
								name='name'
								errorText={fieldErrors.get('name')}
								fullWidth
								required
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormNumberInput
								value={formPayRate}
								onChange={handlePayRateChange}
								label='Pay Rate (%)'
								name='payRate'
								fullWidth
								inputProps={{ min: 0, max: 100, step: 1.0 }}
								required
								errorText={fieldErrors.get('payRate')}
								error={!!fieldErrors.get('payRate')}
							/>
						</Grid>
					</Grid>
					<Grid item container direction='row' spacing={3}>
						<AddressForm
							handleAddressLine1Change={handleAddressLine1Change}
							handleAddressLine2Change={handleAddressLine2Change}
							handleCountryChange={handleCountryChange}
							handleCityChange={handleCityChange}
							handleStateChange={handleStateChange}
							handleZipCodeChange={handleZipCodeChange}
							enableLookup
							addressLine1={formAddressLine1}
							addressLine2={formAddressLine2}
							country={formCountry}
							city={formCity}
							state={formState}
							zipCode={formZipCode}
							fieldErrors={fieldErrors}
						/>
					</Grid>
				</FormSection>
			</Grid>
		</Grid>
	);
};
