import { AttachMoney, MoreVert } from "@mui/icons-material";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, Button } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { LineItemDto, QuoteDto } from "../../../dtos";
import { formatCurrency } from "../../../util";
import { DataTableColumn, StandardListViewV2 } from "../../CoreLib/library";
import { useGetLineItemQuoteSummaryLineItemsQuery } from "../../../store/generated/generatedApi";

export interface IAcceptQuoteLineItemsDialog {
    onClose: () => void;
    onConfirm: (selectedLineItems: LineItemDto[]) => void;
    open: boolean;
    quote: QuoteDto;
}

export const AcceptQuoteLineItemsDialog: FC<IAcceptQuoteLineItemsDialog> = (props) => {
    const { onClose, onConfirm, open, quote } = props;
    const [selected, setSelected] = useState<LineItemDto[]>([]);

    const tableColumns: DataTableColumn<LineItemDto>[] = useMemo(() => {
        let columns = [
            { key: 'description', label: 'Description', sortKey: 'DESCRIPTION', unsorted: true },
            { key: 'type', label: 'Type', sortKey: 'TYPE', unsorted: true },
            { key: 'region', label: 'Region', sortKey: 'REGION', unsorted: true },
            { key: 'equipmentType', label: 'Equipment Type', sortKey: 'EQUIPMENT_TYPE', unsorted: true, fieldMapper: (row) => row.equipmentType?.type },
            { key: 'unitOfMeasure', label: 'Units', sortKey: 'UNIT', align: 'right', unsorted: true },
            { key: 'quantity', label: 'Quantity', sortKey: 'QUANTITY', align: 'right', unsorted: true, fieldMapper: (row) => row.quantity ? row.quantity?.toFixed(2) : '' },
            { key: 'quoteRate', label: 'Quote Rate', sortKey: 'QUOTE_RATE', unsorted: true, align: 'right', fieldMapper: (row) => row.quoteRate !== null ? formatCurrency(row.quoteRate) : '' },
            { key: 'salesPrice', label: 'Sales Price', sortKey: 'SALES_PRICE', unsorted: true, align: 'right', fieldMapper: (row) => row.salesPrice !== null ? formatCurrency(row.salesPrice) : '' },
            { key: 'status', label: 'Status', sortKey: 'STATUS', unsorted: true, align: 'right' },
        ] as DataTableColumn<LineItemDto>[];

        return columns;
    }, []);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg' PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography variant='h2'>
                    {<AttachMoney />} Accept Line Items
                </Typography>
            </DialogTitle>
            <DialogContent>
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle={''}
                    breadCrumbs={[]}
                    isPaginationVisible={false}
                    getDataQuery={useGetLineItemQuoteSummaryLineItemsQuery}
                    additionalQueryParameters={quote ? { quoteId: quote?.id, isAmended: true } : undefined}
                    isShowActiveToggleVisible={false}
                    tableColumns={tableColumns}
                    entityNameSingular='Quote'
                    editIcon={<MoreVert />}
                    permissionName='quote'
                    hideSearch
                    hideMenu
                    hideAddButtonDivider
                    onSelectedChange={(selected) => {
                        setSelected(selected);
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent='end'>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant='contained' size='medium' onClick={() => {
                            onConfirm(selected);
                        }}>
                            Accept
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}