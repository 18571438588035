import { EmojiTransportation, FolderSpecial, Inventory, LocalShipping, LocationOn, Payments } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { FC } from 'react';
import { AuthenticatedComponent, SlimPageHeader } from '../../Components/CoreLib/library';
import { DataManagementCard } from './Components';
import { usePageTitleSetter } from '../../util';

export const DataManagementView: FC = () => {
    usePageTitleSetter('Data Management');

    return (
        <Grid container direction='column'>
            <SlimPageHeader
                icon={<FolderSpecial />}
                title='Data Management'
                breadCrumbProps={{
                    links:
                        [
                            { label: 'Home', navLink: '/' },
                        ],
                    currentPageLabel: "Data Management",
                }}
            />
            <Grid item container direction='row'>
                <AuthenticatedComponent
                    requiredPermissions={[`read:site`]}
                    children={
                        <Grid item xs={12} sm={6} md={3} p={2}>
                            <DataManagementCard
                                icon={<LocationOn fontSize='inherit' color='inherit' />}
                                listViewRoute={'/dataManagement/sites'}
                                addNewRoute={'/dataManagement/site/create'}
                                title={'Sites'}
                                description={'Locations that are regularly hauled to and from.'}
                                permissionName='site'
                            />
                        </Grid>
                    }
                />
                <AuthenticatedComponent
                    requiredPermissions={[`read:material`]}
                    children={
                        <Grid item xs={12} sm={6} md={3} p={2}>
                            <DataManagementCard
                                icon={<Inventory fontSize='inherit' color='inherit' />}
                                listViewRoute={'/dataManagement/materials'}
                                addNewRoute={'/dataManagement/material/create'}
                                title={'Material'}
                                description={'Materials that are bought, sold, and hauled.'}
                                permissionName='material'
                            />
                        </Grid>
                    }
                />
                <AuthenticatedComponent
                    requiredPermissions={[`read:broker`]}
                    children={
                        <Grid item xs={12} sm={6} md={3} p={2}>
                            <DataManagementCard
                                icon={<EmojiTransportation fontSize='inherit' color='inherit' />}
                                listViewRoute={'/dataManagement/brokers'}
                                addNewRoute={'/dataManagement/broker/create'}
                                title={'Broker'}
                                description={'Brokers dispatch orders to their own drivers.'}
                                permissionName='broker'
                            />
                        </Grid>
                    }
                />
                <AuthenticatedComponent
                    requiredPermissions={[`read:equipment`]}
                    children={
                        <Grid item xs={12} sm={6} md={3} p={2}>
                            <DataManagementCard
                                icon={<LocalShipping fontSize='inherit' color='inherit' />}
                                listViewRoute={'/dataManagement/equipment'}
                                addNewRoute={'/dataManagement/equipment/create'}
                                title={'Equipment'}
                                description={'Vehicles used to haul materials.'}
                                permissionName='equipment'
                            />
                        </Grid>
                    }
                />
                <AuthenticatedComponent
                    requiredPermissions={[`read:materialPrice`]}
                    children={
                        <Grid item xs={12} sm={6} md={3} p={2}>
                            <DataManagementCard
                                icon={<Payments fontSize='inherit' color='inherit' />}
                                listViewRoute={'/dataManagement/materialPrices'}
                                addNewRoute={'/dataManagement/materialPrice/create'}
                                title={'Material Prices'}
                                description={'The prices of materials at sites'}
                                permissionName='materialPrice'
                            />
                        </Grid>
                    }
                />
            </Grid>
        </Grid>
    )
}