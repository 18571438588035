import { AddCircle, Group } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, Grid, IconButton } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { CustomerDto } from '../../dtos';
import { useCreateCustomerMutation } from '../../store/generated/generatedApi';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../CoreLib/library';
import { CustomerForm } from '../Forms/CustomerForm';
import { ModalSaveCloseFooter, ModalTitle } from '../Modal';
import { useCustomerForm } from '../Forms/CustomerForm/useCustomerForm';

export interface ICustomerQuickAddButtonProps {
    onAdd: (newCustomer: CustomerDto) => void;
    disabled?: boolean;
}

export const CustomerQuickAddButton: FC<ICustomerQuickAddButtonProps> = ({ onAdd, disabled }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [createCustomer, { data, isError, isSuccess, reset, isLoading }] = useCreateCustomerMutation();
    useSuccessfulCreateSnackbar('Customer', isSuccess, reset);
    useFailedCreateSnackbar('customer', isError, reset);

    const save = useCallback(
        (customer: CustomerDto) => {
            createCustomer(customer);
            setIsModalOpen(false);
        },
        [createCustomer]
    );

    const cancel = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const customerFormManager = useCustomerForm({ save, cancel, isLoading }, true);
    const { handleSave, handleCancel } = customerFormManager;

    useEffect(() => {
        if (data) {
            onAdd(data);
            reset()
        }
    }, [onAdd, data, reset]);

    return (
        <>
            <IconButton onClick={() => setIsModalOpen(true)} color='primary' disabled={disabled}>
                <AddCircle />
            </IconButton>
            <Dialog open={isModalOpen} fullWidth maxWidth='lg'>
                <ModalTitle icon={<Group sx={{ mr: 1 }} />} title={'Add Customer'} />
                <DialogContent>
                    <Grid container direction='row' gap={1}>
                        <CustomerForm
                            customerFormManager={customerFormManager}
                            isLoading={isLoading}
                            hideAccountInformation
                        />
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ height: 48 }}>
                    <ModalSaveCloseFooter onSaveClicked={handleSave} onCancelCloseClicked={handleCancel} />
                </DialogActions>
            </Dialog>
        </>
    );
};
