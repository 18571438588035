import _ from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { EquipmentTypeDto, HourlyLineItemDto, QuoteDto, SiteDto } from '../../../../../dtos';
import { emptyGuid } from '../../../../../util';
import { IFormProps, IFormFieldValidationConfig, isNotBlank, runFormValidation, runFieldValidation, isGreaterThanOrEqualTo } from '../../../../CoreLib/library';
import { SelectChangeEvent } from '@mui/material';

export interface IHourlyLineItemFormValues {
    quoteId: string;
    equipmentTypeId: string;
    siteId?: string;
    quantity?: number;
    driverRate: number;
    brokerRate: number;
    rate: number;
    hourlyType: string;
    driverMemo?: string;
    isActive: boolean;
}

export const DEFAULT_HAULING_LINE_ITEM: HourlyLineItemDto = {
    id: emptyGuid,
    quoteId: '',
    equipmentTypeId: '',
    description: '',
    quantity: 0,
    driverRate: 0,
    brokerRate: 0,
    hourlyType: '',
    rate: 0,
    isActive: true,
    driverMemo: '',
    createdOn: new Date(),
};

export function useHourlyLineItemForm(props: IFormProps<HourlyLineItemDto>) {
    const { save, cancel, initValues } = props;
    const [formQuote, setFormQuote] = useState<QuoteDto>();
    const [formEquipmentType, setFormEquipmentType] = useState<EquipmentTypeDto>();
    const [formSite, setFormSite] = useState(initValues?.site);
    const [formRate, setFormRate] = useState<number>();
    const [formQuantity, setFormQuantity] = useState<number | undefined>(initValues?.quantity);
    const [formDriverRate, setFormDriverRate] = useState<number>();
    const [formBrokerRate, setFormBrokerRate] = useState<number>();
    const [formIsActive, setFormIsActive] = useState(true);
    const [formHourlyType, setFormHourlyType] = useState('');
    const [formDriverMemo, setFormDriverMemo] = useState<string | undefined>(initValues?.driverMemo);

    useEffect(() => {
        setFormQuote(initValues?.quote);
        setFormEquipmentType(initValues?.equipmentType);
        setFormSite(initValues?.site);
        setFormRate(initValues?.rate ?? 0);
        setFormQuantity(initValues?.quantity);
        setFormDriverRate(initValues?.driverRate ?? 0);
        setFormBrokerRate(initValues?.brokerRate ?? 0);
        setFormIsActive(initValues?.isActive ?? true);
        setFormHourlyType(initValues?.hourlyType ?? '');
        setFormDriverMemo(initValues?.driverMemo);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IHourlyLineItemFormValues, string>>(
        new Map([
            ['quoteId', ''],
            ['equipmentTypeId', ''],
            ['siteId', ''],
            ['quantity', ''],
            ['rate', ''],
            ['driverRate', ''],
            ['brokerRate', ''],
            ['hourlyType', ''],
            ['driverMemo', ''],
        ])
    );

    const formFieldValidators = new Map<keyof IHourlyLineItemFormValues, IFormFieldValidationConfig>([
        [
            'quoteId',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Quote',
            },
        ],
        [
            'equipmentTypeId',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Equipment Type',
            },
        ],
        [
            'quantity',
            {
                validators: [isGreaterThanOrEqualTo(0)],
                errorMessageEntityName: 'Quantity',
            },
        ],
        [
            'rate',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Rate',
            },
        ],
        [
            'driverRate',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Driver Rate',
            },
        ],
        [
            'brokerRate',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Broker Rate',
            },
        ],
        [
            'hourlyType',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Type',
            },
        ],
    ]);

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IHourlyLineItemFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    const getCurrentFormValues = (): IHourlyLineItemFormValues => {
        return {
            quoteId: formQuote?.id ?? emptyGuid,
            equipmentTypeId: formEquipmentType?.id ?? emptyGuid,
            siteId: formSite?.id,
            quantity: formQuantity,
            rate: formRate ?? 0,
            driverRate: formDriverRate ?? 0,
            brokerRate: formBrokerRate ?? 0,
            hourlyType: formHourlyType,
            driverMemo: formDriverMemo,
            isActive: formIsActive,
        };
    };

    const validateField = (fieldName: keyof IHourlyLineItemFormValues) => {
        const validationConfig = formFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = getCurrentFormValues();
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    };

    const isFormDirty = () => {
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formQuote?.id !== initValues?.quote?.id;
        isDirty = isDirty || formEquipmentType !== initValues?.equipmentType;
        isDirty = isDirty || formSite?.id !== initValues?.site?.id;
        isDirty = isDirty || formQuantity !== initValues?.quantity
        isDirty = isDirty || formRate !== initValues?.rate;
        isDirty = isDirty || formDriverRate !== initValues?.driverRate;
        isDirty = isDirty || formBrokerRate !== initValues?.brokerRate;
        isDirty = isDirty || formHourlyType !== initValues?.hourlyType;
        isDirty = isDirty || formDriverMemo !== initValues?.driverMemo;

        return isDirty;
    };

    const handleQuoteChange = (quote: QuoteDto) => {
        setFormQuote(quote);
    };

    const handleEquipmentTypeChange = (equipmentType?: EquipmentTypeDto) => {
        setFormEquipmentType(equipmentType);
    };

    const handleSiteChange = (site?: SiteDto) => {
        setFormSite(site);
    };

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormQuantity(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleRateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleDriverRateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormDriverRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleBrokerRateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormBrokerRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
    };

    const handleHourlyTypeChange = (event: SelectChangeEvent<string>) => {
        setFormHourlyType(event.target.value);
    };

    const handleDriverMemoChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormDriverMemo(event.target.value ?? '');
    };

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_HAULING_LINE_ITEM),
                ...formValues,
            };
            save(updatedUserRecord);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    const formTotal = useMemo(() => {
        return (formRate ?? 0) * (formQuantity ?? 0);
    }, [formRate, formQuantity]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleEquipmentTypeChange,
        handleSiteChange,
        handleQuantityChange,
        handleRateChange,
        handleDriverRateChange,
        handleBrokerRateChange,
        handleIsActiveChange,
        handleHourlyTypeChange,
        handleDriverMemoChange,
        formQuote,
        formEquipmentType,
        formSite,
        formQuantity,
        formRate,
        formDriverRate,
        formBrokerRate,
        formIsActive,
        formHourlyType,
        formDriverMemo,
        validateField,
        initValues,
        formTotal
    };
}
