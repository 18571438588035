import { UserRoleDto } from "../../../dtos";
import { ITransferListItem } from "../../CoreLib/library";

export function convertRolesToTransferListItems(items: UserRoleDto[]): ITransferListItem[] {
    return items.map(item => ({
        label: item.name,
        value: item.id,
        description: item.description
    }));
}

export function convertTransferListItemsToRoles(items: ITransferListItem[]): UserRoleDto[] {
    return items.map(item => ({
        id: item.value,
        name: item.label,
        description: item.description ?? ''
    }));
}