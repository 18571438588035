import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../../Components/CoreLib/library';
import { DumpRateDto } from '../../../dtos';
import { useArchiveDumpRateBulkDeleteMutation, useDumpRateBulkArchiveMutation, useDumpRateBulkUnarchiveMutation, useGetDumpRateQuery } from '../../../store/generated/generatedApi';
import { formatCurrency } from '../../../util';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'TYPE',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export interface ISiteDumpRatesListViewProps {
    siteId: string;
}

export const SiteDumpRatesListView: FC<ISiteDumpRatesListViewProps> = props => {
    const { siteId } = props;
    const navigate = useNavigate();

    const tableColumns: DataTableColumn<DumpRateDto>[] = [
        { key: 'type', label: 'Dump Type', sortKey: 'TYPE' },
        { key: 'rate', label: 'Rate', sortKey: 'RATE', fieldMapper: (row) => row.rate ? formatCurrency(row.rate) : '' },
    ];

    const handleCreateNew = () => {
        navigate(`/dataManagement/site/${siteId}/dumpRate/create`);
    };

    const handleTableEdit = (dumpRateId: string) => {
        navigate(`/dataManagement/site/${siteId}/dumpRate/${dumpRateId}`);
    };

    return (
        <StandardListViewV2
            headerIcon={<></>}
            headerTitle=''
            breadCrumbs={[]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetDumpRateQuery}
            isShowActiveToggleVisible
            inactivateMutation={useDumpRateBulkArchiveMutation}
            activateMutation={useDumpRateBulkUnarchiveMutation}
            deleteMutation={useArchiveDumpRateBulkDeleteMutation}
            tableColumns={tableColumns}
            entityNameSingular='Dump Rate'
            permissionName='dumpRate'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
            additionalQueryParameters={{
                siteId
            }}
        />
    );
};
