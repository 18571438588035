import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent } from 'react';
import { BrokerDto } from '../../dtos';
import { useGetBrokerQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface IBrokerSelectProps {
    selectedBrokerId: string;
    handleSelectedBrokerChange: (selectedBroker?: BrokerDto) => void;
    required?: boolean;
    errorMessage?: string;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    disabled?: boolean;
}

export const BrokerSelect: FC<IBrokerSelectProps> = props => {
    const { selectedBrokerId, handleSelectedBrokerChange, required, errorMessage, onBlur, disabled } = props;
    const { data: brokers, isLoading: isLoadingBrokers } = useGetBrokerQuery({
        sortKey: 'CODE',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: BrokerDto | null) => {
        handleSelectedBrokerChange(value ?? undefined);
    }

    const getSelectedBroker = () => {
        if (selectedBrokerId === '' || selectedBrokerId === emptyGuid) {
            return null;
        }
        return brokers?.pageResults.find(x => x.id === selectedBrokerId) ?? null;
    }

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>Broker</FormLabel>
            <Autocomplete
                value={getSelectedBroker()}
                onChange={handleSelectChange}
                loading={isLoadingBrokers}
                options={brokers?.pageResults ?? []}
                getOptionLabel={x => `${x.code} - ${x.name}`}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}