import { Delete, DeleteForever, MoreVert, Restore, Warning } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { ApiError, AuthenticatedComponent, DataTableColumn, LoadingIndicator, PaginatedDataTable, PaginatedProps, SlimPageHeader, StandardSearchInput, useActivateInactivateSnackbar, useFailedActionSnackbar } from '../../Components/CoreLib/library';
import { BaseDto } from '../../dtos';
import { useArchiveTrashBulkPermanentDeleteMutation, useGetTrashQuery, usePatchTrashBulkRestoreMutation } from '../../store/generated/generatedApi';
import { Divider, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { TrashDto } from '../../dtos/generated/TrashDto';
import { ConfirmDialog } from '../../Components/Core/ConfirmDialog';
import { usePageTitleSetter } from '../../util';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'ITEM',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const TrashListView: FC = () => {
    usePageTitleSetter('Trash');
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const [searchText, setSearchText] = useState('');
    const [selectedRows, setSelectedRows] = useState<readonly BaseDto[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const getDateString = (value?: Date) => {
        return value != null ? new Date(Date.parse(value.toString())).toLocaleDateString() : "";
    }

    const tableColumns: DataTableColumn<TrashDto>[] = [
        { key: 'item', label: 'Item', sortKey: 'ITEM' },
        { key: 'type', label: 'Type', sortKey: 'TYPE' },
        { key: 'deletedBy.displayName', label: 'Deleted By', sortKey: 'DELETED_BY', fieldMapper: (row) => row.deletedByUser?.displayName },
        { key: 'deletedOn', label: 'Deleted On', sortKey: 'DELETED_ON', keyFieldFormatter: getDateString },
    ];

    const [paginatedProps, setPaginatedProps] = useState<PaginatedProps>(defaultPaginationProps);

    const [deleteRecords, { isError: isDeleteError, reset: resetDelete, data: deleteResult, isLoading: isDeleting }] = (
        useArchiveTrashBulkPermanentDeleteMutation
    )();

    const [restoreRecords, { isError: isRestoreError, reset: resetRestore, data: restoreResult, isLoading: isRestoring }] = (
        usePatchTrashBulkRestoreMutation
    )();

    useActivateInactivateSnackbar('Delete', "Item", "Items", deleteResult, resetDelete);
    useFailedActionSnackbar('delete', "Items", isDeleteError, resetDelete);
    // TODO: Forced to use activate instead of restore pending a Core lib update.
    useActivateInactivateSnackbar('Activate', "Item", "Items", restoreResult, resetRestore);
    useFailedActionSnackbar('restore', "Items", isRestoreError, resetRestore);


    const { data, isLoading, error, refetch, isFetching } = useGetTrashQuery({
        searchText: searchText,
        sortKey: paginatedProps?.sortKey,
        sortAsc: paginatedProps?.sortAsc,
        page: paginatedProps?.page,
        pageSize: paginatedProps?.pageSize,
        includeInactive: true,
    });

    const handleMenuOpen = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback((afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    }, []);

    const handleSearchChange = useCallback((newText: string) => {
        setSelectedRows([]);
        setSearchText(newText);
    }, []);

    const handleDelete = useCallback(async () => {
        setIsDialogOpen(false);
        if (!data) {
            return;
        }
        const recordIds = selectedRows.map((record: BaseDto) => record.id);

        deleteRecords(recordIds);
        setSelectedRows([]);
    }, [data, deleteRecords, selectedRows]);


    const showDeleteDialog = useCallback(async () => {
        setIsDialogOpen(true);
    }, []);

    const handleRestore = useCallback(async () => {
        if (!data) {
            return;
        }
        const recordIds = selectedRows.map((record: BaseDto) => record.id);

        restoreRecords(recordIds);
        setSelectedRows([]);
    }, [data, restoreRecords, selectedRows]);

    const canBulkModify = useMemo(() => {
        return selectedRows.length > 0;
    }, [selectedRows.length]);


    const renderHeaderActions = useCallback(() => (
        <Grid item container direction='row' alignItems='start' py={1}>
            <Grid item display='flex' alignItems='center' justifyContent={'end'} xs={12} sx={{ gap: 1 }} pr={2}>
                <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />
                <div>
                    <StandardSearchInput
                        searchText={searchText}
                        handleSearchChange={handleSearchChange}
                        isLoading={isFetching}
                        textFieldProps={{
                            size: 'small',
                            variant: 'standard',
                            label: undefined,
                        }}
                    />
                </div>
                <Divider flexItem orientation='vertical' sx={{ ml: 1, mr: 1 }} />
                <AuthenticatedComponent
                    requiredPermissions={[`delete:trash`]}
                    children={
                        <Tooltip title='Delete Permanently'>
                            <span>
                                <IconButton size='large' onClick={showDeleteDialog} disabled={!canBulkModify || isDeleting}>
                                    <DeleteForever fontSize='inherit' />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                />
                <AuthenticatedComponent
                    requiredPermissions={[`restore:trash`]}
                    children={
                        <Tooltip title='Restore'>
                            <IconButton size='large' onClick={handleRestore} disabled={!canBulkModify || isRestoring}>
                                <Restore />
                            </IconButton>
                        </Tooltip>
                    }
                />
                <AuthenticatedComponent
                    requiredPermissions={
                        [`delete:trash`, `restore:trash`]
                    }
                    logic='or'
                    children={
                        <>
                            <IconButton onClick={handleMenuOpen}>
                                <MoreVert fontSize='inherit' />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={menuOpen}
                                onClose={handleMenuClose()}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <AuthenticatedComponent
                                    requiredPermissions={[`delete:trash`]}
                                    children={<MenuItem onClick={handleMenuClose(showDeleteDialog)} disabled={!canBulkModify || isDeleting}>Delete Permanently</MenuItem>}
                                />
                                <AuthenticatedComponent
                                    requiredPermissions={[`restore:trash`]}
                                    children={<MenuItem onClick={handleMenuClose(handleRestore)} disabled={!canBulkModify || isRestoring}>Restore</MenuItem>}
                                />
                            </Menu>
                        </>
                    }
                />
            </Grid>
        </Grid>
    ), [canBulkModify, handleMenuClose, handleMenuOpen, handleRestore, handleSearchChange, isDeleting, isFetching, isRestoring, menuAnchorEl, menuOpen, searchText, showDeleteDialog]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <Grid container direction='column'>
            <SlimPageHeader
                icon={<Delete />}
                title={"Trash"}
                breadCrumbProps={{
                    links:
                        [
                            { label: 'Home', navLink: '/' },
                            { label: 'Admin', navLink: '', isText: true },
                        ],
                    currentPageLabel: "Trash",
                }}
                endSlot={renderHeaderActions()}
            />
            <Grid item px={2}>
                <PaginatedDataTable
                    columns={tableColumns}
                    loading={isLoading || isFetching}
                    queryResults={data}
                    defaultSortKey={defaultPaginationProps.sortKey}
                    defaultSortDesc={!defaultPaginationProps.sortAsc}
                    setPagination={setPaginatedProps}
                    selectedRecords={selectedRows}
                    setSelectedRecords={setSelectedRows}
                />
            </Grid>
            <ConfirmDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}
                content={
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Warning color='primary' />
                        <Typography >This action is permanent and cannot be undone.</Typography>
                    </Stack>
                }
                title={"Delete Permanently?"} titleIcon={<DeleteForever />} onConfirm={handleDelete} confirmText='Delete' />
        </Grid>
    );
};
