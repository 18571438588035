import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent } from 'react';
import { IncomeCodeDto } from '../../dtos';
import { useGetIncomeCodeQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface IIncomeCodeSelectProps {
    selectedIncomeCodeId: string;
    handleSelectedIncomeCodeChange: (selectedIncomeCode?: IncomeCodeDto) => void;
    required?: boolean;
    errorMessage?: string;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    disabled?: boolean;
}

export const IncomeCodeSelect: FC<IIncomeCodeSelectProps> = props => {
    const { selectedIncomeCodeId, handleSelectedIncomeCodeChange, required, errorMessage, onBlur, disabled } = props;
    const { data: incomeCodes, isLoading: isLoadingIncomeCodes } = useGetIncomeCodeQuery({
        sortKey: 'CODE',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: IncomeCodeDto | null) => {
        handleSelectedIncomeCodeChange(value ?? undefined);
    }

    const getSelectedIncomeCode = () => {
        if (selectedIncomeCodeId === '' || selectedIncomeCodeId === emptyGuid) {
            return null;
        }
        return incomeCodes?.pageResults.find(x => x.id === selectedIncomeCodeId) ?? null;
    }

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>Income Code</FormLabel>
            <Autocomplete
                value={getSelectedIncomeCode()}
                onChange={handleSelectChange}
                loading={isLoadingIncomeCodes}
                options={incomeCodes?.pageResults ?? []}
                getOptionLabel={x => `${x.code} - ${x.accountDescription}`}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}