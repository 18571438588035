import { FormControl, FormHelperText, FormLabel, Grid, TextField } from "@mui/material";
import { debounce } from "lodash";
import { FunctionComponent, useMemo, useState } from "react";

export interface ITimeIntervalPickerProps {
    value?: number | null;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    label?: string;
    onChange: (value?: number | null) => void;
    errorText?: string;
};

export const TimeIntervalPicker: FunctionComponent<ITimeIntervalPickerProps> = (props) => {
    const { value, disabled, required, error, label, onChange, errorText } = props;
    const [hourValue, setHourValue] = useState<number | null>(value ? Math.floor(value / 60) : null);
    const [minuteValue, setMinuteValue] = useState<number | null>(value ? value % 60 : null);

    const debouncedHandleChange = useMemo(() => debounce((hour: number | null, minute: number | null) => {
        if (hour !== null && minute !== null) {
            onChange(hour * 60 + minute);
        } else if (hour !== null) {
            onChange(hour * 60);
        } else if (minute !== null) {
            onChange(minute);
        } else {
            onChange(null);
        }
    }, 300), [onChange]);

    return (
        <>
            <FormControl
                fullWidth
                disabled={disabled}
                required={required}
                error={error}>
                <Grid container direction='column' xs={12}>
                    <Grid item>
                        <FormLabel>{label}</FormLabel>
                    </Grid>
                    <Grid container spacing={1} xs={12}>
                        <Grid item xs={6}>
                            <TextField
                                label="Hours"
                                type="number"
                                value={hourValue ?? ''}
                                inputProps={{ min: 0, max: 12, step: 1.0 }}
                                onChange={(e) => {
                                    let value = parseInt(e.target.value) as number | null;
                                    if (isNaN(value!)) {
                                        value = null;
                                    } else {
                                        if (value! > 12) {
                                            value = 12;
                                        }
                                        if (value! < 0) {
                                            value = 0;
                                        }
                                    }
                                    setHourValue(value);
                                    setMinuteValue(minuteValue ?? 0);
                                    debouncedHandleChange(value, minuteValue ?? 0);
                                }}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Minutes"
                                type="number"
                                value={minuteValue ?? ''}
                                inputProps={{ min: 0, max: 60, step: 1.0 }}
                                onChange={(e) => {
                                    let value = parseInt(e.target.value) as number | null;
                                    if (isNaN(value!)) {
                                        value = null;
                                    } else {
                                        if (value! > 60) {
                                            value = 60;
                                        }
                                        if (value! < 0) {
                                            value = 0;
                                        }
                                    }
                                    setMinuteValue(value);
                                    setHourValue(hourValue ?? 0);
                                    debouncedHandleChange(hourValue ?? 0, value);
                                }}
                                fullWidth
                                disabled={disabled}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <FormHelperText>{errorText}</FormHelperText>
            </FormControl>
        </>
    );
}