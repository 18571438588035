import { EventModel } from "@bryntum/scheduler";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormLabel, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC } from "react";
import { HaulToFromForm, TimePicker } from "../../../Components/CommonInputs";
import { FormInput, IEntityAutocomplete, IFormProps } from "../../../Components/CoreLib/library";
import { BrokerDto, DriverDto, EquipmentDto, RegionDto } from "../../../dtos";
import { AssignmentType } from "../../../dtos/generated/AssignmentType";
import { useGetBrokerQuery, useGetDriverQuery, useGetEquipmentQuery, useGetRegionQuery } from "../../../store/generated/generatedApi";
import { useEventEditorForm } from "./useEventEditorForm";

export interface IEventEditorProps extends IFormProps<EventModel> {
    handleDelete: () => void;
    open: boolean;
    isHaulerView: boolean;
    disabled: boolean;
}

export const EventEditor: FC<IEventEditorProps> = (props) => {
    const {
        handleDelete,
        open,
        isHaulerView,
        disabled
    } = props;
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        startTime,
        endTime,
        yardTime,
        assignmentType,
        driver,
        broker,
        equipment,
        handleHaulToAddressChange,
        handleHaulFromAddressChange,
        setFormDriverMemo,
        formDriverMemo,
        formHaulToAddressLine1,
        formHaulToAddressLine2,
        formHaulToCity,
        formHaulToState,
        formHaulToZipCode,
        formHaulToCountry,
        formHaulFromAddressLine1,
        formHaulFromAddressLine2,
        formHaulFromCity,
        formHaulFromState,
        formHaulFromZipCode,
        formHaulFromCountry,
        formHaulToRegion,
        formHaulFromRegion,
        formHaulToSiteName,
        formHaulFromSiteName,
        setFormHaulToSiteName,
        setFormHaulFromSiteName,
        setFormHaulToRegion,
        setFormHaulFromRegion,
        setStartTime,
        setEndTime,
        setYardTime,
        setAssignmentType,
        setDriver,
        setBroker,
        setEquipment,
    } = useEventEditorForm({ ...props, isHaulerView });

    const { data: brokers } = useGetBrokerQuery({ searchText: '', sortKey: 'CODE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
    const { data: drivers } = useGetDriverQuery({ searchText: '', sortKey: 'CODE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
    const { data: equipmentList } = useGetEquipmentQuery({ searchText: '', sortKey: 'NUMBER', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false, brokerId: broker?.id });
    const { data: regions } = useGetRegionQuery({ searchText: '', sortKey: 'NAME', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });

    return (
        <Dialog open={open} maxWidth='md' fullWidth>
            <DialogTitle sx={{ backgroundColor: 'primary', color: '#ffffff' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    Edit Dispatch
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' mt={1} spacing={1}>
                    <Grid item xs={12}>
                        <TimePicker
                            label='Onsite Time'
                            value={startTime}
                            onChange={(date) => {
                                if (date) {
                                    if (!isNaN(Date.parse(date.toString()))) {
                                        setStartTime(date);
                                    }
                                }
                            }}
                            error={!!fieldErrors.get('startTime')}
                            errorText={fieldErrors.get('startTime')}
                            required
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TimePicker
                            label='End Time'
                            value={endTime}
                            onChange={(date) => {
                                if (date) {
                                    if (!isNaN(Date.parse(date.toString()))) {
                                        setEndTime(date);
                                    }
                                }
                            }}
                            error={!!fieldErrors.get('endTime')}
                            errorText={fieldErrors.get('endTime')}
                            required
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TimePicker
                            label='Yard Time'
                            value={yardTime}
                            onChange={(date) => {
                                if (date) {
                                    if (!isNaN(Date.parse(date.toString()))) {
                                        setYardTime(date);
                                    }
                                }
                            }}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth error={!!fieldErrors.get('assignmentType')} disabled={disabled} required>
                            <FormLabel>
                                Assignment Type
                            </FormLabel>
                            <Select
                                required
                                value={assignmentType?.toString() ?? ''}
                                disabled={disabled}
                                onChange={(event: SelectChangeEvent) => {
                                    const type = parseInt(event.target.value);
                                    setAssignmentType(type);
                                    switch (type) {
                                        case AssignmentType.Broker:
                                            setDriver(undefined);
                                            setEquipment(undefined);
                                            break;
                                        case AssignmentType.Driver:
                                            setBroker(undefined);
                                            setEquipment(undefined);
                                            break;
                                        default:
                                            break;
                                    }
                                }}
                            >
                                <MenuItem value={AssignmentType.Broker.toString()}>
                                    Broker
                                </MenuItem>
                                <MenuItem value={AssignmentType.Driver.toString()}>
                                    Driver
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    {assignmentType === AssignmentType.Broker ?
                        <Grid item container direction='column' xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth required error={!!fieldErrors.get('brokerId')}>
                                    <FormLabel>Broker</FormLabel>
                                    <IEntityAutocomplete
                                        options={brokers?.pageResults ?? []}
                                        onChange={(e, value) => {
                                            setBroker(value);
                                            setDriver(undefined);
                                            setEquipment(undefined);
                                        }}
                                        value={broker ?? null}
                                        getOptionLabel={(option: BrokerDto) => `${option.code} - ${option.name}`}
                                        error={!!fieldErrors.get('brokerId')}
                                        disabled={disabled}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        :
                        <Grid item container direction='column' xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <FormControl fullWidth required error={!!fieldErrors.get('driverId')}>
                                    <FormLabel>Driver</FormLabel>
                                    <IEntityAutocomplete
                                        options={drivers?.pageResults ?? []}
                                        onChange={(e, value?: DriverDto) => {
                                            setDriver(value);
                                            setEquipment(value?.equipment)
                                            setBroker(undefined);
                                        }}
                                        value={driver ?? null}
                                        getOptionLabel={(option: DriverDto) => `${option.code} - ${option.name}`}
                                        error={!!fieldErrors.get('driverId')}
                                        disabled={disabled}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    label='Driver Memo'
                                    fullWidth
                                    value={formDriverMemo}
                                    onChange={(e) => { setFormDriverMemo(e.target.value); }}
                                    disabled={disabled}
                                />
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControl fullWidth required={assignmentType === AssignmentType.Driver} error={!!fieldErrors.get('equipmentId')}>
                            <FormLabel>Equipment</FormLabel>
                            <IEntityAutocomplete
                                options={equipmentList?.pageResults ?? []}
                                onChange={(e, value) => { setEquipment(value); }}
                                value={equipment ?? null}
                                getOptionLabel={(option: EquipmentDto) => `${option.number} - ${option.equipmentType?.type}`}
                                error={!!fieldErrors.get('equipmentId')}
                                disabled={disabled}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container direction='column'>
                        <Grid item xs={12} sx={{ paddingTop: '8px' }}>
                            <Accordion>
                                <AccordionSummary expandIcon={<ExpandMore />}>
                                    Haul To/From
                                </AccordionSummary>
                                <AccordionDetails>
                                    <HaulToFromForm
                                        disabled={disabled}
                                        haulToAddressLine1={formHaulToAddressLine1}
                                        haulToAddressLine2={formHaulToAddressLine2}
                                        haulToCountry={formHaulToCountry}
                                        haulToCity={formHaulToCity}
                                        haulToState={formHaulToState}
                                        haulToZipCode={formHaulToZipCode}
                                        haulFromAddressLine1={formHaulFromAddressLine1}
                                        haulFromAddressLine2={formHaulFromAddressLine2}
                                        haulFromCountry={formHaulFromCountry}
                                        haulFromCity={formHaulFromCity}
                                        haulFromState={formHaulFromState}
                                        haulFromZipCode={formHaulFromZipCode}
                                        haulToRegion={formHaulToRegion}
                                        haulFromRegion={formHaulFromRegion}
                                        haulToSiteName={formHaulToSiteName}
                                        haulFromSiteName={formHaulFromSiteName}
                                        enableLookup
                                        regions={regions?.pageResults ?? []}
                                        handleHaulFromSiteNameChange={(value) => {
                                            setFormHaulFromSiteName(value);
                                        }}
                                        handleHaulToSiteNameChange={(value) => {
                                            setFormHaulToSiteName(value);
                                        }}
                                        handleHaulToRegionChange={(value) => {
                                            setFormHaulToRegion(value);
                                        }}
                                        handleHaulFromRegionChange={(value) => {
                                            setFormHaulFromRegion(value);
                                        }}
                                        handleHaulFromAddressChange={(address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }) => {
                                            handleHaulFromAddressChange(address);
                                        }}
                                        handleHaulToAddressChange={(address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }) => {
                                            handleHaulToAddressChange(address);
                                        }}
                                        handleSwap={(haulFromAddress: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string, siteName?: string, region?: RegionDto }, haulToAddress: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string, siteName?: string, region?: RegionDto }) => {
                                            handleHaulToAddressChange(haulFromAddress);
                                            handleHaulFromAddressChange(haulToAddress);

                                            setFormHaulToRegion(haulFromAddress.region);
                                            setFormHaulFromRegion(haulToAddress.region);

                                            setFormHaulToSiteName(!!haulFromAddress.siteName ? haulFromAddress.siteName : '');
                                            setFormHaulFromSiteName(!!haulToAddress.siteName ? haulToAddress.siteName : '');
                                        }}
                                        handleHaulFromSiteLookup={(address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }, siteName?: string, region?: RegionDto) => {
                                            handleHaulFromAddressChange(address);
                                            setFormHaulFromRegion(region);
                                            setFormHaulFromSiteName(!!siteName ? siteName : '');
                                        }}
                                        handleHaulToSiteLookup={(address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }, siteName?: string, region?: RegionDto) => {
                                            handleHaulToAddressChange(address);
                                            setFormHaulToRegion(region);
                                            setFormHaulToSiteName(!!siteName ? siteName : '');
                                        }}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' disabled={!isFormDirty || disabled} onClick={handleSave}>
                    SAVE
                </Button>
                <Button variant='contained' disabled={disabled} onClick={handleDelete}>
                    DELETE
                </Button>
                <Button variant='outlined' onClick={handleCancel}>
                    CANCEL
                </Button>
            </DialogActions>
        </Dialog>
    );
}