import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material';
import { FC, SyntheticEvent, useCallback } from 'react';
import { BrokerDto, DriverDto } from '../../dtos';
import { useGetBrokerQuery, useGetDriverQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface IHaulerSelectProps {
    selectedHaulerId: string;
    handleSelectedHaulerChange: (selectedHauler?: BrokerDto | DriverDto) => void;
    required?: boolean;
    disabled?: boolean;
}

export const HaulerSelect: FC<IHaulerSelectProps> = props => {
    const { selectedHaulerId, handleSelectedHaulerChange, required, disabled } = props;
    const { data: brokers, isLoading: isLoadingBrokers } = useGetBrokerQuery({
        sortKey: 'CODE',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false
    });
    const { data: drivers, isLoading: isLoadingDrivers } = useGetDriverQuery({
        sortKey: 'CODE',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: BrokerDto | DriverDto | null) => {
        handleSelectedHaulerChange(value ?? undefined);
    }

    const getSelectedHauler = useCallback(() => {
        if (selectedHaulerId === '' || selectedHaulerId === emptyGuid) {
            return null;
        }
        const selectedBroker = brokers?.pageResults.find(x => x.id === selectedHaulerId) ?? null;
        if (selectedBroker) {
            return selectedBroker;
        }
        const selectedDriver = drivers?.pageResults.find(x => x.id === selectedHaulerId) ?? null;
        return selectedDriver;
    }, [brokers?.pageResults, drivers?.pageResults, selectedHaulerId]);

    return (
        <FormControl fullWidth required={required} disabled={disabled}>
            <FormLabel>Hauler</FormLabel>
            <Autocomplete
                value={getSelectedHauler()}
                onChange={handleSelectChange}
                loading={isLoadingBrokers || isLoadingDrivers}
                options={[...(brokers?.pageResults ?? []), ...(drivers?.pageResults ?? [])]}
                getOptionLabel={x => `${x.code} - ${x.name}`}
                renderInput={(params) => (
                    <TextField
                        {...params}
                    />
                )}
                disabled={disabled}
            />
        </FormControl>
    )
}