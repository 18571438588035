import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, MenuItem, Select, Typography } from '@mui/material';
import { FC } from 'react';
import { TermsCodeDto, UserDto } from '../../../dtos';
import { useGetTermsCodeQuery, useGetUserQuery } from '../../../store/generated/generatedApi';
import { accountStatuses, bBInvoiceTypes, countries, pricingTypes, usStates } from '../../../util';
import { AddressLookup, DatePicker } from '../../CommonInputs';
import { FormInput, FormNumberInput, IEntityAutocomplete, LoadingIndicator } from '../../CoreLib/library';
import { CustomerFormManager } from './useCustomerForm';
import { usePrompt } from '../../../Views';

// passing the form manager down like this could cause performance issues which we should keep our eye out for. But that is the quickest solution for now.
export interface ICustomerFormProps {
    customerFormManager: CustomerFormManager;
    isLoading?: boolean;
    hideAccountInformation?: boolean;
}

export const CustomerForm: FC<ICustomerFormProps> = ({ customerFormManager, isLoading = false, hideAccountInformation }) => {
    const {
        fieldErrors,
        formName,
        formCustomerNumber,
        formAddressLine1,
        formAddressLine2,
        formCity,
        formState,
        formZipCode,
        formCountry,
        formBBInvoiceType,
        formPricing,
        formMiscCode,
        formSalesPerson,
        formTermsCode,
        formAccountStatus,
        formAccountCreditLimit,
        formAccountComment,
        formAccountHighCreditDate,
        formAccountHighCreditAmount,
        formIsTaxable,
        handleNameChange,
        handleCustomerNumberChange,
        handleAddressLine1Change,
        handleAddressLine2Change,
        handleCityChange,
        handleStateChange,
        handleZipCodeChange,
        handleCountryChange,
        handleBBInvoiceTypeChange,
        handlePricingChange,
        handleMiscCodeChange,
        handleSalesPersonChange,
        handleTermsCodeChange,
        handleAccountStatusChange,
        handleAccountCreditLimitChange,
        handleAccountCommentChange,
        handleAccountHighCreditDateChange,
        handleAccountHighCreditAmountChange,
        handleIsTaxableChange,
        setFormAddressLine1,
        setFormCity,
        setFormCountry,
        setFormState,
        setFormZipCode,
        isFormDirty
    } = customerFormManager;

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const { data: users, isLoading: usersLoading } = useGetUserQuery({
        searchText: '',
        sortKey: 'FIRST_NAME',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: false,
    });
    const { data: termsCodes, isLoading: termsCodesLoading } = useGetTermsCodeQuery({
        searchText: '',
        sortKey: 'CODE',
        page: 0,
        pageSize: 100000,
        sortAsc: true,
        includeInactive: false,
    });

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid item container direction='column' spacing={3}>
            <Grid item container direction='row' spacing={3}>
                <Grid item xs={2}>
                    <FormInput
                        value={formCustomerNumber}
                        onChange={handleCustomerNumberChange}
                        label='Customer #'
                        name='customerNumber'
                        errorText={fieldErrors.get('customerNumber')}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormInput
                        value={formName}
                        onChange={handleNameChange}
                        label='Customer Name'
                        name='name'
                        errorText={fieldErrors.get('name')}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <AddressLookup
                        handleAddressLine1Change={setFormAddressLine1}
                        handleCityChange={setFormCity}
                        handleCountryChange={setFormCountry}
                        handleStateChange={setFormState}
                        handleZipCodeChange={setFormZipCode}
                    />
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <FormInput
                        value={formAddressLine1}
                        onChange={handleAddressLine1Change}
                        label='Address Line 1'
                        name='addressLine1'
                        errorText={fieldErrors.get('addressLine1')}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <FormInput value={formAddressLine2} onChange={handleAddressLine2Change} label='Address Line 2' name='addressLine2' fullWidth />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormInput
                        value={formCity}
                        onChange={handleCityChange}
                        label='City'
                        name='city'
                        errorText={fieldErrors.get('city')}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormControl error={!!fieldErrors.get('state')} fullWidth required>
                        <FormLabel>State</FormLabel>
                        <Select value={formState} onChange={handleStateChange}>
                            {usStates.map((usState) => (
                                <MenuItem key={usState.abbreviation} value={usState.abbreviation}>
                                    {usState.name}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{fieldErrors.get('state')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormInput
                        value={formZipCode}
                        onChange={handleZipCodeChange}
                        label='Zip Code'
                        name='zipCode'
                        errorText={fieldErrors.get('zipCode')}
                        fullWidth
                        required
                    />
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={3}>
                <Grid item xs={3}>
                    <FormControl error={!!fieldErrors.get('country')} fullWidth required>
                        <FormLabel>Country</FormLabel>
                        <Select value={formCountry} onChange={handleCountryChange}>
                            {countries.map((country) => (
                                <MenuItem key={country} value={country}>
                                    {country}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{fieldErrors.get('country')}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={3} alignItems='center'>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <FormLabel>Salesperson</FormLabel>
                        <IEntityAutocomplete
                            options={users?.pageResults}
                            onChange={(e, value) => {
                                handleSalesPersonChange(value ?? null);
                            }}
                            value={formSalesPerson}
                            getOptionLabel={(option: UserDto) => `${option.firstName} ${option.lastName}`}
                            isLoading={usersLoading}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <FormLabel>BB Invoice Type</FormLabel>
                        <Select value={formBBInvoiceType} onChange={handleBBInvoiceTypeChange}>
                            {bBInvoiceTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={1}>
                    <FormControl fullWidth>
                        <FormLabel>Pricing</FormLabel>
                        <Select value={formPricing} onChange={handlePricingChange}>
                            {pricingTypes.map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth required error={!!fieldErrors.get('termsCodeId')}>
                        <FormLabel>Terms Code</FormLabel>
                        <IEntityAutocomplete
                            options={termsCodes?.pageResults}
                            onChange={(e, value) => {
                                handleTermsCodeChange(value ?? null);
                            }}
                            value={formTermsCode}
                            getOptionLabel={(option: TermsCodeDto) => `${option.code} - ${option.description}`}
                            isLoading={termsCodesLoading}
                            error={!!fieldErrors.get('termsCodeId')}
                        />
                        <FormHelperText>{fieldErrors.get('termsCodeId')}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item container direction='row' spacing={3} alignItems='center'>
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <FormControlLabel control={<Checkbox checked={formIsTaxable} onChange={handleIsTaxableChange} />} label='Is Taxable' />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormInput value={formMiscCode} onChange={handleMiscCodeChange} label='Misc Code' name='miscCode' fullWidth />
                </Grid>
            </Grid>
            {!hideAccountInformation && (
                <>
                    <Grid item container direction='row'>
                        <Grid item>
                            <Typography variant='h2'>Account Information</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <FormLabel>Account Status</FormLabel>
                                <Select value={formAccountStatus} onChange={handleAccountStatusChange}>
                                    {accountStatuses.map((status) => (
                                        <MenuItem key={status} value={status}>
                                            {status}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <FormNumberInput
                                value={formAccountCreditLimit}
                                name='creditLimit'
                                onChange={handleAccountCreditLimitChange}
                                label='Credit Limit'
                                fullWidth
                                inputProps={{ min: 0 }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormInput value={formAccountComment} onChange={handleAccountCommentChange} label='Comment' name='accountComment' fullWidth />
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                label='High Credit Date'
                                value={formAccountHighCreditDate}
                                onChange={handleAccountHighCreditDateChange}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormNumberInput
                                value={formAccountHighCreditAmount}
                                name='highCreditAmount'
                                onChange={handleAccountHighCreditAmountChange}
                                label='High Credit Amount'
                                fullWidth
                                inputProps={{ min: 0 }}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    );
};
