import { Add, RateReview } from '@mui/icons-material';
import { Box, Button, Divider, Grid, MenuItem, Paper, Select, Tab, Tabs, Tooltip } from '@mui/material';
import { format, isValid, startOfDay } from 'date-fns';
import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import { DateScroller } from '../../Components/CommonInputs';
import { SlimFormHeader } from '../../Components/Core/SlimFormHeader';
import { AuthenticatedComponent, UserPermissionContext, userHasPermissions } from '../../Components/CoreLib/library';
import { useGetOrderReviewCountQuery, useLazyGetDispatchOrderManagementCurrentMonthQuery, useLazyGetOrderReviewCurrentMonthOrdersQuery } from '../../store/generated/generatedApi';
import { convertYYYYMMDDToDate } from '../../util';
import { InvoiceHistoryButton } from './InvoiceHistoryButton';
import { OrderReviewListView } from './OrderReviewListView';
import { OrderReviewOrdersListView } from './OrderReviewOrdersListView';

export const OrderReviewPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [billingTabValue, setBillingTabValue] = useState(0);
    const [customerServiceTabValue, setCustomerServiceTabValue] = useState(0);
    const dateParam = searchParams.get('date');

    const navigate = useNavigate();

    const [date, setDate] = useState(dateParam ? convertYYYYMMDDToDate(dateParam) : new Date());
    const { data: orderReviewCounts } = useGetOrderReviewCountQuery({ orderDate: format(date, 'yyyy-MM-dd') });

    const { permissions } = useContext(UserPermissionContext);
    const isUserAllowedToViewReview = userHasPermissions(['read:review'], permissions, 'and');
    const isUserAllowedToViewBilling = userHasPermissions(['read:billing'], permissions, 'and');

    const [getCurrentMonthOrders, { data: currentMonthOrders, isFetching: isFetchingOrders }] = useLazyGetOrderReviewCurrentMonthOrdersQuery();
    const [getCurrentMonthDispatch, { data: currentMonthDispatches, isFetching: isFetchingDispatches }] = useLazyGetDispatchOrderManagementCurrentMonthQuery();

    const [daysWithData, setDaysWithData] = useState<string[]>([]);
    const [month, setMonth] = useState(date);

    const loadData = useCallback(
        (date: Date) => {
            const formattedDate = format(date, 'yyyy-MM');
            getCurrentMonthDispatch({ startDate: formattedDate });
            getCurrentMonthOrders({ startDate: formattedDate });
        },
        [getCurrentMonthDispatch, getCurrentMonthOrders]
    );

    const onMonthChange = (newMonth: Date) => {
        setDaysWithData([]);
        setMonth(newMonth);
    };

    const getDefault = useCallback(() => {
        if (isUserAllowedToViewBilling) {
            return 'BILLING';
        } else if (isUserAllowedToViewReview) {
            return 'CUSTOMER-SERVICE';
        } else {
            return '';
        }
    }, [isUserAllowedToViewBilling, isUserAllowedToViewReview]);

    const [viewType, setViewType] = useState(getDefault());
    const [isBillingView, setIsBillingView] = useState(getDefault() === 'BILLING');

    const handleViewChange = useCallback(
        (event) => {
            if (isUserAllowedToViewBilling && event.target.value === 'BILLING') {
                setViewType('BILLING');
                setIsBillingView(true);
            } else if (isUserAllowedToViewReview && event.target.value === 'CUSTOMER-SERVICE') {
                setViewType('CUSTOMER-SERVICE');
                setIsBillingView(false);
            } else {
                setViewType('');
            }
        },
        [isUserAllowedToViewBilling, isUserAllowedToViewReview]
    );

    const updateDate = useCallback(
        (updatedDate: Date) => {
            const newDate = new Date(updatedDate);

            if (isValid(newDate)) {
                setDate(startOfDay(newDate));
                setSearchParams(createSearchParams({ date: format(newDate, 'yyyy-MM-dd'), view: viewType }));
            }
        },
        [viewType, setSearchParams]
    );

    const handleEnterTicket = useCallback(() => {
        navigate('/orderReview/enter-ticket?date=' + format(date, 'yyyy-MM-dd'));
    }, [date, navigate]);

    const handleBillingTabChange = useCallback((_: React.ChangeEvent<{}>, newValue: number) => {
        setBillingTabValue(newValue);
    }, []);

    const handleCustomerServiceTabChange = useCallback((_: React.ChangeEvent<{}>, newValue: number) => {
        setCustomerServiceTabValue(newValue);
    }, []);

    const TabLabel = useCallback((label: string, count?: number) => {
        return (
            <Box display='flex' alignItems='center' gap={1}>
                <span>{label}</span>
                {count !== undefined && <Box borderRadius={3} height={18} width={18} bgcolor='primary.main' color='primary.contrastText' >{count}</Box>}
            </Box>
        )
    }, []);

    useEffect(() => {
        loadData(month);
    }, [month, loadData]);

    useEffect(() => {
        if (!isFetchingOrders && !isFetchingDispatches) {
            const currentDaysFilter = [...(currentMonthDispatches ?? []), ...(currentMonthOrders ?? [])];
            setDaysWithData(currentDaysFilter);
        }
    }, [currentMonthDispatches, currentMonthOrders, isFetchingOrders, isFetchingDispatches]);

    useEffect(() => {
        setSearchParams(createSearchParams({ date: format(date, 'yyyy-MM-dd'), view: viewType }));
    }, [date, viewType, setSearchParams]);

    return (
        <Grid container direction='column'>
            <SlimFormHeader
                objectName=''
                permissionsTypeName={'review'}
                icon={<RateReview />}
                title={'Review'}
                breadcrumbs={[{ label: 'Home', navLink: '/' }]}
                entityNameSingular=''
                hideDividers
                id={''}
                isLoading={false}
                additionalHeaderItems={
                    <Box display='flex' gap={1} alignItems='center'>
                        {isBillingView && (
                            <>
                                <AuthenticatedComponent requiredPermissions={['read:review']}>
                                    <InvoiceHistoryButton size='large' />
                                </AuthenticatedComponent>
                                <Tooltip title='Enter New Ticket'>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        size='large'
                                        onClick={handleEnterTicket}
                                        startIcon={<Add />}
                                        style={{ whiteSpace: 'nowrap' }}>
                                        ENTER TICKETS
                                    </Button>
                                </Tooltip>
                            </>
                        )}
                        <Select value={viewType} onChange={handleViewChange}>
                            {isUserAllowedToViewReview && <MenuItem value='CUSTOMER-SERVICE'>Customer Service</MenuItem>}
                            {isUserAllowedToViewBilling && <MenuItem value='BILLING'>Billing</MenuItem>}
                        </Select>
                        <DateScroller date={date} updateDate={updateDate} fullWidth daysWithData={daysWithData} handleOnMonthChange={onMonthChange} />
                    </Box>
                }
            />
            <Grid item p={2}>
                <Paper square variant='outlined'>
                    {!isBillingView ? (
                        <AuthenticatedComponent
                            requiredPermissions={['read:review']}
                            children={
                                <>
                                    <Tabs value={customerServiceTabValue} onChange={handleCustomerServiceTabChange} variant='fullWidth'>
                                        <Tab label={TabLabel('Ready For Review', orderReviewCounts?.readyForReview)} />
                                        <Tab label={TabLabel('In Review', orderReviewCounts?.customerServiceInReview)} />
                                        <Tab label={TabLabel('Completed', orderReviewCounts?.customerServiceCompleted)} />
                                    </Tabs>
                                    <Divider />
                                    {customerServiceTabValue === 0 && <OrderReviewOrdersListView date={date} />}
                                    {customerServiceTabValue === 1 && <OrderReviewListView date={date} readyForBilling={false} />}
                                    {customerServiceTabValue === 2 && <OrderReviewListView date={date} readyForBilling/>}
                                </>
                            }
                        />
                    ) : (
                        <AuthenticatedComponent
                            requiredPermissions={['read:billing']}
                            children={
                                <>
                                    <Tabs value={billingTabValue} onChange={handleBillingTabChange} variant='fullWidth'>
                                        <Tab label={TabLabel('In Review', orderReviewCounts?.billingInReview)} />
                                        <Tab label={TabLabel('Completed', orderReviewCounts?.billingCompleted)} />
                                    </Tabs>
                                    <Divider />
                                    {billingTabValue === 0 && <OrderReviewListView date={date} readyForBilling sentToQuickBooks={false} showSendToQuickbooksButton />}
                                    {billingTabValue === 1 && <OrderReviewListView date={date} readyForBilling sentToQuickBooks showSendToQuickbooksButton />}
                                </>
                            }
                        />
                    )}
                </Paper>
            </Grid>
        </Grid>
    );
};
