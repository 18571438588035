import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent } from 'react';
import { MaterialDto } from '../../dtos';
import { useGetMaterialQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface IMaterialSelectProps {
    selectedMaterialId: string;
    handleSelectedMaterialChange: (selectedMaterial?: MaterialDto) => void;
    required?: boolean;
    errorMessage?: string;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    disabled?: boolean;
}

export const MaterialSelect: FC<IMaterialSelectProps> = props => {
    const { selectedMaterialId, handleSelectedMaterialChange, required, errorMessage, onBlur, disabled } = props;
    const { data: materials, isLoading: isLoadingMaterials } = useGetMaterialQuery({
        sortKey: 'CODE',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false
    });

    const handleSelectChange = (_: SyntheticEvent<Element, Event>, value: MaterialDto | null) => {
        handleSelectedMaterialChange(value ?? undefined);
    }

    const getSelectedMaterial = () => {
        if (selectedMaterialId === '' || selectedMaterialId === emptyGuid) {
            return null;
        }
        return materials?.pageResults.find(x => x.id === selectedMaterialId) ?? null;
    }

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>Material</FormLabel>
            <Autocomplete
                value={getSelectedMaterial()}
                onChange={handleSelectChange}
                loading={isLoadingMaterials}
                options={materials?.pageResults ?? []}
                getOptionLabel={x => `${x.code} - ${x.name}`}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}