import { debounce, FormControl, FormHelperText, FormLabel, Grid, SxProps, Theme, Tooltip } from "@mui/material";
import { FunctionComponent, useMemo, useState } from "react";
import { DateValidationError, PickerChangeHandlerContext, DatePicker as XDatePicker } from '@mui/x-date-pickers';
import { Info } from "@mui/icons-material";

export interface IDatePickerProps {
    value?: Date | string | null;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    label?: string;
    onChange: (date?: Date | null) => void;
    errorText?: string;
    tooltip?: string;
    minDate?: Date | string;
    maxDate?: Date | string;
    sx?: SxProps<Theme> | undefined
};

export const DatePicker: FunctionComponent<IDatePickerProps> = (props) => {
    const { value, disabled, required, error, label, onChange, errorText, tooltip, minDate, maxDate, sx } = props;
    const [fieldValue, setFieldValue] = useState<Date | string | null | undefined>(value);

    const debouncedHandleChange = useMemo(
        () => debounce((date: Date | null | undefined, context: PickerChangeHandlerContext<DateValidationError>) => {
            if (!context.validationError) {
                if (date) {
                    date.setHours(0, 0, 0, 0);
                    onChange(date);
                } else {
                    onChange(date);
                }
            }
        }, 300)
        , [onChange]);

    return (
        <FormControl
            fullWidth
            disabled={disabled}
            required={required}
            error={error}>
            <Grid container spacing={2}>
                <Grid item>
                    <FormLabel>{label}</FormLabel>
                </Grid>
                {tooltip && <Grid item>
                    <Tooltip title={tooltip}><Info /></Tooltip>
                </Grid>}
            </Grid>
            <XDatePicker
                value={fieldValue ? new Date(fieldValue) : null}
                onChange={(value, context) => {
                    setFieldValue(value);
                    debouncedHandleChange(value, context);
                }}
                slotProps={{
                    textField: {
                        error: error
                    }
                }}
                minDate={minDate ? new Date(minDate) : null}
                maxDate={maxDate ? new Date(maxDate) : null}
                disabled={disabled}
                sx={sx}
                timezone='america/new_york'
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
};