import { RateReview } from '@mui/icons-material';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { convertYYYYMMDDToDate } from '../../../util';
import { usePrompt } from '../../../Views';
import { BrokerDto, DispatchReviewDto, DriverDto, OrderReviewDto } from '../../../dtos';
import { useArchiveOrderReviewDeleteFromDatabaseByIdMutation, useGetBrokerQuery, useGetDriverQuery, useGetOrderReviewQuery } from '../../../store/generated/generatedApi';
import { DateScroller } from '../../CommonInputs';
import { HaulerSelect } from '../../CommonInputs/HaulerSelect';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { LoadingIndicator, IFormProps, UserPermissionContext, userHasPermissions } from '../../CoreLib/library';
import { DispatchTicketEntryTable } from './DispatchTicketEntryTable';
import { useTicketEntryForm } from './useTicketEntryForm';
import { format, isValid, startOfDay } from 'date-fns';
import _ from "lodash";

export interface ITicketFormProps extends IFormProps<OrderReviewDto> {
    dateGiven?: Date;
    setUpdatedDate: (date: string) => void;
}

export const TicketForm: FC<ITicketFormProps> = (props) => {
    const { permissions } = useContext(UserPermissionContext);
    const isUserAllowedToReview = userHasPermissions(['create:review', 'edit:review'], permissions, 'or');
    const isUserAllowedToBill = userHasPermissions(['create:billing', 'edit:billing'], permissions, 'or');

    const {
        isFormDirty,
        handleSave,
        handleCancel,
        dispatchErrors,
        freightErrors,
        handleAddNewFreightBillingLineItem,
        handleRemoveFreightBillingLineItem,
        handleDispatchesChange,
        setFormDispatchReviews,
        formDispatchReviews,
        getOrderById,
        isLoadingOrders
    } = useTicketEntryForm(props);

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const [formHaulerId, setFormHaulerId] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();
    const dateParam = searchParams.get('date');

    const [date, setDate] = useState(dateParam ? convertYYYYMMDDToDate(dateParam) : new Date());

    useEffect(() => {
        props.setUpdatedDate(format(date, 'yyyy-MM-dd'));
    }, [date, props]);

    const { isLoading: loadingBrokers, data: brokers } = useGetBrokerQuery({ searchText: '', sortKey: 'CODE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
    const { isLoading: loadingDrivers, data: drivers } = useGetDriverQuery({ searchText: '', sortKey: 'CODE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
    const { isLoading: loadingOrders, data: orderReviews } = useGetOrderReviewQuery({ searchText: '', sortKey: '', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false, orderDate: format(date, "yyyy-MM-dd") });

    const editDisabled = useMemo(() => {
        if (loadingBrokers || loadingDrivers || loadingOrders) {
            return true;
        }
        if (isUserAllowedToBill && isUserAllowedToReview) {
            return false;
        }
        return true;
    }, [isUserAllowedToBill, isUserAllowedToReview, loadingBrokers, loadingDrivers, loadingOrders]);

    const updateDate = useCallback(
        (updatedDate: Date) => {
            const newDate = new Date(updatedDate);

            if (isValid(newDate)) {
                setDate(startOfDay(newDate));
                setSearchParams(createSearchParams({ date: format(newDate, 'yyyy-MM-dd') }));
            }
        },
        [setSearchParams]
    );

    const handleHaulerChange = useCallback((selectedHauler?: BrokerDto | DriverDto) => {
        setFormHaulerId(selectedHauler?.id ?? '');
    }, []);

    const handleDispatchesUpdated = useCallback((dispatches: DispatchReviewDto[]) => {
        handleDispatchesChange(dispatches);
    }, [handleDispatchesChange]);

    const getFilteredDispatchReviews = useCallback(() => {
        const dispatchReviewSet = new Set<DispatchReviewDto>();
        const orderReviewsData = orderReviews?.pageResults ?? [];

        orderReviewsData.forEach(orderReview => {
            orderReview.orderLineItems.forEach(orderLineItem => {
                orderLineItem.dispatches?.forEach(dispatchReview => {
                    if (dispatchReview.brokerId === formHaulerId || dispatchReview.driverId === formHaulerId) {

                        const updatedOrderLineItem = _.cloneDeep(orderLineItem);
                        updatedOrderLineItem.orderReview = orderReview;

                        const addDispatchReview = _.cloneDeep(dispatchReview);
                        addDispatchReview.orderLineItem = updatedOrderLineItem;
                        dispatchReviewSet.add(addDispatchReview);
                    }
                });
            });
        });

        const dispatchReviewList = Array.from(dispatchReviewSet);
        setFormDispatchReviews(dispatchReviewList);
    },
        [formHaulerId, orderReviews?.pageResults, setFormDispatchReviews]
    );

    useEffect(() => {
        getFilteredDispatchReviews();
    }, [formHaulerId, getFilteredDispatchReviews]);

    const dispatchDetails = useCallback(() => {
        return (
            <Box sx={{ width: '100%', borderBottom: 1, borderRight: 1, borderColor: 'divider', display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}>
                <Box flexGrow={1} overflow='hidden'>
                    <DispatchTicketEntryTable
                        brokers={brokers?.pageResults ?? []}
                        disabled={editDisabled}
                        dispatchReviews={formDispatchReviews}
                        drivers={drivers?.pageResults ?? []}
                        setDispatches={handleDispatchesUpdated}
                        addFreightBillingLineItem={handleAddNewFreightBillingLineItem}
                        removeFreightBillingLineItem={handleRemoveFreightBillingLineItem}
                        dispatchErrors={dispatchErrors}
                        freightErrors={freightErrors}
                        getOrderById={getOrderById}
                    />
                </Box>
            </Box>
        );
    }, [brokers?.pageResults, dispatchErrors, drivers?.pageResults, editDisabled, formDispatchReviews, freightErrors, getOrderById, handleAddNewFreightBillingLineItem, handleDispatchesUpdated, handleRemoveFreightBillingLineItem]);

    const ticketForm = useMemo(() => {
        return (
            <Box display='flex' flexDirection='column' height='100%' overflow='hidden'>
                {!isLoadingOrders ? (
                    <>
                        <SlimFormHeader
                            objectName='Order'
                            permissionsTypeName='order'
                            icon={<RateReview />}
                            title={`Enter Ticket`}
                            breadcrumbs={[
                                { label: 'Home', navLink: '/' },
                                { label: 'Review', navLink: `/orderReview?date=${format((date ? new Date(date) : new Date()), 'yyyy-MM-dd')} ` },
                            ]}
                            isFormDirty={isFormDirty}
                            handleCancel={handleCancel}
                            handleSave={handleSave}
                            entityNameSingular={'order'}
                            hideDividers
                            deleteConfirmationDialog={{ title: 'Delete Ticket?', content: <Typography>Are you sure you would like to permanently delete this record? It will be deleted immediately and cannot be recovered.</Typography> }}
                            deleteMutation={useArchiveOrderReviewDeleteFromDatabaseByIdMutation}
                            additionalHeaderItems={
                                <DateScroller
                                    date={date}
                                    updateDate={updateDate}
                                    fullWidth
                                />
                            }
                            additionalItemsOnRight={true}
                        />
                        <Paper sx={{ height: '100%', display: 'flex', flexGrow: 1, overflow: 'hidden', flexDirection: 'column', p: 2, m: 2 }}>
                            <Box display='flex' flexDirection='column' height='100%' overflow='hidden'>
                                <Grid container justifyContent="center" alignItems="center">
                                    <Grid item xs={8} sm={6} md={4}>
                                        <HaulerSelect
                                            selectedHaulerId={formHaulerId}
                                            handleSelectedHaulerChange={handleHaulerChange}
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Box>
                                    <Typography variant='h2' mt={1}>Dispatches</Typography>
                                </Box>
                                <Box display='flex' flexDirection='column' height='100%' overflow='hidden'>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflow: 'auto' }}>
                                        {dispatchDetails()}
                                    </Box>
                                </Box>
                            </Box>
                        </Paper>
                    </>
                ) : (
                    <LoadingIndicator />
                )}
            </Box>
        );
    }, [date, dispatchDetails, formHaulerId, handleCancel, handleHaulerChange, handleSave, isFormDirty, isLoadingOrders, updateDate]);

    if (loadingBrokers || loadingDrivers || loadingOrders) {
        return <LoadingIndicator />;
    }

    return (
        <>{ticketForm}</>
    );
};
