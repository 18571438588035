import { FormControl, FormLabel, Grid, MenuItem, OutlinedInput, Select, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ConfirmDialog } from "../../Components/Core/ConfirmDialog";
import { ControlPointDuplicate, Info } from "@mui/icons-material";
import { AddressLookup, DatePicker, SiteSelect } from "../../Components/CommonInputs";
import { add } from "date-fns";
import { CloneQuoteDto, RegionDto, SiteDto } from "../../dtos";
import { FormInput, FormNumberInput, IEntityAutocomplete } from "../../Components/CoreLib/library";
import { useGetRegionQuery } from "../../store/generated/generatedApi";
import { countries, usStates } from "../../util";

export interface ICloneQuoteDialog {
    onClose: () => void;
    onConfirm: (
        cloneQuoteDto: CloneQuoteDto
    ) => void;
    open: boolean;
    quoteEffectiveOn: Date;
    isCopying?: boolean;
}

export const CloneQuoteDialog: FC<ICloneQuoteDialog> = (props) => {
    const { onClose, onConfirm, open, quoteEffectiveOn, isCopying } = props;
    const [effectiveOn, setEffectiveOn] = useState<Date | null | undefined>();
    const [priceIncreasePercentage, setPriceIncreasePercentage] = useState<number>();
    const [jobName, setJobName] = useState<string>('');
    const [addressLine1, setAddressLine1] = useState<string>('');
    const [addressLine2, setAddressLine2] = useState<string>('');
    const [city, setCity] = useState<string>('');
    const [state, setState] = useState<string>('');
    const [zipCode, setZipCode] = useState<string>('');
    const [country, setCountry] = useState<string>('');
    const [site, setSite] = useState<SiteDto>();
    const [siteName, setSiteName] = useState<string>('');
    const [region, setRegion] = useState<RegionDto>();
    const [miles, setMiles] = useState<number | undefined>();
    const [minutes, setMinutes] = useState<number | undefined>();
    const [toOrFromJob, setToOrFromJob] = useState<string>('');

    const { currentData: regions } = useGetRegionQuery({ searchText: '', sortKey: 'NAME', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });

    const handleOnConfirm = useCallback(() => {
        if (effectiveOn && !isNaN(Date.parse(effectiveOn.toString()))) {
            onConfirm({
                effectiveOn,
                priceIncreasePercentage,
                jobName,
                addressLine1,
                addressLine2,
                city,
                state,
                zipCode,
                country,
                siteName,
                regionId: region?.id,
                miles,
                minutes,
                toOrFromJob,
            });
            setEffectiveOn(undefined);
            setPriceIncreasePercentage(undefined);
            setJobName('');
            setAddressLine1('');
            setAddressLine2('');
            setCity('');
            setState('');
            setZipCode('');
            setCountry('');
            setSite(undefined);
            setSiteName('');
            setRegion(undefined);
            setMiles(undefined);
            setMinutes(undefined);
            setToOrFromJob('');
        }
    }, [addressLine1, addressLine2, city, country, effectiveOn, jobName, miles, minutes, onConfirm, priceIncreasePercentage, region?.id, siteName, state, toOrFromJob, zipCode]);

    const minEffectiveDate = useMemo(() => {
        if (quoteEffectiveOn) {
            const effectiveOn = new Date(quoteEffectiveOn.getFullYear(), quoteEffectiveOn.getMonth(), quoteEffectiveOn.getDate());
            if (isCopying) {
                return effectiveOn;
            } else {
                return add(effectiveOn, { days: 1 });
            }
        }

        return undefined;

    }, [quoteEffectiveOn, isCopying]);

    useEffect(() => {
        if (!effectiveOn) {
            setEffectiveOn(minEffectiveDate);
        }
    }, [effectiveOn, minEffectiveDate]);

    const jobFormFields = useMemo(() => {
        return (
            <>
                <Grid item container direction='row' spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AddressLookup
                            handleAddressLine1Change={(value) => setAddressLine1(value)}
                            handleCityChange={(value) => setCity(value)}
                            handleCountryChange={(value) => setCountry(value)}
                            handleStateChange={(value) => setState(value)}
                            handleZipCodeChange={(value) => setZipCode(value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <SiteSelect
                            selectedSiteId={site?.id ?? ''}
                            handleSelectedSiteChange={(value) => {
                                setSite(value);
                                setAddressLine1(value?.addressLine1 ?? '');
                                setAddressLine2(value?.addressLine2 ?? '');
                                setCity(value?.city ?? '');
                                setState(value?.state ?? '');
                                setZipCode(value?.zipCode ?? '');
                                setCountry(value?.country ?? '');
                                setRegion(value?.region);
                                setSiteName(value?.name ?? '');
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormInput
                            value={siteName}
                            onChange={(e) => setSiteName(e.target.value)}
                            label='Site Name'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormControl fullWidth>
                            <FormLabel>Region</FormLabel>
                            <IEntityAutocomplete
                                options={regions?.pageResults ?? []}
                                onChange={(_e, value) => {
                                    setRegion(value);
                                }}
                                value={region}
                                getOptionLabel={(option: RegionDto) => option.name}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item container direction='row' spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormInput
                            value={addressLine1}
                            onChange={(e) => setAddressLine1(e.target.value)}
                            label='Address Line 1'
                            name='addressLine1'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <FormInput
                            value={addressLine2}
                            onChange={(e) => setAddressLine2(e.target.value)}
                            label='Address Line 2'
                            name='addressLine2'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <FormInput
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                            label='City'
                            name='city'
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <FormControl fullWidth>
                            <FormLabel>State</FormLabel>
                            <Select value={state} onChange={(e) => setState(e.target.value)}>
                                {usStates.map((usState) => (
                                    <MenuItem key={usState.abbreviation} value={usState.abbreviation}>
                                        {usState.name}
                                    </MenuItem>
                                ))}
                                <MenuItem value='' key='noState'>-</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <FormInput
                            value={zipCode}
                            onChange={(e) => setZipCode(e.target.value)}
                            label='Zip Code'
                            name='zipCode'
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid item container direction='row' spacing={3}>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <FormLabel>Country</FormLabel>
                            <Select value={country} onChange={(e) => setCountry(e.target.value)}>
                                {countries.map((country) => (
                                    <MenuItem key={country} value={country}>
                                        {country}
                                    </MenuItem>
                                ))}
                                <MenuItem value='' key='noCountry'>-</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormNumberInput
                            value={miles}
                            name='miles'
                            onChange={(e) => setMiles(e.target.valueAsNumber)}
                            label='Miles'
                            fullWidth
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormNumberInput
                            value={minutes}
                            name='minutes'
                            onChange={(e) => setMinutes(e.target.valueAsNumber)}
                            label='Minutes'
                            fullWidth
                            inputProps={{ min: 0 }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormInput
                            value={toOrFromJob}
                            onChange={(e) => setToOrFromJob(e.target.value)}
                            label='To or from Job'
                            name='toOrFromJob'
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </>
        );
    }, [addressLine1, addressLine2, city, country, miles, minutes, region, regions?.pageResults, site?.id, siteName, state, toOrFromJob, zipCode]);

    return (
        <ConfirmDialog
            maxWidth={isCopying ? 'lg' : 'sm'}
            open={open}
            onClose={() => {
                setEffectiveOn(undefined);
                setPriceIncreasePercentage(undefined);
                onClose();
            }}
            content={
                <Grid item container direction='column' spacing={2}>
                    <Grid item>
                        <Typography>Please enter the following data to initialize the new Quote.</Typography>
                    </Grid>
                    <Grid item container direction='row' spacing={2} justifyContent={!isCopying ? 'space-between' : 'normal'}>
                        <Grid item xs={isCopying ? 3 : 6}>
                            <FormControl fullWidth>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormLabel required>Effective On</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title='The date the new quote will become effective on.'><Info /></Tooltip>
                                    </Grid>
                                </Grid>
                                <DatePicker
                                    value={effectiveOn ?? minEffectiveDate ?? null}
                                    onChange={(date) => setEffectiveOn(date)}
                                    minDate={minEffectiveDate}
                                    error={!(effectiveOn || minEffectiveDate)}
                                    errorText={!(effectiveOn || minEffectiveDate) ? 'Effective On Date is required.' : ''}
                                />
                            </FormControl>
                        </Grid>
                        {!isCopying && <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <FormLabel>Price Increase Percentage (%)</FormLabel>
                                    </Grid>
                                    <Grid item>
                                        <Tooltip title='All Line Item rates (except Material Line Items using standard pricing) will be increased using this percentage.'><Info /></Tooltip>
                                    </Grid>
                                </Grid>
                                <OutlinedInput
                                    onChange={
                                        (event: React.ChangeEvent<HTMLInputElement>) => {
                                            setPriceIncreasePercentage(event.target.valueAsNumber);
                                        }
                                    }
                                    type={'number'}
                                    required
                                    placeholder="10"
                                    value={priceIncreasePercentage ?? ''}
                                />
                            </FormControl>
                        </Grid>}
                        {isCopying &&
                            <Grid item xs={3} mt={1}>
                                <FormInput
                                    value={jobName}
                                    onChange={(event) => setJobName(event.target.value)}
                                    label='Job Name'
                                    fullWidth
                                />
                            </Grid>}
                    </Grid>
                    {isCopying && jobFormFields}
                </Grid>
            }
            title={isCopying ? 'Copy Quote' : 'Clone Quote'}
            titleIcon={<ControlPointDuplicate />}
            onConfirm={handleOnConfirm}
            confirmText='Confirm'
            requireConfirmation={false}
        />
    );
}