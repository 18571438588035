import { AddCircle, Cancel, MoreVert, PlaylistAdd, Save } from '@mui/icons-material';
import { Button, Divider, Grid, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { emptyGuid, usePageTitleSetter } from '../../../util';
import { DatePicker, SiteSelect } from '../../CommonInputs';
import { FormSection, LoadingIndicator, SlimPageHeader } from '../../CoreLib/library';
import { MaterialPriceCostLineItem } from './Components';
import { IUseImportPriceListFormProps } from './types';
import { useInputPriceListForm } from './useInputPriceListForm';

export const InputPriceListForm: FC<IUseImportPriceListFormProps> = (props) => {
    usePageTitleSetter('Input Price List');
    const {
        handleSave,
        handleCancel,
        mainFieldErrors,
        existingLineItemFieldErrors,
        newLineItemFieldErrors,
        handleSiteChange,
        handleEffectiveOnChange,
        handleExistingMaterialPriceCostsChange,
        handleNewMaterialPriceCostsChange,
        formSiteId,
        formEffectiveOnDate,
        formExistingPriceLineItems,
        formNewPriceLineItems,
        validateMainFormField,
        validateExistingLineItemField,
        validateNewLineItemField,
        isLoadingInitialSitePriceList,
        isInitialSitePriceListLoaded,
        handleAddNewMaterialPrice,
        handleRemoveNewMaterialPrice,
    } = useInputPriceListForm(props);

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const renderPricesSection = () => {
        if (isLoadingInitialSitePriceList) {
            return <LoadingIndicator />;
        }

        if (!isInitialSitePriceListLoaded || formSiteId === emptyGuid) {
            return null;
        }

        return (
            <>
                <Grid item container direction='row'>
                    <Typography variant='h2'>Existing Material Prices</Typography>
                </Grid>
                <Grid item container direction='column'>
                    {formExistingPriceLineItems.map((existingLineItem, idx) => (
                        <MaterialPriceCostLineItem
                            key={`updated-price-${existingLineItem.referenceMaterialPriceId}`}
                            idx={idx}
                            priceListLineItem={existingLineItem}
                            handleChange={handleExistingMaterialPriceCostsChange}
                            handleBlur={validateExistingLineItemField}
                            errors={existingLineItemFieldErrors[idx]}
                        />
                    ))}
                </Grid>
                <Grid item container direction='row'>
                    <Typography variant='h2'>New Material Prices</Typography>
                </Grid>
                <Grid item container direction='column'>
                    {formNewPriceLineItems.map((newLineItem, idx) => (
                        <MaterialPriceCostLineItem
                            key={`new-price-${idx}`}
                            idx={idx}
                            priceListLineItem={newLineItem}
                            handleChange={handleNewMaterialPriceCostsChange}
                            handleDelete={() => handleRemoveNewMaterialPrice(idx)}
                            handleBlur={validateNewLineItemField}
                            errors={newLineItemFieldErrors[idx]}
                            splitMaterialAndIncome
                        />
                    ))}
                    <Grid item>
                        <Tooltip title='Add New Material Price'>
                            <IconButton color='primary' onClick={handleAddNewMaterialPrice}>
                                <AddCircle fontSize='large' />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </>
        );
    };

    return (
        <Grid container direction='column'>
            <SlimPageHeader
                icon={<PlaylistAdd />}
                title='Input Price List'
                breadCrumbProps={{
                    links: [
                        { label: 'Home', navLink: '/' },
                        { label: 'Material Prices', navLink: '/dataManagement/materialPrices' },
                    ],
                    currentPageLabel: 'Input Price List',
                }}
                endSlot={
                    <Grid item container direction='row' alignItems='start' py={1}>
                        <Grid item display='flex' alignItems='center' justifyContent={'end'} xs={12} sx={{ gap: 1 }} pr={2}>
                            <Divider flexItem orientation='vertical' sx={{ mx: 1 }} />
                            <Button variant='outlined' color='primary' size='small' onClick={handleCancel} startIcon={<Cancel />} sx={{ width: 90 }}>
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                color='primary'
                                size='small'
                                onClick={handleSave}
                                disabled={isLoadingInitialSitePriceList}
                                startIcon={<Save />}
                                sx={{ width: 90 }}>
                                Save
                            </Button>
                            <IconButton onClick={handleMenuOpen}>
                                <MoreVert fontSize='inherit' />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchorEl}
                                open={!!menuAnchorEl}
                                onClose={handleMenuClose()}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}>
                                <MenuItem onClick={handleMenuClose(handleSave)}>Save</MenuItem>
                                <MenuItem onClick={handleMenuClose(handleCancel)}>Cancel</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                }
            />
            <Grid item mx={2} mt={1}>
                <FormSection>
                    <Grid item container direction='column' spacing={3}>
                        <Grid item container direction='row' spacing={3}>
                            <Grid item xs={12} sm={6} md={4}>
                                <SiteSelect
                                    selectedSiteId={formSiteId}
                                    handleSelectedSiteChange={handleSiteChange}
                                    required
                                    errorMessage={mainFieldErrors.get('siteId')}
                                    onBlur={() => validateMainFormField('siteId')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <DatePicker
                                    label='Effective On'
                                    value={formEffectiveOnDate}
                                    onChange={handleEffectiveOnChange}
                                    error={!!mainFieldErrors.get('effectiveOn')}
                                    errorText={mainFieldErrors.get('effectiveOn')}
                                    required
                                />
                            </Grid>
                        </Grid>
                        {renderPricesSection()}
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
