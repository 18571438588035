import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QuoteDto } from '../../dtos';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { useGetQuoteQuery } from '../../store/generated/generatedApi';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'QUOTE_NUMBER',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export interface IJobQuoteListViewProps {
    setSelected: (selected: QuoteDto[]) => void;
}

export const JobQuoteListView: FC<IJobQuoteListViewProps> = props => {
    const { setSelected } = props;
    const navigate = useNavigate();
    const { customerId } = useParams();

    const tableColumns: DataTableColumn<QuoteDto>[] = [
        { key: 'quoteNumber', label: 'Quote #', sortKey: 'QUOTE_NUMBER' },
        { key: 'quoteDate', label: 'Quote Date', sortKey: 'QUOTE_DATE', fieldMapper: (row) => (row.quoteDate ? new Date(row.quoteDate.toString()).toLocaleDateString() : '') },
        { key: 'salesperson', label: 'Salesperson', sortKey: 'SALESPERSON', fieldMapper: (row) => row.salesPerson?.displayName },
        { key: 'type', label: 'Type', sortKey: 'TYPE' },
        { key: 'status', label: 'Status', sortKey: 'STATUS' }
    ];

    const handleTableEdit = (id: string) => {
        if (customerId) {
            navigate(`/customer/${customerId}/quote/${id}`);
        } else {
            navigate(`/quote/${id}`);
        }
    };

    return (
        <>
            <StandardListViewV2
                headerIcon={<></>}
                headerTitle={''}
                breadCrumbs={[]}
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetQuoteQuery}
                isShowActiveToggleVisible={false}
                tableColumns={tableColumns}
                entityNameSingular='Quote'
                permissionName='quote'
                handleEdit={handleTableEdit}
                onSelectedChange={(selected: QuoteDto[]) => {
                    setSelected(selected);
                }}
                additionalQueryParameters={{ accepted: true, includeQuotesWithJobs: false }}
            />
        </>
    );
};