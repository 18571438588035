import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
    IFormFieldValidationConfig,
    IFormProps,
    isNotBlank,
    isShorterThanMaxLength,
    isValidEmail,
    runFieldValidation,
    runFormValidation,
} from '../../CoreLib/library';
import { emptyGuid } from '../../../util';
import { ContactDto } from '../../../dtos/generated/ContactDto';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

export interface IContactFormValues {
    firstName: string;
    lastName: string;
    title: string;
    landlinePhone: string;
    cellPhone: string;
    fax: string;
    email: string;
    memo: string;
    isActive: boolean;
}

const DEFAULT_Contact: ContactDto = {
    id: emptyGuid,
    customerId: '',
    firstName: '',
    lastName: '',
    memo: '',
    isActive: true,
    createdOn: new Date(),
};

export function useContactForm(props: IFormProps<ContactDto>, fixedCustomerId?: string, clearOnSave: boolean = false) {
    const { save, cancel, initValues } = props;
    const { customerId: paramCustomerId, siteId } = useParams();
    const customerId = useMemo(() => fixedCustomerId ?? paramCustomerId, [paramCustomerId, fixedCustomerId]);

    const [formFirstName, setFormFirstName] = useState(initValues?.firstName ?? '');
    const [formLastName, setFormLastName] = useState(initValues?.lastName ?? '');
    const [formTitle, setFormTitle] = useState(initValues?.title ?? '');
    const [formLandlinePhone, setFormLandlinePhone] = useState(initValues?.landlinePhone ?? '');
    const [formCellPhone, setFormCellPhone] = useState(initValues?.cellPhone ?? '');
    const [formFax, setFormFax] = useState(initValues?.fax ?? '');
    const [formEmail, setFormEmail] = useState(initValues?.email ?? '');
    const [formMemo, setFormMemo] = useState(initValues?.memo ?? '');
    const [formIsActive, setFormIsActive] = useState(initValues?.isActive ?? true);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setFormFirstName(initValues?.firstName ?? '');
        setFormLastName(initValues?.lastName ?? '');
        setFormTitle(initValues?.title ?? '');
        setFormLandlinePhone(initValues?.landlinePhone ?? '');
        setFormCellPhone(initValues?.cellPhone ?? '');
        setFormFax(initValues?.fax ?? '');
        setFormEmail(initValues?.email ?? '');
        setFormMemo(initValues?.memo ?? '');
        setFormIsActive(initValues?.isActive ?? true);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof ContactDto, string>>(
        new Map([
            ['firstName', ''],
            ['lastName', ''],
            ['landlinePhone', ''],
            ['cellPhone', ''],
            ['fax', ''],
            ['memo', ''],
        ])
    );

    const [formFieldValidators] = useState(
        new Map<keyof ContactDto, IFormFieldValidationConfig>([
            [
                'firstName',
                {
                    validators: [isNotBlank, isShorterThanMaxLength(250)],
                    errorMessageEntityName: 'First Name',
                },
            ],
            [
                'lastName',
                {
                    validators: [isShorterThanMaxLength(250)],
                    errorMessageEntityName: 'Last Name',
                },
            ],
            [
                'title',
                {
                    validators: [isShorterThanMaxLength(250)],
                    errorMessageEntityName: 'Title',
                },
            ],
            [
                'email',
                {
                    validators: [isValidEmail],
                    errorMessageEntityName: 'Email',
                },
            ],
            [
                'memo',
                {
                    validators: [isShorterThanMaxLength(4000)],
                    errorMessageEntityName: 'Memo',
                },
            ],
            [
                'landlinePhone',
                {
                    validators: [isShorterThanMaxLength(25)],
                    errorMessageEntityName: 'Land Line',
                },
            ],
            [
                'cellPhone',
                {
                    validators: [isShorterThanMaxLength(25)],
                    errorMessageEntityName: 'Cell Phone',
                },
            ],
            [
                'fax',
                {
                    validators: [isShorterThanMaxLength(25)],
                    errorMessageEntityName: 'Fax',
                },
            ],
        ])
    );

    const getCurrentFormValues = useCallback((): IContactFormValues => {
        return {
            firstName: formFirstName,
            lastName: formLastName,
            title: formTitle,
            landlinePhone: formLandlinePhone,
            cellPhone: formCellPhone,
            fax: formFax,
            email: formEmail,
            memo: formMemo,
            isActive: formIsActive,
        };
    }, [formCellPhone, formEmail, formFax, formFirstName, formIsActive, formLandlinePhone, formLastName, formMemo, formTitle]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<ContactDto>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IContactFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    const isFormDirty = useCallback(() => {
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formFirstName !== initValues?.firstName;
        isDirty = isDirty || formLastName !== initValues?.lastName;
        isDirty = isDirty || formTitle !== initValues?.title;
        isDirty = isDirty || formLandlinePhone !== initValues?.landlinePhone;
        isDirty = isDirty || formCellPhone !== initValues?.cellPhone;
        isDirty = isDirty || formFax !== initValues?.fax;
        isDirty = isDirty || formEmail !== initValues?.email;
        isDirty = isDirty || formMemo !== initValues?.memo;
        isDirty = isDirty || formIsActive !== initValues?.isActive;
        isDirty = isDirty || isChanged;
        return isDirty;
    }, [
        formCellPhone,
        formEmail,
        formFax,
        formFirstName,
        formIsActive,
        formLandlinePhone,
        formLastName,
        formMemo,
        formTitle,
        initValues?.cellPhone,
        initValues?.email,
        initValues?.fax,
        initValues?.firstName,
        initValues?.isActive,
        initValues?.landlinePhone,
        initValues?.lastName,
        initValues?.memo,
        initValues?.title,
        isChanged,
    ]);

    const handleFirstNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormFirstName(event.target.value);
        setIsChanged(true);
    }, []);

    const handleLastNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormLastName(event.target.value);
        setIsChanged(true);
    }, []);

    const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormEmail(event.target.value.trim());
        setIsChanged(true);
    }, []);

    const handleMemoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormMemo(event.target.value);
        setIsChanged(true);
    }, []);

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    }, []);

    const handleTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormTitle(event.target.value);
        setIsChanged(true);
    }, []);

    const handleLandlinePhoneChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormLandlinePhone(event.target.value);
        setIsChanged(true);
    }, []);

    const handleCellPhoneChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormCellPhone(event.target.value);
        setIsChanged(true);
    }, []);

    const handleFaxChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormFax(event.target.value);
        setIsChanged(true);
    }, []);

    const clearForm = useCallback(() => {
        setFormFirstName('');
        setFormLastName('');
        setFormTitle('');
        setFormLandlinePhone('');
        setFormCellPhone('');
        setFormFax('');
        setFormEmail('');
        setFormMemo('');
        setFormIsActive(false);
    }, []);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedContactRecord = {
                ...(initValues ?? DEFAULT_Contact),
                customerId: customerId,
                siteId: siteId,
                ...formValues,
            };
            save(updatedContactRecord);
            if (clearOnSave) {
                clearForm();
            }
            setIsChanged(false);
        }
    }, [clearForm, clearOnSave, customerId, getCurrentFormValues, initValues, save, siteId, validateForm]);

    const handleCancel = useCallback(() => {
        cancel();
        if (clearOnSave) {
            clearForm();
        }
        setIsChanged(false);
    }, [cancel, clearForm, clearOnSave]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleFirstNameChange,
        handleLastNameChange,
        handleEmailChange,
        handleMemoChange,
        handleIsActiveChange,
        handleTitleChange,
        handleLandlinePhoneChange,
        handleCellPhoneChange,
        handleFaxChange,
        formFirstName,
        formLastName,
        formEmail,
        formMemo,
        formIsActive,
        formTitle,
        formLandlinePhone,
        formCellPhone,
        formFax,
        validateField,
        customerId,
        siteId,
        isChanged,
    };
}

export type ContactFormManager = ReturnType<typeof useContactForm>;
