import { FormControl, FormHelperText, FormLabel } from '@mui/material';
import { FC, useMemo } from 'react';
import { EquipmentDto } from '../../dtos';
import { useGetEquipmentQuery } from '../../store/generated/generatedApi';
import { IEntityAutocomplete } from '../CoreLib/library';

export interface IEquipmentAutocompleteProps {
    initialEquipment?: EquipmentDto;
    formEquipment?: EquipmentDto;
    handleEquipmentChange: (selectedEquipment?: EquipmentDto) => void;
    required?: boolean;
    errorMessage?: string;
}

export const EquipmentAutocomplete: FC<IEquipmentAutocompleteProps> = props => {
    const { initialEquipment, formEquipment, handleEquipmentChange, required, errorMessage } = props;

    const { data: equipment, isLoading: equipmentLoading } = useGetEquipmentQuery({ searchText: '', sortKey: 'NUMBER', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false, filterOutEntriesWithDriver: true });

    const equipmentResults: EquipmentDto[] = useMemo(() => {
        if (!equipment) {
            return [];
        }
        if (!initialEquipment || equipment?.pageResults.includes(initialEquipment)) {
            return equipment?.pageResults;
        }
        return [initialEquipment, ...equipment?.pageResults];
    }, [equipment, initialEquipment])

    return (
        <FormControl fullWidth required={required} error={!!errorMessage}>
            <FormLabel>Assigned Equipment</FormLabel>
            <IEntityAutocomplete
                options={equipmentResults}
                onChange={(e, value) => { handleEquipmentChange(value ?? null); }}
                value={formEquipment}
                getOptionLabel={(option: EquipmentDto) => `${option.number} - ${option.equipmentType?.type}`}
                isLoading={equipmentLoading}
                error={!!errorMessage}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}