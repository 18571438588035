import { AddCircle, AccountBox } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, Grid, IconButton } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { ContactDto } from '../../dtos';
import { useCreateContactMutation } from '../../store/generated/generatedApi';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../CoreLib/library';
import { ContactForm } from '../Forms/ContactForm';
import { ModalSaveCloseFooter, ModalTitle } from '../Modal';
import { useContactForm } from '../Forms/ContactForm/useContactForm';

export interface IContactQuickAddButtonProps {
    onAdd: (newCustomer: ContactDto) => void;
    customerId?: string;
    disabled?: boolean;
}

export const ContactQuickAddButton: FC<IContactQuickAddButtonProps> = ({ onAdd, customerId, disabled }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [createContact, { data, isError, isSuccess, reset, isLoading }] = useCreateContactMutation();
    useSuccessfulCreateSnackbar('Contact', isSuccess, reset);
    useFailedCreateSnackbar('contact', isError, reset);

    const save = useCallback(
        (contact: ContactDto) => {
            createContact(contact);
            setIsModalOpen(false);
        },
        [createContact]
    );

    const cancel = useCallback(() => {
        setIsModalOpen(false);
    }, []);

    const contactFormManager = useContactForm({ save, cancel, isLoading }, customerId, true);
    const { handleSave, handleCancel } = contactFormManager;

    useEffect(() => {
        if (data) {
            onAdd(data);
            reset()
        }
    }, [onAdd, data, reset]);

    return (
        <>
            <IconButton onClick={() => setIsModalOpen(true)} color='primary' disabled={disabled}>
                <AddCircle />
            </IconButton>
            <Dialog open={isModalOpen} fullWidth maxWidth='lg'>
                <ModalTitle icon={<AccountBox sx={{ mr: 1 }} />} title={'Add Contact'} />
                <DialogContent>
                    <Grid container direction='row' gap={1}>
                        <ContactForm
                            contactFormManager={contactFormManager}
                            isLoading={isLoading}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ height: 48 }}>
                    <ModalSaveCloseFooter onSaveClicked={handleSave} onCancelCloseClicked={handleCancel} />
                </DialogActions>
            </Dialog>
        </>
    );
};
