import { FC, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSuccessfulUpdateSnackbar, useFailedUpdateSnackbar, LoadingIndicator, ApiError } from "../../../../Components/CoreLib/library";
import { MaterialLineItemDto } from "../../../../dtos";
import { useLazyGetQuoteByIdQuery, useGetMaterialLineItemByIdQuery, useUpdateMaterialLineItemMutation } from "../../../../store/generated/generatedApi";
import { MaterialLineItemForm } from "../../../../Components/Forms/QuoteForm/LineItemForms/MaterialLineItem/MaterialLineItemForm";


export const EditMaterialLineItemView: FC = () => {
    const { quoteId, lineItemId } = useParams();
    const navigate = useNavigate();
    const [getQuote, { data: quote, isLoading: isQuoteLoading }] = useLazyGetQuoteByIdQuery();
    const { data, isLoading, error, refetch } = useGetMaterialLineItemByIdQuery({ id: lineItemId! });
    const [updateMaterialLineItem, { isSuccess, error: updateError, isError, reset }] = useUpdateMaterialLineItemMutation();

    useEffect(() => {
        if (quoteId && !quote) {
            getQuote({ id: quoteId! });
        }
    }, [quote, quoteId, getQuote]);

    useSuccessfulUpdateSnackbar('Material Line Item', isSuccess, () => {
        if (quoteId) {
            navigate(`/quote/${quoteId}/lineItems`);
        } else {
            navigate(`/quotes`);
        }
    });
    useFailedUpdateSnackbar('Material Line Item', isError, reset, updateError);

    const handleSave = (values: MaterialLineItemDto) => {
        updateMaterialLineItem(values);
    };

    const handleCancel = () => {
        if (quoteId) {
            navigate(`/quote/${quoteId}/lineItems`)
        } else {
            navigate(`/quotes`);
        }
    };

    if (isLoading || isQuoteLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <MaterialLineItemForm save={handleSave} cancel={handleCancel} initValues={data} quote={quote} />;
};
