import { SelectChangeEvent } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    IFormFieldValidationConfig,
    IFormProps,
    isAlphanumericUppercase,
    isNotBlank,
    isShorterThanMaxLength,
    runFieldValidation,
    runFormValidation,
} from '../../CoreLib/library';
import { CustomerDto, TermsCodeDto, UserDto } from '../../../dtos';
import { accountStatuses, bBInvoiceTypes, countries, emptyGuid } from '../../../util';

export interface ICustomerFormValues {
    name: string;
    customerNumber: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    bbInvoiceType: string;
    pricing: string;
    miscCode: string;
    salesPersonId?: string;
    termsCodeId: string;
    isTaxable?: boolean;
    accountStatus: string;
    accountCreditLimit?: number;
    accountComment: string;
    accountHighCreditDate?: Date;
    accountHighCreditAmount?: number;
    isActive: boolean;
}

const DEFAULT_CUSTOMER: CustomerDto = {
    id: emptyGuid,
    name: '',
    customerNumber: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: countries[2], // US
    bbInvoiceType: bBInvoiceTypes[1], // Standard
    pricing: '',
    miscCode: '',
    salesPersonId: emptyGuid,
    termsCodeId: emptyGuid,
    isTaxable: false,
    accountStatus: accountStatuses[1],
    accountCreditLimit: undefined,
    accountComment: '',
    accountHighCreditDate: undefined,
    accountHighCreditAmount: undefined,
    isActive: true,
    createdOn: new Date(),
    clientId: emptyGuid,
};

export function useCustomerForm(props: IFormProps<CustomerDto>, clearOnSave: boolean = false) {
    const { save, cancel, initValues } = props;
    const [formName, setFormName] = useState('');
    const [formCustomerNumber, setFormCustomerNumber] = useState('');
    const [formAddressLine1, setFormAddressLine1] = useState('');
    const [formAddressLine2, setFormAddressLine2] = useState('');
    const [formCity, setFormCity] = useState('');
    const [formState, setFormState] = useState('');
    const [formZipCode, setFormZipCode] = useState('');
    const [formCountry, setFormCountry] = useState(countries[2]);
    const [formBBInvoiceType, setFormBBInvoiceType] = useState(bBInvoiceTypes[1]);
    const [formPricing, setFormPricing] = useState('');
    const [formIsTaxable, setFormIsTaxable] = useState(initValues?.isTaxable ?? false);
    const [formMiscCode, setFormMiscCode] = useState('');
    const [formSalesPerson, setFormSalesPerson] = useState<UserDto | null>(null);
    const [formTermsCode, setFormTermsCode] = useState<TermsCodeDto | null>(null);
    const [formAccountStatus, setFormAccountStatus] = useState(accountStatuses[1]);
    const [formAccountCreditLimit, setFormAccountCreditLimit] = useState<number>();
    const [formAccountComment, setFormAccountComment] = useState('');
    const [formAccountHighCreditDate, setFormAccountHighCreditDate] = useState<Date | null | undefined>(null);
    const [formAccountHighCreditAmount, setFormAccountHighCreditAmount] = useState<number>();
    const [formIsActive, setFormIsActive] = useState(true);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setFormName(initValues?.name ?? '');
        setFormCustomerNumber(initValues?.customerNumber ?? '');
        setFormAddressLine1(initValues?.addressLine1 ?? '');
        setFormAddressLine2(initValues?.addressLine2 ?? '');
        setFormCity(initValues?.city ?? '');
        setFormState(initValues?.state ?? '');
        setFormZipCode(initValues?.zipCode ?? '');
        setFormCountry(initValues?.country ?? countries[2]);
        setFormBBInvoiceType(initValues?.bbInvoiceType ?? bBInvoiceTypes[1]);
        setFormPricing(initValues?.pricing ?? '');
        setFormMiscCode(initValues?.miscCode ?? '');
        setFormSalesPerson(initValues?.salesPerson ?? null);
        setFormTermsCode(initValues?.termsCode ?? null);
        setFormAccountStatus(initValues?.accountStatus ?? accountStatuses[1]);
        setFormAccountCreditLimit(initValues?.accountCreditLimit);
        setFormAccountComment(initValues?.accountComment ?? '');
        setFormAccountHighCreditDate(initValues?.accountHighCreditDate ?? null);
        setFormAccountHighCreditAmount(initValues?.accountHighCreditAmount);
        setFormIsActive(initValues?.isActive ?? true);
        setFormIsTaxable(initValues?.isTaxable ?? false);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof CustomerDto, string>>(
        new Map([
            ['name', ''],
            ['customerNumber', ''],
            ['addressLine1', ''],
            ['city', ''],
            ['state', ''],
            ['zipCode', ''],
            ['country', ''],
            ['termsCodeId', ''],
        ])
    );

    const formFieldValidators = new Map<keyof CustomerDto, IFormFieldValidationConfig>([
        [
            'name',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Customer Name',
            },
        ],
        [
            'customerNumber',
            {
                validators: [isNotBlank, isShorterThanMaxLength(5), isAlphanumericUppercase()],
                errorMessageEntityName: 'Customer Number',
            },
        ],
        [
            'addressLine1',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Address Line 1',
            },
        ],
        [
            'city',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'City',
            },
        ],
        [
            'state',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'State',
            },
        ],
        [
            'zipCode',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Zip Code',
            },
        ],
        [
            'country',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Country',
            },
        ],
        [
            'termsCodeId',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Terms Code',
            },
        ],
    ]);

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<CustomerDto>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    const getCurrentFormValues = (): ICustomerFormValues => {
        return {
            name: formName,
            customerNumber: formCustomerNumber,
            addressLine1: formAddressLine1,
            addressLine2: formAddressLine2,
            city: formCity,
            state: formState,
            zipCode: formZipCode,
            country: formCountry,
            bbInvoiceType: formBBInvoiceType,
            pricing: formPricing,
            miscCode: formMiscCode,
            salesPersonId: formSalesPerson?.id,
            termsCodeId: formTermsCode?.id ?? '',
            isTaxable: formIsTaxable,
            accountStatus: formAccountStatus,
            accountCreditLimit: formAccountCreditLimit ?? undefined,
            accountComment: formAccountComment,
            accountHighCreditDate: formAccountHighCreditDate ?? undefined,
            accountHighCreditAmount: formAccountHighCreditAmount ?? undefined,
            isActive: formIsActive,
        };
    };

    const validateField = (fieldName: keyof ICustomerFormValues) => {
        const validationConfig = formFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = getCurrentFormValues();
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    };

    const isFormDirty = () => {
        return isChanged;
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormName(event.target.value);
        setIsChanged(true);
    };

    const handleCustomerNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormCustomerNumber(event.target.value);
        setIsChanged(true);
    };

    const handleAddressLine1Change = (event: ChangeEvent<HTMLInputElement>) => {
        setFormAddressLine1(event.target.value);
        setIsChanged(true);
    };

    const handleAddressLine2Change = (event: ChangeEvent<HTMLInputElement>) => {
        setFormAddressLine2(event.target.value);
        setIsChanged(true);
    };

    const handleCityChange = (event: SelectChangeEvent<string>) => {
        setFormCity(event.target.value);
        setIsChanged(true);
    };

    const handleStateChange = (event: SelectChangeEvent) => {
        setFormState(event.target.value);
        setIsChanged(true);
    };

    const handleZipCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormZipCode(event.target.value);
        setIsChanged(true);
    };

    const handleCountryChange = (event: SelectChangeEvent) => {
        setFormCountry(event.target.value);
        setIsChanged(true);
    };

    const handleBBInvoiceTypeChange = (event: SelectChangeEvent) => {
        setFormBBInvoiceType(event.target.value);
        setIsChanged(true);
    };

    const handlePricingChange = (event: SelectChangeEvent) => {
        setFormPricing(event.target.value);
        setIsChanged(true);
    };

    const handleIsTaxableChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsTaxable(isChecked);
        setIsChanged(true);
    };

    const handleMiscCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormMiscCode(event.target.value);
        setIsChanged(true);
    };

    const handleSalesPersonChange = (value: any) => {
        setFormSalesPerson(value);
        setIsChanged(true);
    };

    const handleTermsCodeChange = (value: any) => {
        setFormTermsCode(value);
        setIsChanged(true);
    };

    const handleAccountStatusChange = (event: SelectChangeEvent) => {
        setFormAccountStatus(event.target.value);
        setIsChanged(true);
    };

    const handleAccountCreditLimitChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormAccountCreditLimit(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    };

    const handleAccountCommentChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormAccountComment(event.target.value);
        setIsChanged(true);
    };

    const handleAccountHighCreditDateChange = (value: Date | null | undefined) => {
        setFormAccountHighCreditDate(value);
        setIsChanged(true);
    };

    const handleAccountHighCreditAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormAccountHighCreditAmount(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    };

    const clearForm = useCallback(() => {
        setFormName('');
        setFormCustomerNumber('');
        setFormAddressLine1('');
        setFormAddressLine2('');
        setFormCity('');
        setFormState('');
        setFormZipCode('');
        setFormCountry(countries[2]);
        setFormBBInvoiceType(bBInvoiceTypes[1]);
        setFormPricing('');
        setFormIsTaxable(false);
        setFormMiscCode('');
        setFormSalesPerson(null);
        setFormTermsCode(null);
        setFormAccountStatus(accountStatuses[1]);
        setFormAccountCreditLimit(undefined);
        setFormAccountComment('');
        setFormAccountHighCreditDate(null);
        setFormAccountHighCreditAmount(undefined);
        setFormIsActive(true);
    }, []);

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_CUSTOMER),
                ...formValues,
            };
            save(updatedUserRecord);
            if (clearOnSave) {
                clearForm();
            }
            setIsChanged(false);
        }
    };

    const handleCancel = () => {
        cancel();
        if (clearOnSave) {
            clearForm();
        }
        setIsChanged(false);
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        formIsActive,
        formName,
        formCustomerNumber,
        formAddressLine1,
        formAddressLine2,
        formCity,
        formState,
        formZipCode,
        formCountry,
        formBBInvoiceType,
        formPricing,
        formIsTaxable,
        formMiscCode,
        formSalesPerson,
        formTermsCode,
        formAccountStatus,
        formAccountCreditLimit,
        formAccountComment,
        formAccountHighCreditDate,
        formAccountHighCreditAmount,
        handleNameChange,
        handleCustomerNumberChange,
        handleAddressLine1Change,
        handleAddressLine2Change,
        handleCityChange,
        handleStateChange,
        handleZipCodeChange,
        handleCountryChange,
        handleBBInvoiceTypeChange,
        handlePricingChange,
        handleIsTaxableChange,
        handleMiscCodeChange,
        handleSalesPersonChange,
        handleTermsCodeChange,
        handleAccountStatusChange,
        handleAccountCreditLimitChange,
        handleAccountCommentChange,
        handleAccountHighCreditDateChange,
        handleAccountHighCreditAmountChange,
        handleIsActiveChange,
        validateField,
        setFormAddressLine1,
        setFormCity,
        setFormCountry,
        setFormState,
        setFormZipCode,
    };
}

export type CustomerFormManager = ReturnType<typeof useCustomerForm>;
