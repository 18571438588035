import { History } from '@mui/icons-material';
import { Button } from '@mui/material';
import { FC, useState } from 'react';
import { InvoiceHistoryDialog } from './InvoiceHistoryDialog';

export interface IInvoiceHistoryButtonProps {
    orderReviewId?: string;
    size?: 'small' | 'medium' | 'large';
};

export const InvoiceHistoryButton: FC<IInvoiceHistoryButtonProps> = ({ orderReviewId, size }) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClick = () => setIsOpen(true);

    return (
        <>
            <Button variant='contained' onClick={handleClick} startIcon={<History />} size={size}>Invoice History</Button>
            <InvoiceHistoryDialog open={isOpen} onClose={() => setIsOpen(false)} orderReviewId={orderReviewId} />
        </>
    );
};