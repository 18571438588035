import { useCallback, useEffect } from "react";
import { useConfirmExit } from "./useConfirmExit";

export function usePrompt(message: string, when = true) {
    useEffect(() => {
        if (when) {
            window.onbeforeunload = function () {
                return message;
            };
        }

        return () => {
            window.onbeforeunload = null;
        };
    }, [message, when]);

    const confirmExit = useCallback(() => {
        const confirm = window.confirm(message);
        return confirm;
    }, [message]);
    useConfirmExit(confirmExit, when);
}