import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, OutlinedInput, Typography } from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { IConfirmDialogProps } from './types';

export const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props) => {
    const { open, onClose, content, title, titleIcon, cancelText, confirmText = 'Yes', onConfirm, requireConfirmation = true, maxWidth = 'sm', disableConfirm = false } = props;

    const [confirmationText, setConfirmationText] = useState('');

    const handleConfirm = () => {
        setConfirmationText('');
        onConfirm();
    }

    return (
        <Dialog onClose={onClose} open={open} maxWidth={maxWidth} fullWidth>
            <DialogTitle sx={{ backgroundColor: 'primary', color: '#ffffff' }}>
                <Grid container direction='row' alignItems='center' gap={1}>
                    {titleIcon}
                    {title}
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' marginTop={2}>
                    <Grid item>{content}</Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {requireConfirmation && <Grid direction={"column"} container>
                    <Grid item>
                        <Typography>Type the word "{confirmText.toLocaleUpperCase()}" below to confirm</Typography>
                    </Grid>
                    <Grid item>
                        <OutlinedInput value={confirmationText} onChange={(e) => setConfirmationText(e.target.value)}></OutlinedInput>
                    </Grid>
                </Grid>}
                <Button variant='outlined' onClick={onClose}>
                    {cancelText ?? 'Cancel'}
                </Button>
                <Button variant='contained' onClick={handleConfirm} disabled={(requireConfirmation && confirmText.toLocaleUpperCase() !== confirmationText.toLocaleUpperCase()) || disableConfirm}>
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
