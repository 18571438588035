import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { QuoteDto } from '../../dtos';
import { useCreateJobMutation } from '../../store/generated/generatedApi';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { JobQuoteListView } from './JobQuoteListView';
import { DEFAULT_JOB } from '../../Components/Forms/JobForm/useJobForm';
import { Article } from '@mui/icons-material';

export interface IAddJobViewProps {
    onClose: () => void;
    open: boolean;
}

export const AddJobView: FC<IAddJobViewProps> = props => {
    const { onClose, open } = props;
    const navigate = useNavigate();
    const [createJob, { data, isError, isSuccess, reset }] = useCreateJobMutation();
    const [selectedQuotes, setSelectedQuotes] = useState<QuoteDto[]>([]);

    useSuccessfulCreateSnackbar('Job', isSuccess, () => navigate(`/job/${data?.id}`));
    useFailedCreateSnackbar('Job', isError, reset)

    const handleConfirm = useCallback(() => {
        if (selectedQuotes.length === 1) {
            createJob({ ...DEFAULT_JOB, quotes: [{ id: selectedQuotes[0].id } as QuoteDto] });
        }
    }, [createJob, selectedQuotes]);

    return (
        <Dialog onClose={onClose} open={open} maxWidth='lg' fullWidth>
            <DialogTitle sx={{ backgroundColor: 'primary', color: '#ffffff' }}>
                <Typography variant='h2'>
                    {<Article />} Create Job
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' mt={2}>
                    <Grid item>
                        <Typography>Select an accepted quote to convert into a new job.</Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <JobQuoteListView setSelected={setSelectedQuotes} />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>
                    Cancel
                </Button>
                <Button variant='contained' onClick={handleConfirm} disabled={selectedQuotes.length !== 1}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
