import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { EquipmentTypeDto } from '../../dtos';
import { useGetEquipmentTypeQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface IEquipmentTypeSelectProps {
    selectedEquipmentTypeId: string;
    handleSelectedEquipmentTypeChange: (selectedEquipmentType?: EquipmentTypeDto) => void;
    required?: boolean;
    errorMessage?: string;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    disabled?: boolean;
}

export const EquipmentTypeSelect: FC<IEquipmentTypeSelectProps> = props => {
    const { selectedEquipmentTypeId, handleSelectedEquipmentTypeChange, required, errorMessage, onBlur, disabled } = props;
    const { data: equipmentTypes, isLoading: isLoadingEquipmentTypes } = useGetEquipmentTypeQuery({
        sortKey: 'TYPE',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false
    });
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (initialized || !equipmentTypes || equipmentTypes?.pageResults.length === 0) {
            return;
        }

        if ((selectedEquipmentTypeId === '' || selectedEquipmentTypeId === emptyGuid) && equipmentTypes.pageResults.length > 0) {
            var result = equipmentTypes?.pageResults.find(x => x.type === 'DT');
            if (result != null) {
                handleSelectedEquipmentTypeChange(result);
            }
        }
        setInitialized(true);
    }, [equipmentTypes, handleSelectedEquipmentTypeChange, initialized, selectedEquipmentTypeId]);

    const handleSelectChange = useCallback((_: SyntheticEvent<Element, Event>, value: EquipmentTypeDto | null) => {
        handleSelectedEquipmentTypeChange(value ?? undefined);
    }, [handleSelectedEquipmentTypeChange]);

    const currentEquipmentType = useMemo(() => {
        if (selectedEquipmentTypeId === '' || selectedEquipmentTypeId === emptyGuid) {
            return null;
        }
        return equipmentTypes?.pageResults.find(x => x.id === selectedEquipmentTypeId) ?? null;
    }, [equipmentTypes?.pageResults, selectedEquipmentTypeId]);

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>Equipment Type</FormLabel>
            <Autocomplete
                value={currentEquipmentType}
                onChange={handleSelectChange}
                loading={isLoadingEquipmentTypes}
                options={equipmentTypes?.pageResults ?? []}
                getOptionLabel={(option: EquipmentTypeDto) => `${option.type} - ${option.description}`}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}