import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QuoteDto } from '../../dtos';
import { LoadingIndicator, useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateQuoteMutation, useLazyGetCustomerByIdQuery } from '../../store/generated/generatedApi';
import { QuoteForm } from '../../Components/Forms/QuoteForm';

export const AddQuoteView: FC = () => {
    const { customerId } = useParams();
    const navigate = useNavigate();
    const [getCustomer, { data: customer, isLoading: isCustomerLoading }] = useLazyGetCustomerByIdQuery();
    const [createQuote, { data, isError, isSuccess, reset }] = useCreateQuoteMutation();

    useEffect(() => {
        if (customerId && !customer) {
            getCustomer({ id: customerId! });
        }
    }, [customer, customerId, getCustomer]);

    useSuccessfulCreateSnackbar('Quote', isSuccess, () => {
        if (customerId) {
            navigate(`/customer/${customerId}/quote/${data?.id}`);
        } else {
            navigate(`/quote/${data?.id}`);
        }
    });
    useFailedCreateSnackbar('quote', isError, reset);

    const handleSave = (values: QuoteDto) => {
        createQuote(values);
    };

    const handleCancel = () => {
        if (customerId) {
            navigate(`/customer/${customerId}/dashboard`);
        } else {
            navigate('/quotes');
        }
    };

    if (isCustomerLoading) {
        return <LoadingIndicator />;
    }

    return <QuoteForm save={handleSave} cancel={handleCancel} customer={customer} />;
};
