import { Inventory, Payments } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useMemo } from 'react';
import { MaterialDto } from '../../../dtos';
import { useArchiveMaterialDeleteByIdMutation } from '../../../store/generated/generatedApi';
import { UnitOfMeasureSelect } from '../../CommonInputs';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { FormInput, FormNumberInput, FormSection, IFormProps, LoadingIndicator } from '../../CoreLib/library';
import { useMaterialForm } from './useMaterialForm';
import { usePrompt } from '../../../Views';

export const MaterialForm: FC<IFormProps<MaterialDto>> = (props) => {
	const { isLoading } = props;
	const {
		isFormDirty,
		handleSave,
		handleCancel,
		fieldErrors,
		handleCodeChange,
		handleNameChange,
		handleDescriptionChange,
		handleDefaultUnitChange,
		handleIsActiveChange,
		handleRatePerLoadChange,
		handleRatePerTonChange,
		handleRatePerYardChange,
		formCode,
		formName,
		formDescription,
		formDefaultUnit,
		formIsActive,
		formRatePerLoad,
		formRatePerTon,
		formRatePerYard,
		validateField
	} = useMaterialForm(props);

	usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

	const currentMaterialId = useMemo(() => {
		return props.initValues?.id;
	}, [props.initValues?.id]);

	const navigateToMaterialPricePage = useCallback(() => {
		if (!props.initValues) {
			return;
		}
		const { id, code } = props.initValues;
		window.open(`${window.origin}/dataManagement/materialPrices?materialId=${id}&materialName=${code}`, '_blank');
	}, [props.initValues]);

	const viewMaterialPriceButton = useMemo(() => {
		return (
			<Tooltip title='View Material Prices'>
				<Box>
					<IconButton onClick={navigateToMaterialPricePage} disabled={!props.initValues}>
						<Payments />
					</IconButton>
				</Box>
			</Tooltip>
		)
	}, [navigateToMaterialPricePage, props.initValues]);

	if (isLoading) {
		return <LoadingIndicator />;
	}

	return (
		<Grid container direction='column' spacing={3}>
			<SlimFormHeader
				objectName='Material'
				permissionsTypeName={'material'}
				icon={<Inventory />}
				title={formName ?? 'New Material'}
				breadcrumbs={[
					{ label: 'Home', navLink: '/' },
					{ label: 'Data Management', navLink: '/dataManagement' },
					{ label: 'Materials', navLink: '/dataManagement/materials' },
				]}
				isActive={formIsActive}
				handleIsActiveChange={handleIsActiveChange}
				id={currentMaterialId}
				isFormDirty={isFormDirty}
				handleCancel={handleCancel}
				handleSave={handleSave}
				entityNameSingular={'material'}
				deleteMutation={useArchiveMaterialDeleteByIdMutation}
				additionalHeaderItems={viewMaterialPriceButton}
			/>
			<Grid item mx={2}>
				<FormSection>
					<Grid item container direction='row' spacing={3}>
						<Grid item xs={12} sm={6} md={3}>
							<FormInput
								value={formCode}
								onChange={handleCodeChange}
								label='Code'
								name='code'
								errorText={fieldErrors.get('code')}
								fullWidth
								required
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormInput
								value={formName}
								onChange={handleNameChange}
								label='Name'
								name='name'
								errorText={fieldErrors.get('name')}
								fullWidth
								required
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<FormInput
								value={formDescription}
								onChange={handleDescriptionChange}
								label='Description'
								name='description'
								errorText={fieldErrors.get('description')}
								fullWidth
								disabled={isLoading}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<UnitOfMeasureSelect
								selectedUnit={formDefaultUnit}
								handleSelectedUnitChange={handleDefaultUnitChange}
								errorMessage={fieldErrors.get('defaultUnitOfMeasure')}
								onBlur={() => validateField('defaultUnitOfMeasure')}
								disabled={isLoading}
								label='Default Unit of Measure'
							/>
						</Grid>
					</Grid>
					<Grid item container direction='row' spacing={3}>
						<Grid item>
							<Typography variant='h2'>Additional Hauling Rate</Typography>
						</Grid>
					</Grid>
					<Grid item container direction='row' spacing={3}>
						<Grid item xs={12} sm={6} md={3}>
							<FormNumberInput
								value={formRatePerTon}
								onChange={handleRatePerTonChange}
								label='Per Ton'
								name='perTon'
								fullWidth
								inputProps={{ min: 0 }}
							/>
						</Grid>
					</Grid>
					<Grid item container direction='row' spacing={3}>
						<Grid item xs={12} sm={6} md={3}>
							<FormNumberInput
								value={formRatePerLoad}
								onChange={handleRatePerLoadChange}
								label='Per Load'
								name='perLoad'
								fullWidth
								inputProps={{ min: 0 }}
							/>
						</Grid>
					</Grid>
					<Grid item container direction='row' spacing={3}>
						<Grid item xs={12} sm={6} md={3}>
							<FormNumberInput
								value={formRatePerYard}
								onChange={handleRatePerYardChange}
								label='Per Yard'
								name='perYard'
								fullWidth
								inputProps={{ min: 0 }}
							/>
						</Grid>
					</Grid>
				</FormSection>
			</Grid>
		</Grid>
	);
};
