import { Menu, Settings, Summarize } from '@mui/icons-material';
import { Button, Divider, Grid, IconButton, styled, Typography } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, Theme } from '@mui/material/styles';
import { FC, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import { AuthenticatedComponent, userHasPermissions, UserPermissionContext } from '../CoreLib/library';
import { INavigationDrawerProps, NavigationDrawerRouteDTO } from './types';

const openedWidth = '235px';
const closedWidth = '65px';

const openedMixin = (theme: Theme): CSSObject => ({
	width: openedWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.easeOut,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.easeIn,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: closedWidth,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
	width: openedWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

const NavigationDrawer: FC<INavigationDrawerProps> = (props) => {
	const { routes, adminRoutes, reportRoutes } = props;
	const cookies = new Cookies(document.cookie);
	const [isOpen, setIsOpen] = useState(cookies.get('drawerIsOpen') === 'false' ? false : true);
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { permissions } = useContext(UserPermissionContext);

	const manualToggleDrawer = () => {
		setDrawerOpen(!isOpen);
	};

	const setDrawerOpen = (open: boolean) => {
		setIsOpen(open);
		cookies.set('drawerIsOpen', open);
	};

	const renderNavigationButtons = (routesToRender: NavigationDrawerRouteDTO[]) => {
		const visibleRoutes = routesToRender.filter((r) => !r.isHidden);
		return visibleRoutes.map((routeToRender) => {
			return createNavButton(routeToRender);
		});
	};

	const getPermittedAdminRoutes = () => {
		if (!adminRoutes) {
			return [];
		}

		return adminRoutes.filter(adminRoute => userHasPermissions(adminRoute.requiredPermissions ?? [], permissions, adminRoute.permissionLogic));
	}

	const getPermittedReportRoutes = () => {
		if (!reportRoutes) {
			return [];
		}

		return reportRoutes.filter(reportRoute => userHasPermissions(reportRoute.requiredPermissions ?? [], permissions, reportRoute.permissionLogic));
	}

	const createNavButton = (route: NavigationDrawerRouteDTO) => {
		const NavButton =
			isOpen ? (
				<Button
					key={route.route}
					className='nav-button full-width'
					onClick={(e) => {
						e.preventDefault();
						navigate(route.route!);
					}}
					href={route.route!}
					startIcon={route.icon}
					sx={pathname === route.route ? { backgroundColor: 'rgba(255,255,255,.2)', fontWeight: 'bold' } : { fontWeight: 'normal' }}>
					{route.name}
				</Button>
			) : (
				<IconButton
					key={route.route}
					className='nav-button'
					onClick={(e) => {
						e.preventDefault();
						navigate(route.route!);
					}}
					href={route.route!}
					sx={pathname === route.route ? { backgroundColor: 'rgba(255,255,255,.2)', left: '-4px' } : { left: '-4px' }}
				>
					{route.icon}
				</IconButton>
			);

		return <AuthenticatedComponent key={route.route} children={NavButton} requiredPermissions={route.requiredPermissions ?? []} logic={route.permissionLogic} />;
	};

	return (
		<>
			<Drawer
				variant='permanent'
				anchor='left'
				open={isOpen}
			>
				<Grid container alignItems='start' sx={{ marginBottom: '20px' }}>
					<IconButton edge='start' onClick={manualToggleDrawer}>
						<Menu />
					</IconButton>
				</Grid>
				<Grid
					container
					direction='column'
					alignItems='start'
					gap='8px'
				>
					{renderNavigationButtons(routes)}
					{!(isOpen) && getPermittedReportRoutes().length > 0 && (
						<IconButton className='nav-button' sx={{ fontWeight: 'bold', left: '-4px' }}>
							<Summarize />
						</IconButton>
					)}
					{!(isOpen) && getPermittedAdminRoutes().length > 0 && (
						<IconButton className='nav-button' sx={{ fontWeight: 'bold', left: '-4px' }} onClick={manualToggleDrawer}>
							<Settings />
						</IconButton>
					)}
				</Grid>
				{(isOpen) && getPermittedReportRoutes().length > 0 && (
					<>
						<Divider sx={{ background: 'white', margin: '20px -20px 20px' }} />
						<Grid container>
							<Typography sx={{ marginBottom: '10px' }}>Reports</Typography>
							{renderNavigationButtons(getPermittedReportRoutes())}
						</Grid>
					</>
				)}
				{(isOpen) && getPermittedAdminRoutes().length > 0 && (
					<>
						<Divider sx={{ background: 'white', margin: '20px -20px 20px' }} />
						<Grid container>
							<Typography sx={{ marginBottom: '10px' }}>Admin</Typography>
							{renderNavigationButtons(getPermittedAdminRoutes())}
						</Grid>
					</>
				)}

			</Drawer>
			<Grid
				sx={
					isOpen
						? { marginLeft: openedWidth, transition: 'margin .2s', transitionTimingFunction: 'ease-out' }
						: { marginLeft: closedWidth, transition: 'margin .2s', transitionTimingFunction: 'ease-in' }
				}>
				{props.children}
			</Grid>
		</>
	);
};

export default NavigationDrawer;
