import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, IStandardListViewExtraItems, PaginatedProps, StandardListViewV2, useBulkActionSnackbar } from '../../Components/CoreLib/library';
import { OrderReviewDto } from '../../dtos';
import {
    useArchiveOrderReviewBulkDeleteFromDatabaseMutation,
    useCreateOrderReviewPdfsMutation,
    useCreateOrderReviewSendToQuickBooksMutation,
    useGetOrderReviewQuery,
} from '../../store/generated/generatedApi';
import { CircularProgress, Typography } from '@mui/material';
import { format } from 'date-fns';
import { exportToZip } from '../../util';
import { Download, Send } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

export interface IOrderReviewsListViewProps {
    date: Date;
    readyForBilling: boolean;
    sentToQuickBooks?: boolean;
    showSendToQuickbooksButton?: boolean;
}

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'ORDER_NUMBER',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const OrderReviewListView: FC<IOrderReviewsListViewProps> = (props) => {
    const {
        date,
        readyForBilling,
        sentToQuickBooks,
        showSendToQuickbooksButton = false,
    } = props;
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [downloadZip, { isLoading: isDownloadingFile }] = useCreateOrderReviewPdfsMutation();
    const [sendOrdersToQuickbooks, { data: bulkSendToQuickbooksResult, isLoading: isSendingOrdersToQuickbooks, reset: ResetSendToQuickbooks }] = useCreateOrderReviewSendToQuickBooksMutation();
    useBulkActionSnackbar('sent to QuickBooks', 'sending to QuickBooks', 'order', 'orders', bulkSendToQuickbooksResult, ResetSendToQuickbooks)

    const tableColumns: DataTableColumn<OrderReviewDto>[] = useMemo(() => [
        { key: 'job.projectName', label: 'Project Name', sortKey: 'PROJECT_NAME' },
        { key: 'job.jobName', label: 'Job Name', sortKey: 'JOB_NAME' },
        { key: 'job.customerName', label: 'Customer', sortKey: 'CUSTOMER' },
        { key: 'quote.addressLine1', label: 'Address', sortKey: 'ADDRESS' },
        { key: 'quote.city', label: 'City', sortKey: 'CITY' },
        { key: 'quote.state', label: 'State', sortKey: 'STATE' },
        { key: 'quote.zipCode', label: 'Zip', sortKey: 'ZIP_CODE' },
        { key: 'quote.quoteNumber', label: 'Quote #', sortKey: 'QUOTE_NUMBER' },
        { key: 'job.jobNumber', label: 'Job #', sortKey: 'JOB_NUMBER' },
        { key: 'poNumber', label: 'PO #', sortKey: 'PO_NUMBER' },
        { key: 'orderNumber', label: 'Order #', sortKey: 'ORDER_NUMBER' },
        { key: 'orderLineItemCount', label: 'Line Items Count', sortKey: 'LINE_ITEM_COUNT' },
        { key: 'statusName', label: 'Order Status', sortKey: 'STATUS' }
    ], []);

    const handleTableEdit = useCallback((id: string, record: OrderReviewDto) => {
        navigate(`/job/${record.jobId}/order/${record.orderId}/orderReview/${id}`);
    }, [navigate]);

    const handleDownloadZipFile = useCallback((selectedItems: OrderReviewDto[]) => {
        if (selectedItems.length > 0) {
            const selectedIds = selectedItems.map(x => x.id);
            downloadZip(selectedIds).then((response: any) => {
                if (response.data) {
                    exportToZip(response.data, `Invoices-${selectedItems[0].statusName}-${format(new Date(), 'yyyy-MM-dd-HH-mm')}.zip`.replace(/\s+/g, ''));
                }
            })
        }
    }, [downloadZip]);

    const handleSendOrdersToQuickbooks = useCallback((selectedItems: OrderReviewDto[]) => {
        let orderReviewsReadyToBeSent = selectedItems.filter(x => x.canBeSentToQuickbooks);
        if (orderReviewsReadyToBeSent.length > 0) {
            const orderReviewIds = orderReviewsReadyToBeSent.map(x => x.id);
            sendOrdersToQuickbooks(orderReviewIds);
        }
        let selectedOrdersNotReadyToBeSent = selectedItems.filter(x => !x.canBeSentToQuickbooks);
        if (selectedOrdersNotReadyToBeSent.length > 0) {
            enqueueSnackbar(`${selectedOrdersNotReadyToBeSent.length} of the selected orders were not sent to QuickBooks because they lack required information. Please review the following orders and try again: ${selectedOrdersNotReadyToBeSent.map(o => o.orderNumber).join(', ')}`, { variant: 'warning', autoHideDuration: 10000 });
        }
        
    }, [sendOrdersToQuickbooks, enqueueSnackbar]);

    const additionalActionItems: IStandardListViewExtraItems[] = useMemo(() => {
        const items: IStandardListViewExtraItems[] = [
            {
                text: 'DOWNLOAD INVOICE',
                onClick: (selectedItems: OrderReviewDto[]) => {
                    handleDownloadZipFile(selectedItems);
                },
                icon: <Download />,
                enabledCheck: (selected: OrderReviewDto[]) => selected.length > 0 && !isDownloadingFile,
                clearSelectedItems: true,
            }
        ];
        if (showSendToQuickbooksButton) {
            items.push({
                text: isSendingOrdersToQuickbooks ? 'SENDING TO QUICKBOOKS' : 'SEND TO QUICKBOOKS',
                onClick: handleSendOrdersToQuickbooks,
                icon: isSendingOrdersToQuickbooks ? <CircularProgress size={18} color='primary' /> : <Send />,
                enabledCheck: (selected: OrderReviewDto[]) => selected.length > 0 && selected.some(o => o.canBeSentToQuickbooks) && !isSendingOrdersToQuickbooks,
                clearSelectedItems: true
            });
        }
        return items;
    }
    , [showSendToQuickbooksButton, handleDownloadZipFile, isDownloadingFile, isSendingOrdersToQuickbooks, handleSendOrdersToQuickbooks]);

    return (
        <>
            <StandardListViewV2
                headerIcon={<></>}
                headerTitle=''
                breadCrumbs={[]}
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetOrderReviewQuery}
                isShowActiveToggleVisible={false}
                deleteMutation={useArchiveOrderReviewBulkDeleteFromDatabaseMutation}
                tableColumns={tableColumns}
                entityNameSingular='OrderReview'
                permissionName='review'
                handleEdit={handleTableEdit}
                additionalQueryParameters={{ sentToQuickBooks, readyForBilling, orderDate: format(date, "yyyy-MM-dd") }}
                disableDeleteForRecord={(record: OrderReviewDto) => record.sentToQuickBooks!}
                deleteConfirmationDialog={{ title: 'Delete Order Review?', content: <Typography>Are you sure you would like to permanently delete this record? It will be deleted immediately and cannot be recovered.</Typography> }}
                additionalActionItems={additionalActionItems}
            />
        </>
    );
};
