import { FC, KeyboardEventHandler, useEffect, useRef, useState } from 'react';
import {
    LoadingIndicator,
    StandardCardWithHeader,
    useFailedActionSnackbar,
    useFailedCreateSnackbar,
    useSuccessfulCreateSnackbar,
} from '../../../Components/CoreLib/library';
import { Badge, Box, Grid, IconButton, TextField } from '@mui/material';
import { Notes, Send } from '@mui/icons-material';
import { useCreateJobJobNoteMutation, useGetJobJobNoteQuery } from '../../../store/generated/generatedApi';
import { emptyGuid } from '../../../util';
import { NoteEntry } from './NoteEntry';

export interface IJobNotesCardProps {
    jobId: string;
}

export const JobNotesCard: FC<IJobNotesCardProps> = (props) => {
    const { jobId } = props;
    const [newNoteText, setNewNoteText] = useState('');
    const noteTextBoxRef = useRef<HTMLDivElement>(null);
    const {
        data: jobNotes,
        isLoading: isLoadingJobNotes,
        isError: isGetJobNotesError,
    } = useGetJobJobNoteQuery({
        jobId: jobId,
        sortKey: 'CREATED_ON',
        sortAsc: false,
        page: 0,
        pageSize: 10000,
        includeInactive: false,
    });
    useFailedActionSnackbar('retrieve', 'job notes', isGetJobNotesError);

    const [createJobNote, { isSuccess: isCreateNoteComplete, isError: isCreateNoteError, reset: resetCreateNote, isLoading: isCreatingNote }] =
        useCreateJobJobNoteMutation();
    useFailedCreateSnackbar('Job Note', isCreateNoteError, resetCreateNote);
    useSuccessfulCreateSnackbar('Job Note', isCreateNoteComplete, resetCreateNote);

    useEffect(() => {
        if (isCreateNoteComplete) {
            resetCreateNote();
            setNewNoteText('');
            noteTextBoxRef.current?.focus();
        }
    }, [isCreateNoteComplete, resetCreateNote, noteTextBoxRef]);

    const submitNote = () => {
        if (!newNoteText.trim() || isCreatingNote) {
            return;
        }
        createJobNote({
            params: { jobId },
            payload: { jobId, text: newNoteText, id: emptyGuid, isActive: true, createdOn: new Date() },
        });
    };

    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            submitNote();
        }
    };

    return (
        <StandardCardWithHeader 
            headerTitle='Notes' 
            headerIcon={
                <Badge badgeContent={jobNotes?.pageResults.length} color='secondary'>
                    <Notes/>
                </Badge>}
        >
            <Grid item container direction='column' height={400} display='flex' sx={{ flexFlow: 'column' }}>
                <Grid item container direction='row' px={2} rowSpacing={1} sx={{ overflowY: 'auto', flex: '1 1 auto' }} display='flex' alignContent='flex-start'>
                    {isLoadingJobNotes && <LoadingIndicator />}
                    {jobNotes?.pageResults.map((note) => (
                        <NoteEntry key={note.id} note={note} />
                    ))}
                </Grid>
                <Grid item display='flex' gap={1} alignItems='center' px={2} py={1} sx={{ flex: '0 1 40px' }}>
                    <TextField
                        value={newNoteText}
                        onChange={(e) => setNewNoteText(e.target.value)}
                        variant='outlined'
                        fullWidth
                        size='small'
                        disabled={isCreatingNote}
                        multiline
                        onKeyDown={handleKeyDown}
                        inputRef={noteTextBoxRef}
                    />
                    <Box>
                        <IconButton onClick={submitNote} disabled={isCreatingNote || !newNoteText.trim()} color='secondary'>
                            <Send />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </StandardCardWithHeader>
    );
};
