import { Payments, Place } from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, IconButton, Grid, Tabs, Tab, FormControl, FormLabel, Select, MenuItem, FormHelperText, Tooltip } from '@mui/material';
import { FC, useState, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ContactsListView } from '../../../Views/Contact/ContactsListView';
import { RegionDto, SiteDto } from '../../../dtos';
import { useArchiveSiteDeleteByIdMutation, useCreateRegionMutation, useGetRegionQuery } from '../../../store/generated/generatedApi';
import { usStates, countries, emptyGuid, getErrorMessage } from '../../../util';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { IFormProps, LoadingIndicator, FormSection, FormInput, useSuccessfulCreateSnackbar, useFailedCreateSnackbar, useStandardSnackbar } from '../../CoreLib/library';
import { useSiteForm } from './useSiteForm';
import { SiteDumpRatesListView } from '../../../Views/Site';
import { AddressLookup } from '../../CommonInputs';
import { IEntityAutocomplete } from '../../CommonInputs/IEntityAutocomplete';
import { usePrompt } from '../../../Views';

export const SiteForm: FC<IFormProps<SiteDto>> = (props) => {
	const { isLoading, initValues } = props;
	const {
		isFormDirty,
		handleSave,
		handleCancel,
		fieldErrors,
		handleCodeChange,
		handleNameChange,
		handleCompanyChange,
		handleAddressLine1Change,
		handleAddressLine2Change,
		handleCityChange,
		handleStateChange,
		handleZipCodeChange,
		handleCountryChange,
		handleIsActiveChange,
		handleDirectionsChange,
		handleHoursOfOperationChange,
		formCode,
		formName,
		formCompany,
		formAddressLine1,
		formAddressLine2,
		formCity,
		formState,
		formZipCode,
		formCountry,
		formIsActive,
		formDirections,
		formHoursOfOperation,
		formRegion,
		setFormAddressLine1,
		setFormCity,
		setFormCountry,
		setFormState,
		setFormZipCode,
		setFormRegion,
	} = useSiteForm(props);

	usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

	const { tab } = useParams();
	const [selectedTab, setSelectedTab] = useState(tab ?? '');

	const { data: regions, isLoading: regionsLoading } = useGetRegionQuery({ searchText: '', sortKey: 'NAME', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
	const [createRegion, { isSuccess: isCreateRegionSuccess, isError: isCreateRegionError, error: createRegionError, reset: resetRegion }] = useCreateRegionMutation();

	const isRegionUniqueError = !!createRegionError && (getErrorMessage(createRegionError) === "Region already exists");
	useFailedCreateSnackbar('region', isCreateRegionError && !isRegionUniqueError, resetRegion);
	useStandardSnackbar(isRegionUniqueError, "Region already exists", 'error', resetRegion);
	useSuccessfulCreateSnackbar('Region', isCreateRegionSuccess, () => {
		resetRegion();
	});

	const currentSiteId = useMemo(() => {
		return props.initValues?.id;
	}, [props.initValues?.id]);

	const navigateToMaterialPricePage = useCallback(() => {
		if (!props.initValues) {
			return;
		}
		const { id, code } = props.initValues;
		window.open(`${window.origin}/dataManagement/materialPrices?siteId=${id}&siteName=${code}`, '_blank');
	}, [props.initValues]);

	const viewMaterialPriceButton = useMemo(() => {
		return (
			<Tooltip title='View Material Prices'>
				<Box>
					<IconButton onClick={navigateToMaterialPricePage} disabled={!props.initValues}>
						<Payments />
					</IconButton>
				</Box>
			</Tooltip>
		)
	}, [navigateToMaterialPricePage, props.initValues]);

	const saveRegion = (formValue: string) => {
		createRegion({
			id: emptyGuid,
			name: formValue,
			isActive: true,
			createdOn: new Date()
		}).then((response: any) => {
			if (response?.data) {
				setFormRegion(response.data);
			}
		});
	};

	if (isLoading) {
		return <LoadingIndicator />;
	}

	return (
		<Grid container direction='column' spacing={3}>
			<SlimFormHeader
				objectName='Site'
				permissionsTypeName={'material'}
				icon={<Place />}
				title={formName ?? 'New Site'}
				breadcrumbs={[
					{ label: 'Home', navLink: '/' },
					{ label: 'Data Management', navLink: '/dataManagement' },
					{ label: 'Sites', navLink: '/dataManagement/sites' },
				]}
				isActive={formIsActive}
				handleIsActiveChange={handleIsActiveChange}
				id={currentSiteId}
				isFormDirty={isFormDirty}
				handleCancel={handleCancel}
				handleSave={handleSave}
				entityNameSingular={'material'}
				deleteMutation={useArchiveSiteDeleteByIdMutation}
				additionalHeaderItems={viewMaterialPriceButton}
			/>
			<Grid item mx={2}>
				<FormSection>
					<TabContext value={selectedTab}>
						<Box sx={{ borderBottom: '1px solid black' }}>
							<Tabs
								value={selectedTab}
								onChange={(e, value) => setSelectedTab(value)}
								variant='fullWidth'
								textColor='primary'
								indicatorColor='primary'
							>
								<Tab label={'Site'} key={0} value={''} />
								{initValues?.id !== undefined ? <Tab label={'CONTACTS'} key={1} value={'contacts'} /> : <></>}
								{initValues?.id !== undefined ? <Tab label={'DUMP RATES'} key={2} value={'dumpRates'} /> : <></>}
							</Tabs>
						</Box>
						<TabPanel value={''}>

							<Grid item container direction='row' spacing={3}>
								<Grid item xs={12} sm={6} md={4}>
									<FormInput
										value={formCode}
										onChange={handleCodeChange}
										label='Code'
										name='code'
										errorText={fieldErrors.get('code')}
										fullWidth
										required
										disabled={isLoading}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<FormInput
										value={formName}
										onChange={handleNameChange}
										label='Name'
										name='name'
										errorText={fieldErrors.get('name')}
										fullWidth
										required
										disabled={isLoading}
									/>
								</Grid>
								<Grid item xs={12} sm={6} md={4}>
									<FormInput
										value={formCompany}
										onChange={handleCompanyChange}
										label='Company'
										name='company'
										errorText={fieldErrors.get('company')}
										fullWidth
										disabled={isLoading}
									/>
								</Grid>
								<Grid item container direction='row' spacing={3}>
									<Grid item xs={12} sm={6} md={3}>
										<AddressLookup
											handleAddressLine1Change={setFormAddressLine1}
											handleCityChange={setFormCity}
											handleCountryChange={setFormCountry}
											handleStateChange={setFormState}
											handleZipCodeChange={setFormZipCode}
										/>
									</Grid>
								</Grid>
								<Grid item container direction='row' spacing={3}>
									<Grid item xs={12} sm={6} md={2}>
										<FormInput
											value={formAddressLine1}
											onChange={handleAddressLine1Change}
											label='Address Line 1'
											name='addressLine1'
											errorText={fieldErrors.get('addressLine1')}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<FormInput
											value={formAddressLine2}
											onChange={handleAddressLine2Change}
											label='Address Line 2'
											name='addressLine2'
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<FormInput
											value={formCity}
											onChange={handleCityChange}
											label='City'
											name='city'
											errorText={fieldErrors.get('city')}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<FormControl error={!!fieldErrors.get('state')} fullWidth required>
											<FormLabel>State</FormLabel>
											<Select value={formState} onChange={handleStateChange}>
												{usStates.map((usState) => (
													<MenuItem key={usState.abbreviation} value={usState.abbreviation}>
														{usState.name}
													</MenuItem>
												))}
											</Select>
											<FormHelperText>{fieldErrors.get('state')}</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<FormInput
											value={formZipCode}
											onChange={handleZipCodeChange}
											label='Zip Code'
											name='zipCode'
											errorText={fieldErrors.get('zipCode')}
											fullWidth
											required
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={2}>
										<FormControl error={!!fieldErrors.get('country')} fullWidth required>
											<FormLabel>Country</FormLabel>
											<Select value={formCountry} onChange={handleCountryChange}>
												{countries.map((country) => (
													<MenuItem key={country} value={country}>
														{country}
													</MenuItem>
												))}
											</Select>
											<FormHelperText>{fieldErrors.get('country')}</FormHelperText>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={6} md={12}>
										<FormInput
											value={formDirections}
											onChange={handleDirectionsChange}
											label='Directions'
											name='directions'
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<FormInput
											value={formHoursOfOperation}
											onChange={handleHoursOfOperationChange}
											label='Hours of Operation'
											name='hoursOfOperation'
											fullWidth
										/>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<FormControl fullWidth>
											<FormLabel>Region</FormLabel>
											<IEntityAutocomplete
												options={regions?.pageResults ?? []}
												onChange={(_e, value) => { setFormRegion(value); }}
												value={formRegion}
												getOptionLabel={(option: RegionDto) => option.name}
												isLoading={regionsLoading}
												quickAddProps={{
													requiredPermissions: ['create:regions'],
													objectName: 'Region',
													handleSave: saveRegion
												}}
											/>
										</FormControl>
									</Grid>
								</Grid>
							</Grid>
						</TabPanel>
						<TabPanel value={'contacts'}>
							<ContactsListView />
						</TabPanel>
						<TabPanel value={'dumpRates'}>
							<SiteDumpRatesListView siteId={currentSiteId ?? emptyGuid} />
						</TabPanel>
					</TabContext>

				</FormSection>
			</Grid>
		</Grid>
	);
};
