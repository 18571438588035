import { FC, useContext, useEffect } from 'react';
import { LoadingIndicator, UserPermissionContext } from '../Components/CoreLib/library';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useLazyGetUserAuth0UserIdByIdQuery } from '../store/generated/generatedApi';

export const HomePageNavigator: FC = () => {
    const navigate = useNavigate();
    const { permissions } = useContext(UserPermissionContext);
    const { user, isAuthenticated } = useAuth0();
    const [getUserData, { data: userData, isLoading: isLoadingUser }] = useLazyGetUserAuth0UserIdByIdQuery();

    useEffect(() => {
        if (isAuthenticated) {
            if (user?.sub) {
                getUserData({
                    id: user?.sub
                });
            }
        }
    }, [getUserData, isAuthenticated, navigate, user]);

    useEffect(() => {
        if (!isAuthenticated || permissions.length === 0) {
            return;
        }
        if (isLoadingUser || userData == null) {
            return;
        }

        const path = localStorage.getItem('redirectUrl');
        if (!!path && path !== '/') {
            navigate(path);
        } else {
            if (userData?.defaultHomepage) {
                var defaultHomepage = userData.defaultHomepage;
                if (defaultHomepage === 'Quotes') {
                    navigate('/quotes');
                } else if (defaultHomepage === 'Jobs') {
                    navigate('/jobs');
                } else if (defaultHomepage === 'Dispatch') {
                    navigate('/dispatch');
                } else if (defaultHomepage === 'Order Review') {
                    navigate('/orderReview');
                }
            } else if (permissions.includes('is:hauler')) {
                navigate('/driver');
            } else {
                navigate('/home');
            }
        }
    }, [navigate, permissions, isAuthenticated, userData, isLoadingUser]);

    return <LoadingIndicator />;
};
