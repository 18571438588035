import { FC, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateContactMutation } from '../../store/generated/generatedApi';
import { ContactFullPageForm } from '../../Components/Forms/ContactForm';
import { ContactDto } from '../../dtos/generated/ContactDto';

export const AddContactView: FC = () => {
    const navigate = useNavigate();
    const { customerId, siteId } = useParams();
    const [createContact, { isError, isSuccess, reset }] = useCreateContactMutation();

    const redirectUrl = useMemo(() => (customerId ? `/customer/${customerId}` : `/dataManagement/site/${siteId}`) + `/contacts`, [customerId, siteId]);

    useSuccessfulCreateSnackbar('Contact', isSuccess, () => navigate(redirectUrl));
    useFailedCreateSnackbar('contact', isError, reset)

    const handleSave = (values: ContactDto) => {
        createContact(values);
    };

    const handleCancel = () => {
        navigate(redirectUrl);
    };

    return <ContactFullPageForm save={handleSave} cancel={handleCancel} redirectUrl={redirectUrl} />;
};

