import { Group } from '@mui/icons-material';
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { CustomerDto } from '../../dtos';
import { useArchiveCustomerBulkDeleteMutation, useCustomerBulkArchiveMutation, useCustomerBulkUnarchiveMutation, useGetCustomerQuery } from '../../store/generated/generatedApi';
import { usePageTitleSetter } from '../../util';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'NAME',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const CustomerListView: FC = () => {
    const navigate = useNavigate();
    usePageTitleSetter('Customers');

    const tableColumns: DataTableColumn<CustomerDto>[] = [
        { key: 'name', label: 'Customer Name', sortKey: 'NAME' },
        { key: 'city', label: 'City', sortKey: 'CITY' },
        { key: 'state', label: 'State', sortKey: 'STATE' },
        { key: 'accountStatus', label: 'Account Status', sortKey: 'ACCOUNT_STATUS' },
    ];

    const handleCreateNew = useCallback(() => {
        navigate('/customer/create');
    }, [navigate]);

    const handleTableEdit = useCallback((id: string) => {
        navigate(`/customer/${id}`);
    }, [navigate]);

    const handleRowClick = useCallback((id: string) => {
        navigate(`/customer/${id}/dashboard`);
    }, [navigate]);

    return (
        <StandardListViewV2
            headerIcon={<Group />}
            headerTitle='Customers'
            breadCrumbs={[
                { label: 'Home', navLink: '/' },
            ]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetCustomerQuery}
            isShowActiveToggleVisible
            inactivateMutation={useCustomerBulkArchiveMutation}
            activateMutation={useCustomerBulkUnarchiveMutation}
            deleteMutation={useArchiveCustomerBulkDeleteMutation}
            tableColumns={tableColumns}
            entityNameSingular='Customer'
            permissionName='customer'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
            handleRowClick={handleRowClick}
            additionalMenuItems={[
                {
                    text: 'View',
                    onClick: (selected: CustomerDto[]) => handleRowClick(selected[0]?.id),
                    enabledCheck: (selected: CustomerDto[]) => selected.length === 1
                }
            ]}
        />
    );
};
