import { FormControl } from "@mui/base";
import { FormLabel, TextField } from "@mui/material";
import { FC } from "react";
import { usePlacesWidget } from 'react-google-autocomplete';

export interface IAddressLookupProps {
    handleAddressLine1Change?: (value: string) => void;
    handleCountryChange?: (value: string) => void;
    handleCityChange?: (value: string) => void;
    handleStateChange?: (value: string) => void;
    handleZipCodeChange?: (value: string) => void;
    handleAddressChange?: (address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }, isHaulTo?: boolean) => void;
    isHaulTo?: boolean;
    disabled?: boolean;
};

export const AddressLookup: FC<IAddressLookupProps> = props => {
    const {
        handleAddressLine1Change,
        handleCityChange,
        handleCountryChange,
        handleStateChange,
        handleZipCodeChange,
        handleAddressChange,
        isHaulTo,
        disabled
    } = props;

    const { ref: materialRef } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API,
        onPlaceSelected: (place) => {
            let addressLine1 = '';
            let city = '';
            let state = '';
            let country = '';
            let zipCode = '';
            const addressComponents = place?.address_components;
            if (addressComponents) {

                addressComponents.forEach(addressComponent => {
                    switch (addressComponent.types[0]) {
                        case 'street_number':
                            addressLine1 += addressComponent.long_name;
                            break;
                        case 'route':
                            addressLine1 += ` ${addressComponent.long_name}`;
                            break;
                        case 'locality':
                            city += addressComponent.long_name;
                            break;
                        case 'administrative_area_level_1':
                            state += addressComponent.short_name;
                            break;
                        case 'country':
                            country += addressComponent.long_name;
                            break;
                        case 'postal_code':
                            zipCode += addressComponent.long_name;
                            break;
                        case 'postal_code_suffix':
                            zipCode += ` - ${addressComponent.long_name}`;
                            break;
                        default:
                            break;
                    }
                });
            }
            handleAddressLine1Change?.(addressLine1);
            handleCityChange?.(city);
            handleCountryChange?.(country);
            handleStateChange?.(state);
            handleZipCodeChange?.(zipCode);
            handleAddressChange?.({ addressLine1, city, country, state, zipCode }, isHaulTo);
        },
        options: {
            componentRestrictions: { country: 'us' },
            fields: ['address_components', 'adr_address'],
            types: ['address'],
        },
    });

    return (
        <FormControl disabled={disabled}>
            <FormLabel disabled={disabled}>Address Lookup</FormLabel>
            <TextField
                fullWidth
                defaultValue={''}
                variant='outlined'
                inputRef={materialRef}
                disabled={disabled}
            />
        </FormControl>
    );
};