import { Article } from '@mui/icons-material';
import { FC, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2, UserPermissionContext } from '../../Components/CoreLib/library';
import { JobDto } from '../../dtos';
import {
    useArchiveJobBulkDeleteMutation,
    useGetJobQuery,
    useJobBulkArchiveMutation,
    useJobBulkUnarchiveMutation,
} from '../../store/generated/generatedApi';
import { AddJobView } from './AddJobView';
import { Link, Typography } from '@mui/material';
import { usePageTitleSetter } from '../../util';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'JOB_NUMBER',
    sortAsc: false,
    page: 0,
    pageSize: 25,
};

export const JobListView: FC = () => {
    const navigate = useNavigate();
    usePageTitleSetter('Jobs');
    const [showAddScreen, setShowAddScreen] = useState(false);
    const { permissions } = useContext(UserPermissionContext);

    const tableColumns: DataTableColumn<JobDto>[] = useMemo(() => [
        { key: 'jobName', label: 'Job Name', sortKey: 'JOB_NAME' },
        { key: 'projectName', label: 'Project Name', sortKey: 'PROJECT_NAME' },
        { key: 'customerName', label: 'Customer', sortKey: 'CUSTOMER' },
        { key: 'addressLine1', label: 'Address', sortKey: 'ADDRESS_LINE_1' },
        { key: 'city', label: 'City', sortKey: 'CITY' },
        { key: 'state', label: 'State', sortKey: 'STATE' },
        { key: 'zipCode', label: 'Zip', sortKey: 'ZIP' },
        {
            key: 'quoteNumber', label: 'Current Quote #', sortKey: 'QUOTE_NUMBER', fieldMapper: (row) => <Typography>
                {permissions.includes("edit:quote") ? <Link
                    href={`/quote/${row.currentQuote?.id}`}
                    variant="body2"
                    onClick={() => { navigate(`/quote/${row.currentQuote?.id}`) }}>{row.currentQuote?.quoteNumber}</Link> : <>{row.currentQuote?.quoteNumber}</>}
            </Typography>
        },
        { key: 'jobNumber', label: 'Job #', sortKey: 'JOB_NUMBER' },
        { key: 'poNumber', label: 'PO #', sortKey: 'PO_NUMBER' }
    ], [navigate, permissions]);

    const handleTableEdit = (id: string) => {
        navigate(`/job/${id}`);
    };

    return (
        <>
            <AddJobView onClose={() => { setShowAddScreen(false) }} open={showAddScreen} />
            <StandardListViewV2
                headerIcon={<Article />}
                headerTitle='Jobs'
                breadCrumbs={[
                    { label: 'Home', navLink: '/' }
                ]}
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetJobQuery}
                isShowActiveToggleVisible
                inactivateMutation={useJobBulkArchiveMutation}
                activateMutation={useJobBulkUnarchiveMutation}
                deleteMutation={useArchiveJobBulkDeleteMutation}
                tableColumns={tableColumns}
                entityNameSingular='Job'
                permissionName='job'
                handleEdit={handleTableEdit}
            />
        </>
    );
};
