import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomerDto } from '../../dtos';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetCustomerByIdQuery, useUpdateCustomerMutation } from '../../store/generated/generatedApi';
import { CustomerFullPageForm } from '../../Components/Forms/CustomerForm';

export const EditCustomerView: FC = () => {
    const { customerId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetCustomerByIdQuery({ id: customerId! });
    const [updateCustomer, { isSuccess, isError, reset }] = useUpdateCustomerMutation();

    useSuccessfulUpdateSnackbar('Customer', isSuccess, reset);
    useFailedUpdateSnackbar('customer', isError, reset);

    const handleSave = (values: CustomerDto) => {
        updateCustomer(values);
    };

    const handleCancel = () => {
        navigate('/customers');
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <CustomerFullPageForm save={handleSave} cancel={handleCancel} initValues={data} />;
};
