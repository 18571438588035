import { Cancel, Edit, Error, Restore } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Button, FormControl, FormLabel, Grid, OutlinedInput, Typography } from "@mui/material";
import Tab from '@mui/material/Tab';
import _ from "lodash";
import { FC, useCallback, useState } from "react";
import { EquipmentTypeDto, LineItemBillingRatesDto, MaterialLineItemDto, OrderLineItemDto, RegionDto } from "../../../dtos";
import { OrderByType } from "../../../dtos/generated/OrderByType";
import { useCreateBillingRatesLineItemBillingRatesMutation, useGetEquipmentTypeQuery, useGetRegionQuery } from "../../../store/generated/generatedApi";
import { LineItemTypes, emptyGuid, formatCurrency } from "../../../util";
import { EquipmentTypeSelect, HaulToFromForm, OrderBySelect, TimeIntervalPicker, TimePicker } from "../../CommonInputs";
import { PricingMethodTypeSelect } from "../../CommonInputs/PricingMethodTypeSelect";
import { SelectableList } from "../../CommonInputs/SelectableList";
import { FormInput, FormNumberInput, LoadingIndicator } from "../../CoreLib/library";
import { BillingRateFormDialog } from "../BillingRateForm/BillingRatesFormDialog";
import { getUniqueKey } from "../../../util/getUniqueKey";
export interface IOrderLineItemTableProps {
    orderLineItems: OrderLineItemDto[];
    removeLineItem: (index: number) => void;
    setOrderLineItems: (value: OrderLineItemDto[] | ((currentValues: OrderLineItemDto[]) => OrderLineItemDto[])) => void;
    isReadOnly?: boolean;
    fieldErrors?: Map<keyof OrderLineItemDto, string>[];
    handleCancelOrderLineItem: (index: number) => void;
    handleRestoreOrderLineItem: (index: number) => void;
    isFob: boolean;
};

export const OrderLineItemTable: FC<IOrderLineItemTableProps> = (props) => {
    const {
        orderLineItems,
        removeLineItem,
        setOrderLineItems,
        isReadOnly,
        fieldErrors,
        handleCancelOrderLineItem,
        handleRestoreOrderLineItem,
        isFob
    } = props;
    const [getBillingRates] = useCreateBillingRatesLineItemBillingRatesMutation();

    const { data: equipmentTypes, isLoading: equipmentTypesLoading } = useGetEquipmentTypeQuery({ searchText: '', sortKey: 'TYPE', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
    const { data: regions } = useGetRegionQuery({ searchText: '', sortKey: 'NAME', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });

    const [selectedTab, setSelectedTab] = useState('0');
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(orderLineItems.length > 0 ? 0 : undefined);
    const [isBillingRatesFormDialogOpen, setIsBillingRatesFormDialogOpen] = useState<number | undefined>(undefined);

    const calculateBillingRates = useCallback((billingRates: LineItemBillingRatesDto): Promise<LineItemBillingRatesDto> => {
        return getBillingRates(billingRates).then((response) => {
            return (response as any).data as LineItemBillingRatesDto;
        });
    }, [getBillingRates]);

    const updateRow = useCallback(async (index: number, fieldName: string, value: any) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        updatedLineItem[fieldName] = value;

        if (fieldName === 'billingRates' || fieldName === 'billingRates.quantity' || fieldName === 'billingRates.pricingMethodType' || (fieldName === 'loadOrQuantityReq' && updatedLineItem.orderBy === OrderByType.Quantity)) {
            let billingRates = { ...updatedLineItem.billingRates };
            if (fieldName === 'billingRates.quantity' || fieldName === 'loadOrQuantityReq') {
                billingRates.quantity = value;
            }
            if (fieldName === 'billingRates.pricingMethodType') {
                billingRates.pricingMethodType = value;
            }

            billingRates = { ...await calculateBillingRates(billingRates) };
            billingRates.hasBeenModified = true;

            updatedLineItem['billingRates'] = billingRates;
            if (updatedLineItem.orderBy === OrderByType.Quantity) {
                updatedLineItem.loadOrQuantityReq = updatedLineItem.billingRates.quantity;
            }
        }

        if (fieldName === 'orderBy') {
            switch (value) {
                case OrderByType.Quantity:
                case OrderByType.Loads:
                    updatedLineItem.equipmentRequired = undefined;
                    break;
                case OrderByType.Equipment:
                    updatedLineItem.loadOrQuantityReq = undefined;
                    break;
                default:
                    break;
            }
        }

        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [calculateBillingRates, orderLineItems, setOrderLineItems]);

    const handleEquipmentTypeChange = useCallback((index: number, equipmentType?: EquipmentTypeDto) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        updatedLineItem["equipmentType"] = equipmentType;
        updatedLineItem["equipmentTypeId"] = equipmentType?.id;
        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const handleAddressSwap = useCallback((index: number, haulFromAddress: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string, siteName?: string, region?: RegionDto }, haulToAddress: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string, siteName?: string, region?: RegionDto }) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        updatedLineItem["haulToAddressLine1"] = haulFromAddress.addressLine1;
        updatedLineItem["haulToAddressLine2"] = haulFromAddress.addressLine2;
        updatedLineItem["haulToCountry"] = haulFromAddress.country;
        updatedLineItem["haulToCity"] = haulFromAddress.city;
        updatedLineItem["haulToState"] = haulFromAddress.state;
        updatedLineItem["haulToZipCode"] = haulFromAddress.zipCode;
        updatedLineItem["haulFromAddressLine1"] = haulToAddress.addressLine1;
        updatedLineItem["haulFromAddressLine2"] = haulToAddress.addressLine2;
        updatedLineItem["haulFromCountry"] = haulToAddress.country;
        updatedLineItem["haulFromCity"] = haulToAddress.city;
        updatedLineItem["haulFromState"] = haulToAddress.state;
        updatedLineItem["haulFromZipCode"] = haulToAddress.zipCode;
        updatedLineItem["haulFromSiteName"] = haulToAddress.siteName;
        updatedLineItem["haulToSiteName"] = haulFromAddress.siteName;
        updatedLineItem["haulFromRegion"] = haulToAddress.region;
        updatedLineItem["haulToRegion"] = haulFromAddress.region;
        updatedLineItem["haulFromRegionId"] = !!haulToAddress.region ? haulToAddress.region?.id : undefined;
        updatedLineItem["haulToRegionId"] = !!haulFromAddress.region ? haulFromAddress.region?.id : undefined;

        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const handleHaulFromSiteLookup = useCallback((index: number, address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }, siteName?: string, region?: RegionDto) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };
        updatedLineItem["haulFromAddressLine1"] = address.addressLine1;
        updatedLineItem["haulFromAddressLine2"] = address.addressLine2;
        updatedLineItem["haulFromCountry"] = address.country;
        updatedLineItem["haulFromCity"] = address.city;
        updatedLineItem["haulFromState"] = address.state;
        updatedLineItem["haulFromZipCode"] = address.zipCode;
        updatedLineItem["haulFromSiteName"] = siteName;
        updatedLineItem["haulFromRegion"] = region;
        updatedLineItem["haulFromRegionId"] = !!region ? region?.id : undefined;
        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const handleHaulToSiteLookup = useCallback((index: number, address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }, siteName?: string, region?: RegionDto) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };
        updatedLineItem["haulToAddressLine1"] = address.addressLine1;
        updatedLineItem["haulToAddressLine2"] = address.addressLine2;
        updatedLineItem["haulToCountry"] = address.country;
        updatedLineItem["haulToCity"] = address.city;
        updatedLineItem["haulToState"] = address.state;
        updatedLineItem["haulToZipCode"] = address.zipCode;
        updatedLineItem["haulToSiteName"] = siteName;
        updatedLineItem["haulToRegion"] = region;
        updatedLineItem["haulToRegionId"] = !!region ? region?.id : undefined;
        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const handleHaulFromRegionChange = useCallback((index: number, region: RegionDto) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        updatedLineItem["haulFromRegion"] = region;
        updatedLineItem["haulFromRegionId"] = region?.id;

        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const handleHaulFromSiteNameChange = useCallback((index: number, siteName: string) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        updatedLineItem["haulFromSiteName"] = siteName;

        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const handleHaulToSiteNameChange = useCallback((index: number, siteName: string) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        updatedLineItem["haulToSiteName"] = siteName;

        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);


    const handleHaulToRegionChange = useCallback((index: number, region: RegionDto) => {
        var updatedLineItems = _.cloneDeep(orderLineItems);
        var updatedLineItem = { ...updatedLineItems[index] };

        updatedLineItem["haulToRegion"] = region;
        updatedLineItem["haulToRegionId"] = region?.id;

        updatedLineItems.splice(index, 1, updatedLineItem);

        setOrderLineItems(updatedLineItems);
    }, [orderLineItems, setOrderLineItems]);

    const handleHaulFromAddressChange = useCallback((index: number, address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }) => {
        setOrderLineItems((stateValues: OrderLineItemDto[]) => {
            var updatedOrderLineItems = _.cloneDeep(stateValues);
            var updatedOrderLineItem = { ...updatedOrderLineItems[index] };

            updatedOrderLineItem.haulFromAddressLine1 = address.addressLine1;
            updatedOrderLineItem.haulFromAddressLine2 = address.addressLine2;
            updatedOrderLineItem.haulFromCountry = address.country;
            updatedOrderLineItem.haulFromCity = address.city;
            updatedOrderLineItem.haulFromState = address.state;
            updatedOrderLineItem.haulFromZipCode = address.zipCode;
            updatedOrderLineItems[index] = updatedOrderLineItem;
            return updatedOrderLineItems;
        });
    }, [setOrderLineItems]);

    const handleHaulToAddressChange = useCallback((index: number, address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }) => {
        setOrderLineItems((stateValues: OrderLineItemDto[]) => {
            var updatedOrderLineItems = _.cloneDeep(stateValues);
            var updatedOrderLineItem = { ...updatedOrderLineItems[index] };

            updatedOrderLineItem.haulToAddressLine1 = address.addressLine1;
            updatedOrderLineItem.haulToAddressLine2 = address.addressLine2;
            updatedOrderLineItem.haulToCountry = address.country;
            updatedOrderLineItem.haulToCity = address.city;
            updatedOrderLineItem.haulToState = address.state;
            updatedOrderLineItem.haulToZipCode = address.zipCode;
            updatedOrderLineItems[index] = updatedOrderLineItem;
            return updatedOrderLineItems;
        });
    }, [setOrderLineItems]);


    const checkForGeneralErrors = useCallback((index: number) => {
        const generalTabFields: (keyof OrderLineItemDto)[] = ['poNumber', 'equipmentType', 'orderBy', 'equipmentRequired', 'loadOrQuantityReq', 'billingRates', 'memo'];

        return generalTabFields.some((field) => !!fieldErrors?.at(index)?.get(field));
    }, [fieldErrors]);

    const checkForHaulToFromErrors = useCallback((index: number) => {
        const haulToFromTabFields: (keyof OrderLineItemDto)[] = ['haulToSiteName', 'haulToRegionId', 'haulToAddressLine1', 'haulToAddressLine2', 'haulToCity', 'haulToState', 'haulToZipCode', 'haulToCountry', 'haulFromSiteName', 'haulFromRegionId', 'haulFromAddressLine1', 'haulFromAddressLine2', 'haulFromCity', 'haulFromState', 'haulFromZipCode', 'haulFromCountry'];
        return haulToFromTabFields.some((field) => !!fieldErrors?.at(index)?.get(field));
    }, [fieldErrors]);

    const checkForDispatchErrors = useCallback((index: number) => {
        const dispatchTabFields: (keyof OrderLineItemDto)[] = ['onsiteTime', 'yardTime', 'dispatchingIntervalTime', 'dispatchMemo', 'driverMemo'];
        return dispatchTabFields.some((field) => !!fieldErrors?.at(index)?.get(field));
    }, [fieldErrors]);

    const checkForLineItemErrors = useCallback((index: number) => {
        const isGeneralErrors = checkForGeneralErrors(index);
        const isDispatchErrors = checkForDispatchErrors(index);
        return isGeneralErrors || isDispatchErrors;
    }, [checkForGeneralErrors, checkForDispatchErrors]);

    const getQuantityLabel = useCallback((orderBy: OrderByType) => {
        switch (orderBy) {
            case OrderByType.Quantity:
                return 'Quantity Req.';
            case OrderByType.Loads:
                return 'Loads Req.';
            default:
                return '';
        }
    }, []);

    const isNotModifiable = useCallback((orderLineItem: OrderLineItemDto) => {
        return isReadOnly || (orderLineItem?.canceledOn !== null && orderLineItem?.canceledOn !== undefined) || !!orderLineItem.isOrderInReview;
    }, [isReadOnly]);

    const canChangePricingMethod = useCallback((orderLineItem: OrderLineItemDto) => {
        if (orderLineItem.lineItem?.type === LineItemTypes.Material) {
            if ((orderLineItem.lineItem as MaterialLineItemDto).isBilledToCustomer) {
                return false;
            }
        }
        return true;
    }, []);

    const isStandardMaterialLineItem = useCallback((orderLineItem: OrderLineItemDto) => {
        return orderLineItem.lineItem?.type === LineItemTypes.Material && !isFob;
    }, [isFob]);

    const generalDetails = useCallback((orderLineItem: OrderLineItemDto, index: number) => {
        const errors = fieldErrors?.at(index);
        const isMaterial = isStandardMaterialLineItem(orderLineItem);
        return (
            <Box>
                <Grid container direction={'column'} spacing={2}>
                    <Grid item>
                        <Box>
                            <Grid item container direction='column' spacing={1}>
                                <Grid item container direction='row' xs={12} spacing={1}>
                                    <Grid item xs={2}>
                                        <FormNumberInput
                                            value={orderLineItem.orderLineItemNumber}
                                            name='orderLineItemNumber'
                                            label='Line Item #'
                                            fullWidth
                                            disabled
                                            onChange={() => { }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormInput
                                            value={orderLineItem?.lineItem?.description}
                                            onChange={() => { }}
                                            label='Description'
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormInput
                                            value={orderLineItem.poNumber}
                                            label='PO Number'
                                            fullWidth
                                            disabled={isNotModifiable(orderLineItem)}
                                            onChange={(event) => {
                                                updateRow(index, 'poNumber', event.target.value);
                                            }}
                                            errorText={errors?.get('poNumber')}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <EquipmentTypeSelect
                                            selectedEquipmentTypeId={orderLineItem?.equipmentType?.id ?? ''}
                                            handleSelectedEquipmentTypeChange={(value) => {
                                                handleEquipmentTypeChange(index, value ?? undefined);
                                            }}
                                            disabled={isNotModifiable(orderLineItem) || equipmentTypes === undefined}
                                            errorMessage={errors?.get('equipmentType')}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormInput
                                            value={`${orderLineItem?.lineItem?.site?.name ?? 'N/A'}${orderLineItem?.lineItem?.site?.region ? ' - ' + orderLineItem?.lineItem?.site?.region?.name : ''}`}
                                            onChange={() => { }}
                                            label='Site'
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormInput
                                            value={orderLineItem?.lineItem?.zone}
                                            onChange={() => { }}
                                            label='Zone'
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container direction='row' xs={12} spacing={1}>
                                    {isMaterial && <Grid item xs={2}>
                                        <PricingMethodTypeSelect
                                            selectedPricingMethodType={orderLineItem.billingRates.pricingMethodType!}
                                            onChange={(value) => {
                                                updateRow(index, 'billingRates.pricingMethodType', value);
                                            }}
                                            disabled={isNotModifiable(orderLineItem) || !canChangePricingMethod(orderLineItem)}
                                        />
                                    </Grid>}
                                    <Grid item xs={isMaterial ? 2 : 3}>
                                        <FormNumberInput
                                            label='Quantity'
                                            name='quantity'
                                            value={orderLineItem.billingRates.quantity}
                                            onChange={(e) => updateRow(index, 'billingRates.quantity', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)}
                                            fullWidth
                                            inputProps={{ min: 0 }}
                                            disabled={isNotModifiable(orderLineItem)}
                                        />
                                    </Grid>
                                    <Grid item xs={isMaterial ? 2 : 3}>
                                        <FormInput
                                            value={orderLineItem.billingRates.unitOfMeasure}
                                            onChange={() => { }}
                                            label='Units'
                                            fullWidth
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <>
                                            <FormControl fullWidth disabled>
                                                <FormLabel>{'Rate ' + (orderLineItem.billingRates?.flatRateApplied && isMaterial ? '(+ Flat)' : '')}</FormLabel>
                                                <OutlinedInput
                                                    value={formatCurrency(orderLineItem.billingRates?.quoteRate)}
                                                    disabled
                                                    inputProps={{ min: 0 }}
                                                    endAdornment={<Edit color='secondary' sx={{ cursor: 'pointer' }} onClick={() => {
                                                        setIsBillingRatesFormDialogOpen(index);
                                                    }} />}
                                                />
                                            </FormControl>
                                            <BillingRateFormDialog
                                                open={isBillingRatesFormDialogOpen === index}
                                                onClose={() => setIsBillingRatesFormDialogOpen(undefined)}
                                                onConfirm={(formValues) => {
                                                    updateRow(index, 'billingRates', formValues);
                                                }}
                                                billingRates={orderLineItem.billingRates}
                                                lineItem={orderLineItem}
                                                isFob={isFob}
                                                isOrder
                                                disabled={isNotModifiable(orderLineItem)}
                                            />
                                        </>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl fullWidth disabled>
                                            <FormLabel>Amount</FormLabel>
                                            <OutlinedInput
                                                value={formatCurrency(orderLineItem.billingRates?.salesPrice)}
                                                disabled
                                                inputProps={{ min: 0 }}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormInput
                                        value={orderLineItem.memo}
                                        onChange={(event) => {
                                            updateRow(index, 'memo', event.target.value);
                                        }}
                                        label='Internal Memo'
                                        fullWidth
                                        disabled={isNotModifiable(orderLineItem)}
                                        errorText={errors?.get('memo')}
                                    />
                                </Grid>
                            </Grid>
                            {orderLineItem.canceledOn && (
                                <Grid item container xs={12} spacing={1} pt={1}>
                                    <Grid item xs={8}>
                                        <FormInput
                                            value={orderLineItem.cancellationReason}
                                            name='cancellationReason'
                                            label='Cancellation Reason'
                                            fullWidth
                                            disabled
                                            onChange={() => { }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormInput
                                            value={orderLineItem.canceledByUser}
                                            name='canceledByUser'
                                            label='Canceled By'
                                            fullWidth
                                            disabled
                                            onChange={() => { }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Box>
                    </Grid>
                    <Grid item container xs={12} alignSelf={'center'} justifyContent='flex-end'>
                        <Grid item>
                            <Button
                                size='small'
                                color='primary'
                                variant='contained'
                                sx={{ marginTop: '12px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    if (orderLineItem.canceledOn) {
                                        handleRestoreOrderLineItem(index);
                                    } else {
                                        handleCancelOrderLineItem(index);
                                    }
                                }}
                                endIcon={orderLineItem.canceledOn ? <Restore /> : <Cancel />}
                                disabled={isReadOnly || orderLineItem?.id === emptyGuid || (!(!!orderLineItem.canceledOn) && !orderLineItem.isCancelable)}>
                                {orderLineItem.canceledOn ? 'Restore' : 'Cancel'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }, [fieldErrors, isStandardMaterialLineItem, isNotModifiable, equipmentTypes, canChangePricingMethod, isBillingRatesFormDialogOpen, isFob, isReadOnly, updateRow, handleEquipmentTypeChange, handleRestoreOrderLineItem, handleCancelOrderLineItem]);

    const haulToFromDetails = useCallback((orderLineItem: OrderLineItemDto, index: number) => {
        return (
            <HaulToFromForm
                disabled={isNotModifiable(orderLineItem)}
                haulToAddressLine1={orderLineItem.haulToAddressLine1 ?? ""}
                haulToAddressLine2={orderLineItem.haulToAddressLine2 ?? ""}
                haulToCountry={orderLineItem.haulToCountry ?? ""}
                haulToCity={orderLineItem.haulToCity ?? ""}
                haulToState={orderLineItem.haulToState ?? ""}
                haulToZipCode={orderLineItem.haulToZipCode ?? ""}
                haulFromAddressLine1={orderLineItem.haulFromAddressLine1 ?? ""}
                haulFromAddressLine2={orderLineItem.haulFromAddressLine2 ?? ""}
                haulFromCountry={orderLineItem.haulFromCountry ?? ""}
                haulFromCity={orderLineItem.haulFromCity ?? ""}
                haulFromState={orderLineItem.haulFromState ?? ""}
                haulFromZipCode={orderLineItem.haulFromZipCode ?? ""}
                enableLookup
                regions={regions?.pageResults ?? []}
                haulFromRegion={orderLineItem.haulFromRegion}
                haulToRegion={orderLineItem.haulToRegion}
                haulToSiteName={orderLineItem.haulToSiteName}
                haulFromSiteName={orderLineItem.haulFromSiteName}
                handleHaulFromSiteNameChange={(value) => {
                    handleHaulFromSiteNameChange(index, value);
                }}
                handleHaulToSiteNameChange={(value) => {
                    handleHaulToSiteNameChange(index, value);
                }}
                handleHaulToRegionChange={(value) => {
                    handleHaulToRegionChange(index, value);
                }}
                handleHaulFromRegionChange={(value) => {
                    handleHaulFromRegionChange(index, value);
                }}
                handleHaulFromAddressChange={(address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }) => {
                    handleHaulFromAddressChange?.(index, address);
                }}
                handleHaulToAddressChange={(address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }) => {
                    handleHaulToAddressChange?.(index, address);
                }}
                handleSwap={(address1: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string, siteName?: string, region?: RegionDto }, address2: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string, siteName?: string, region?: RegionDto }) => {
                    handleAddressSwap(index, address1, address2);
                }}
                handleHaulFromSiteLookup={(address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }, siteName?: string, region?: RegionDto) => {
                    handleHaulFromSiteLookup(index, address, siteName, region);
                }}
                handleHaulToSiteLookup={(address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }, siteName?: string, region?: RegionDto) => {
                    handleHaulToSiteLookup(index, address, siteName, region);
                }}
            />
        );
    }, [regions?.pageResults, handleAddressSwap, handleHaulFromAddressChange, handleHaulFromRegionChange, handleHaulFromSiteLookup, handleHaulFromSiteNameChange, handleHaulToAddressChange, handleHaulToRegionChange, handleHaulToSiteLookup, isNotModifiable, handleHaulToSiteNameChange]);

    const dispatchDetails = useCallback((orderLineItem: OrderLineItemDto, index: number) => {
        const errors = fieldErrors?.at(index);

        return (
            <Box key={getUniqueKey(orderLineItem)}>
                <Grid container direction='column' spacing={2}>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={3}>
                            <TimePicker
                                value={orderLineItem.onsiteTime}
                                onChange={(date) => {
                                    if (date) {
                                        updateRow(index, 'onsiteTime', date);
                                    }
                                }}
                                label={`Onsite Time`}
                                disabled={isNotModifiable(orderLineItem)}
                                error={!!errors?.get('onsiteTime')}
                                errorText={errors?.get('onsiteTime')}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TimePicker
                                value={orderLineItem.yardTime}
                                onChange={(date) => {
                                    if (date) {
                                        updateRow(index, 'yardTime', date);
                                    }
                                }}
                                label={`Yard Time`}
                                disabled={isNotModifiable(orderLineItem)}
                                error={!!errors?.get('yardTime')}
                                errorText={errors?.get('yardTime')}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TimeIntervalPicker
                                value={orderLineItem.dispatchingIntervalTime}
                                onChange={(interval) => {
                                    updateRow(index, 'dispatchingIntervalTime', interval);
                                }}
                                label={`Interval Time`}
                                disabled={isNotModifiable(orderLineItem)}
                                error={!!errors?.get('dispatchingIntervalTime')}
                                errorText={errors?.get('dispatchingIntervalTime')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={2}>
                        <Grid item xs={3}>
                            <OrderBySelect
                                selectedOrderBy={orderLineItem.orderBy!}
                                onChange={(value) => {
                                    updateRow(index, 'orderBy', value);
                                }}
                                disabled={isNotModifiable(orderLineItem)}
                                errorMessage={errors?.get('orderBy')}
                            />
                        </Grid>
                        {orderLineItem.orderBy !== OrderByType.Equipment && (
                            <Grid item xs={3}>
                                <FormNumberInput
                                    value={orderLineItem.orderBy === OrderByType.Quantity ? orderLineItem.billingRates.quantity : orderLineItem.loadOrQuantityReq}
                                    name='loadOrQuantityReq'
                                    onChange={(event) => {
                                        updateRow(index, 'loadOrQuantityReq', isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
                                    }}
                                    label={getQuantityLabel(orderLineItem.orderBy!)}
                                    fullWidth
                                    inputProps={{ min: 0 }}
                                    error={!!errors?.get('loadOrQuantityReq')}
                                    errorText={errors?.get('loadOrQuantityReq')}
                                    disabled={isNotModifiable(orderLineItem)}
                                />
                            </Grid>
                        )}
                        {!orderLineItem.orderBy && (
                            <Grid item xs={3}>
                                <FormNumberInput
                                    value={orderLineItem.equipmentRequired}
                                    name='equipmentRequired'
                                    onChange={(event) => {
                                        updateRow(
                                            index,
                                            'equipmentRequired',
                                            isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber
                                        );
                                    }}
                                    label='Equipment Req.'
                                    fullWidth
                                    inputProps={{ min: 0 }}
                                    error={!!errors?.get('equipmentRequired')}
                                    errorText={errors?.get('equipmentRequired')}
                                    disabled={isNotModifiable(orderLineItem)}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            value={orderLineItem.dispatchMemo}
                            onChange={(event) => {
                                updateRow(index, 'dispatchMemo', event.target.value);
                            }}
                            label='Dispatch Memo'
                            fullWidth
                            disabled={isNotModifiable(orderLineItem)}
                            errorText={errors?.get('dispatchMemo')}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput
                            value={orderLineItem.driverMemo}
                            onChange={(event) => {
                                updateRow(index, 'driverMemo', event.target.value);
                            }}
                            label='Driver Memo'
                            fullWidth
                            disabled={isNotModifiable(orderLineItem)}
                            errorText={errors?.get('driverMemo')}
                        />
                    </Grid>
                </Grid>
            </Box>
        );
    }, [fieldErrors, getQuantityLabel, isNotModifiable, updateRow]);

    const details = useCallback((orderLineItem?: OrderLineItemDto, index?: number) => {
        const isGeneralErrors = index !== undefined && checkForGeneralErrors(index);
        const isHaulToFromErrors = index !== undefined && checkForHaulToFromErrors(index);
        const isDispatchErrors = index !== undefined && checkForDispatchErrors(index);

        return (
            <Box key={getUniqueKey(orderLineItem!)} sx={{ height: '100%', width: '100%', borderBottom: 1, borderRight: 1, borderColor: 'divider', display: 'flex', flexDirection: 'column' }}>
                <TabContext value={orderLineItem ? selectedTab : ''}>
                    <Box sx={{ width: '100%', borderTop: 1, borderBottom: 1, borderColor: 'divider' }}>
                        <TabList variant='fullWidth' sx={{ width: '100%' }} onChange={(_e, newValue) => setSelectedTab(newValue)}>
                            <Tab label='General' value='0' disabled={!orderLineItem} iconPosition='end' icon={isGeneralErrors ? <Error color='error' /> : undefined} sx={{ minHeight: '48px' }} />
                            <Tab label='Haul To/From' value='1' disabled={!orderLineItem} iconPosition='end' icon={isHaulToFromErrors ? <Error color='error' /> : undefined} sx={{ minHeight: '48px' }} />
                            <Tab label='Dispatch' value='2' disabled={!orderLineItem} iconPosition='end' icon={isDispatchErrors ? <Error color='error' /> : undefined} sx={{ minHeight: '48px' }} />
                        </TabList>
                    </Box>
                    {!orderLineItem && <Box textAlign='center'><Typography mt={2} color='GrayText'>No Item Selected</Typography></Box>}
                    <TabPanel value='0' sx={{ overflowY: 'auto' }}>
                        {orderLineItem && index !== undefined && generalDetails(orderLineItem, index)}
                    </TabPanel>
                    <TabPanel value='1' sx={{ overflowY: 'auto' }}>
                        {orderLineItem && index !== undefined && haulToFromDetails(orderLineItem, index)}
                    </TabPanel>
                    <TabPanel value='2' sx={{ overflowY: 'auto' }}>
                        {orderLineItem && index !== undefined && dispatchDetails(orderLineItem, index)}
                    </TabPanel>
                </TabContext>
            </Box>
        );
    }, [dispatchDetails, generalDetails, selectedTab, checkForDispatchErrors, checkForGeneralErrors, checkForHaulToFromErrors, haulToFromDetails]);

    if (equipmentTypesLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', height: '100%' }}>
            <SelectableList
                items={orderLineItems}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                hasError={checkForLineItemErrors}
                getKey={(item) => item.id}
                getDesc={(item) => `${item.orderLineItemNumber} ${item.lineItem?.description}`}
                style={{ border: 1, borderColor: 'divider', minWidth: 240, height: '100%', bgcolor: 'background.paper', overflowY: 'auto' }}
                removeItem={(item, index) => {
                    removeLineItem(index);
                }}
                disableRemove={(item) => isNotModifiable(item) || !!item.isDispatched}
            />
            {details(selectedIndex !== undefined ? orderLineItems[selectedIndex] : undefined, selectedIndex)}
        </Box>
    );
};