import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import {
    FormValidationMethod,
    IFormFieldValidationConfig,
    IFormProps,
    isNotBlank,
    isShorterThanMaxLength,
    isValidEmail,
    runFieldValidation,
    runFormValidation,
} from '../../CoreLib/library';
import { BrokerDto, DriverDto, UserDto, UserRoleDto } from '../../../dtos';
import { emptyGuid } from '../../../util';
import { DEFAULT_DRIVER } from '../DriverForm/useDriverForm';

export interface IUserFormValues {
    firstName: string;
    lastName: string;
    email: string;
    roles: UserRoleDto[];
    phoneNumber: string;
    isActive: boolean;
    title: string;
    brokerId?: string;
    driver?: DriverDto;
}

const DEFAULT_USER: UserDto = {
    id: emptyGuid,
    auth0UserId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    roles: [],
    isActive: true,
    createdOn: new Date(),
    title: '',
    clientId: emptyGuid,
    displayName: '',
    displayRoles: '',
    defaultHomepage: '',
};

export function useUserForm(props: IFormProps<UserDto>) {
    const { save, cancel, initValues } = props;
    const [formFirstName, setFormFirstName] = useState(initValues?.firstName ?? '');
    const [formLastName, setFormLastName] = useState(initValues?.lastName ?? '');
    const [formEmail, setFormEmail] = useState(initValues?.email ?? '');
    const [formPhoneNumber, setFormPhoneNumber] = useState(initValues?.phoneNumber ?? '');
    const [formRoles, setFormRoles] = useState<UserRoleDto[]>(initValues?.roles ?? []);
    const [formIsActive, setFormIsActive] = useState(initValues?.isActive ?? true);
    const [formTitle, setFormTitle] = useState(initValues?.title ?? '');
    const [formBrokerId, setFormBrokerId] = useState(initValues?.brokerId ?? '');
    const [haulerType, setHaulerType] = useState(
        initValues?.roles?.find((x) => x.name === 'Hauler') ? (initValues?.brokerId ? 'broker' : initValues?.driver ? 'driver' : '') : undefined
    );
    const [formDriver, setFormDriver] = useState(initValues?.driver);
    const [formDriverValid, setFormDriverValid] = useState<boolean>();
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setFormFirstName(initValues?.firstName ?? '');
        setFormLastName(initValues?.lastName ?? '');
        setFormEmail(initValues?.email ?? '');
        setFormPhoneNumber(initValues?.phoneNumber ?? '');
        setFormRoles(initValues?.roles ?? []);
        setFormIsActive(initValues?.isActive ?? true);
        setFormBrokerId(initValues?.brokerId ?? '');
        setFormDriver(initValues?.driver);
        setFormTitle(initValues?.title ?? '');
        setHaulerType(initValues?.roles?.find((x) => x.name === 'Hauler') ? (initValues?.brokerId ? 'broker' : initValues?.driver ? 'driver' : '') : undefined);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof UserDto, string>>(
        new Map([
            ['firstName', ''],
            ['lastName', ''],
            ['email', ''],
            ['phoneNumber', ''],
            ['brokerId', ''],
            ['driver', ''],
            ['title', ''],
        ])
    );

    function driverValid(): FormValidationMethod {
        return (value: UserDto) => {
            let isValid = true;

            if (formDriverValid === false) {
                isValid = false;
            }

            const errorMessageBuilder = (fieldName: string) => (isValid ? '' : 'Driver data is invalid.');
            return {
                isValid,
                errorMessageBuilder,
            };
        };
    }

    const [formFieldValidators] = useState(
        new Map<keyof UserDto, IFormFieldValidationConfig>([
            [
                'firstName',
                {
                    validators: [isNotBlank, isShorterThanMaxLength(30)],
                    errorMessageEntityName: 'First Name',
                },
            ],
            [
                'lastName',
                {
                    validators: [isNotBlank, isShorterThanMaxLength(30)],
                    errorMessageEntityName: 'Last Name',
                },
            ],
            [
                'email',
                {
                    validators: [isNotBlank, isShorterThanMaxLength(50), isValidEmail],
                    errorMessageEntityName: 'Email',
                },
            ],
            [
                'phoneNumber',
                {
                    validators: [isShorterThanMaxLength(20)],
                    errorMessageEntityName: 'Phone Number',
                },
            ],
            [
                'brokerId',
                {
                    validators: haulerType === 'broker' ? [isNotBlank] : [],
                    errorMessageEntityName: 'Broker',
                },
            ],
            [
                'driver',
                {
                    validators: haulerType === 'driver' ? [driverValid()] : [],
                    errorMessageEntityName: 'Driver',
                },
            ],
        ])
    );

    const getCurrentFormValues = useCallback((): IUserFormValues => {
        return {
            firstName: formFirstName,
            lastName: formLastName,
            email: formEmail,
            phoneNumber: formPhoneNumber,
            roles: formRoles,
            isActive: formIsActive,
            brokerId: haulerType === 'broker' ? formBrokerId : undefined,
            title: formTitle,
            driver:
                haulerType === 'driver'
                    ? { ...(formDriver ?? DEFAULT_DRIVER), isActive: true, userId: initValues?.id ?? emptyGuid }
                    : formDriver
                    ? { ...formDriver, isActive: false, userId: initValues?.id ?? emptyGuid }
                    : undefined,
        };
    }, [formBrokerId, formDriver, formEmail, formFirstName, formIsActive, formLastName, formPhoneNumber, formRoles, formTitle, haulerType, initValues?.id]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<UserDto>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IUserFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    const isFormDirty = useCallback(() => {
        return isChanged;
    }, [isChanged]);

    const handleFirstNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormFirstName(event.target.value);
        setIsChanged(true);
    }, []);

    const handleLastNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormLastName(event.target.value);
        setIsChanged(true);
    }, []);

    const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormEmail(event.target.value.trim());
        setIsChanged(true);
    }, []);

    const handlePhoneNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormPhoneNumber(event.target.value);
        setIsChanged(true);
    }, []);

    const handleAssignedRolesChange = useCallback(
        (updatedRoles: UserRoleDto[]) => {
            if (!updatedRoles.find((x) => x.name === 'Hauler')) {
                setHaulerType(undefined);
            } else if (haulerType === undefined) {
                setHaulerType('');
            }
            setFormRoles(updatedRoles);
            setIsChanged(true);
        },
        [haulerType]
    );

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    }, []);

    const handleTitleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormTitle(event.target.value);
        setIsChanged(true);
    }, []);

    const handleBrokerChange = useCallback((selectedBroker?: BrokerDto) => {
        setFormBrokerId(selectedBroker?.id ?? '');
        setIsChanged(true);
    }, []);

    const handleFormHaulerTypeChange = useCallback((type?: string) => {
        setHaulerType(type);
        setIsChanged(true);
    }, []);

    const handleDriverChange = useCallback((driver?: DriverDto) => {
        setFormDriver(driver);
    }, []);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_USER),
                ...formValues,
            };
            save(updatedUserRecord);
            setIsChanged(false);
        }
    }, [getCurrentFormValues, initValues, save, validateForm]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleFirstNameChange,
        handleLastNameChange,
        handleEmailChange,
        handlePhoneNumberChange,
        handleAssignedRolesChange,
        handleIsActiveChange,
        handleBrokerChange,
        handleTitleChange,
        handleFormHaulerTypeChange,
        formFirstName,
        formLastName,
        formEmail,
        formPhoneNumber,
        formRoles,
        formIsActive,
        formBrokerId,
        formTitle,
        formDriver,
        haulerType,
        initValues,
        setHaulerType,
        handleDriverChange,
        validateField,
        setFormDriverValid,
    };
}
