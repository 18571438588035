import Papa from 'papaparse';

export const downloadFile = (blob: Blob, fileName: string) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const downloadFileByFileLink = (downloadLink: string, fileName: string) => {
    const a = document.createElement('a');
    a.download = fileName;
    a.href = downloadLink;
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const exportToPdf = (data: string, fileName: string) => {
    // Convert array buffer to Base64
    const binary_string = window.atob(data);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }

    // Convert Base64 string to dataURL
    const file = new Blob([bytes], {
        type: 'application/pdf',
    });

    downloadFile(file, fileName);
};

export const exportToZip = (data: string, fileName: string) => {
    // Convert array buffer to Base64
    const binary_string = window.atob(data);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }

    // Convert Base64 string to dataURL
    const file = new Blob([bytes], {
        type: 'application/zip',
    });

    downloadFile(file, fileName);
};

export const exportToCsv = (data: any, fields: string[], fileName: string) => {
    let csv = Papa.unparse<unknown>(data, {
        header: false,
    });
    var csvWithHeader = fields.toString().concat('\n').concat(csv);
    const blob = new Blob([[csvWithHeader].join('\n')], { type: 'text/csv' });

    downloadFile(blob, fileName);
};
