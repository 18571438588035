import _ from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { MaterialDto } from '../../../dtos';
import { emptyGuid, unitsOfMeasure } from '../../../util';
import { IFormFieldValidationConfig, IFormProps, isNotBlank, isShorterThanMaxLength, runFieldValidation, runFormValidation } from '../../CoreLib/library';

export type UnitOfMeasure = 'Tons' | 'Loads' | 'Yards';

const isValidUnitOfMeasure = (value: string): value is UnitOfMeasure => {
    return unitsOfMeasure.includes(value);
};
export interface IMaterialFormValues {
    code: string;
    name: string;
    description: string;
    defaultUnitOfMeasure: UnitOfMeasure;
    isActive: boolean;
    ratePerLoad?: number;
    ratePerTon?: number;
    ratePerYard?: number;
}

const DEFAULT_MATERIAL: MaterialDto = {
    id: emptyGuid,
    code: '',
    name: '',
    description: '',
    defaultUnitOfMeasure: 'Tons',
    isActive: true,
    createdOn: new Date(),
};

export function useMaterialForm(props: IFormProps<MaterialDto>) {
    const { save, cancel, initValues } = props;
    const [formCode, setFormCode] = useState('');
    const [formName, setFormName] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [formDefaultUnit, setFormDefaultUnit] = useState<UnitOfMeasure>('Tons');
    const [formIsActive, setFormIsActive] = useState(true);
    const [formRatePerLoad, setFormRatePerLoad] = useState<number>();
    const [formRatePerTon, setFormRatePerTon] = useState<number>();
    const [formRatePerYard, setFormRatePerYard] = useState<number>();
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setFormCode(initValues?.code ?? '');
        setFormName(initValues?.name ?? '');
        setFormDescription(initValues?.description ?? '');
        setFormDefaultUnit((initValues?.defaultUnitOfMeasure as UnitOfMeasure) ?? 'Tons');
        setFormIsActive(initValues?.isActive ?? true);
        setFormRatePerLoad(initValues?.ratePerLoad);
        setFormRatePerTon(initValues?.ratePerTon);
        setFormRatePerYard(initValues?.ratePerYard);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IMaterialFormValues, string>>(
        new Map([
            ['code', ''],
            ['name', ''],
            ['description', ''],
            ['defaultUnitOfMeasure', ''],
        ])
    );

    const formFieldValidators = new Map<keyof IMaterialFormValues, IFormFieldValidationConfig>([
        [
            'code',
            {
                validators: [isNotBlank, isShorterThanMaxLength(250)],
                errorMessageEntityName: 'Code',
            },
        ],
        [
            'name',
            {
                validators: [isNotBlank, isShorterThanMaxLength(250)],
                errorMessageEntityName: 'Name',
            },
        ],
        [
            'description',
            {
                validators: [isShorterThanMaxLength(1000)],
                errorMessageEntityName: 'Description',
            },
        ],
        [
            'defaultUnitOfMeasure',
            {
                validators: [isNotBlank, isShorterThanMaxLength(20)],
                errorMessageEntityName: 'Default Unit Of Measure',
            },
        ],
    ]);

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IMaterialFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    const getCurrentFormValues = (): IMaterialFormValues => {
        return {
            code: formCode,
            name: formName,
            description: formDescription,
            defaultUnitOfMeasure: formDefaultUnit,
            isActive: formIsActive,
            ratePerLoad: formRatePerLoad,
            ratePerTon: formRatePerTon,
            ratePerYard: formRatePerYard,
        };
    };

    const validateField = (fieldName: keyof IMaterialFormValues) => {
        const validationConfig = formFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = getCurrentFormValues();
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    };

    const isFormDirty = () => {
        return isChanged;
    };

    const handleCodeChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormCode(event.target.value.replaceAll(' ', ''));
        setIsChanged(true);
    };

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormName(event.target.value);
        setIsChanged(true);
    };

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormDescription(event.target.value);
        setIsChanged(true);
    };

    const handleDefaultUnitChange = (value?: string) => {
        if (isValidUnitOfMeasure(value ?? '')) {
            setFormDefaultUnit(value as UnitOfMeasure);
        } else {
            console.error('Attempted to set default form type to invalid value.');
        }
        setIsChanged(true);
    };

    const handleRatePerLoadChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormRatePerLoad(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    };

    const handleRatePerTonChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormRatePerTon(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    };

    const handleRatePerYardChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormRatePerYard(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    };

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_MATERIAL),
                ...formValues,
            };
            save(updatedUserRecord);
            setIsChanged(false);
        }
    };

    const handleCancel = () => {
        cancel();
        setIsChanged(false);
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleCodeChange,
        handleNameChange,
        handleDescriptionChange,
        handleDefaultUnitChange,
        handleIsActiveChange,
        handleRatePerLoadChange,
        handleRatePerTonChange,
        handleRatePerYardChange,
        formCode,
        formName,
        formDescription,
        formDefaultUnit,
        formIsActive,
        formRatePerLoad,
        formRatePerTon,
        formRatePerYard,
        validateField,
    };
}
