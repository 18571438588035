import { FC, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetCustomerByIdQuery } from '../../../store/generated/generatedApi';
import { ApiError, LoadingIndicator, SlimPageHeader } from '../../../Components/CoreLib/library';
import { Box, Divider, Grid, IconButton, Paper, Switch, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { formatCurrency } from '../../../util';
import { Edit, Group } from '@mui/icons-material';
import { QuoteListView } from '../../Quote';
import { ContactsListView } from '../../Contact/ContactsListView';


export const CustomerDashboard: FC = () => {
    const navigate = useNavigate();

    const { customerId } = useParams();
    const [selectedTab, setSelectedTab] = useState('quotes');
    const { data: customer, isLoading, error, refetch } = useGetCustomerByIdQuery({ id: customerId! });

    const handleEdit = useCallback(() => {
        navigate(`/customer/${customerId}`);
    }, [customerId, navigate]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return (
        <>
            <SlimPageHeader
                icon={<Group />}
                title={customer?.name ?? ''}
                breadCrumbProps={{
                    links:
                        [
                            { label: 'Home', navLink: '/' },
                            { label: 'Customers', navLink: '/customers' },
                        ],
                    currentPageLabel: `${customer?.name}`
                }}
            />
            <Grid container direction='row' spacing={3} p={3}>
                <Grid item xs={12} md={12} lg={3}>
                    <Paper elevation={0} variant='outlined' square sx={{ minHeight: 'calc(100vh - 72px)' }}>
                        <Grid item container direction='column' spacing={1} p={2}>
                            <Grid item container justifyContent='space-between'>
                                <Grid item>
                                    <Typography variant='h1'>{customer?.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Tooltip title='Edit'>
                                        <IconButton size='medium' onClick={handleEdit}>
                                            <Edit fontSize='inherit' />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography>{customer?.addressLine1}</Typography>
                            </Grid>
                            {customer?.addressLine2 && <Grid item>
                                <Typography>{customer?.addressLine2}</Typography>
                            </Grid>}
                            <Grid item>
                                <Typography>{customer?.city}, {customer?.state} {customer?.zipCode}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>{customer?.country}</Typography>
                            </Grid>
                            <Grid item>
                                <Divider />
                            </Grid>
                            <Grid item>
                                <Typography>Salesperson: {customer?.salesPerson?.displayName}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Invoice Type: {customer?.bbInvoiceType}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Pricing: {customer?.pricing}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Terms Code: {customer?.termsCode?.code}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Is Taxable: {customer?.isTaxable ? 'Yes' : 'No'}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Misc Code: {customer?.miscCode}</Typography>
                            </Grid>
                            <Grid item mt={2}>
                                <Typography variant='h6'>Account Information</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Account Status: {customer?.accountStatus}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Credit Limit: {formatCurrency(customer?.accountCreditLimit)}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>High Credit Date: {customer?.accountHighCreditDate ? new Date(customer?.accountHighCreditDate).toLocaleDateString() : ''}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>High Credit Amount: {formatCurrency(customer?.accountHighCreditAmount)}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Comment: {customer?.accountComment}</Typography>
                            </Grid>
                            <Grid item mt={2}>
                                <Typography variant='h6'>Dates</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Customer Since: {customer?.accountHighCreditDate ? new Date(customer?.createdOn).toLocaleDateString() : ''}</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Last Statement:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Last Active Date:</Typography>
                            </Grid>
                            <Grid item>
                                <Typography>Last Payment:</Typography>
                            </Grid>
                            <Grid item>
                                <Switch checked={customer?.isActive ?? false} disabled /> Active
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={9}>
                    <Paper elevation={0} variant='outlined' square sx={{ minHeight: 'calc(100vh - 72px)' }}>
                        <Grid item container direction='column' spacing={2} p={2}>
                            <Grid item>
                                <TabContext value={selectedTab}>
                                    <Box sx={{ borderBottom: '1px solid black' }}>
                                        <Tabs
                                            value={selectedTab}
                                            onChange={(e, value) => setSelectedTab(value)}
                                            variant='fullWidth'
                                            textColor='primary'
                                            indicatorColor='primary'
                                        >
                                            <Tab label={'QUOTES'} key={0} value={'quotes'} />
                                            <Tab label={'CONTACTS'} key={1} value={'contacts'} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={'quotes'}>
                                        <QuoteListView dashboardView />
                                    </TabPanel>
                                    <TabPanel value={'contacts'}>
                                        <ContactsListView />
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </>

    );
};
