import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { ContactDto, CustomerDto, QuoteDto, RegionDto, SiteDto, UserDto } from '../../../dtos';
import { countries, emptyGuid, toOrFromJobTypes } from '../../../util';
import { IFormFieldValidationConfig, IFormProps, isGreaterThanOrEqualTo, isNotBlank, runFieldValidation, runFormValidation } from '../../CoreLib/library';
import _ from 'lodash';
import { SelectChangeEvent } from '@mui/material';
import { AppraisalType } from '../../../dtos/generated/AppraisalType';
import { add } from 'date-fns';

export interface IQuoteFormValues {
    quoteNumber?: number;
    quoteDate?: Date;
    expirationDate?: Date;
    maxExpirationDate?: Date;
    customerId: string;
    contactId?: string;
    effectiveOn?: Date;
    poNumber?: string;
    salesPersonId?: string;
    projectName?: string;
    addressLine1: string;
    addressLine2?: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    miles?: number;
    minutes?: number;
    toOrFromJob?: string;
    isTaxable?: boolean;
    memo?: string;
    status?: string;
    isActive: boolean;
    type?: string;
    appraisalType?: AppraisalType;
    jobName?: string;
    siteName?: string;
    regionId?: string;
    region?: RegionDto | undefined;
}

const DEFAULT_QUOTE: QuoteDto = {
    id: emptyGuid,
    quoteNumber: undefined,
    quoteDate: new Date(),
    expirationDate: add(new Date(), { months: 2 }),
    maxExpirationDate: undefined,
    customerId: emptyGuid,
    contactId: emptyGuid,
    effectiveOn: new Date(),
    poNumber: '',
    salesPersonId: emptyGuid,
    projectName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    zipCode: '',
    country: countries[2], // US
    miles: undefined,
    minutes: undefined,
    toOrFromJob: toOrFromJobTypes[0],
    isTaxable: false,
    memo: '',
    status: 'In Progress',
    isActive: true,
    createdOn: new Date(),
    clientId: emptyGuid,
    type: '',
    appraisalType: AppraisalType.Quote,
    jobName: '',
    siteName: '',
    region: undefined,
};

export function useQuoteForm(props: IFormProps<QuoteDto>) {
    const { save, cancel, initValues } = props;
    const [formIsActive, setFormIsActive] = useState(initValues?.isActive ?? true);
    const [formQuoteNumber, setFormQuoteNumber] = useState(initValues?.quoteNumber);
    const [formQuoteDate, setFormQuoteDate] = useState<Date | null | undefined>(initValues?.quoteDate ?? new Date());
    const [formExpirationDate, setFormExpirationDate] = useState<Date | null | undefined>(initValues?.expirationDate ?? add(new Date(), { months: 2 }));
    const [formCustomer, setFormCustomer] = useState<CustomerDto | null>(initValues?.customer ?? null);
    const [formContact, setFormContact] = useState<ContactDto | null>(initValues?.contact ?? null);
    const [formEffectiveOn, setFormEffectiveOn] = useState<Date | null | undefined>(initValues?.effectiveOn ?? new Date());
    const [formPoNumber, setFormPoNumber] = useState(initValues?.poNumber ?? '');
    const [formProjectName, setFormProjectName] = useState(initValues?.projectName ?? '');
    const [formAddressLine1, setFormAddressLine1] = useState(initValues?.addressLine1 ?? '');
    const [formAddressLine2, setFormAddressLine2] = useState(initValues?.addressLine2 ?? '');
    const [formCity, setFormCity] = useState(initValues?.city ?? '');
    const [formState, setFormState] = useState(initValues?.state ?? '');
    const [formZipCode, setFormZipCode] = useState(initValues?.zipCode ?? '');
    const [formCountry, setFormCountry] = useState(initValues?.country ?? countries[2]);
    const [formMiles, setFormMiles] = useState<number | undefined>(initValues?.miles ?? undefined);
    const [formMinutes, setFormMinutes] = useState<number | undefined>(initValues?.minutes ?? undefined);
    const [formToOrFromJob, setFormToOrFromJob] = useState(initValues?.toOrFromJob ?? '');
    const [formIsTaxable, setFormIsTaxable] = useState(initValues?.isTaxable ?? false);
    const [formSalesPerson, setFormSalesPerson] = useState<UserDto | null>(initValues?.salesPerson ?? null);
    const [formMemo, setFormMemo] = useState(initValues?.memo ?? '');
    const [formStatus, setFormStatus] = useState(initValues?.status ?? 'In Progress');
    const [formType, setFormType] = useState(initValues?.type ?? '');
    const [formAppraisalType, setFormAppraisalType] = useState<AppraisalType>(initValues?.appraisalType ?? AppraisalType.Quote);
    const [formJobName, setFormJobName] = useState(initValues?.jobName ?? '');
    const [formSiteName, setFormSiteName] = useState(initValues?.siteName ?? '');
    const [formSite, setFormSite] = useState<SiteDto | undefined>();
    const [formRegion, setFormRegion] = useState<RegionDto | undefined>(initValues?.region ?? undefined);
    const [isSaved, setIsSaved] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const isMasterPriceListQuote = useMemo(() => {
        return formAppraisalType === AppraisalType.MasterPriceList;
    }, [formAppraisalType]);

    useEffect(() => {
        if (formCustomer) {
            setFormIsTaxable(initValues?.isTaxable ?? formCustomer?.isTaxable ?? false);
        }
    }, [formCustomer, initValues?.isTaxable]);

    const quoteLabel = useMemo(() => {
        if (formAppraisalType === AppraisalType.Estimate) {
            return 'Estimate';
        } else if (formAppraisalType === AppraisalType.Quote) {
            return 'Quote';
        } else {
            return 'MPL Quote';
        }
    }, [formAppraisalType]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof QuoteDto, string>>(
        new Map([
            ['customerId', ''],
            ['addressLine1', ''],
            ['city', ''],
            ['state', ''],
            ['zipCode', ''],
            ['country', ''],
            ['miles', ''],
            ['minutes', ''],
            ['type', ''],
            ['quoteDate', ''],
            ['effectiveOn', ''],
            ['expirationDate', ''],
        ])
    );

    const formFieldValidators = useMemo(() => {
        return new Map<keyof QuoteDto, IFormFieldValidationConfig>([
            [
                'customerId',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Customer',
                },
            ],
            [
                'addressLine1',
                {
                    validators: isMasterPriceListQuote ? [] : [isNotBlank],
                    errorMessageEntityName: 'Address Line 1',
                },
            ],
            [
                'city',
                {
                    validators: isMasterPriceListQuote ? [] : [isNotBlank],
                    errorMessageEntityName: 'City',
                },
            ],
            [
                'state',
                {
                    validators: isMasterPriceListQuote ? [] : [isNotBlank],
                    errorMessageEntityName: 'State',
                },
            ],
            [
                'zipCode',
                {
                    validators: isMasterPriceListQuote ? [] : [isNotBlank],
                    errorMessageEntityName: 'Zip Code',
                },
            ],
            [
                'country',
                {
                    validators: isMasterPriceListQuote ? [] : [isNotBlank],
                    errorMessageEntityName: 'Country',
                },
            ],
            [
                'type',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Type',
                },
            ],
            [
                'miles',
                {
                    validators: isMasterPriceListQuote ? [] : [isGreaterThanOrEqualTo(0)],
                    errorMessageEntityName: 'Miles',
                },
            ],
            [
                'minutes',
                {
                    validators: isMasterPriceListQuote ? [] : [isGreaterThanOrEqualTo(0)],
                    errorMessageEntityName: 'Minutes',
                },
            ],
            [
                'quoteDate',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: `${quoteLabel} Date`,
                },
            ],
            [
                'effectiveOn',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Effective On',
                },
            ],
            [
                'expirationDate',
                {
                    validators: [isNotBlank], // Need date validation
                    errorMessageEntityName: 'Expiration Date',
                },
            ],
        ]);
    }, [isMasterPriceListQuote, quoteLabel]);

    const getCurrentFormValues = useCallback((): IQuoteFormValues => {
        return {
            quoteNumber: formQuoteNumber,
            quoteDate: formQuoteDate ?? undefined,
            expirationDate: formExpirationDate ?? undefined,
            customerId: formCustomer?.id ?? emptyGuid,
            contactId: formContact?.id,
            effectiveOn: formEffectiveOn ?? undefined,
            poNumber: formPoNumber,
            projectName: formProjectName,
            addressLine1: formAddressLine1,
            addressLine2: formAddressLine2,
            city: formCity,
            state: formState,
            zipCode: formZipCode,
            country: formCountry,
            miles: formMiles,
            minutes: formMinutes,
            toOrFromJob: formToOrFromJob,
            isTaxable: formIsTaxable,
            salesPersonId: formSalesPerson?.id,
            memo: formMemo,
            status: formStatus,
            isActive: formIsActive,
            type: formType,
            appraisalType: formAppraisalType ? formAppraisalType : AppraisalType.Quote,
            jobName: formJobName,
            siteName: formSiteName,
            regionId: formRegion?.id,
            region: formRegion,
        };
    }, [
        formQuoteNumber,
        formQuoteDate,
        formExpirationDate,
        formCustomer?.id,
        formContact?.id,
        formEffectiveOn,
        formPoNumber,
        formProjectName,
        formAddressLine1,
        formAddressLine2,
        formCity,
        formState,
        formZipCode,
        formCountry,
        formMiles,
        formMinutes,
        formToOrFromJob,
        formIsTaxable,
        formSalesPerson?.id,
        formMemo,
        formStatus,
        formIsActive,
        formType,
        formAppraisalType,
        formJobName,
        formSiteName,
        formRegion,
    ]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<QuoteDto>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IQuoteFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    const isFormDirty = useCallback(() => {
        if (isSaved) {
            return false;
        }
        return isChanged;
    }, [isChanged, isSaved]);

    const handleQuoteNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormQuoteNumber(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    }, []);

    const handleQuoteDateChange = useCallback((value: Date | null | undefined) => {
        setFormQuoteDate(value);
        setIsChanged(true);
    }, []);

    const handleExpirationDateChange = useCallback((value: Date | null | undefined) => {
        setFormExpirationDate(value);
        setIsChanged(true);
    }, []);

    const handleCustomerChange = useCallback((value: any) => {
        setFormCustomer(value);
        setFormContact(null);
        setIsChanged(true);
    }, []);

    const handleContactChange = useCallback((value: any) => {
        setFormContact(value);
        setIsChanged(true);
    }, []);

    const handleEffectiveOnDateChange = useCallback((value: Date | null | undefined) => {
        setFormEffectiveOn(value);
        setIsChanged(true);
    }, []);

    const handlePoNumberChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormPoNumber(event.target.value);
        setIsChanged(true);
    }, []);

    const handleProjectNameChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormProjectName(event.target.value);
        setIsChanged(true);
    }, []);

    const handleAddressLine1Change = useCallback((value: string) => {
        setFormAddressLine1(value);
        setIsChanged(true);
    }, []);

    const handleAddressLine2Change = useCallback((value: string) => {
        setFormAddressLine2(value);
        setIsChanged(true);
    }, []);

    const handleCityChange = useCallback((value: string) => {
        setFormCity(value);
        setIsChanged(true);
    }, []);

    const handleStateChange = useCallback((value: string) => {
        setFormState(value);
        setIsChanged(true);
    }, []);

    const handleZipCodeChange = useCallback((value: string) => {
        setFormZipCode(value);
        setIsChanged(true);
    }, []);

    const handleCountryChange = useCallback((value: string) => {
        setFormCountry(value);
        setIsChanged(true);
    }, []);

    const handleMilesChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormMiles(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    }, []);

    const handleMinutesChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormMinutes(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    }, []);

    const handleToOrFromJobChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormToOrFromJob(event.target.value);
        setIsChanged(true);
    }, []);

    const handleFormTypeChange = useCallback((event: SelectChangeEvent) => {
        setFormType(event.target.value);
        setIsChanged(true);
    }, []);

    const handleIsTaxableChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsTaxable(isChecked);
        setIsChanged(true);
    };

    const handleSalesPersonChange = useCallback((value: UserDto) => {
        setFormSalesPerson(value);
        setIsChanged(true);
    }, []);

    const handleMemoChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setFormMemo(event.target.value);
        setIsChanged(true);
    }, []);

    const handleStatusChange = useCallback((event: SelectChangeEvent) => {
        setFormStatus(event.target.value);
        setIsChanged(true);
    }, []);

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    }, []);

    const handleSiteNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormSiteName(event.target.value);
        setIsChanged(true);
    };

    const handleRegionChange = (value: RegionDto) => {
        setFormRegion(value);
        setIsChanged(true);
    };

    const handleSiteChange = (value?: SiteDto) => {
        setFormSite(value);
        setFormSiteName(value?.name ?? '');
        setFormRegion(value?.region);
        setIsChanged(true);
        setFormAddressLine1(value?.addressLine1 ?? '');
        setFormAddressLine2(value?.addressLine2 ?? '');
        setFormCity(value?.city ?? '');
        setFormState(value?.state ?? '');
        setFormZipCode(value?.zipCode ?? '');
        setFormCountry(value?.country ?? '');
    };

    const handleJobNameChange = useCallback((value: string) => {
        setFormJobName(value);
        setIsChanged(true);
    }, []);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_QUOTE),
                ...formValues,
            };
            save(updatedUserRecord);
            setIsSaved(true);
            setIsChanged(false);
        }
    }, [getCurrentFormValues, initValues, save, validateForm]);

    const handleCancel = useCallback(() => {
        cancel();
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        formIsActive,
        formQuoteNumber,
        formQuoteDate,
        formExpirationDate,
        formCustomer,
        formContact,
        formEffectiveOn,
        formPoNumber,
        formProjectName,
        formAddressLine1,
        formAddressLine2,
        formCity,
        formState,
        formZipCode,
        formCountry,
        formMiles,
        formMinutes,
        formToOrFromJob,
        formIsTaxable,
        formSalesPerson,
        formMemo,
        formStatus,
        formType,
        formAppraisalType,
        formJobName,
        formSiteName,
        formSite,
        formRegion,
        handleIsActiveChange,
        handleQuoteNumberChange,
        handleQuoteDateChange,
        handleExpirationDateChange,
        handleCustomerChange,
        handleContactChange,
        handleEffectiveOnDateChange,
        handlePoNumberChange,
        handleProjectNameChange,
        handleAddressLine1Change,
        handleAddressLine2Change,
        handleCityChange,
        handleStateChange,
        handleZipCodeChange,
        handleCountryChange,
        handleMilesChange,
        handleMinutesChange,
        handleToOrFromJobChange,
        handleIsTaxableChange,
        handleSalesPersonChange,
        handleMemoChange,
        handleStatusChange,
        handleFormTypeChange,
        handleSiteNameChange,
        handleRegionChange,
        handleSiteChange,
        validateField,
        setFormType,
        setFormAddressLine1,
        setFormCity,
        setFormCountry,
        setFormState,
        setFormZipCode,
        setFormAppraisalType,
        handleJobNameChange,
        setFormSalesPerson,
        quoteLabel,
        isMasterPriceListQuote,
    };
}
