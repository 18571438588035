import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

export function useConfirmExit(confirmExit: () => boolean, when = true) {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) { return; }

        const push = navigator.push;
        navigator.push = (...args: Parameters<typeof push>) => {
            const result = confirmExit();
            if (result !== false) {
                push(...args);
            }
        };

        return () => {
            navigator.push = push;
        };
    }, [navigator, confirmExit, when]);
}