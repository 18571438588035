import { Autocomplete, FormControl, FormHelperText, FormLabel, TextField } from '@mui/material';
import { FC, FocusEventHandler, SyntheticEvent, useCallback } from 'react';
import { EquipmentDto } from '../../dtos';
import { useGetEquipmentQuery } from '../../store/generated/generatedApi';
import { emptyGuid } from '../../util';

export interface IEquipmentSelectProps {
    selectedEquipmentId: string;
    handleSelectedEquipmentChange: (selectedEquipment?: EquipmentDto) => void;
    required?: boolean;
    errorMessage?: string;
    onBlur?: FocusEventHandler<HTMLDivElement>;
    disabled?: boolean;
    brokerId?: string;
}

export const EquipmentSelect: FC<IEquipmentSelectProps> = props => {
    const { selectedEquipmentId, handleSelectedEquipmentChange, required, errorMessage, onBlur, disabled, brokerId } = props;
    const { data: equipment, isLoading: isLoadingEquipments } = useGetEquipmentQuery({
        sortKey: 'NUMBER',
        sortAsc: true,
        page: 0,
        pageSize: 10000,
        includeInactive: false,
        brokerId: brokerId
    });

    const handleSelectChange = useCallback((_: SyntheticEvent<Element, Event>, value: EquipmentDto | null) => {
        handleSelectedEquipmentChange(value ?? undefined);
    }, [handleSelectedEquipmentChange]);

    const getSelectedEquipment = useCallback(() => {
        if (selectedEquipmentId === '' || selectedEquipmentId === emptyGuid) {
            return null;
        }
        return equipment?.pageResults.find(x => x.id === selectedEquipmentId) ?? null;
    }, [equipment?.pageResults, selectedEquipmentId]);

    return (
        <FormControl error={!!errorMessage} fullWidth required={required} disabled={disabled}>
            <FormLabel>Equipment</FormLabel>
            <Autocomplete
                value={getSelectedEquipment()}
                onChange={handleSelectChange}
                loading={isLoadingEquipments}
                options={equipment?.pageResults ?? []}
                getOptionLabel={(option: EquipmentDto) => `${option.number} - ${option.equipmentType?.type}`}
                renderInput={(params) => (
                    <TextField
                        error={!!errorMessage}
                        {...params}
                    />
                )}
                onBlur={onBlur}
                disabled={disabled}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    )
}