import { FC, useCallback, useMemo, useState } from 'react';
import { JobNoteDto } from '../../../dtos';
import { Box, Grid, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import { format } from 'date-fns';
import { Check, Close, MoreVert } from '@mui/icons-material';
import { AuthenticatedComponent, useFailedActionSnackbar, useFailedUpdateSnackbar, useSuccessfulActionSnackbar, useSuccessfulUpdateSnackbar } from '../../../Components/CoreLib/library';
import { useArchiveJobJobNoteDeleteByIdMutation, useUpdateJobJobNoteMutation } from '../../../store/generated/generatedApi';

export interface INoteEntry {
    note: JobNoteDto;
}

export const NoteEntry: FC<INoteEntry> = (props) => {
    const { note } = props;
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | (EventTarget & SVGSVGElement)>(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [editedText, setEditedText] = useState('');
    const [updateJobNote, { isLoading: isUpdatingNote, isSuccess: isUpdateNoteSuccess, isError: isUpdateNoteError, reset: restUpdateNote }] =
        useUpdateJobJobNoteMutation();
    useFailedUpdateSnackbar('Job Note', isUpdateNoteError, restUpdateNote);
    useSuccessfulUpdateSnackbar('Job Note', isUpdateNoteSuccess, () => {
        restUpdateNote();
        setIsEditMode(false);
        setEditedText('');
    });

    const [deleteNote, { isLoading: isDeleteNoteLoading, isSuccess: isDeleteNoteSuccess, isError: isDeleteNoteError, reset: resetDeleteNote }] = useArchiveJobJobNoteDeleteByIdMutation();
    useSuccessfulActionSnackbar('deleted', 'Job Note', isDeleteNoteSuccess, resetDeleteNote);
    useFailedActionSnackbar('deleting', 'Job Note', isDeleteNoteError, resetDeleteNote);

    const handleEditClicked = useCallback(() => {
        setMenuAnchorEl(null);
        setIsEditMode(true);
        setEditedText(note.text);
    }, [note.text]);

    const handleApplyEditClicked = useCallback(() => {
        if (isUpdatingNote) {
            return;
        }
        updateJobNote({
            params: { jobId: note.jobId },
            payload: {...note, text: editedText}
        });
    }, [isUpdatingNote, note, editedText, updateJobNote]);

    const handleCancelEditClicked = useCallback(() => {
        if (isUpdatingNote) {
            return;
        }
        setIsEditMode(false);
        setEditedText('');
    }, [isUpdatingNote]);

    const handleDeleteClicked = useCallback(() => {
        if (isDeleteNoteLoading) {
            return;
        }
        deleteNote({ jobId: note.jobId, id: note.id });
    }, [isDeleteNoteLoading, note, deleteNote]);

    const renderNoteText = useMemo(() => {
        if (isEditMode) {
            return <TextField value={editedText} onChange={(e) => setEditedText(e.target.value)} fullWidth multiline size='small' sx={{ mb: 1 }} />;
        } else {
            return (
                <Box border='1px solid #58585A' p={1} borderRadius={1} whiteSpace='pre-line'>
                    {note.text}
                </Box>
            );
        }
    }, [isEditMode, note.text, editedText]);

    const renderNoteSubText = useMemo(() => {
        if (isEditMode) {
            return (
                <Box display='flex' flexDirection='row' justifyContent='flex-end' alignContent='center' gap={1}>
                    <IconButton onClick={handleCancelEditClicked} color='error' size='small' disabled={isUpdatingNote}>
                        <Close />
                    </IconButton>
                    <IconButton onClick={handleApplyEditClicked} color='success' size='small' disabled={isUpdatingNote}>
                        <Check />
                    </IconButton>
                </Box>
            );
        } else {
            var parsedCreatedDate = new Date(note.createdOn);
            parsedCreatedDate.setMinutes(parsedCreatedDate.getMinutes() - new Date().getTimezoneOffset());

            return (
                <Box display='flex' flexDirection='row' justifyContent='flex-end' alignContent='center'>
                    - {note.authorName}, {format(parsedCreatedDate, 'M/d/yyyy h:mm aa')}
                    <AuthenticatedComponent
                        requiredPermissions={['create:jobNote', 'edit:jobNote']}
                        logic='or'
                        children={<MoreVert sx={{ cursor: 'pointer' }} onClick={(e) => setMenuAnchorEl(e.currentTarget)} />}
                    />
                </Box>
            );
        }
    }, [isEditMode, note, handleApplyEditClicked, handleCancelEditClicked, isUpdatingNote]);

    return (
        <Grid item xs={12}>
            {renderNoteText}
            {renderNoteSubText}
            <Menu
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl}
                onClose={() => setMenuAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <AuthenticatedComponent requiredPermissions={['edit:jobNote']} children={<MenuItem onClick={handleEditClicked}>Edit</MenuItem>} />
                <AuthenticatedComponent requiredPermissions={['delete:jobNote']} children={<MenuItem onClick={handleDeleteClicked}>Delete</MenuItem>} />
            </Menu>
        </Grid>
    );
};
