import { Restore } from "@mui/icons-material";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, Button } from "@mui/material";
import { FC, useCallback, useState } from "react";
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from "../../Components/CoreLib/library";
import { useGetCanceledDispatchQuery } from "../../store/generated/generatedApi";
import { formatDate } from "../../util";
import { CanceledDispatchDto } from "../../dtos";

export interface IRestoreDispatchesDialogProps {
    onClose: () => void;
    onConfirm: (selectedDispatches: CanceledDispatchDto[]) => void;
    open: boolean;
    startDate: Date;
}

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'START_DATE',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const RestoreDispatchesDialog: FC<IRestoreDispatchesDialogProps> = props => {
    const { onClose, onConfirm, open, startDate } = props;
    const [selectedDispatches, setSelectedDispatches] = useState<CanceledDispatchDto[]>([]);

    const tableColumns: DataTableColumn<CanceledDispatchDto>[] = [
        { key: 'customerName', label: 'Customer', sortKey: 'CUSTOMER_NAME' },
        { key: 'projectName', label: 'Project', sortKey: 'PROJECT_NAME' },
        { key: 'jobName', label: 'Job', sortKey: 'JOB_NAME' },
        { key: 'orderNumber', label: 'Order #', sortKey: 'ORDER_NUMBER' },
        { key: 'orderLineItemNumber', label: 'LI #', sortKey: 'LINE_ITEM_NUMBER' },
        { key: 'description', label: 'Description', sortKey: 'DESCRIPTION' },
        { key: 'haulerType', label: 'Hauler Type', sortKey: 'HAULER_TYPE' },
        { key: 'haulerName', label: 'Hauler Name', sortKey: 'HAULER_NAME' },
        { key: 'onsiteTime', label: 'Onsite Time', sortKey: 'START_DATE' },
        { key: 'endTime', label: 'End Time', sortKey: 'END_DATE' },
        { key: 'cancellationReason', label: 'Cancellation Reason', sortKey: 'REASON' },
        { key: 'canceledBy', label: 'Canceled By', sortKey: 'CANCELED_BY' },
    ];

    const handleConfirm = useCallback(() => {
        onConfirm(selectedDispatches);
    }, [onConfirm, selectedDispatches]);

    return (
        <Dialog onClose={onClose} open={open} maxWidth='lg' fullWidth>
            <DialogTitle sx={{ backgroundColor: 'primary', color: '#ffffff' }}>
                <Typography variant='h2'>
                    {<Restore />} Restore Dispatches
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container direction='column' mt={2}>
                    <Grid item>
                        <Typography>Select canceled dispatches you would like to restore.</Typography>
                    </Grid>
                    <Grid item>
                        <StandardListViewV2
                            headerIcon={<></>}
                            headerTitle={''}
                            breadCrumbs={[]}
                            defaultPaginationProps={defaultPaginationProps}
                            getDataQuery={useGetCanceledDispatchQuery}
                            isShowActiveToggleVisible={false}
                            tableColumns={tableColumns}
                            entityNameSingular='Dispatch'
                            permissionName='dispatch'
                            onSelectedChange={(selected: CanceledDispatchDto[]) => {
                                setSelectedDispatches(selected);
                            }}
                            additionalQueryParameters={{ startDate: formatDate(startDate, 'yyyy-MM-dd') }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={onClose}>
                    Cancel
                </Button>
                <Button startIcon={<Restore />} variant='contained' onClick={handleConfirm} disabled={selectedDispatches.length < 1}>
                    Restore
                </Button>
            </DialogActions>
        </Dialog>
    );
};