import { useCallback } from 'react';
import { DispatchReviewDto, LineItemDto, OrderByType, OrderLineItemDto, OrderReviewLineItemDto, QuoteDto } from '../dtos';
import { useCreateBillingRatesLineItemMutation } from '../store/generated/generatedApi';
import { emptyGuid, LineItemTypes } from './constants';

export function useLineItemConverter(quote?: QuoteDto) {
    const [getBillingRates] = useCreateBillingRatesLineItemMutation();

    const getHaulToAddress = useCallback(
        (lineItem: LineItemDto) => {
            switch (lineItem.type) {
                case LineItemTypes.Material:
                case LineItemTypes.SiteHauling: {
                    return {
                        addressLine1: quote?.addressLine1,
                        addressLine2: quote?.addressLine2,
                        city: quote?.city,
                        state: quote?.state,
                        zipCode: quote?.zipCode,
                        country: quote?.country,
                        region: quote?.region,
                        regionId: quote?.region?.id,
                        siteName: quote?.siteName,
                    };
                }
                case LineItemTypes.Dump: {
                    return {
                        addressLine1: lineItem.site?.addressLine1,
                        addressLine2: lineItem.site?.addressLine2,
                        city: lineItem.site?.city,
                        state: lineItem.site?.state,
                        zipCode: lineItem.site?.zipCode,
                        country: lineItem.site?.country,
                        region: lineItem.site?.region,
                        regionId: lineItem.site?.regionId,
                        siteName: lineItem.site?.name,
                    };
                }
                default: {
                    return {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: '',
                        region: undefined,
                        regionId: undefined,
                        siteName: '',
                    };
                }
            }
        },
        [quote?.addressLine1, quote?.addressLine2, quote?.city, quote?.country, quote?.state, quote?.zipCode, quote?.region, quote?.siteName]
    );

    const getHaulFromAddress = useCallback(
        (lineItem: LineItemDto) => {
            switch (lineItem.type) {
                case LineItemTypes.Hourly:
                case LineItemTypes.Dump:
                case LineItemTypes.Misc: {
                    return {
                        addressLine1: quote?.addressLine1,
                        addressLine2: quote?.addressLine2,
                        city: quote?.city,
                        state: quote?.state,
                        zipCode: quote?.zipCode,
                        country: quote?.country,
                        region: quote?.region,
                        regionId: quote?.region?.id,
                        siteName: quote?.siteName,
                        isHaulFromAddressLocked: true,
                    };
                }
                case LineItemTypes.Material:
                case LineItemTypes.SiteHauling: {
                    return {
                        addressLine1: lineItem.site?.addressLine1,
                        addressLine2: lineItem.site?.addressLine2,
                        city: lineItem.site?.city,
                        state: lineItem.site?.state,
                        zipCode: lineItem.site?.zipCode,
                        country: lineItem.site?.country,
                        region: lineItem.site?.region,
                        regionId: lineItem.site?.regionId,
                        siteName: lineItem.site?.name,
                        isHaulFromAddressLocked: true,
                    };
                }
                default: {
                    return {
                        addressLine1: '',
                        addressLine2: '',
                        city: '',
                        state: '',
                        zipCode: '',
                        country: '',
                        region: undefined,
                        regionId: undefined,
                        siteName: '',
                        isHaulFromAddressLocked: false,
                    };
                }
            }
        },
        [quote?.addressLine1, quote?.addressLine2, quote?.city, quote?.country, quote?.state, quote?.zipCode, quote?.region, quote?.siteName]
    );

    const convertQuoteLineItemToOrderLineItem = async (quoteLineItem: LineItemDto, newLineNumber: number): Promise<OrderLineItemDto> => {
        var haulToAddress = getHaulToAddress(quoteLineItem);
        var haulFromAddress = getHaulFromAddress(quoteLineItem);
        let billingRates = await getBillingRates(quoteLineItem).unwrap();

        return {
            id: emptyGuid,
            isActive: true,
            createdOn: new Date(),
            equipmentType: quoteLineItem.equipmentType,
            equipmentTypeId: quoteLineItem.equipmentTypeId,
            lineItemId: quoteLineItem.id,
            lineItem: quoteLineItem,
            orderBy: OrderByType.Quantity,
            loadOrQuantityReq: 0,
            equipmentRequired: 0,
            memo: '',
            driverMemo: quoteLineItem.driverMemo ?? '',
            dispatches: [],
            dispatchMemo: '',
            yardTime: undefined,
            onsiteTime: undefined,
            dispatchingIntervalTime: undefined,
            orderLineItemNumber: newLineNumber,
            name: quoteLineItem.description,
            poNumber: quote?.poNumber ?? '',
            haulToAddressLine1: haulToAddress.addressLine1,
            haulToAddressLine2: haulToAddress.addressLine2,
            haulToCity: haulToAddress.city,
            haulToState: haulToAddress.state,
            haulToZipCode: haulToAddress.zipCode,
            haulToCountry: haulToAddress.country,
            haulToRegion: haulToAddress.region,
            haulToRegionId: haulToAddress.regionId,
            haulToSiteName: haulToAddress.siteName,
            haulFromAddressLine1: haulFromAddress.addressLine1,
            haulFromAddressLine2: haulFromAddress.addressLine2,
            haulFromCity: haulFromAddress.city,
            haulFromState: haulFromAddress.state,
            haulFromZipCode: haulFromAddress.zipCode,
            haulFromCountry: haulFromAddress.country,
            haulFromRegion: haulFromAddress.region,
            haulFromRegionId: haulFromAddress.regionId,
            haulFromSiteName: haulFromAddress.siteName,
            billingRates: billingRates,
        };
    };

    const convertQuoteLineItemToOrderReviewLineItem = async (quoteLineItem: LineItemDto, newLineNumber: number): Promise<OrderReviewLineItemDto> => {
        var orderLineItem = await convertQuoteLineItemToOrderLineItem(quoteLineItem, newLineNumber);
        return {
            ...orderLineItem,
            description: orderLineItem.name,
            dispatches: (orderLineItem.dispatches ?? []) as DispatchReviewDto[],
            isDispatched: (orderLineItem.dispatches?.length ?? 0) > 0,
            isInvoiced: false,
        };
    };

    return {
        convertQuoteLineItemToOrderLineItem,
        convertQuoteLineItemToOrderReviewLineItem,
    };
}
