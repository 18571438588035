import { useCallback, useState } from 'react';
import { IFilterChip } from '../../../Components/CoreLib/library';
import { JobDto } from '../../../dtos';

export interface IQuoteFilterOptions {
    job?: JobDto;
    quoteDateBefore?: Date;
    quoteDateAfter?: Date;
    effectiveDateBefore?: Date;
    effectiveDateAfter?: Date;
    expirationDateBefore?: Date;
    expirationDateAfter?: Date;
    showHistorical?: boolean;
}

export const CHIP_KEYS = {
    job: 'Job',
    quoteDateBefore: 'Quote Date Before',
    quoteDateAfter: 'Quote Date After',
    effectiveOnBefore: 'Effective Date Before',
    effectiveOnAfter: 'Effective Date After',
    expirationDateBefore: 'Expiration Date Before',
    expirationDateAfter: 'Expiration Date After',
    showHistorical: 'Show Historical'
};

export function useQuoteFilters() {
    const [quoteFilters, setQuoteFilters] = useState<IQuoteFilterOptions>({
        job: undefined,
        quoteDateBefore: undefined,
        quoteDateAfter: undefined,
        effectiveDateBefore: undefined,
        effectiveDateAfter: undefined,
        expirationDateBefore: undefined,
        expirationDateAfter: undefined,
        showHistorical: false
    });

    const handleSearchTermFilterChange = (updatedFilters: IQuoteFilterOptions) => {
        setQuoteFilters(updatedFilters);
    };

    const getFilterChips = useCallback(() => {
        const filterChips: IFilterChip[] = [];
        if (!!quoteFilters.job) {
            filterChips.push({
                key: CHIP_KEYS.job,
                text: quoteFilters.job?.jobNumber?.toString() ?? '',
            });
        }
        if (!!quoteFilters.quoteDateAfter) {
            filterChips.push({
                key: CHIP_KEYS.quoteDateAfter,
                text: quoteFilters.quoteDateAfter.toLocaleDateString(),
            });
        }
        if (!!quoteFilters.quoteDateBefore) {
            filterChips.push({
                key: CHIP_KEYS.quoteDateBefore,
                text: quoteFilters.quoteDateBefore.toLocaleDateString(),
            });
        }
        if (!!quoteFilters.effectiveDateAfter) {
            filterChips.push({
                key: CHIP_KEYS.effectiveOnAfter,
                text: quoteFilters.effectiveDateAfter.toLocaleDateString(),
            });
        }
        if (!!quoteFilters.effectiveDateBefore) {
            filterChips.push({
                key: CHIP_KEYS.effectiveOnBefore,
                text: quoteFilters.effectiveDateBefore.toLocaleDateString(),
            });
        }
        if (!!quoteFilters.expirationDateAfter) {
            filterChips.push({
                key: CHIP_KEYS.expirationDateAfter,
                text: quoteFilters.expirationDateAfter.toLocaleDateString(),
            });
        }
        if (!!quoteFilters.expirationDateBefore) {
            filterChips.push({
                key: CHIP_KEYS.expirationDateBefore,
                text: quoteFilters.expirationDateBefore.toLocaleDateString(),
            });
        }
        if (quoteFilters.showHistorical !== undefined && quoteFilters.showHistorical !== false) {
            filterChips.push({
                key: CHIP_KEYS.showHistorical,
                text: !quoteFilters.showHistorical ? 'No' : 'Yes'
            });
        }
        return filterChips;
    }, [quoteFilters]);

    const handleRemoveFilterChip = useCallback(
        (filterChipKey: string) => {
            switch (filterChipKey) {
                case CHIP_KEYS.job:
                    setQuoteFilters({
                        ...quoteFilters,
                        job: undefined,
                    });
                    break;
                case CHIP_KEYS.quoteDateBefore:
                    setQuoteFilters({
                        ...quoteFilters,
                        quoteDateBefore: undefined,
                    });
                    break;
                case CHIP_KEYS.quoteDateAfter:
                    setQuoteFilters({
                        ...quoteFilters,
                        quoteDateAfter: undefined,
                    });
                    break;
                case CHIP_KEYS.effectiveOnBefore:
                    setQuoteFilters({
                        ...quoteFilters,
                        effectiveDateBefore: undefined,
                    });
                    break;
                case CHIP_KEYS.effectiveOnAfter:
                    setQuoteFilters({
                        ...quoteFilters,
                        effectiveDateAfter: undefined,
                    });
                    break;
                case CHIP_KEYS.expirationDateBefore:
                    setQuoteFilters({
                        ...quoteFilters,
                        expirationDateBefore: undefined,
                    });
                    break;
                case CHIP_KEYS.expirationDateAfter:
                    setQuoteFilters({
                        ...quoteFilters,
                        expirationDateAfter: undefined,
                    });
                    break;
                case CHIP_KEYS.showHistorical:
                    setQuoteFilters({
                        ...quoteFilters,
                        showHistorical: false,
                    });
                    break;
                default:
                    console.warn('Invalid filter chip key provided: ', filterChipKey);
                    break;
            }
        },
        [quoteFilters]
    );

    return {
        quoteFilters,
        handleSearchTermFilterChange,
        getFilterChips,
        handleRemoveFilterChip,
    };
}
