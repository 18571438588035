import { Payments } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, MenuItem, Select } from '@mui/material';
import { FC, useMemo } from 'react';
import { DumpRateDto } from '../../../dtos';
import { useArchiveDumpRateDeleteByIdMutation } from '../../../store/generated/generatedApi';
import { dumpTypes } from '../../../util';
import { IncomeCodeSelect, SiteSelect, UnitOfMeasureSelect } from '../../CommonInputs';
import { SlimFormHeader } from '../../Core/SlimFormHeader';
import { FormNumberInput, FormSection, IFormProps, LoadingIndicator } from '../../CoreLib/library';
import { useDumpRateForm } from './useDumpRateForm';
import { usePrompt } from '../../../Views';

export interface IDumpRateFormProps extends IFormProps<DumpRateDto> {
    siteId: string;
    siteName: string;
}

export const DumpRateForm: FC<IDumpRateFormProps> = (props) => {
    const { isLoading, initValues, siteId, siteName } = props;
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleSiteChange,
        handleDumpTypeChange,
        handleCostChange,
        handleRateChange,
        handleIncomeCodeChange,
        handleIsActiveChange,
        handleUnitOfMeasureChange,
        formSiteId,
        formDumpType,
        formCost,
        formRate,
        formUnitOfMeasure,
        formIncomeCodeId,
        formIsActive,
        validateField,
    } = useDumpRateForm(props, siteId);

    usePrompt('Are you sure you want to leave this page?\nUnsaved changes will be lost.', isFormDirty());

    const currentDumpRateId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column' spacing={3}>
            <SlimFormHeader
                objectName='Dump Rate'
                permissionsTypeName={'dumpRate'}
                icon={<Payments />}
                title={initValues ? 'Edit Dump Rate' : 'New Dump Rate'}
                breadcrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Sites', navLink: '/dataManagement/sites' },
                    { label: siteName, navLink: `/dataManagement/site/${siteId}` },
                ]}
                isActive={formIsActive}
                handleIsActiveChange={handleIsActiveChange}
                id={currentDumpRateId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'dump rate'}
                deleteMutation={useArchiveDumpRateDeleteByIdMutation}
            />
            <Grid item mx={2}>
                <FormSection>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <SiteSelect selectedSiteId={formSiteId} handleSelectedSiteChange={handleSiteChange} disabled required />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormControl error={!!fieldErrors.get('type')} fullWidth required>
                                <FormLabel>Dump Type</FormLabel>
                                <Select value={formDumpType} onChange={handleDumpTypeChange} onBlur={() => validateField('type')}>
                                    {dumpTypes.map(dt => <MenuItem key={dt} value={dt}>{dt}</MenuItem>)}
                                </Select>
                                <FormHelperText>{fieldErrors.get('type')}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={formCost}
                                onChange={handleCostChange}
                                label='Dump Cost'
                                name='dumpCost'
                                fullWidth
                                required
                                error={!!fieldErrors.get('cost')}
                                errorText={fieldErrors.get('cost')}
                                inputProps={{ min: 0 }}
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <UnitOfMeasureSelect
                                selectedUnit={formUnitOfMeasure ?? ''}
                                handleSelectedUnitChange={handleUnitOfMeasureChange}
                                required
                                errorMessage={fieldErrors.get('unitOfMeasure')}
                                onBlur={() => validateField('unitOfMeasure')}
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={2}>
                            <FormNumberInput
                                value={formRate}
                                onChange={handleRateChange}
                                label='Dump Rate'
                                name='dumpRate'
                                fullWidth
                                required
                                error={!!fieldErrors.get('rate')}
                                errorText={fieldErrors.get('rate')}
                                inputProps={{ min: 0 }}
                                disabled={isLoading}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <IncomeCodeSelect
                                selectedIncomeCodeId={formIncomeCodeId}
                                handleSelectedIncomeCodeChange={handleIncomeCodeChange}
                                required
                                errorMessage={fieldErrors.get('incomeCodeId')}
                                onBlur={() => validateField('incomeCodeId')}
                            />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
