import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderDto } from '../../dtos';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetOrderByIdQuery, useUpdateOrderMutation } from '../../store/generated/generatedApi';
import { OrderForm } from '../../Components/Forms/OrderForm';

export const EditOrderView: FC = () => {
    const { orderId, jobId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetOrderByIdQuery({ id: orderId! });
    const [updateOrder, { isSuccess, isError, reset, error: updateError }] = useUpdateOrderMutation();

    useSuccessfulUpdateSnackbar('Order', isSuccess, reset);
    useFailedUpdateSnackbar('order', isError, reset, updateError);

    const handleSave = (values: OrderDto) => {
        updateOrder(values);
    };

    const handleCancel = () => {
        navigate(`/job/${jobId}`);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <OrderForm save={handleSave} cancel={handleCancel} initValues={data} jobId={jobId ?? ''} />;
};
