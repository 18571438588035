export const emptyGuid = '00000000-0000-0000-0000-000000000000';

interface UsState {
    name: string;
    abbreviation: string;
}

export const usStates: UsState[] = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
];

export const countriesByCode = {
    US: 'United States',
    MX: 'Mexico',
    CA: 'Canada',
};

export const countries = [countriesByCode.CA, countriesByCode.MX, countriesByCode.US];

export const bBInvoiceTypes = ['IMI', 'Standard'];

export const pricingTypes = ['A', 'B', 'C', 'D', 'E', 'Custom'];

export const accountStatuses = ['Credit Message', 'Normal', 'On Hold'];

export const toOrFromJobTypes = ['Standard', 'Round Trip', 'To Job', 'From Job'];

export const unitsOfMeasure = ['Loads', 'Tons', 'Yards'];
export type UnitOfMeasure = 'Loads' | 'Tons' | 'Yards';

export const dumpTypes = ['Clean', 'Fee', 'Mixed', 'Refuse', 'Slabs', 'Trash', 'Open Top'];

export const QuoteType = {
    Standard: 'Standard',
    FOB: 'FOB',
};
export const quoteTypes = [QuoteType.Standard, QuoteType.FOB];

export const LineItemTypes = {
    SiteHauling: 'Haul Rate',
    Hourly: 'Hourly',
    Material: 'Material',
    Dump: 'Dump',
    Misc: 'Misc',
};

export const HourlyTypes = {
    Hauling: 'Hauling',
    Overtime: 'Overtime',
    Travel: 'Travel',
    ESH: 'ESH',
    Demurrage: 'Demurrage',
    PW: 'Prevailing Wage',
};
export const hourlyTypesList = [HourlyTypes.Hauling, HourlyTypes.Overtime, HourlyTypes.Travel, HourlyTypes.ESH, HourlyTypes.Demurrage, HourlyTypes.PW];

export const LineItemStatus = {
    InProgress: 'In Progress',
    Accepted: 'Accepted',
    Amended: 'Amended',
};

export const Roles = {
    Admin: 'rol_aMZcn1f7XZx0R14m',
    Participant: 'rol_khOvY60V2tbRXj2D',
};

export const isValidUnitOfMeasure = (value: string): value is UnitOfMeasure => {
    return unitsOfMeasure.includes(value);
};
