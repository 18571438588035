import { debounce, FormControl, FormHelperText, FormLabel } from "@mui/material";
import { FunctionComponent, useMemo, useState } from "react";
import { TimePicker as XTimePicker } from '@mui/x-date-pickers';

export interface ITimePickerProps {
    value?: Date | null;
    disabled?: boolean;
    required?: boolean;
    error?: boolean;
    label?: string;
    onChange: (date?: Date | null) => void;
    errorText?: string;
    views?: ('hours' | 'minutes' | 'seconds')[];
};

export const TimePicker: FunctionComponent<ITimePickerProps> = (props) => {
    const { value, disabled, required, error, label, onChange, errorText, views } = props;
    const [fieldValue, setFieldValue] = useState<Date | null | undefined>((value));

    const debouncedHandleChange = useMemo(
        () => debounce((date: Date | null | undefined) => {
            onChange(date);
        }, 300)
        , [onChange]);

    return (
        <FormControl
            fullWidth
            disabled={disabled}
            required={required}
            error={error}>
            <FormLabel>{label}</FormLabel>
            <XTimePicker
                value={fieldValue}
                onChange={(value) => {
                    setFieldValue(value);
                    debouncedHandleChange(value);
                }}
                slotProps={{
                    textField: {
                        error: error
                    }
                }}
                disabled={disabled}
                views={views}
                timezone='america/new_york'
            />
            <FormHelperText>{errorText}</FormHelperText>
        </FormControl>
    );
};