import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { SiteDto } from '../../dtos';
import {
    useArchiveSiteBulkDeleteMutation,
    useGetSiteQuery,
    useSiteBulkArchiveMutation,
    useSiteBulkUnarchiveMutation,
} from '../../store/generated/generatedApi';
import { Payments, Place } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { usePageTitleSetter } from '../../util';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'CODE',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const SiteListView: FC = () => {
    const navigate = useNavigate();
    usePageTitleSetter('Sites');

    const navigateToMaterialPricePage = useCallback((site: SiteDto) => {
        const { id, code } = site;
        window.open(`${window.origin}/dataManagement/materialPrices?siteId=${id}&siteName=${code}`, '_blank');
    }, []);

    const renderViewMaterialPriceButton = useCallback((site: SiteDto) => {

        const handleClick: MouseEventHandler = (e) => {
            e.stopPropagation();
            navigateToMaterialPricePage(site);
        }

        return (
            <Tooltip title='View Material Prices'>
                <Box className='hover-content'>
                    <IconButton onClick={handleClick}>
                        <Payments />
                    </IconButton>
                </Box>
            </Tooltip>
        )
    }, [navigateToMaterialPricePage]);

    const tableColumns: DataTableColumn<SiteDto>[] = useMemo(() => [
        { key: 'code', label: 'Site Code', sortKey: 'CODE' },
        { key: 'name', label: 'Site Name', sortKey: 'NAME' },
        { key: 'city', label: 'City', sortKey: 'CITY' },
        { key: 'company', label: 'Company', sortKey: 'COMPANY' },
        { key: '', label: '', sortKey: '', width: '64px', fieldMapper: renderViewMaterialPriceButton }
    ], [renderViewMaterialPriceButton]);

    const handleCreateNew = () => {
        navigate('/dataManagement/site/create');
    };

    const handleTableEdit = (id: string) => {
        navigate(`/dataManagement/site/${id}`);
    };

    return (
        <StandardListViewV2
            headerIcon={<Place />}
            headerTitle='Sites'
            breadCrumbs={[
                { label: 'Home', navLink: '/' },
                { label: 'Data Management', navLink: '/dataManagement' },
            ]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetSiteQuery}
            isShowActiveToggleVisible
            inactivateMutation={useSiteBulkArchiveMutation}
            activateMutation={useSiteBulkUnarchiveMutation}
            deleteMutation={useArchiveSiteBulkDeleteMutation}
            tableColumns={tableColumns}
            entityNameSingular='Site'
            permissionName='site'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
        />
    );
};
