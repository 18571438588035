import { Archive, Delete, Payments, PlaylistAddCircleRounded, Unarchive } from '@mui/icons-material';
import { FC, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DataTableColumn,
    LoadingIndicator,
    PaginatedProps,
    StandardListViewV2,
    useActivateInactivateSnackbar,
    useFailedActionSnackbar,
} from '../../Components/CoreLib/library';
import { MaterialPriceDto, QuoteAffectingChangeTypeDto, QuoteAffectingChangesDto } from '../../dtos';
import { ChangeType } from '../../dtos/generated/ChangeType';
import {
    useArchiveMaterialPriceBulkDeleteMutation,
    useGetMaterialPriceQuery,
    useLazyGetQuoteAffectedQuery,
    useMaterialPriceBulkArchiveMutation,
    useMaterialPriceBulkUnarchiveMutation,
} from '../../store/generated/generatedApi';
import { formatCurrency, usePageTitleSetter } from '../../util';
import { QuotesAffectedByChangeListViewDialog } from '../Quote';
import { MaterialPriceFilterDialog } from './Components';
import { useMaterialPriceFilters } from './Utils';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'MATERIAL_CODE',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

const enum ActionType {
    ACTIVATE,
    INACTIVATE,
    DELETE,
}

export const MaterialPriceListView: FC = () => {
    const navigate = useNavigate();
    usePageTitleSetter('Material Prices');
    const [isFilterDialogVisible, setIsFilterDialogVisible] = useState(false);
    const [selectedForAction, setSelectedForAction] = useState<MaterialPriceDto[]>([]);
    const [selectedInTable, setSelectedInTable] = useState<MaterialPriceDto[]>([]);
    const [currentActionType, setCurrentActionType] = useState<ActionType>();

    const { materialPriceFilters, handleSearchTermFilterChange, getFilterChips, handleRemoveFilterChip } = useMaterialPriceFilters();

    const tableColumns: DataTableColumn<MaterialPriceDto>[] = [
        { key: 'materialCode', label: 'Material Code', sortKey: 'MATERIAL_CODE', fieldMapper: (row) => row.material?.code },
        { key: 'siteCode', label: 'Site Code', sortKey: 'SITE_CODE', fieldMapper: (row) => row.site?.code },
        { key: 'currentCost', label: 'Cost', sortKey: 'COST', fieldMapper: (row) => (row.currentCost ? formatCurrency(row.currentCost) : '') },
        { key: 'currentPriceA', label: 'Price A', sortKey: 'PRICE_A', fieldMapper: (row) => (row.currentPriceA ? formatCurrency(row.currentPriceA) : '') },
        { key: 'currentPriceB', label: 'Price B', sortKey: 'PRICE_B', fieldMapper: (row) => (row.currentPriceB ? formatCurrency(row.currentPriceB) : '') },
        { key: 'currentPriceC', label: 'Price C', sortKey: 'PRICE_C', fieldMapper: (row) => (row.currentPriceC ? formatCurrency(row.currentPriceC) : '') },
        { key: 'currentPriceD', label: 'Price D', sortKey: 'PRICE_D', fieldMapper: (row) => (row.currentPriceD ? formatCurrency(row.currentPriceD) : '') },
        { key: 'currentPriceE', label: 'Price E', sortKey: 'PRICE_E', fieldMapper: (row) => (row.currentPriceE ? formatCurrency(row.currentPriceE) : '') },
        { key: 'currentUnitOfMeasure', label: 'Unit of Measure', sortKey: 'UNIT_OF_MEASURE' },
    ];

    const [inactivateMaterialPrices, { data: inactivateResult, isLoading: isInactivating, isError: isInactivateMaterialPriceError, reset: resetInactivateMaterialPrice }] =
        useMaterialPriceBulkArchiveMutation();
    useActivateInactivateSnackbar('Inactivate', 'Material Price', 'Material Prices', inactivateResult, resetInactivateMaterialPrice);
    useFailedActionSnackbar('inactivate', 'Material Prices', isInactivateMaterialPriceError, resetInactivateMaterialPrice);

    const [
        activateMaterialPrices,
        { data: activateResult, isLoading: isActivating, isError: isActivateMaterialPriceError, reset: resetActivateMaterialPrice },
    ] = useMaterialPriceBulkUnarchiveMutation();
    useActivateInactivateSnackbar('Activate', 'Material Price', 'Material Prices', activateResult, resetActivateMaterialPrice);
    useFailedActionSnackbar('activate', 'Material Prices', isActivateMaterialPriceError, resetActivateMaterialPrice);

    const [deleteMaterialPrices, { data: deleteResult, isLoading: isDeleting, isError: isDeleteMaterialPriceError, reset: resetDeleteMaterialPrice }] =
        useArchiveMaterialPriceBulkDeleteMutation();
    useActivateInactivateSnackbar('Delete', 'Material Price', 'Material Prices', deleteResult, resetDeleteMaterialPrice);
    useFailedActionSnackbar('delete', 'Material Prices', isDeleteMaterialPriceError, resetDeleteMaterialPrice);

    const [getAffectedQuotes, { isLoading: isLoadingAffectedQuotes, isError: isErrorLoadingAffectedQuotes }] = useLazyGetQuoteAffectedQuery();
    useFailedActionSnackbar('check for', 'affected quotes', isErrorLoadingAffectedQuotes);

    const isLoading = useMemo(() => {
        return isInactivating || isActivating || isDeleting || isLoadingAffectedQuotes;
    }, [isInactivating, isActivating, isDeleting, isLoadingAffectedQuotes]);

    const handleCreateNew = useCallback(() => {
        navigate('/dataManagement/materialPrice/create');
    }, [navigate]);

    const handleTableEdit = useCallback(
        (id: string) => {
            navigate(`/dataManagement/materialPrice/${id}`);
        },
        [navigate]
    );

    const handleFilterClicked = useCallback(() => {
        setIsFilterDialogVisible(true);
    }, []);

    const handleInputPriceListClicked = useCallback(() => {
        navigate('/dataManagement/materialPrice/inputPriceList');
    }, [navigate]);

    const currentChanges: QuoteAffectingChangesDto = useMemo(() => {
        const changes = selectedForAction.map((x) => {
            return {
                id: x?.id,
                changeType: ChangeType.MaterialPriceDeleted,
                materialPrice: undefined,
            };
        });
        return { changes };
    }, [selectedForAction]);

    const performActionOnSelectedRecords = useCallback(
        (selectedItems: MaterialPriceDto[], actionType: ActionType) => {
            const recordIds = selectedItems.map((item) => item.id);
            switch (actionType) {
                case ActionType.ACTIVATE:
                    activateMaterialPrices(recordIds);
                    break;
                case ActionType.INACTIVATE:
                    inactivateMaterialPrices(recordIds);
                    break;
                case ActionType.DELETE:
                    deleteMaterialPrices(recordIds);
                    break;
            }
            setCurrentActionType(undefined);
            setSelectedForAction([]);
        },
        [activateMaterialPrices, inactivateMaterialPrices, deleteMaterialPrices]
    );

    const initiateActionOnSelectedRecords = useCallback(
        (actionType: ActionType) => (selectedItems: MaterialPriceDto[]) => {
            if (actionType === ActionType.ACTIVATE) {
                // No checks need to be performed for re-activating material prices
                performActionOnSelectedRecords(selectedItems, actionType);
                return;
            }
            const changes = selectedItems.map<QuoteAffectingChangeTypeDto>((item) => ({ id: item.id, changeType: ChangeType.MaterialPriceDeleted }));
            getAffectedQuotes({
                serializedDto: JSON.stringify({ changes }),
            })
                .unwrap()
                .then((result) => {
                    if (result.totalQueryResults === 0) {
                        performActionOnSelectedRecords(selectedItems, actionType);
                    } else {
                        setSelectedForAction(selectedItems);
                        setCurrentActionType(actionType);
                    }
                });
        },
        [performActionOnSelectedRecords, getAffectedQuotes]
    );

    const ActivateOrInactivateActionItem = useMemo(() => {
        if (selectedInTable.length > 0 && selectedInTable.every((x) => !x.isActive)) {
            return {
                text: 'Make Active',
                onClick: initiateActionOnSelectedRecords(ActionType.ACTIVATE),
                icon: <Unarchive />,
                buttonIsIconButton: true,
                enabledCheck: (selected: MaterialPriceDto[]) =>
                    isLoading || (selected.filter((x) => !x.isActive).length > 0 && selected.every((x) => !x.isActive)),
                clearSelectedItems: true,
            };
        } else {
            return {
                text: 'Make Inactive',
                onClick: initiateActionOnSelectedRecords(ActionType.INACTIVATE),
                icon: <Archive />,
                buttonIsIconButton: true,
                enabledCheck: (selected: MaterialPriceDto[]) =>
                    isLoading || (selected.filter((x) => x.isActive).length > 0 && selected.every((x) => x.isActive)),
                clearSelectedItems: true,
            };
        }
    }, [initiateActionOnSelectedRecords, isLoading, selectedInTable]);

    if (isLoading) {
        <LoadingIndicator />;
    }

    return (
        <>
            <QuotesAffectedByChangeListViewDialog
                onClose={() => setCurrentActionType(undefined)}
                onConfirm={() => performActionOnSelectedRecords(selectedForAction, currentActionType!)}
                open={currentActionType !== undefined}
                changes={currentChanges}
            />
            <StandardListViewV2
                headerIcon={<Payments />}
                headerTitle='Material Prices'
                breadCrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Data Management', navLink: '/dataManagement' },
                ]}
                defaultPaginationProps={defaultPaginationProps}
                getDataQuery={useGetMaterialPriceQuery}
                isShowActiveToggleVisible
                onSelectedChange={setSelectedInTable}
                tableColumns={tableColumns}
                entityNameSingular='Material Price'
                permissionName='materialPrice'
                handleAdd={handleCreateNew}
                handleEdit={handleTableEdit}
                handleFilterClicked={handleFilterClicked}
                additionalActionItems={[
                    ActivateOrInactivateActionItem,
                    {
                        text: 'Delete',
                        onClick: initiateActionOnSelectedRecords(ActionType.DELETE),
                        icon: <Delete />,
                        buttonIsIconButton: true,
                        enabledCheck: (selected: MaterialPriceDto[]) => isLoading || selected.length > 0,
                        clearSelectedItems: true,
                    },
                    {
                        text: 'INPUT PRICE LIST',
                        onClick: handleInputPriceListClicked,
                        icon: <PlaylistAddCircleRounded />,
                    },
                ]}
                additionalMenuItems={[
                    {
                        text: 'Make Active',
                        onClick: initiateActionOnSelectedRecords(ActionType.ACTIVATE),
                        enabledCheck: (selected: MaterialPriceDto[]) => selected.filter((x) => !x.isActive).length > 0 && selected.every((x) => !x.isActive),
                        clearSelectedItems: true,
                    },
                    {
                        text: 'Make Inactive',
                        onClick: initiateActionOnSelectedRecords(ActionType.INACTIVATE),
                        enabledCheck: (selected: MaterialPriceDto[]) => selected.filter((x) => x.isActive).length > 0 && selected.every((x) => x.isActive),
                        clearSelectedItems: true,
                    },
                    {
                        text: 'Delete',
                        onClick: initiateActionOnSelectedRecords(ActionType.DELETE),
                        enabledCheck: (selected: MaterialPriceDto[]) => selected.length > 0,
                        clearSelectedItems: true,
                    },
                    {
                        text: 'Input Price List',
                        onClick: handleInputPriceListClicked,
                        icon: <PlaylistAddCircleRounded />,
                    },
                ]}
                filterChips={getFilterChips()}
                additionalQueryParameters={{
                    siteId: materialPriceFilters.siteId,
                    materialId: materialPriceFilters.materialId,
                }}
                handleFilterChipDelete={handleRemoveFilterChip}
            />
            <MaterialPriceFilterDialog
                isVisible={isFilterDialogVisible}
                onClose={() => setIsFilterDialogVisible(false)}
                filterProps={materialPriceFilters}
                setFilterProps={handleSearchTermFilterChange}
            />
        </>
    );
};
