import { EmojiTransportation } from '@mui/icons-material';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataTableColumn, PaginatedProps, StandardListViewV2 } from '../../Components/CoreLib/library';
import { BrokerDto } from '../../dtos';
import {
    useGetBrokerQuery,
	useBrokerBulkArchiveMutation,
	useBrokerBulkUnarchiveMutation,
	useArchiveBrokerBulkDeleteMutation
} from '../../store/generated/generatedApi';
import { usePageTitleSetter } from '../../util';

const defaultPaginationProps: PaginatedProps = {
    sortKey: 'CODE',
    sortAsc: true,
    page: 0,
    pageSize: 25,
};

export const BrokerListView: FC = () => {
    const navigate = useNavigate();
    usePageTitleSetter('Brokers');

    const tableColumns: DataTableColumn<BrokerDto>[] = useMemo(() => [
        { key: 'code', label: 'Code', sortKey: 'CODE' },
        { key: 'name', label: 'Name', sortKey: 'NAME' },
        { key: 'addressLine1', label: 'Address Line 1', sortKey: 'ADDRESS_LINE_1' },
        { key: 'addressLine2', label: 'Address Line 2', sortKey: 'ADDRESS_LINE_2' },
        { key: 'city', label: 'City', sortKey: 'CITY' },
        { key: 'state', label: 'State', sortKey: 'STATE' },
        { key: 'zipCode', label: 'Zip', sortKey: 'ZIP' }
    ], []);

    const handleCreateNew = () => {
        navigate('/dataManagement/broker/create');
    };

    const handleTableEdit = (id: string) => {
        navigate(`/dataManagement/broker/${id}`);
    };

    return (
        <StandardListViewV2
            headerIcon={<EmojiTransportation />}
            headerTitle='Brokers'
            breadCrumbs={[
                { label: 'Home', navLink: '/' },
                { label: 'Data Management', navLink: '/dataManagement' },
            ]}
            defaultPaginationProps={defaultPaginationProps}
            getDataQuery={useGetBrokerQuery}
            isShowActiveToggleVisible
            inactivateMutation={useBrokerBulkArchiveMutation}
            activateMutation={useBrokerBulkUnarchiveMutation}
            deleteMutation={useArchiveBrokerBulkDeleteMutation}
            tableColumns={tableColumns}
            entityNameSingular='Broker'
            permissionName='broker'
            handleAdd={handleCreateNew}
            handleEdit={handleTableEdit}
        />
    );
};
