import { FilterList } from '@mui/icons-material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid, Typography
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { MaterialSelect, SiteSelect } from '../../../Components/CommonInputs';
import { MaterialDto, SiteDto } from '../../../dtos';
import { emptyGuid } from '../../../util';
import { IMaterialPriceFilterOptions } from '../Utils';

export interface IMaterialPriceFilterDialogProps {
    isVisible: boolean;
    onClose: () => void;
    filterProps: IMaterialPriceFilterOptions;
    setFilterProps: (updatedFilters: IMaterialPriceFilterOptions) => void;
}

export const MaterialPriceFilterDialog: FC<IMaterialPriceFilterDialogProps> = (props) => {
    const { isVisible, onClose, filterProps, setFilterProps } = props;
    const [materialPriceFilters, setMaterialPriceFilters] = useState<IMaterialPriceFilterOptions>(filterProps);
    useEffect(() => {
        if (isVisible) {
            setMaterialPriceFilters(filterProps);
        }
    }, [filterProps, isVisible]);

    const isFormClean = filterProps.siteId === materialPriceFilters.siteId && filterProps.materialId === materialPriceFilters.materialId;

    const handleClose = () => {
        onClose();
    };

    const handleFilter = () => {
        setFilterProps({ ...materialPriceFilters });
        handleClose();
    };

    const handleClear = () => {
        setMaterialPriceFilters({
            siteId: emptyGuid,
            siteName: '',
            materialId: emptyGuid,
            materialName: '',
        });
    };

    const handleSiteChange = (selectedSite?: SiteDto) => {
        if (!selectedSite) {
            setMaterialPriceFilters({
                ...materialPriceFilters,
                siteId: emptyGuid,
                siteName: '',
            })
        } else {
            setMaterialPriceFilters({
                ...materialPriceFilters,
                siteId: selectedSite.id,
                siteName: selectedSite.code,
            })
        }
    };

    const handleMaterialChange = (selectedMaterial?: MaterialDto) => {
        if (!selectedMaterial) {
            setMaterialPriceFilters({
                ...materialPriceFilters,
                materialId: emptyGuid,
                materialName: '',
            });
        } else {
            setMaterialPriceFilters({
                ...materialPriceFilters,
                materialId: selectedMaterial.id,
                materialName: selectedMaterial.code,
            });
        }
    };

    return (
        <Dialog open={isVisible} onClose={handleClose} fullWidth maxWidth='md' PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography variant='h2'>
                    {<FilterList />} {'Filter Material Prices'}
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 3 }}>
                <Grid container direction='row' spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <MaterialSelect selectedMaterialId={materialPriceFilters.materialId} handleSelectedMaterialChange={handleMaterialChange} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <SiteSelect selectedSiteId={materialPriceFilters.siteId} handleSelectedSiteChange={handleSiteChange} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent={'space-between'}>
                    <Grid item>
                        <Button onClick={handleClear} size='large'>
                            Clear All
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={handleClose}>
                            {isFormClean ? 'Close' : 'Cancel'}
                        </Button>
                        <Button variant='contained' size='medium' onClick={handleFilter} sx={{ color: 'error', backgroundColor: 'primary' }}>
                            Filter
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};
