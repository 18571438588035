import { AddCircle } from "@mui/icons-material";
import { LineItemDto, MaterialDto, MaterialLineItemDto, SiteHaulingLineItemDto } from "../../../../dtos";
import { ConfirmDialog } from "../../../Core/ConfirmDialog";
import { useCallback, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { MaterialSelect } from "../../../CommonInputs";
import { useCreateMaterialLineItemMutation } from "../../../../store/generated/generatedApi";
import { useFailedActionSnackbar, useSuccessfulActionSnackbar } from "../../../CoreLib/library";

export interface IQuickAddMaterialLineItemDialogProps {
    open: boolean;
    onClose: () => void;
    onAdd: (material: MaterialLineItemDto) => void;
    siteHaulingLineItem: LineItemDto;
}

export const QuickAddMaterialLineItemDialog: React.FC<IQuickAddMaterialLineItemDialogProps> = ({ open, onClose, onAdd, siteHaulingLineItem }) => {
    const [material, setMaterial] = useState<MaterialDto>();

    const [createMaterialLineItem, { isSuccess, isError, reset }] = useCreateMaterialLineItemMutation();

    useSuccessfulActionSnackbar('created', 'material Line Item', isSuccess, reset);
    useFailedActionSnackbar('create', 'material Line Item', isError, reset);

    const handleOnConfirm = useCallback(() => {
        if (!material) {
            return;
        }
        const newMaterialLineItem = {
            additionalRate: undefined,
            driverMemo: '',
            cost: 0,
            equipmentTypeId: siteHaulingLineItem.equipmentTypeId,
            isActive: true,
            isBilledToCustomer: true,
            quantity: 1,
            materialId: material?.id,
            priceCode: 'Custom',
            quoteId: siteHaulingLineItem.quoteId,
            rate: 0,
            rateToggle: (siteHaulingLineItem as SiteHaulingLineItemDto).rateToggle,
            siteId: siteHaulingLineItem.siteId,
            unitOfMeasure: siteHaulingLineItem.unitOfMeasure,


        } as MaterialLineItemDto;

        createMaterialLineItem(newMaterialLineItem).then((response) => {
            if ((response as any).data) {
                onAdd((response as any).data);
            }
        });

    }, [createMaterialLineItem, material, onAdd, siteHaulingLineItem]);

    return (
        <ConfirmDialog
            open={open}
            onClose={onClose}
            content={
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Typography>Please select the Material to be hauled.</Typography>
                    </Grid>
                    <Grid item>
                        <MaterialSelect
                            selectedMaterialId={material?.id ?? ''}
                            handleSelectedMaterialChange={(selectedMaterial) => setMaterial(selectedMaterial)}
                            required
                        />
                    </Grid>
                </Grid>
            }
            title={'Add Haul Only Material Line Item'}
            titleIcon={<AddCircle />}
            onConfirm={handleOnConfirm}
            confirmText='Confirm'
            requireConfirmation={false}
        />

    );
};
