import _ from 'lodash';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { EquipmentTypeDto, MiscLineItemDto, QuoteDto, SiteDto } from '../../../../../dtos';
import { emptyGuid } from '../../../../../util';
import {
    IFormFieldValidationConfig,
    IFormProps,
    isGreaterThanOrEqualTo,
    isNotBlank,
    isShorterThanMaxLength,
    runFieldValidation,
    runFormValidation,
} from '../../../../CoreLib/library';

export interface IMiscLineItemFormValues {
    quoteId: string;
    equipmentTypeId: string;
    siteId?: string;
    cost: number;
    rate: number;
    quantity?: number;
    description: string;
    driverMemo?: string;
    unitOfMeasure?: string;
    isActive: boolean;
}

export const DEFAULT_MISC_ITEM: MiscLineItemDto = {
    id: emptyGuid,
    quoteId: '',
    equipmentTypeId: emptyGuid,
    cost: 0,
    rate: 0,
    quantity: 0,
    description: '',
    driverMemo: '',
    unitOfMeasure: '',
    isActive: true,
    createdOn: new Date(),
};

export function useMiscLineItemForm(props: IFormProps<MiscLineItemDto>) {
    const { save, cancel, initValues } = props;
    const [formQuote, setFormQuote] = useState<QuoteDto>();
    const [formEquipmentType, setFormEquipmentType] = useState<EquipmentTypeDto>();
    const [formSite, setFormSite] = useState(initValues?.site);
    const [formCost, setFormCost] = useState<number>();
    const [formRate, setFormRate] = useState<number>();
    const [formQuantity, setFormQuantity] = useState<number | undefined>(initValues?.quantity);
    const [formDescription, setFormDescription] = useState('');
    const [formDriverMemo, setFormDriverMemo] = useState<string | undefined>(initValues?.driverMemo);
    const [formUnitOfMeasure, setFormUnitOfMeasure] = useState<string>(initValues?.unitOfMeasure ?? '');
    const [formIsActive, setFormIsActive] = useState(true);

    useEffect(() => {
        setFormQuote(initValues?.quote);
        setFormEquipmentType(initValues?.equipmentType);
        setFormSite(initValues?.site);
        setFormCost(initValues?.cost ?? 0);
        setFormRate(initValues?.rate ?? 0);
        setFormQuantity(initValues?.quantity);
        setFormDescription(initValues?.description ?? '');
        setFormIsActive(initValues?.isActive ?? true);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IMiscLineItemFormValues, string>>(
        new Map([
            ['quoteId', ''],
            ['equipmentTypeId', ''],
            ['siteId', ''],
            ['cost', ''],
            ['rate', ''],
            ['quantity', ''],
            ['description', ''],
            ['driverMemo', ''],
            ['unitOfMeasure', ''],
        ])
    );

    const formFieldValidators = new Map<keyof IMiscLineItemFormValues, IFormFieldValidationConfig>([
        [
            'quoteId',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Quote',
            },
        ],
        [
            'equipmentTypeId',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Equipment Type',
            },
        ],
        [
            'cost',
            {
                validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                errorMessageEntityName: 'Cost',
            },
        ],
        [
            'rate',
            {
                validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                errorMessageEntityName: 'Rate',
            },
        ],
        [
            'quantity',
            {
                validators: [isGreaterThanOrEqualTo(0)],
                errorMessageEntityName: 'Quantity',
            },
        ],
        [
            'description',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Description',
            },
        ],
        [
            'unitOfMeasure',
            {
                validators: [isShorterThanMaxLength(20)],
                errorMessageEntityName: 'Unit Of Measure',
            },
        ],
    ]);

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IMiscLineItemFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    const getCurrentFormValues = (): IMiscLineItemFormValues => {
        return {
            quoteId: formQuote?.id ?? emptyGuid,
            equipmentTypeId: formEquipmentType?.id ?? emptyGuid,
            siteId: formSite?.id,
            cost: formCost ?? 0,
            rate: formRate ?? 0,
            quantity: formQuantity,
            description: formDescription ?? '',
            driverMemo: formDriverMemo ?? '',
            unitOfMeasure: formUnitOfMeasure ?? '',
            isActive: formIsActive,
        };
    };

    const validateField = (fieldName: keyof IMiscLineItemFormValues) => {
        const validationConfig = formFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = getCurrentFormValues();
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    };

    const isFormDirty = () => {
        var isDirty = formIsActive !== initValues?.isActive;
        isDirty = isDirty || formQuote?.id !== initValues?.quote?.id;
        isDirty = isDirty || formEquipmentType?.id !== initValues?.equipmentType?.id;
        isDirty = isDirty || formSite?.id !== initValues?.site?.id;
        isDirty = isDirty || formRate !== initValues?.rate;
        isDirty = isDirty || formCost !== initValues?.cost;
        isDirty = isDirty || formQuantity !== initValues?.quantity;
        isDirty = isDirty || formDescription !== initValues?.description;
        isDirty = isDirty || formUnitOfMeasure !== initValues?.unitOfMeasure;
        isDirty = isDirty || formDriverMemo !== initValues?.driverMemo;

        return isDirty;
    };

    const handleQuoteChange = (quote: QuoteDto) => {
        setFormQuote(quote);
    };

    const handleEquipmentTypeChange = (equipmentType?: EquipmentTypeDto) => {
        setFormEquipmentType(equipmentType);
    };

    const handleSiteChange = (site?: SiteDto) => {
        setFormSite(site);
    };

    const handleRateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleCostChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormCost(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormQuantity(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
    };

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormDescription(event.target.value);
    };

    const handleDriverMemoChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormDriverMemo(event.target.value ?? '');
    };

    const handleUnitOfMeasureChange = (value?: string) => {
        setFormUnitOfMeasure(value ?? '');
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
    };

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_MISC_ITEM),
                ...formValues,
            };
            save(updatedUserRecord);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    const formTotal = useMemo(() => {
        return (formRate ?? 0) * (formQuantity ?? 0);
    }, [formRate, formQuantity]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleEquipmentTypeChange,
        handleSiteChange,
        handleRateChange,
        handleCostChange,
        handleQuantityChange,
        handleDescriptionChange,
        handleDriverMemoChange,
        handleUnitOfMeasureChange,
        handleIsActiveChange,
        formQuote,
        formEquipmentType,
        formSite,
        formRate,
        formCost,
        formQuantity,
        formDescription,
        formDriverMemo,
        formUnitOfMeasure,
        formIsActive,
        validateField,
        initValues,
        formTotal
    };
}
