import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderReviewDto } from '../../dtos';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateOrderReviewMutation, useGetOrderOrderReviewQuery } from '../../store/generated/generatedApi';
import { OrderReviewForm } from '../../Components/Forms/OrderReviewForm';
import { format } from 'date-fns';

export const AddOrderReviewView: FC = () => {
    const { jobId, orderId } = useParams();
    const navigate = useNavigate();
    const [createOrderReview, { data, isError, isLoading: isCreating, isSuccess, reset }] = useCreateOrderReviewMutation();
    const { data: order } = useGetOrderOrderReviewQuery({ id: orderId! });

    useSuccessfulCreateSnackbar('Order Review', isSuccess, () => {
        navigate(`/job/${jobId}/order/${data?.orderId}/orderReview/${data?.id}`)
    });
    useFailedCreateSnackbar('order Review', isError, reset)

    const handleSave = (values: OrderReviewDto) => {
        createOrderReview(values);
    };

    const handleCancel = () => {
        if (order) {
            navigate(`/orderReview?date=${format(order.orderDate, 'yyyy-MM-dd')}`);
        }
    };

    return <OrderReviewForm save={handleSave} cancel={handleCancel} jobId={jobId ?? ''} orderId={orderId ?? ''} isLoading={isCreating} />;
};
