import { AttachMoney } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { MiscLineItemDto, OrderDto, QuoteDto } from '../../../../../dtos';
import { useArchiveMiscLineItemDeleteFromDatabaseByIdMutation } from '../../../../../store/generated/generatedApi';
import { LineItemStatus, emptyGuid } from '../../../../../util';
import { EquipmentTypeSelect, SiteSelect, UnitOfMeasureSelect } from '../../../../CommonInputs';
import { SlimFormHeader } from '../../../../Core/SlimFormHeader';
import { FormInput, FormNumberInput, FormSection, IFormProps, LoadingIndicator } from '../../../../CoreLib/library';
import { useMiscLineItemForm } from './useMiscLineItemForm';

export interface IMiscLineItemFormProps extends IFormProps<MiscLineItemDto> {
    quote?: QuoteDto;
    order?: OrderDto;
}

export const MiscLineItemForm: FC<IMiscLineItemFormProps> = (props) => {
    const { isLoading, quote, order } = props;
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleQuoteChange,
        handleEquipmentTypeChange,
        handleSiteChange,
        handleRateChange,
        handleCostChange,
        handleQuantityChange,
        handleDescriptionChange,
        handleDriverMemoChange,
        handleUnitOfMeasureChange,
        formQuote,
        formEquipmentType,
        formSite,
        formRate,
        formCost,
        formQuantity,
        formDescription,
        formDriverMemo,
        formUnitOfMeasure,
        formIsActive,
        initValues,
        formTotal
    } = useMiscLineItemForm(props);

    const isLocked = useMemo(
        () => initValues?.id !== emptyGuid && !!quote?.acceptedOn && initValues?.status !== LineItemStatus.Amended,
        [initValues?.id, initValues?.status, quote?.acceptedOn]
    );

    useEffect(() => {
        if (quote && !formQuote) {
            handleQuoteChange(quote);
        }
    }, [formQuote, handleQuoteChange, quote]);

    const currentMiscLineItemId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column'>
            <SlimFormHeader
                objectName='Misc Line Item'
                permissionsTypeName={'lineItem'}
                icon={<AttachMoney />}
                title={formQuote ? `Misc. for Quote #${formQuote.quoteNumber}` : 'New Misc. Line Item'}
                breadcrumbs={
                    order
                        ? [
                              { label: 'Home', navLink: '/' },
                              { label: 'Jobs', navLink: '/jobs' },
                              { label: `Job #${quote?.quoteNumber}`, navLink: `/job/${order?.jobId}` },
                              { label: `Order #${order?.orderNumber}`, navLink: `/job/${order?.jobId}/order/${order?.id}` },
                          ]
                        : [
                              { label: 'Home', navLink: '/' },
                              { label: 'Quotes', navLink: '/quotes' },
                              { label: `${quote?.customer?.name ?? ''} (Quote #${quote?.quoteNumber ?? 'Quote'})`, navLink: `/quote/${quote?.id}` },
                              { label: `Line Items`, navLink: `/quote/${quote?.id}/lineItems` },
                          ]
                }
                isActive={formIsActive}
                id={currentMiscLineItemId}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'misc line item'}
                deleteMutation={useArchiveMiscLineItemDeleteFromDatabaseByIdMutation}
                canDelete={!isLocked}
                canSave={!isLocked}
            />
            <Grid item mx={2}>
                <FormSection>
                    <Grid item>
                        <Typography variant='h2'>General</Typography>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
                            <EquipmentTypeSelect
                                selectedEquipmentTypeId={formEquipmentType?.id ?? ''}
                                handleSelectedEquipmentTypeChange={handleEquipmentTypeChange}
                                required
                                errorMessage={fieldErrors.get('equipmentTypeId')}
                                disabled={isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
                            <FormInput
                                value={formDescription}
                                onChange={handleDescriptionChange}
                                label='Description'
                                name='description'
                                errorText={fieldErrors.get('description')}
                                fullWidth
                                required
                                disabled={isLocked}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant='h2'>Pricing</Typography>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <UnitOfMeasureSelect
                                selectedUnit={formUnitOfMeasure ?? ''}
                                handleSelectedUnitChange={handleUnitOfMeasureChange}
                                errorMessage={fieldErrors.get('unitOfMeasure')}
                                disabled={isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={2}>
                            <FormNumberInput
                                value={formCost}
                                onChange={handleCostChange}
                                label='Cost'
                                name='cost'
                                errorText={fieldErrors.get('cost')}
                                error={!!fieldErrors.get('cost')}
                                fullWidth
                                required
                                disabled={isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <FormNumberInput
                                value={formQuantity ?? ''}
                                onChange={handleQuantityChange}
                                label='Quantity'
                                name='quantity'
                                errorText={fieldErrors.get('quantity')}
                                error={!!fieldErrors.get('quantity')}
                                fullWidth
                                disabled={isLoading || isLocked}
                                inputProps={{ min: 0 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                            <FormNumberInput
                                value={formRate}
                                onChange={handleRateChange}
                                label='Rate'
                                name='Rate'
                                errorText={fieldErrors.get('rate')}
                                error={!!fieldErrors.get('rate')}
                                fullWidth
                                required
                                disabled={isLocked}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                            <FormNumberInput value={formTotal ?? ''} onChange={() => {}} label='Total' name='total' fullWidth disabled isCurrency />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant='h2'>Hauling</Typography>
                    </Grid>
                    <Grid item container direction='row' spacing={3}>
                        <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
                            <SiteSelect
                                selectedSiteId={formSite?.id ?? ''}
                                handleSelectedSiteChange={handleSiteChange}
                                errorMessage={fieldErrors.get('siteId')}
                                disabled={isLocked}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput value={formDriverMemo ?? ''} onChange={handleDriverMemoChange} label='Driver Memo' name='driverMemo' fullWidth rows={3} />
                        </Grid>
                    </Grid>
                </FormSection>
            </Grid>
        </Grid>
    );
};
