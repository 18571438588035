import { Group } from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { FC, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { ContactsListView } from "../../../Views/Contact/ContactsListView";
import { CustomerDto } from "../../../dtos";
import { useArchiveCustomerDeleteByIdMutation } from "../../../store/generated/generatedApi";
import { SlimFormHeader } from "../../Core/SlimFormHeader";
import { FormSection, IFormProps, LoadingIndicator } from "../../CoreLib/library";
import { useCustomerForm } from "./useCustomerForm";
import { CustomerForm } from "./CustomerForm";

export const CustomerFullPageForm: FC<IFormProps<CustomerDto>> = (props) => {
    const { isLoading, initValues } = props;
    const { tab } = useParams();
    const [selectedTab, setSelectedTab] = useState(tab ?? '');

    const customerFormManager = useCustomerForm(props);
    const {
        isFormDirty,
        handleSave,
        handleCancel,
        formIsActive,
        formName,
        handleIsActiveChange
    } = customerFormManager;

    const displayName = useMemo(() => {
        return (formName).trim();
    }, [formName]);

    const currentId = useMemo(() => {
        return props.initValues?.id;
    }, [props.initValues?.id]);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container direction='column'>
            <SlimFormHeader
                objectName='Customer'
                permissionsTypeName={'customer'}
                icon={<Group />}
                title={displayName}
                breadcrumbs={[
                    { label: 'Home', navLink: '/' },
                    { label: 'Customers', navLink: '/customers' },
                ]}
                isActive={formIsActive}
                handleIsActiveChange={handleIsActiveChange}
                id={currentId}
                isLoading={isLoading}
                isFormDirty={isFormDirty}
                handleCancel={handleCancel}
                handleSave={handleSave}
                entityNameSingular={'customer'}
                deleteMutation={useArchiveCustomerDeleteByIdMutation}
            />
            <Grid item mx={2}>
                <FormSection>
                    <TabContext value={selectedTab}>
                        <Box sx={{ borderBottom: '1px solid black' }}>
                            <Tabs
                                value={selectedTab}
                                onChange={(e, value) => setSelectedTab(value)}
                                variant='fullWidth'
                                textColor='primary'
                                indicatorColor='primary'
                            >
                                <Tab label={'CUSTOMER'} key={0} value={''} />
                                {initValues?.id !== undefined ? <Tab label={'CONTACTS'} key={1} value={'contacts'} /> : <></>}
                            </Tabs>
                        </Box>
                        <TabPanel value={''}>
                        <CustomerForm customerFormManager={customerFormManager} />
                        </TabPanel>
                        <TabPanel value={'contacts'}>
                            <ContactsListView />
                        </TabPanel>
                    </TabContext>
                </FormSection>
            </Grid>
        </Grid>
    );
}