import { FormHelperText, FormLabel, Grid, MenuItem, Select, FormControl } from "@mui/material";
import { FC } from "react";
import { AddressLookup } from "./AddressLookup";
import { FormInput } from "../CoreLib/library";
import { usStates, countries } from "../../util";

export interface IAddressFormProps {
    handleAddressLine1Change?: (value: string) => void;
    handleAddressLine2Change?: (value: string) => void;
    handleCountryChange?: (value: string) => void;
    handleCityChange?: (value: string) => void;
    handleStateChange?: (value: string) => void;
    handleZipCodeChange?: (value: string) => void;
    handleAddressChange?: (address: { addressLine1: string, addressLine2?: string, country: string, city: string, state: string, zipCode: string }) => void;
    enableLookup?: boolean;
    disabled?: boolean;
    addressLine1: string;
    addressLine2?: string;
    country: string;
    city: string;
    state: string;
    zipCode: string;
    fieldErrors?: any;
    formDirection?: 'row' | 'column';
    allOptional?: boolean;
    enableAddressLine2?: boolean;
};

export const AddressForm: FC<IAddressFormProps> = props => {
    const {
        handleAddressLine1Change,
        handleAddressLine2Change,
        handleCityChange,
        handleCountryChange,
        handleStateChange,
        handleZipCodeChange,
        handleAddressChange,
        enableLookup = false,
        disabled,
        addressLine1,
        addressLine2,
        country,
        city,
        state,
        zipCode,
        fieldErrors,
        formDirection = 'row',
        allOptional = false,
        enableAddressLine2,
    } = props;



    return (
        <Grid item container direction='column' spacing={3}>
            {enableLookup && <Grid item container direction='row' spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <AddressLookup
                        handleAddressLine1Change={handleAddressLine1Change}
                        handleCityChange={handleCityChange}
                        handleCountryChange={handleCountryChange}
                        handleStateChange={handleStateChange}
                        handleZipCodeChange={handleZipCodeChange}
                        handleAddressChange={handleAddressChange}
                    />
                </Grid>
            </Grid>}
            <Grid item container direction={formDirection} spacing={formDirection === 'row' ? 2 : 1} wrap="nowrap">
                <Grid item xs={12} sm={6} md={2}>
                    <FormInput
                        value={addressLine1}
                        onChange={(event) => {
                            handleAddressLine1Change?.(event.target.value);
                            handleAddressChange?.({ addressLine1: event.target.value, addressLine2, city, country, state, zipCode });
                        }}
                        label='Address Line 1'
                        name='addressLine1'
                        errorText={fieldErrors?.get('addressLine1')}
                        fullWidth
                        required={!allOptional}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormInput
                        value={addressLine2}
                        onChange={(event) => {
                            handleAddressLine2Change?.(event.target.value);
                            handleAddressChange?.({ addressLine1, addressLine2: event.target.value, city, country, state, zipCode });
                        }}
                        label='Address Line 2'
                        name='addressLine2'
                        fullWidth
                        disabled={disabled && !enableAddressLine2}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormInput
                        value={city}
                        onChange={(event) => {
                            handleCityChange?.(event.target.value);
                            handleAddressChange?.({ addressLine1, addressLine2, city: event.target.value, country, state, zipCode });
                        }}
                        label='City'
                        name='city'
                        errorText={fieldErrors?.get('city')}
                        fullWidth
                        required={!allOptional}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormControl
                        error={!!fieldErrors?.get('state')}
                        fullWidth
                        disabled={disabled}
                        required={!allOptional}>
                        <FormLabel>State</FormLabel>
                        <Select
                            value={state}
                            disabled={disabled}
                            onChange={(event) => {
                                handleStateChange?.(event.target.value);
                                handleAddressChange?.({ addressLine1, addressLine2, city, country, state: event.target.value, zipCode });
                            }}
                            >
                            {usStates.map((usState) => (
                                <MenuItem key={usState.abbreviation} value={usState.abbreviation}>
                                    {usState.name}
                                </MenuItem>
                            ))}
                            <MenuItem value='' key='noState'>
                                -
                            </MenuItem>
                        </Select>
                        <FormHelperText>{fieldErrors?.get('state')}</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormInput
                        value={zipCode}
                        onChange={(event) => {
                            handleZipCodeChange?.(event.target.value);
                            handleAddressChange?.({ addressLine1, addressLine2, city, country, state, zipCode: event.target.value });
                        }}
                        label='Zip Code'
                        name='zipCode'
                        errorText={fieldErrors?.get('zipCode')}
                        fullWidth
                        required={!allOptional}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <FormControl error={!!fieldErrors?.get('country')} fullWidth required={!allOptional}
                        disabled={disabled}
                    >
                        <FormLabel>Country</FormLabel>
                        <Select
                            value={country}
                            disabled={disabled}
                            onChange={(event) => {
                                handleCountryChange?.(event.target.value);
                                handleAddressChange?.({ addressLine1, addressLine2, city, country: event.target.value, state, zipCode });
                            }}>
                            {countries.map((country) => (
                                <MenuItem key={country} value={country}>
                                    {country}
                                </MenuItem>
                            ))}
                            <MenuItem key='noCountry' value=''>
                                -
                            </MenuItem>
                        </Select>
                        <FormHelperText>{fieldErrors?.get('country')}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        </Grid>
    );
};