import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { IncomeCodeDto, MaterialDto, MaterialPriceCostDto, MaterialPriceDto, SiteDto } from '../../../dtos';
import { areDatesEqual, emptyGuid } from '../../../util';
import { FormValidationMethod, IFormFieldValidationConfig, IFormProps, isNotBlank, runFieldValidation, runFormValidation } from '../../CoreLib/library';

export type UnitOfMeasure = 'Tons' | 'Loads' | 'Yards';

export interface IMaterialPriceFormValues {
    materialId: string;
    material?: MaterialDto;
    siteId: string;
    site?: SiteDto;
    incomeCodeId: string;
    incomeCode?: IncomeCodeDto;
    clientId?: string;
    materialPriceCosts: MaterialPriceCostDto[];
    isActive: boolean;
}

export const DEFAULT_MATERIAL_PRICE: MaterialPriceDto = {
    id: emptyGuid,
    materialId: emptyGuid,
    siteId: emptyGuid,
    incomeCodeId: emptyGuid,
    materialPriceCosts: [],
    isActive: true,
    createdOn: new Date(),
};

export const DEFAULT_MATERIAL_PRICE_COST: MaterialPriceCostDto = {
    id: emptyGuid,
    materialPriceId: emptyGuid,
    cost: undefined,
    priceA: undefined,
    priceB: undefined,
    priceC: undefined,
    priceD: undefined,
    priceE: undefined,
    unitOfMeasure: '',
    effectiveOn: new Date(),
    canDelete: true,
    isActive: true,
    createdOn: new Date(),
};

export function useMaterialPriceForm(props: IFormProps<MaterialPriceDto>) {
    const { save, cancel, initValues } = props;
    const [formMaterial, setFormMaterial] = useState(initValues?.material);
    const [formSite, setFormSite] = useState(initValues?.site);
    const [formIncomeCode, setFormIncomeCode] = useState(initValues?.incomeCode);
    const [formMaterialPriceCosts, setFormMaterialPriceCosts] = useState<MaterialPriceCostDto[]>([
        { ...DEFAULT_MATERIAL_PRICE_COST, unitOfMeasure: initValues?.id === emptyGuid ? initValues?.material?.defaultUnitOfMeasure ?? '' : '' },
    ]);
    const [formIsActive, setFormIsActive] = useState(initValues?.isActive ?? true);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setFormMaterial(initValues?.material);
        setFormSite(initValues?.site);
        setFormIncomeCode(initValues?.incomeCode);
        setFormMaterialPriceCosts(
            initValues?.materialPriceCosts ?? [
                { ...DEFAULT_MATERIAL_PRICE_COST, unitOfMeasure: initValues?.id === emptyGuid ? initValues?.material?.defaultUnitOfMeasure ?? '' : '' },
            ]
        );
        setFormIsActive(initValues?.isActive ?? true);
    }, [initValues]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IMaterialPriceFormValues, string>>(
        new Map([
            ['material', ''],
            ['site', ''],
            ['incomeCode', ''],
            ['materialPriceCosts', ''],
        ])
    );

    function isMaterialPriceCostsValid(): FormValidationMethod {
        return (value: MaterialPriceCostDto[]) => {
            let isValid = true;
            let errors = {};
            value.forEach((materialCost, index) => {
                let fieldErrors = [] as string[];
                let errorMessage = '';
                let hasMissingValueError = false;

                if (materialCost.cost === undefined || Number.isNaN(materialCost.cost)) {
                    fieldErrors.push('cost');
                    hasMissingValueError = true;
                }
                if (materialCost.priceA === undefined || Number.isNaN(materialCost.priceA)) {
                    fieldErrors.push('priceA');
                    hasMissingValueError = true;
                }
                if (materialCost.priceB === undefined || Number.isNaN(materialCost.priceB)) {
                    fieldErrors.push('priceB');
                    hasMissingValueError = true;
                }
                if (materialCost.priceC === undefined || Number.isNaN(materialCost.priceC)) {
                    fieldErrors.push('priceC');
                    hasMissingValueError = true;
                }
                if (materialCost.unitOfMeasure === '') {
                    fieldErrors.push('unitOfMeasure');
                    hasMissingValueError = true;
                }
                if (materialCost.effectiveOn === undefined || materialCost.effectiveOn === null) {
                    fieldErrors.push('effectiveOn');
                    hasMissingValueError = true;
                }

                if (hasMissingValueError) {
                    errorMessage += 'A required field is missing data. ';
                }

                const otherRows = [...value];
                otherRows.splice(index, 1);

                if (_.some(otherRows, (x) => areDatesEqual(new Date(materialCost.effectiveOn!), new Date(x.effectiveOn!)))) {
                    if (!fieldErrors.some((x) => x === 'effectiveOn')) {
                        fieldErrors.push('effectiveOn');
                    }
                    errorMessage += 'Prices may not be made effective on the same date. ';
                }
                if (fieldErrors.length > 0) {
                    isValid = false;
                }
                errors[index] = {
                    fieldErrors,
                    errorMessage,
                };
            });
            const errorMessageBuilder = (fieldName: string) => JSON.stringify(errors);
            return {
                isValid,
                errorMessageBuilder,
            };
        };
    }

    const [formFieldValidators] = useState(
        new Map<keyof IMaterialPriceFormValues, IFormFieldValidationConfig>([
            [
                'materialId',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Material',
                },
            ],
            [
                'siteId',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Site',
                },
            ],
            [
                'incomeCodeId',
                {
                    validators: [isNotBlank],
                    errorMessageEntityName: 'Income Code',
                },
            ],
            [
                'materialPriceCosts',
                {
                    validators: [isMaterialPriceCostsValid()],
                    errorMessageEntityName: 'Material Price Costs',
                },
            ],
        ])
    );

    const getCurrentFormValues = useCallback((): IMaterialPriceFormValues => {
        return {
            materialId: formMaterial?.id ?? emptyGuid,
            siteId: formSite?.id ?? emptyGuid,
            incomeCodeId: formIncomeCode?.id ?? emptyGuid,
            materialPriceCosts: formMaterialPriceCosts,
            isActive: formIsActive,
        };
    }, [formIncomeCode?.id, formIsActive, formMaterial?.id, formMaterialPriceCosts, formSite?.id]);

    const validateForm = useCallback(() => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IMaterialPriceFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    }, [formFieldValidators, getCurrentFormValues]);

    const validateField = useCallback(
        (fieldName: keyof IMaterialPriceFormValues) => {
            const validationConfig = formFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = getCurrentFormValues();
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== fieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(fieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setFieldErrors(updatedFieldErrors);
                }
            }
        },
        [fieldErrors, formFieldValidators, getCurrentFormValues]
    );

    const isFormDirty = useCallback(() => {
        return isChanged;
    }, [isChanged]);

    const handleMaterialChange = useCallback((value?: MaterialDto) => {
        setFormMaterial(value);
        setIsChanged(true);
    }, []);

    const handleSiteChange = useCallback((value?: SiteDto) => {
        setFormSite(value);
        setIsChanged(true);
    }, []);

    const handleIncomeCodeChange = useCallback((value?: IncomeCodeDto) => {
        setFormIncomeCode(value);
        setIsChanged(true);
    }, []);

    const handleMaterialPriceCostsChange = useCallback((value?: MaterialPriceCostDto[]) => {
        setFormMaterialPriceCosts(value ?? []);
        setIsChanged(true);
    }, []);

    const handleIsActiveChange = useCallback((_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    }, []);

    const handleSave = useCallback(() => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const updatedUserRecord = {
                ...(initValues ?? DEFAULT_MATERIAL_PRICE),
                ...formValues,
            };
            save(updatedUserRecord);
            setIsChanged(false);
        }
    }, [getCurrentFormValues, initValues, save, validateForm]);

    const handleCancel = useCallback(() => {
        cancel();
        setIsChanged(false);
    }, [cancel]);

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleMaterialChange,
        handleSiteChange,
        handleIncomeCodeChange,
        handleMaterialPriceCostsChange,
        handleIsActiveChange,
        formMaterial,
        formSite,
        formIncomeCode,
        formMaterialPriceCosts,
        formIsActive,
        validateField,
        getCurrentFormValues,
    };
}
