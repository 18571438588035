import { AddCircle, ListAlt } from '@mui/icons-material';
import { Card, Grid, Typography, Box, IconButton, Tooltip } from '@mui/material';
import { FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedComponent } from '../../../Components/CoreLib/library';

export interface IDataManagementCardProps {
    icon: ReactElement;
    listViewRoute: string;
    addNewRoute?: string;
    title: string;
    description: string;
    permissionName: string;
}

export const DataManagementCard: FC<IDataManagementCardProps> = (props) => {
    const { icon, listViewRoute, addNewRoute, title, description, permissionName } = props;
    const navigate = useNavigate();

    const goToListView = () => {
        navigate(listViewRoute);
    };

    const goToAddView = () => {
        if (addNewRoute) {
            navigate(addNewRoute);
        }
    };

    return (
        <Card sx={{ width: '100%', height: 196, cursor: 'pointer' }} className='hover-content-container' onClick={goToListView}>
            <Grid item container direction='column' justifyContent='space-between' height={1} spacing={2} p={1}>
                <Grid item container direction='row' alignItems='center'>
                    <Grid item width={60} height={60} sx={{ fontSize: 60, color: '#707070' }}>
                        {icon}
                    </Grid>
                    <Grid item pl={1} width={`calc(100% - 72px)`} overflow='hidden'>
                        <Typography fontSize={24} fontWeight='bold' textOverflow='ellipsis' noWrap>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' flexGrow={1} p={1}>
                    <Grid item xs={12} maxHeight={48}>
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                                textAlign: 'center'
                            }}>
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item container direction='row' justifyContent='center' px={1} pb={1}>
                    <Grid item className='hover-content' height={24} sx={{ display: 'flex', alignItems: 'end' }}>
                        <Box>
                            <Tooltip title='View All'>
                                <IconButton
                                    size='small'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        goToListView();
                                    }}>
                                    <ListAlt />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {addNewRoute && <Box>
                            <AuthenticatedComponent
                                requiredPermissions={[`create:${permissionName}`]}
                                children={
                                    <Tooltip title='Add New'>
                                        <IconButton
                                            size='small'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                goToAddView();
                                            }}>
                                            <AddCircle />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                        </Box>}
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};
