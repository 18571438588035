import { Delete } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import { FC } from 'react';

export interface IModalSaveCloseFooter {
    isFormDirty?: boolean;
    onCancelCloseClicked: () => void;
    onSaveClicked: () => void;
    onDeleteClicked?: () => void;
    isSaveDisabled?: boolean;
}

export const ModalSaveCloseFooter: FC<IModalSaveCloseFooter> = (props) => {
    const { onCancelCloseClicked, onSaveClicked, isFormDirty = true, onDeleteClicked, isSaveDisabled = false } = props;

    return (
        <Grid
            item
            xs={12}
            width='calc(100% - 223px)'
            display='flex'
            justifyContent='end'
            gap={2}
            position='absolute'
            bottom={0}
            right={15}
            pb={1}
            sx={{ background: 'white' }}>
            {onDeleteClicked && (
                <IconButton onClick={onDeleteClicked}>
                    <Delete />
                </IconButton>
            )}
            <Button variant='outlined' color='primary' size='small' onClick={onCancelCloseClicked} sx={{ width: 90 }}>
                {isFormDirty ? 'Cancel' : 'Close'}
            </Button>
            <Button variant='contained' color='primary' size='small' onClick={onSaveClicked} sx={{ width: 90 }} disabled={isSaveDisabled}>
                Save
            </Button>
        </Grid>
    );
};
