import { FC, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../../Components/CoreLib/library';
import { DumpRateForm } from '../../../Components/Forms/DumpRateForm';
import { DumpRateDto } from '../../../dtos';
import { useGetDumpRateByIdQuery, useUpdateDumpRateMutation } from '../../../store/generated/generatedApi';
import { emptyGuid } from '../../../util';


export const EditDumpRateView: FC = () => {
    const { siteId, dumpRateId } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetDumpRateByIdQuery({ id: dumpRateId! });
    const [updateDumpRate, { isSuccess, isError, reset }] = useUpdateDumpRateMutation();

    useSuccessfulUpdateSnackbar('Dump Rate', isSuccess, reset);
    useFailedUpdateSnackbar('dump rate', isError, reset);

    const redirectUrl = useMemo(() => `/dataManagement/site/${siteId}/dumpRates`, [siteId]);

    const handleSave = (values: DumpRateDto) => {
        updateDumpRate(values);
    };

    const handleCancel = () => {
        navigate(redirectUrl);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <DumpRateForm save={handleSave} cancel={handleCancel} initValues={data} siteId={data?.siteId ?? emptyGuid} siteName={data?.site?.name ?? ''} />;
};
