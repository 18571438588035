import { EventModel } from '@bryntum/scheduler';

export default class DispatchEventModel extends EventModel {
    static get $name() {
        return 'DispatchEventModel';
    }

    static get fields() {
        return [
            'brokerId',
            'broker',
            'clientId',
            'driverId',
            'driver',
            'endDate',
            'equipmentId',
            'equipment',
            'id',
            'name',
            'orderLineItemId',
            'orderLineItem',
            'startDate',
            'onsiteTime',
            'yardTime',
            'released',
            'addressLine1',
            'addressLine2',
            'country',
            'city',
            'state',
            'zipCode',
            'isScheduled',
            'isOrderInReview',
            { name: 'lineItemNumber', dataSource: 'orderLineItem.orderLineItemNumber' },
            { name: 'jobNumber', dataSource: 'orderLineItem.jobNumber' },
            { name: 'orderNumber', dataSource: 'orderLineItem.orderNumber' },
            { name: 'description', dataSource: 'orderLineItem.lineItem.description' },
            { name: 'equipmentNeeded', dataSource: 'orderLineItem.equipmentRequired' },
            { name: 'arrivalTime', dataSource: 'orderLineItem.arrivalTime' },
        ];
    }

    static get defaults() {
        return {
            durationUnit: 'h',

            name: 'New event',
        };
    }

    getDurationMS = () => {
        return this.durationMS;
    };
}
