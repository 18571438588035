import { Edit, RemoveCircle } from '@mui/icons-material';
import { Box, FormControl, FormLabel, Grid, IconButton, OutlinedInput, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { FreightBillingLineItemDto, OrderDto, OrderReviewLineItemDto } from '../../../dtos';
import { LineItemTypes, QuoteType, formatCurrency } from '../../../util';
import { FormInput, FormNumberInput } from '../../CoreLib/library';
import { BillingRateFormDialog } from '../BillingRateForm/BillingRatesFormDialog';
import { useCreateBillingRatesFreightLineItemBillingRatesMutation } from '../../../store/generated/generatedApi';
import { PricingMethodTypeSelect } from '../../CommonInputs';

export interface IFreightBillingTableProps {
    disabled?: boolean;
    dispatchReviewIndex: number;
    orderReviewLineItem: OrderReviewLineItemDto;
    order: OrderDto;
    freightBillingLineItems: FreightBillingLineItemDto[];
    lineItemIndex?: number;
    onRemoveClicked: (index: number) => void;
    setFreightBillingLineItems: (lineItemIndex: number, dispatchReviewIndex: number, freightBillingLineItems: FreightBillingLineItemDto[]) => void;
    freightErrors: Map<number, Map<keyof FreightBillingLineItemDto, string>>;
}

export const FreightBillingLineItemTable: React.FC<IFreightBillingTableProps> = (props) => {
    const {
        disabled,
        dispatchReviewIndex,
        orderReviewLineItem,
        order,
        freightBillingLineItems,
        lineItemIndex,
        onRemoveClicked,
        setFreightBillingLineItems,
        freightErrors,
    } = props;

    const [isBillingRatesFormDialogOpen, setIsBillingRatesFormDialogOpen] = useState<number | undefined>(undefined);
    const [getBillingRates] = useCreateBillingRatesFreightLineItemBillingRatesMutation();

    const calculateBillingRates = useCallback((billingRates: FreightBillingLineItemDto): Promise<FreightBillingLineItemDto> => {
        return getBillingRates(billingRates).then((response) => {
            return (response as any).data as FreightBillingLineItemDto;
        });
    }, [getBillingRates]);

    const handleFreightBillingLineItemChange = useCallback(
        async (index: number, key: string, value: any) => {
            var updatedFreightBillingLineItems = _.cloneDeep(freightBillingLineItems);
            var updatedFreightBillingLineItem = { ...updatedFreightBillingLineItems[index] };

            updatedFreightBillingLineItem[key] = value;

            let billingRates = updatedFreightBillingLineItem;
            if (key === 'quantity' || key === 'pricingMethodType') {
                billingRates = await calculateBillingRates(updatedFreightBillingLineItem);
            }

            updatedFreightBillingLineItems.splice(index, 1, { ...billingRates });

            setFreightBillingLineItems(lineItemIndex ?? 0, dispatchReviewIndex, updatedFreightBillingLineItems);
        },
        [calculateBillingRates, dispatchReviewIndex, freightBillingLineItems, lineItemIndex, setFreightBillingLineItems]
    );

    const handleFullFreightBillingLineItemChange = useCallback(
        (index: number, freightBillingLineItem: FreightBillingLineItemDto) => {
            var updatedFreightBillingLineItems = _.cloneDeep(freightBillingLineItems);
            updatedFreightBillingLineItems.splice(index, 1, freightBillingLineItem);

            setFreightBillingLineItems(lineItemIndex ?? 0, dispatchReviewIndex, updatedFreightBillingLineItems);
        },
        [dispatchReviewIndex, freightBillingLineItems, lineItemIndex, setFreightBillingLineItems]
    );

    const isFob = useMemo(() => {
        return order?.quote?.type === QuoteType.FOB;
    }, [order?.quote?.type]);

    const isStandardMaterialLineItem = useMemo(() => {
        return orderReviewLineItem.lineItem?.type === LineItemTypes.Material && !isFob;
    }, [isFob, orderReviewLineItem.lineItem?.type]);

    const isMaterialLineItem = useMemo(() => {
        return orderReviewLineItem.lineItem?.type === LineItemTypes.Material;
    }, [orderReviewLineItem.lineItem?.type]);

    const isMaterialOrDumpLineItem = useMemo(() => {
        return orderReviewLineItem.lineItem?.type === LineItemTypes.Material || orderReviewLineItem.lineItem?.type === LineItemTypes.Dump;
    }, [orderReviewLineItem.lineItem?.type]);

    const isDisabled = useCallback((freightBillingLineItem: FreightBillingLineItemDto) => {
        return disabled || freightBillingLineItem.isInvoiced!;
    }, [disabled]);

    return (
        <Box display='flex' flexDirection='column'>
            {freightBillingLineItems.map((freightBillingLineItem, freightLineItemIndex) => (
                <Box key={`${freightBillingLineItem.id}-${freightLineItemIndex}`}>
                    <Grid item container spacing={1} xs={12} wrap='nowrap'>
                        <Grid item container xs={11} spacing={1} wrap='nowrap'>
                            {isStandardMaterialLineItem && <Grid item xs={2}>
                                <PricingMethodTypeSelect
                                    selectedPricingMethodType={freightBillingLineItem.pricingMethodType}
                                    onChange={(value) => handleFreightBillingLineItemChange(freightLineItemIndex, 'pricingMethodType', value)}
                                    required
                                    disabled={isDisabled(freightBillingLineItem)}
                                    errorMessage={freightErrors.get(freightLineItemIndex)?.get('pricingMethodType')}
                                />
                            </Grid>}
                            <Grid item>
                                <FormInput
                                    label='Freight Bill #'
                                    value={freightBillingLineItem.freightBillNumber}
                                    onChange={(e) => handleFreightBillingLineItemChange(freightLineItemIndex, 'freightBillNumber', e.target.value)}
                                    fullWidth
                                    disabled={isDisabled(freightBillingLineItem)}
                                    required
                                    errorText={freightErrors.get(freightLineItemIndex)?.get('freightBillNumber')}
                                />
                            </Grid>
                            <Grid item>
                                <FormNumberInput
                                    label='Quantity'
                                    name='quantity'
                                    value={freightBillingLineItem.quantity}
                                    onChange={(e) =>
                                        handleFreightBillingLineItemChange(freightLineItemIndex, 'quantity', isNaN(e.target.valueAsNumber) ? undefined : e.target.valueAsNumber)
                                    }
                                    fullWidth
                                    disabled={isDisabled(freightBillingLineItem)}
                                    required={isMaterialOrDumpLineItem}
                                    inputProps={{ min: 0 }}
                                    error={!!freightErrors.get(freightLineItemIndex)?.get('quantity')}
                                    errorText={freightErrors.get(freightLineItemIndex)?.get('quantity')}
                                />
                            </Grid>
                            <Grid item>
                                <FormInput value={freightBillingLineItem.unitOfMeasure} onChange={() => { }} label='Units' fullWidth disabled />
                            </Grid>
                            <Grid item>
                                <>
                                    <FormControl fullWidth disabled>
                                        <FormLabel>{'Rate ' + (freightBillingLineItem.flatRateApplied && isMaterialLineItem ? '(+ Flat)' : '')}</FormLabel>
                                        <OutlinedInput
                                            value={formatCurrency(freightBillingLineItem.quoteRate ?? 0)}
                                            disabled
                                            inputProps={{ min: 0 }}
                                            endAdornment={
                                                <Edit
                                                    color='secondary'
                                                    sx={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setIsBillingRatesFormDialogOpen(freightLineItemIndex);
                                                    }}
                                                />
                                            }
                                        />
                                    </FormControl>
                                    <BillingRateFormDialog
                                        open={isBillingRatesFormDialogOpen === freightLineItemIndex}
                                        onClose={() => setIsBillingRatesFormDialogOpen(undefined)}
                                        onConfirm={(formValues) => {
                                            handleFullFreightBillingLineItemChange(freightLineItemIndex, {
                                                ...freightBillingLineItem,
                                                ...formValues,
                                            });
                                        }}
                                        billingRates={freightBillingLineItem}
                                        lineItem={orderReviewLineItem}
                                        isFob={order?.quote?.type === QuoteType.FOB}
                                        disabled={isDisabled(freightBillingLineItem)}
                                        isOrder={false}
                                    />
                                </>
                            </Grid>
                            <Grid item>
                                <FormInput
                                    label='Amount'
                                    name='amount'
                                    value={formatCurrency(freightBillingLineItem.salesPrice ?? freightBillingLineItem.quoteRate ?? 0)}
                                    onChange={() => { }}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={1}>
                            <Tooltip title='Remove Freight Billing' placement='top'>
                                <IconButton
                                    disabled={isDisabled(freightBillingLineItem)}
                                    size='small'
                                    color='primary'
                                    sx={{ marginTop: '34px' }}
                                    onClick={() => onRemoveClicked(freightLineItemIndex)}>
                                    <RemoveCircle />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Box>
            ))}
        </Box>
    );
};
