import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { BrokerDto, DispatchReviewDto, DriverDto, EquipmentDto, FreightBillingLineItemDto, OrderDto } from '../../../dtos';
import _, { } from 'lodash';
import { AssignmentType } from '../../../dtos/generated/AssignmentType';
import { Box, FormControl, FormHelperText, FormLabel, Grid, IconButton, MenuItem, Select, SelectChangeEvent, Tab, Tooltip, Typography } from '@mui/material';
import { FormInput, IEntityAutocomplete } from '../../CoreLib/library';
import { AddCircle, Error } from '@mui/icons-material';
import { useGetRegionQuery, useLazyGetEquipmentQuery } from '../../../store/generated/generatedApi';
import { HaulToFromForm, SelectableList, TimePicker } from '../../CommonInputs';
import { FreightBillingLineItemTable } from './FreightBillingLineItemTable';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { isErrorInMap } from './useOrderReviewForm';
import { TicketFormGeneralTab } from './TicketFormGeneralTab';
import { getUniqueKey } from '../../../util/getUniqueKey';

export interface IDispatchReviewTableProps {
    brokers: BrokerDto[];
    disabled: boolean;
    dispatchReviews: DispatchReviewDto[];
    drivers: DriverDto[];
    setDispatches: (dispatchReviews: DispatchReviewDto[]) => void;
    addFreightBillingLineItem: (dispatchIndex: number) => void;
    removeFreightBillingLineItem: (dispatchIndex: number, index: number) => void;
    dispatchErrors: Map<number, Map<keyof DispatchReviewDto, string>>;
    freightErrors: Map<number, Map<number, Map<keyof FreightBillingLineItemDto, string>>>;
    getOrderById: (orderId: string) => OrderDto | undefined;
}

export const DispatchTicketEntryTable: FC<IDispatchReviewTableProps> = (props) => {
    const {
        brokers,
        disabled,
        dispatchReviews,
        drivers,
        setDispatches,
        removeFreightBillingLineItem,
        addFreightBillingLineItem,
        dispatchErrors,
        freightErrors,
        getOrderById
    } = props;

    const [getEquipment, { data: equipment }] = useLazyGetEquipmentQuery();
    const { data: regions } = useGetRegionQuery({ searchText: '', sortKey: 'NAME', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });

    const [selectedTab, setSelectedTab] = useState('0');
    const [selectedIndex, setSelectedIndex] = useState<number | undefined>(0);

    useEffect(() => {
        if (!equipment) {
            getEquipment({
                searchText: '',
                sortKey: 'NUMBER',
                page: 0,
                pageSize: 100000,
                sortAsc: true,
                includeInactive: false,
                brokerId: dispatchReviews[selectedIndex ?? 0]?.brokerId,
            });
        }
    }, [dispatchReviews, equipment, getEquipment, selectedIndex]);

    const updateDispatch = useCallback(
        (index: number, fieldName: string, value: any) => {
            var updatedDispatchReviews = _.cloneDeep(dispatchReviews);
            var updatedDispatchReview = { ...updatedDispatchReviews[index] };

            updatedDispatchReview[fieldName] = value;

            if (fieldName === 'assignmentType') {
                if (updatedDispatchReview.assignmentType === AssignmentType.Broker) {
                    updatedDispatchReview.driverId = undefined;
                    updatedDispatchReview.driver = undefined;
                    updatedDispatchReview.equipmentId = undefined;
                    updatedDispatchReview.equipment = undefined;
                } else {
                    updatedDispatchReview.brokerId = undefined;
                    updatedDispatchReview.broker = undefined;
                    updatedDispatchReview.equipmentId = undefined;
                    updatedDispatchReview.equipment = undefined;
                    getEquipment({ searchText: '', sortKey: 'NUMBER', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false });
                }
            }

            updatedDispatchReviews.splice(index, 1, updatedDispatchReview);

            setDispatches(updatedDispatchReviews);
        },
        [dispatchReviews, getEquipment, setDispatches]
    );

    const handleBrokerChange = useCallback(
        (index: number, broker?: BrokerDto) => {
            var updatedDispatchReviews = _.cloneDeep(dispatchReviews);
            var updatedDispatchReview = { ...updatedDispatchReviews[index] };

            updatedDispatchReview['broker'] = broker;
            updatedDispatchReview['brokerId'] = broker?.id;
            updatedDispatchReviews.splice(index, 1, updatedDispatchReview);

            setDispatches(updatedDispatchReviews);
            getEquipment({ searchText: '', sortKey: 'NUMBER', page: 0, pageSize: 100000, sortAsc: true, includeInactive: false, brokerId: broker?.id });
        },
        [dispatchReviews, getEquipment, setDispatches]
    );

    const handleDriverChange = useCallback(
        (index: number, driver?: DriverDto) => {
            var updatedDispatchReviews = _.cloneDeep(dispatchReviews);
            var updatedDispatchReview = { ...updatedDispatchReviews[index] };

            updatedDispatchReview['driver'] = driver;
            updatedDispatchReview['driverId'] = driver?.id;
            updatedDispatchReview['equipmentId'] = driver?.equipmentId;
            updatedDispatchReview['equipment'] = driver?.equipment;
            updatedDispatchReviews.splice(index, 1, updatedDispatchReview);

            setDispatches(updatedDispatchReviews);
        },
        [dispatchReviews, setDispatches]
    );

    const handleEquipmentChange = useCallback(
        (index: number, equipment?: EquipmentDto) => {
            var updatedDispatchReviews = _.cloneDeep(dispatchReviews);
            var updatedDispatchReview = { ...updatedDispatchReviews[index] };

            updatedDispatchReview['equipment'] = equipment;
            updatedDispatchReview['equipmentId'] = equipment?.id;
            updatedDispatchReviews.splice(index, 1, updatedDispatchReview);

            setDispatches(updatedDispatchReviews);
        },
        [dispatchReviews, setDispatches]
    );

    const checkDispatchForGeneralErrors = useCallback(
        (index: number) => {
            const errorMap = dispatchErrors.get(index);
            const generalFields: (keyof DispatchReviewDto)[] = ['brokerId', 'driverId', 'equipmentId', 'onsiteTime', 'startDate', 'endDate', 'driverMemo'];
            return generalFields.some((x) => !!errorMap?.get(x));
        },
        [dispatchErrors]
    );

    const checkDispatchForHaulToFromErrors = useCallback(
        (index: number) => {
            const errorMap = dispatchErrors.get(index);
            const haulToFromFields: (keyof DispatchReviewDto)[] = [
                'haulToAddressLine1',
                'haulToAddressLine2',
                'haulToCity',
                'haulToState',
                'haulToZipCode',
                'haulToCountry',
                'haulFromAddressLine1',
                'haulFromAddressLine2',
                'haulFromCity',
                'haulFromState',
                'haulFromZipCode',
                'haulFromCountry',
            ];
            return haulToFromFields.some((x) => !!errorMap?.get(x));
        },
        [dispatchErrors]
    );

    const checkDispatchForBillingErrors = useCallback(
        (index: number) => {
            const errorMaps = freightErrors.get(index);
            return Array.from(errorMaps?.values() ?? []).some((billingErrorMap) => isErrorInMap(billingErrorMap));
        },
        [freightErrors]
    );

    const checkDispatchForErrors = useCallback(
        (index: number) => {
            const isGeneralErrors = checkDispatchForGeneralErrors(index);
            const isHaulToFromErrors = checkDispatchForHaulToFromErrors(index);
            const isBillingErrors = checkDispatchForBillingErrors(index);

            return isGeneralErrors || isHaulToFromErrors || isBillingErrors;
        },
        [checkDispatchForGeneralErrors, checkDispatchForHaulToFromErrors, checkDispatchForBillingErrors]
    );

    const buildDriverString = useCallback((driver: DriverDto | undefined) => {
        if (!!driver) {
            return `${driver.code} - ${driver.name}`;
        } else {
            return '';
        }
    }, []);

    const generalDetails = useCallback(
        (dispatchReview: DispatchReviewDto, index: number) => {
            return (
                <TicketFormGeneralTab orderReview={dispatchReview.orderLineItem!.orderReview!} orderLineItem={dispatchReview.orderLineItem!} key={getUniqueKey(dispatchReview)} />
            );
        }, []);

    const dispatchDetails = useCallback(
        (dispatchReview: DispatchReviewDto, index: number) => {
            var errors = dispatchErrors.get(index);
            return (
                <Grid item container direction='column' key={getUniqueKey(dispatchReview)}>
                    <Grid item container direction='row' xs={12} spacing={1}>
                        <Grid item xs={12} xl={4}>
                            <FormControl fullWidth disabled={disabled} error={!!errors?.get('assignmentType')}>
                                <FormLabel>Assign. Type</FormLabel>
                                <Select
                                    value={dispatchReview.assignmentType === AssignmentType.Broker ? 'Broker' : 'Driver'}
                                    disabled={true}
                                    onChange={(event: SelectChangeEvent) => {
                                        const value = event.target.value === 'Broker' ? AssignmentType.Broker : AssignmentType.Driver;
                                        updateDispatch(index, 'assignmentType', value);
                                    }}>
                                    <MenuItem value={'Broker'}>Broker</MenuItem>
                                    <MenuItem value={'Driver'}>Driver</MenuItem>
                                </Select>
                                {errors?.get('assignmentType') && <FormHelperText>{errors?.get('assignmentType')}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        {dispatchReview.assignmentType === AssignmentType.Driver && (
                            <Grid item xs={12} xl={4} spacing={1}>
                                <FormControl error={!!errors?.get('driverId')} fullWidth required disabled={disabled}>
                                    <FormLabel>Driver</FormLabel>
                                    <IEntityAutocomplete
                                        options={drivers}
                                        onChange={(e, value) => {
                                            handleDriverChange(index, value ?? null);
                                        }}
                                        value={dispatchReview.driver}
                                        getOptionLabel={(option: DriverDto) => buildDriverString(option)}
                                        error={!!errors?.get('driverId')}
                                        disabled={true}
                                    />
                                    {errors?.get('driverId') && <FormHelperText>{errors?.get('driverId')}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        )}
                        {dispatchReview.assignmentType === AssignmentType.Broker && (
                            <Grid item xs={12} xl={4}>
                                <FormControl error={!!errors?.get('brokerId')} fullWidth required disabled={disabled}>
                                    <FormLabel>Broker</FormLabel>
                                    <IEntityAutocomplete
                                        options={brokers}
                                        onChange={(e, value) => {
                                            handleBrokerChange(index, value ?? null);
                                        }}
                                        value={dispatchReview.broker}
                                        getOptionLabel={(option: BrokerDto) => `${option.code} - ${option.name}`}
                                        error={!!errors?.get('brokerId')}
                                        disabled={disabled}
                                    />
                                    {errors?.get('brokerId') && <FormHelperText>{errors?.get('brokerId')}</FormHelperText>}
                                </FormControl>
                            </Grid>
                        )}
                        <Grid item xs={12} xl={4}>
                            <FormControl
                                error={!!errors?.get('equipmentId')}
                                fullWidth
                                required={dispatchReview.assignmentType === AssignmentType.Driver}
                                disabled={disabled}>
                                <FormLabel>Equipment</FormLabel>
                                <IEntityAutocomplete
                                    options={
                                        dispatchReview.assignmentType === AssignmentType.Broker
                                            ? equipment?.pageResults ?? []
                                            : dispatchReview?.driver?.equipment
                                                ? [dispatchReview?.driver?.equipment]
                                                : []
                                    }
                                    onChange={(e, value) => {
                                        handleEquipmentChange(index, value ?? null);
                                    }}
                                    value={dispatchReview.equipment}
                                    getOptionLabel={(option: EquipmentDto) => `${option.number} - ${option.equipmentType?.type}`}
                                    error={!!errors?.get('equipmentId')}
                                    disabled={disabled}
                                />
                                {errors?.get('equipmentId') && <FormHelperText>{errors?.get('equipmentId')}</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} xl={4}>
                            <TimePicker
                                value={dispatchReview.startDate}
                                onChange={(date) => {
                                    if (date) {
                                        updateDispatch(index, 'startDate', date);
                                    }
                                }}
                                error={!!errors?.get('startDate')}
                                errorText={errors?.get('startDate')}
                                label='Onsite Time'
                                disabled={disabled}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} xl={4}>
                            <TimePicker
                                value={dispatchReview.endDate}
                                onChange={(date) => {
                                    if (date) {
                                        updateDispatch(index, 'endDate', date);
                                    }
                                }}
                                error={!!errors?.get('endDate')}
                                errorText={errors?.get('endDate')}
                                label='End Time'
                                disabled={disabled}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} xl={4}>
                            <TimePicker
                                value={dispatchReview.yardTime}
                                onChange={(date) => {
                                    if (date) {
                                        updateDispatch(index, 'yardTime', date);
                                    }
                                }}
                                label='Yard Time'
                                disabled={disabled}
                                error={!!errors?.get('yardTime')}
                                errorText={errors?.get('yardTime')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <FormInput label='Driver Memo' disabled fullWidth value={dispatchReview.driverMemo} onChange={() => { }} />
                    </Grid>
                </Grid>
            );
        },
        [
            brokers,
            buildDriverString,
            disabled,
            drivers,
            equipment?.pageResults,
            handleBrokerChange,
            handleDriverChange,
            handleEquipmentChange,
            updateDispatch,
            dispatchErrors,
        ]
    );

    const haulToFromDetails = useCallback(
        (dispatchReview: DispatchReviewDto, index: number) => {
            return (
                <HaulToFromForm
                    haulToAddressLine1={dispatchReview.haulToAddressLine1 ?? ''}
                    haulToAddressLine2={dispatchReview.haulToAddressLine2 ?? ''}
                    haulToCountry={dispatchReview.haulToCountry ?? ''}
                    haulToCity={dispatchReview.haulToCity ?? ''}
                    haulToState={dispatchReview.haulToState ?? ''}
                    haulToZipCode={dispatchReview.haulToZipCode ?? ''}
                    haulFromAddressLine1={dispatchReview.haulFromAddressLine1 ?? ''}
                    haulFromAddressLine2={dispatchReview.haulFromAddressLine2 ?? ''}
                    haulFromCountry={dispatchReview.haulFromCountry ?? ''}
                    haulFromCity={dispatchReview.haulFromCity ?? ''}
                    haulFromState={dispatchReview.haulFromState ?? ''}
                    haulFromZipCode={dispatchReview.haulFromZipCode ?? ''}
                    enableLookup={false}
                    regions={regions?.pageResults ?? []}
                    haulFromRegion={dispatchReview.haulFromRegion}
                    haulToRegion={dispatchReview.haulToRegion}
                    haulToSiteName={dispatchReview.haulToSiteName}
                    haulFromSiteName={dispatchReview.haulFromSiteName}
                    disabled
                />
            );
        },
        [regions?.pageResults]
    );

    const billingDetails = useCallback(
        (dispatchReview: DispatchReviewDto, dispatchReviewIndex: number) => {
            const orderId = dispatchReviews.find(dispatch => dispatch.id === dispatchReview.id)?.orderLineItem?.orderReview?.orderId;
            const order = getOrderById(orderId ?? '');
            const orderReviewLineItem = dispatchReviews.find(dispatch => dispatch.id === dispatchReview.id)?.orderLineItem;

            return (
                <Grid item container direction='column' height='100%' wrap='nowrap'>
                    {(dispatchReview.freightBillingLineItems ?? []).length === 0 && (
                        <Grid item textAlign='center' xs={12}>
                            <Typography color='GrayText'>No billing items have been added to this dispatch.</Typography>
                        </Grid>
                    )}
                    {(dispatchReview.freightBillingLineItems ?? []).length > 0 && order && orderReviewLineItem && (
                        <Grid item xs={12}>
                            <FreightBillingLineItemTable
                                disabled={disabled}
                                dispatchReviewIndex={dispatchReviewIndex}
                                order={order}
                                freightBillingLineItems={dispatchReview.freightBillingLineItems ?? []}
                                orderReviewLineItem={orderReviewLineItem}
                                onRemoveClicked={(freightLineItemIndex) => removeFreightBillingLineItem(dispatchReviewIndex, freightLineItemIndex)}
                                setFreightBillingLineItems={(
                                    lineItemIndex: number,
                                    dispatchReviewIndex: number,
                                    freightBillingLineItems: FreightBillingLineItemDto[]
                                ) => {
                                    var updatedDispatchReviews = _.cloneDeep(dispatchReviews);
                                    var updatedDispatchReview = { ...updatedDispatchReviews[dispatchReviewIndex] };

                                    updatedDispatchReview.freightBillingLineItems = freightBillingLineItems;
                                    updatedDispatchReviews.splice(dispatchReviewIndex, 1, updatedDispatchReview);

                                    setDispatches(updatedDispatchReviews);
                                }}
                                freightErrors={freightErrors.get(dispatchReviewIndex) ?? new Map<number, Map<keyof FreightBillingLineItemDto, string>>()}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Tooltip title='Add Freight Billing'>
                            <IconButton
                                disabled={disabled}
                                size='small'
                                color='primary'
                                sx={{ marginTop: '12px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    orderReviewLineItem && addFreightBillingLineItem(dispatchReviewIndex);
                                }}>
                                <AddCircle />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            );
        },
        [getOrderById, disabled, removeFreightBillingLineItem, freightErrors, dispatchReviews, setDispatches, addFreightBillingLineItem]
    );

    const details = useCallback(
        (index?: number) => {
            const item = dispatchReviews.at(index ?? 0);
            const isDispatchErrors = index !== undefined ? checkDispatchForGeneralErrors(index) : false;
            const isGeneralErrors = false;
            const isHaulToFromErrors = index !== undefined ? checkDispatchForHaulToFromErrors(index) : false;
            const isBillingErrors = index !== undefined ? checkDispatchForBillingErrors(index) : false;
            return (
                <Box key={item?.id} sx={{ width: '100%', borderBottom: 1, borderRight: 1, borderColor: 'divider', display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}>
                    <TabContext value={item ? selectedTab : ''}>
                        <Box sx={{ width: '100%', borderTop: 1, borderBottom: 1, borderColor: 'divider' }}>
                            <TabList variant='fullWidth' sx={{ width: '100%' }} onChange={(_e, newValue) => setSelectedTab(newValue)}>
                                <Tab
                                    label='General'
                                    value='0'
                                    iconPosition='end'
                                    icon={isGeneralErrors ? <Error color='error' /> : undefined}
                                    sx={{ minHeight: '48px' }}
                                />
                                <Tab
                                    label='Dispatch'
                                    value='1'
                                    iconPosition='end'
                                    icon={isDispatchErrors ? <Error color='error' /> : undefined}
                                    sx={{ minHeight: '48px' }}
                                />
                                <Tab
                                    label='Haul To / From'
                                    value='2'
                                    iconPosition='end'
                                    icon={isHaulToFromErrors ? <Error color='error' /> : undefined}
                                    sx={{ minHeight: '48px' }}
                                />
                                <Tab
                                    label='Billing'
                                    value='3'
                                    iconPosition='end'
                                    icon={isBillingErrors ? <Error color='error' /> : undefined}
                                    sx={{ minHeight: '48px' }}
                                />
                            </TabList>
                        </Box>
                        {!item && (
                            <Box textAlign='center'>
                                <Typography mt={2} color='GrayText'>
                                    No Item Selected
                                </Typography>
                            </Box>
                        )}
                        <TabPanel value='0' sx={{ padding: '5px', overflowY: 'auto' }}>
                            {item && index !== undefined && generalDetails(item, index)}
                        </TabPanel>
                        <TabPanel value='1' sx={{ overflowY: 'auto' }}>
                            {item && index !== undefined && dispatchDetails(item, index)}
                        </TabPanel>
                        <TabPanel value='2' sx={{ overflowY: 'auto' }}>
                            {item && index !== undefined && haulToFromDetails(item, index)}
                        </TabPanel>
                        <TabPanel value='3' sx={{ overflowY: 'auto' }}>
                            {item && index !== undefined && billingDetails(item, index)}
                        </TabPanel>
                    </TabContext>
                </Box>
            );
        },
        [dispatchReviews, checkDispatchForGeneralErrors, checkDispatchForHaulToFromErrors, checkDispatchForBillingErrors, selectedTab, generalDetails, dispatchDetails, haulToFromDetails, billingDetails]
    );

    const getDesc = useCallback((item: any) => {
        const order = getOrderById(dispatchReviews.find(dispatch => dispatch.id === item.id)?.orderLineItem?.orderReview?.orderId ?? '');
        return `${order?.job?.customerName ?? ''} - ${item.orderLineItem.description ?? ''} - ${order?.poNumber ?? 0}`;
    }, [dispatchReviews, getOrderById]);

    const ticketEntryForm = useMemo(() => {
        return <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', flexGrow: 1, height: '100%' }}>
            <SelectableList
                items={dispatchReviews}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
                hasError={checkDispatchForErrors}
                getKey={(item) => item.id}
                getDesc={getDesc}
                style={{
                    borderRight: 1,
                    borderLeft: 1,
                    borderTop: 1,
                    borderColor: 'divider',
                    minWidth: 240,
                    height: '100%',
                    bgcolor: 'background.paper',
                    overflowY: 'auto'
                }}
                disableAdd
                disableRemove={() => true}
            />
            {details(selectedIndex)}
        </Box>;
    }, [checkDispatchForErrors, details, dispatchReviews, getDesc, selectedIndex]);

    if (!dispatchReviews || selectedIndex === undefined) {
        return <></>;
    }

    return (
        <>{ticketEntryForm}</>
    );
};
