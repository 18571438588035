import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSuccessfulCreateSnackbar, useFailedCreateSnackbar, LoadingIndicator } from '../../../../Components/CoreLib/library';
import { DEFAULT_MISC_ITEM, MiscLineItemForm } from '../../../../Components/Forms/QuoteForm';
import { MiscLineItemDto } from '../../../../dtos';
import { useCreateMiscLineItemMutation, useLazyGetOrderByIdQuery, useLazyGetQuoteByIdQuery } from '../../../../store/generated/generatedApi';

export const AddMiscLineItemView: FC = () => {
    const { quoteId, jobId, orderId } = useParams();
    const navigate = useNavigate();
    const [getQuote, { data: quote, isLoading: isQuoteLoading }] = useLazyGetQuoteByIdQuery();
    const [createMiscLineItem, { isError, isSuccess, reset }] = useCreateMiscLineItemMutation();
    const [getOrder, { data: order, isLoading: isOrderLoading }] = useLazyGetOrderByIdQuery();

    useEffect(() => {
        if (quoteId && !quote) {
            getQuote({ id: quoteId! });
        }
    }, [getQuote, quote, quoteId]);

    useEffect(() => {
        if (orderId && !order) {
            getOrder({ id: orderId! });
        }
    }, [getOrder, order, orderId]);

    useSuccessfulCreateSnackbar('Misc Line Item', isSuccess, () => {
        if (orderId || jobId) {
            navigate(`/job/${jobId}/order/${order?.id ?? 'create'}`);
        } else if (quoteId) {
            navigate(`/quote/${quoteId}/lineItems`);
        } else {
            navigate(`/quotes`);
        }
    });
    useFailedCreateSnackbar('miscellaneous line item', isError, reset)

    const handleSave = (values: MiscLineItemDto) => {
        createMiscLineItem(values);
    };

    const handleCancel = () => {
        if (orderId || jobId) {
            navigate(`/job/${jobId}/order/${order?.id ?? 'create'}`);
        } else if (quoteId) {
            navigate(`/quote/${quoteId}/lineItems`)
        } else {
            navigate('/quotes');
        }
    };

    if (isQuoteLoading || isOrderLoading) {
        return <LoadingIndicator />;
    }

    return <MiscLineItemForm save={handleSave} cancel={handleCancel} quote={quote} order={order} initValues={DEFAULT_MISC_ITEM} />;
};
