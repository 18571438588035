// https://www.abrahamberg.com/blog/aspnet-signalr-and-react/
import * as signalR from '@microsoft/signalr';
const URL = process.env.REACT_APP_BASE_URL ?? 'http://localhost:5000';

export class WebhookConnector {
    private connection: signalR.HubConnection;
    public events: (onUpdated: (date: string, id: string) => void) => void;
    static instance: WebhookConnector;
    private userId: string;
    constructor(userId: string) {
        this.userId = userId;
        this.connection = new signalR.HubConnectionBuilder().withUrl(`${URL}/hub`).withAutomaticReconnect().build();
        this.connection.start().catch((err) => document.write(err));
        this.events = (onUpdated) => {
            this.connection.on('updated', (date, id) => {
                onUpdated(date, id);
            });
        };
    }

    public static getInstance(userId): WebhookConnector {
        if (!WebhookConnector.instance) {
            WebhookConnector.instance = new WebhookConnector(userId);
        }
        return WebhookConnector.instance;
    }
}
export default WebhookConnector.getInstance;
