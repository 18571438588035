import { Assignment, Info } from "@mui/icons-material";
import { Dialog, DialogTitle, Typography, DialogContent, Grid, DialogActions, Button } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { DataTableColumn, StandardListViewV2 } from "../../../Components/CoreLib/library";
import { QuoteAffectingChangesDto, QuoteDto } from "../../../dtos";
import { useGetQuoteAffectedQuery } from "../../../store/generated/generatedApi";

export interface IQuotesAffectedByChangeListViewDialog {
    onClose: () => void;
    onConfirm: () => void;
    open: boolean;
    changes: QuoteAffectingChangesDto;
}

export const QuotesAffectedByChangeListViewDialog: FC<IQuotesAffectedByChangeListViewDialog> = (props) => {
    const { onClose, onConfirm, open, changes } = props;

    const tableColumns: DataTableColumn<QuoteDto>[] = useMemo(() => {
        let columns = [
            { key: 'quoteNumber', label: 'Quote #', sortKey: 'QUOTE_NUMBER', },
            { key: 'projectName', label: 'Project Name', sortKey: 'PROJECT_NAME' },
            { key: 'status', label: 'Status', sortKey: 'STATUS' },
            { key: 'quoteDate', label: 'Quote Date', sortKey: 'QUOTE_DATE', fieldMapper: (row) => (row.quoteDate ? new Date(row.quoteDate.toString()).toLocaleDateString() : '') },
            { key: 'effectiveOn', label: 'Effective On Date', sortKey: 'EFFECTIVE_ON_DATE', fieldMapper: (row) => (row.effectiveOn ? new Date(row.effectiveOn.toString()).toLocaleDateString() : '') },
            { key: 'expirationDate', label: 'Current Expiration Date', sortKey: 'EXPIRATION_DATE', fieldMapper: (row) => (row.expirationDate ? new Date(row.expirationDate.toString()).toLocaleDateString() : '') },
            {
                key: 'newExpirationDate', label: 'New Expiration Date', sortKey: 'NEW_EXPIRATION_DATE', fieldMapper: (row) => (row.newExpirationDate ?
                    <Grid container direction='row' alignItems='center' wrap='nowrap' spacing={1}>
                        <Grid item>
                            <Typography>{new Date(row.newExpirationDate.toString()).toLocaleDateString()}</Typography>
                        </Grid>
                        <Grid item>
                            <Info color='error' />
                        </Grid>
                    </Grid>
                    : '')
            }

        ] as DataTableColumn<QuoteDto>[];

        return columns;
    }, []);

    const getQueryParameters = useCallback(() => {
        return JSON.stringify(changes);
    }, [changes]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg' PaperProps={{ sx: { width: '100%' } }}>
            <DialogTitle>
                <Typography variant='h2'>
                    {<Assignment />} Affected Quotes
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography variant='h6' mt={2}>The following quotes will be affected by the Material Price change.</Typography>
                <StandardListViewV2
                    headerIcon={<></>}
                    headerTitle={''}
                    disableSelection
                    hideMenu
                    hideSearch
                    breadCrumbs={[]}
                    isPaginationVisible={false}
                    getDataQuery={useGetQuoteAffectedQuery}
                    additionalQueryParameters={{ serializedDto: getQueryParameters() }}
                    isShowActiveToggleVisible={false}
                    tableColumns={tableColumns}
                    entityNameSingular='Quote'
                    permissionName='quote'
                    hideAddButtonDivider
                    onNoDataLoaded={() => {
                        onConfirm();
                    }}
                />
            </DialogContent>
            <DialogActions sx={{ py: 2 }}>
                <Grid container direction='row' justifyContent='end'>
                    <Grid item>
                        <Button variant='outlined' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={onClose}>
                            Close
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button variant='contained' style={{ boxShadow: 'none', marginRight: '15px' }} onClick={onConfirm}>
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}