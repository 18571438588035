import { format, isAfter, isEqual, isValid } from 'date-fns';

export function isDateGreaterThanOrEqual(a, b) {
    // return true; // For testing purposes
    const dateA = format(a, 'yyyy-MM-dd');
    const dateB = format(b, 'yyyy-MM-dd');

    return isEqual(new Date(dateA), new Date(dateB)) || isAfter(new Date(dateA), new Date(dateB));
}

export function areDatesEqual(a, b) {
    const dateA = format(new Date(a), 'yyyy-MM-dd');
    const dateB = format(new Date(b), 'yyyy-MM-dd');

    return isEqual(new Date(dateA), new Date(dateB));
}

export function areDateTimesEqual(a: any, b: any) {
    var formattedDateA = a && isValid(new Date(a as any)) ? new Date(a).toISOString() : undefined;
    var formattedDateB = b && isValid(new Date(b as any)) ? new Date(b).toISOString() : undefined;

    return formattedDateA === formattedDateB;
}
