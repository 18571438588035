import { format } from 'date-fns';

export const formatDate = (date?: Date | string | null, formatString: string = 'M/d/yyyy') => {
    if (!date) {
        return '';
    }
    const convertedDate = new Date(date);
    if (isNaN(convertedDate.getTime())) {
        return '';
    }

    const estConvertedDate = new Date(convertedDate.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    return format(estConvertedDate.toLocaleString(), formatString);
};

export const convertYYYYMMDDToDate = (dateString: string) => {
    const dateParts = dateString.split('-').map((part) => parseInt(part, 10));
    const date = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
    return date;
};

export const formatTime = (date?: Date) => {
    if (!date) {
        return '';
    }
    return new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'America/New_York' });
};
