import { FC, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSuccessfulUpdateSnackbar, useFailedUpdateSnackbar, LoadingIndicator, ApiError } from '../../Components/CoreLib/library';
import { ContactFullPageForm } from '../../Components/Forms/ContactForm';
import { ContactDto } from '../../dtos';
import { useGetContactByIdQuery, useUpdateContactMutation } from '../../store/generated/generatedApi';


export const EditContactView: FC = () => {
    const { customerId, siteId, id } = useParams();
    const navigate = useNavigate();
    const { data, isLoading, error, refetch } = useGetContactByIdQuery({ id: id! });
    const [updateContact, { isSuccess, isError, reset }] = useUpdateContactMutation();

    useSuccessfulUpdateSnackbar('Contact', isSuccess, reset);
    useFailedUpdateSnackbar('contact', isError, reset);

    const redirectUrl = useMemo(() => (customerId ? `/customer/${customerId}` : `/dataManagement/site/${siteId}`) + `/contacts`, [customerId, siteId]);

    const handleSave = (values: ContactDto) => {
        updateContact(values);
    };

    const handleCancel = () => {
        navigate(redirectUrl);
    };

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <ContactFullPageForm save={handleSave} cancel={handleCancel} initValues={data} redirectUrl={redirectUrl} />;
};
