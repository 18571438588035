import { Grid, Typography } from '@mui/material';
import { FunctionComponent } from 'react';

export interface NotAuthorizedErrorProps {
    children: any;
}

export const NotAuthorizedError: FunctionComponent<NotAuthorizedErrorProps> = (props) => {
    const { children } = props;
    return (
        <Grid container direction='column' alignItems='center'>
            <Grid item>
                <Typography fontWeight={'bold'} fontSize={24}>
                    Oops... something went wrong
                </Typography>
            </Grid>
            <Grid item>
                <Typography>You've attempted to do something that you do not have access to. Please contact support with this issue.</Typography>
            </Grid>
            {children}
        </Grid>
    );
};
