import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { SiteDto } from '../../dtos';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateSiteMutation } from '../../store/generated/generatedApi';
import { SiteForm } from '../../Components/Forms/SiteForm';

export const AddSiteView: FC = () => {
    const navigate = useNavigate();
    const [createSite, { data, isError, isSuccess, reset }] = useCreateSiteMutation();

    useSuccessfulCreateSnackbar('Site', isSuccess, () => navigate(`/dataManagement/site/${data?.id}`));
    useFailedCreateSnackbar('site', isError, reset)

    const handleSave = (values: SiteDto) => {
        createSite(values);
    };

    const handleCancel = () => {
        navigate('/dataManagement/sites');
    };

    return <SiteForm save={handleSave} cancel={handleCancel} />;
};
