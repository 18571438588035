import { FC, useCallback, useMemo } from "react";
import { ContactDto, OrderByType, OrderReviewDto, OrderReviewLineItemDto } from "../../../dtos";
import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormLabel, Grid, Link, Typography } from "@mui/material";
import { EquipmentTypeSelect, PricingMethodTypeSelect, UnitOfMeasureSelect, OrderBySelect, TimePicker, DatePicker } from "../../CommonInputs";
import { FormNumberInput, FormInput, IEntityAutocomplete } from "../../CoreLib/library";
import { LineItemTypes, QuoteType } from "../../../util";
import { useNavigate } from "react-router-dom";

export interface ITicketFormGeneralTabProps {
    orderReview: OrderReviewDto;
    orderLineItem: OrderReviewLineItemDto;
}

export const TicketFormGeneralTab: FC<ITicketFormGeneralTabProps> = (props) => {
    const { orderReview, orderLineItem } = props;
    const navigate = useNavigate();

    const isFob = useMemo(() => {
        return orderReview.order?.quote?.type === QuoteType.FOB;
    }, [orderReview]);

    const isMaterialLineItem = useCallback((orderLineItem: OrderReviewLineItemDto) => {
        return orderLineItem?.lineItem?.type === LineItemTypes.Material && !isFob;
    }, [isFob]);

    const currentQuote = useMemo(() => {
        return orderReview?.quote;
    }, [orderReview?.quote]);

    const generalDetails = useCallback((orderReview: OrderReviewDto, orderLineItem: OrderReviewLineItemDto) => {
        const isMaterial = isMaterialLineItem(orderLineItem);
        if (!orderReview) {
            return <Box p={2} />;
        }

        return (
            <Box p={2}>
                <Grid item container direction='column' spacing={1}>
                    <Grid container direction='row' spacing={1}>
                        <Grid item container direction='row' xs={12} sm={6} md={4} alignItems='center' justifyContent='space-evenly'>
                            <Grid item>
                                <Typography>
                                    Quote <Link
                                        href={`/quote/${currentQuote?.id} `}
                                        variant="body2"
                                        onClick={() => {
                                            navigate(`/quote/${currentQuote?.id} `);
                                        }}>
                                        #{currentQuote?.quoteNumber}
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Job <Link
                                        href={`/job/${orderReview?.jobId} `}
                                        variant="body2"
                                        onClick={() => {
                                            navigate(`/job/${orderReview?.jobId} `);
                                        }}>
                                        #{orderReview?.job?.jobNumber}
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography>
                                    Order <Link
                                        href={`/job/${orderReview?.jobId}/order/${orderReview?.orderId} `}
                                        variant="body2"
                                        onClick={() => {
                                            navigate(`/job/${orderReview?.jobId}/order/${orderReview?.orderId} `);
                                        }}>
                                        #{orderReview?.orderNumber}
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Chip color='secondary' label={orderReview?.statusName ?? 'Awaiting Review'} />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} sm={6} md={4} spacing={2}>
                            <Grid item xs={5}>
                                <DatePicker
                                    label='Order Date'
                                    value={orderReview.orderDate}
                                    onChange={() => { }}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <FormControl fullWidth disabled>
                                    <FormLabel>Contact</FormLabel>
                                    <IEntityAutocomplete
                                        options={[]}
                                        disabled
                                        value={orderReview?.contact}
                                        getOptionLabel={(option: ContactDto) =>
                                            option.firstName +
                                            (option.lastName ? ' ' + option.lastName : '') +
                                            (option.title ? ' - ' + option.title : '') +
                                            (option.cellPhone ? ' - ' + option.cellPhone : '')
                                        }
                                    />
                                </FormControl>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <FormInput
                                value={orderReview?.poNumber}
                                onChange={() => { }}
                                label='PO Number'
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item container xs={12} sm={12} md={12} alignItems='center'>
                            <Grid item xs={12} sm={3} md={1}>
                                <FormControl fullWidth disabled>
                                    <FormControlLabel control={<Checkbox disabled checked={orderReview?.isTaxable} onChange={() => { }} />} label='Is Taxable' />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={9} md={11}>
                                <FormInput
                                    value={orderReview?.memo}
                                    onChange={() => { }}
                                    label='Memo'
                                    fullWidth
                                    maxLength={300}
                                    disabled
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' xs={12} spacing={1} wrap='nowrap'>
                        <Grid item xs={2}>
                            <FormNumberInput
                                value={orderLineItem?.orderLineItemNumber}
                                name='orderLineItemNumber'
                                label='Line Item #'
                                fullWidth
                                disabled
                                onChange={() => { }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInput
                                value={orderLineItem?.description}
                                onChange={() => { }}
                                label='Description'
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInput
                                value={orderLineItem.poNumber}
                                label='PO Number'
                                fullWidth
                                onChange={() => { }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <EquipmentTypeSelect
                                selectedEquipmentTypeId={orderLineItem?.equipmentType?.id ?? ''}
                                handleSelectedEquipmentTypeChange={() => { }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInput
                                value={orderLineItem?.lineItem?.site?.name}
                                onChange={() => { }}
                                label='Site'
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormInput
                                value={orderLineItem?.lineItem?.zone}
                                onChange={() => { }}
                                label='Zone'
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' xs={12} spacing={1} wrap='nowrap'>
                        {isMaterial && <Grid item xs={3}>
                            <PricingMethodTypeSelect
                                selectedPricingMethodType={orderLineItem?.billingRates!.pricingMethodType!}
                                onChange={() => { }}
                                disabled
                            />
                        </Grid>}
                        <Grid item xs={isMaterial ? 3 : 4}>
                            <FormNumberInput
                                value={orderLineItem?.billingRates?.salesPrice ?? orderLineItem?.billingRates?.quoteRate ?? orderLineItem?.billingRates?.lineItemRate}
                                onChange={() => { }}
                                label={`Rate ${orderLineItem?.billingRates?.flatRateApplied && isMaterial ? '(+ Flat)' : ''}`}
                                name='lineItemRate'
                                fullWidth
                                disabled
                                isCurrency
                            />
                        </Grid>
                        <Grid item xs={isMaterial ? 3 : 4}>
                            <FormNumberInput
                                label='Quantity'
                                name='quantity'
                                value={orderLineItem?.billingRates?.quantity}
                                onChange={() => { }}
                                fullWidth
                                inputProps={{ min: 0 }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={isMaterial ? 3 : 4}>
                            {!!orderLineItem.lineItemId ? <FormInput
                                value={orderLineItem?.billingRates?.unitOfMeasure}
                                onChange={() => { }}
                                label='Units'
                                fullWidth
                                disabled
                            /> :
                                <UnitOfMeasureSelect
                                    selectedUnit={orderLineItem?.billingRates?.unitOfMeasure ?? ''}
                                    handleSelectedUnitChange={() => { }}
                                    disabled
                                />}
                        </Grid>
                    </Grid>
                    <Grid item container spacing={1} xs={12}>
                        <Grid item xs={3}>
                            <TimePicker
                                value={orderLineItem.onsiteTime}
                                onChange={() => { }}
                                label={`Onsite Time`}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TimePicker
                                value={orderLineItem.yardTime}
                                onChange={() => { }}
                                label={`Yard Time`}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <OrderBySelect
                                selectedOrderBy={orderLineItem.orderBy!}
                                onChange={() => { }}
                                disabled
                            />
                        </Grid>
                        {orderLineItem.orderBy !== OrderByType.Equipment && (
                            <Grid item xs={3}>
                                <FormNumberInput
                                    value={orderLineItem.loadOrQuantityReq}
                                    name='loadOrQuantityReq'
                                    onChange={() => { }}
                                    label='Quantity'
                                    fullWidth
                                    inputProps={{ min: 0 }}
                                    disabled
                                />
                            </Grid>
                        )}
                        {!orderLineItem.orderBy && (
                            <Grid item xs={3}>
                                <FormNumberInput
                                    value={orderLineItem.equipmentRequired}
                                    name='equipmentRequired'
                                    onChange={() => { }}
                                    label='Equipment Req.'
                                    fullWidth
                                    inputProps={{ min: 0 }}
                                    disabled
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item container direction='column' xs={12}>
                        <Grid item xs={12}>
                            <FormInput
                                value={orderLineItem.memo}
                                onChange={() => { }}
                                label='Internal Memo'
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormInput
                                value={orderLineItem.dispatchMemo}
                                onChange={() => { }}
                                label='Dispatch Memo'
                                fullWidth
                                disabled
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }, [currentQuote?.id, currentQuote?.quoteNumber, isMaterialLineItem, navigate]);

    return (
        <>{generalDetails(orderReview, orderLineItem)}</>
    );
};