import { PriceListDto, PriceListLineItemDto } from '../../../dtos';
import { emptyGuid } from '../../../util';

export interface IUseImportPriceListFormProps {
    save: (values: PriceListDto) => void;
    cancel: () => void;
}

export interface IInputPriceListFormValues {
    siteId: string;
    effectiveOn: Date | null | undefined;
    priceLineItems: PriceListLineItemDto[];
}

export const DEFAULT_PRICE_LIST: PriceListDto = {
    siteId: emptyGuid,
    effectiveOn: undefined,
    updatedPriceLineItems: [],
    newPriceLineItems: [],
};

export const DEFAULT_PRICE_LIST_LINE_ITEM: PriceListLineItemDto = {
    referenceMaterialPriceId: undefined,
    materialId: emptyGuid,
    materialCode: '',
    incomeCodeId: emptyGuid,
    incomeCodeDescription: '',
    cost: undefined,
    priceA: undefined,
    priceB: undefined,
    priceC: undefined,
    priceD: undefined,
    priceE: undefined,
    unitOfMeasure: '',
    isSkipped: false,
};
