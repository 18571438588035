import { useAuth0 } from '@auth0/auth0-react';
import { Article, Assignment, Delete, FolderSpecial, Group, Home, LocalShipping, Person, RateReview, Settings } from '@mui/icons-material';
import { FC, PropsWithChildren, useEffect } from 'react';
import { LoadingIndicator } from '../CoreLib/library';
import NavigationDrawer, { NavigationDrawerRouteDTO } from '../NavigationDrawer';
import { useLazyGetUserAuth0UserIdByIdQuery } from '../../store/generated/generatedApi';

export const NavigationDrawerContainer: FC<PropsWithChildren> = (props) => {
    const { user, isLoading, error } = useAuth0();
    const [getUserData] = useLazyGetUserAuth0UserIdByIdQuery();

    useEffect(() => {
        if (user?.sub) {
            getUserData({ id: user?.sub });
        }
    }, [user, getUserData]);

    const publicRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <Home />,
            name: 'Home',
            route: '/home',
        },
        {
            icon: <Group />,
            name: 'Customers',
            route: '/customers',
            requiredPermissions: ['read:customer']
        },
        {
            icon: <Assignment />,
            name: 'Quotes',
            route: '/quotes',
            requiredPermissions: ['read:quote']
        },
        {
            icon: <Article />,
            name: 'Jobs',
            route: '/jobs',
            requiredPermissions: ['read:job']
        },
        {
            icon: <LocalShipping />,
            name: 'Dispatch',
            route: '/dispatch',
            requiredPermissions: ['read:dispatch']
        },
        {
            icon: <RateReview />,
            name: 'Order Review',
            route: '/orderReview',
            requiredPermissions: ['read:review', 'read:order']
        },
        {
            icon: <FolderSpecial />,
            name: 'Data Management',
            route: '/dataManagement',
            requiredPermissions: ['read:site', 'read:material', 'read:driver', 'read:broker', 'read:equipment', 'read:materialPrice'],
            permissionLogic: 'or'
        },
    ];

    const reportRoutes: NavigationDrawerRouteDTO[] = [];

    const adminRoutes: NavigationDrawerRouteDTO[] = [
        {
            icon: <Person />,
            name: 'Users',
            route: '/users',
            requiredPermissions: ['read:users']
        },
        {
            icon: <Delete />,
            name: 'Trash',
            route: '/trash',
            requiredPermissions: ['read:trash']
        },
        {
            icon: <Settings />,
            name: 'Settings',
            route: '/settings',
            requiredPermissions: ['read:miscSettings']
        }
    ];

    if (isLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <p>{error.message}</p>;
    }
    return (
        <div>
            <NavigationDrawer routes={publicRoutes} adminRoutes={adminRoutes} reportRoutes={reportRoutes}>
                {props.children}
            </NavigationDrawer>
        </div>
    );
};
