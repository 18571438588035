import { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { QuoteDto } from '../../dtos';
import { ApiError, LoadingIndicator, useFailedUpdateSnackbar, useSuccessfulUpdateSnackbar } from '../../Components/CoreLib/library';
import { useGetQuoteByIdQuery, useLazyGetCustomerByIdQuery, useUpdateQuoteMutation } from '../../store/generated/generatedApi';
import { QuoteForm } from '../../Components/Forms/QuoteForm';

export const EditQuoteView: FC = () => {
    const { customerId, quoteId } = useParams();
    const navigate = useNavigate();
    const [getCustomer, { data: customer, isLoading: isCustomerLoading }] = useLazyGetCustomerByIdQuery();
    const { data, isLoading, error, refetch } = useGetQuoteByIdQuery({ id: quoteId! });
    const [updateQuote, { isSuccess, isError, reset }] = useUpdateQuoteMutation();

    useEffect(() => {
        if (customerId && !customer) {
            getCustomer({ id: customerId! });
        }
    }, [customer, customerId, getCustomer]);

    useSuccessfulUpdateSnackbar('Quote', isSuccess, reset);
    useFailedUpdateSnackbar('quote', isError, reset);

    const handleSave = (values: QuoteDto) => {
        updateQuote(values);
    };

    const handleCancel = () => {
        if (customerId) {
            navigate(`/customer/${customerId}/dashboard`);
        } else {
            navigate('/quotes');
        }
    };

    if (isLoading || isCustomerLoading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <ApiError onReloadClick={refetch} />;
    }

    return <QuoteForm save={handleSave} cancel={handleCancel} initValues={data} customer={customer} />;
};
