import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PriceListDto, PriceListLineItemDto, SiteDto } from '../../../dtos';
import { useLazyGetPriceListSiteByIdQuery } from '../../../store/generated/generatedApi';
import { emptyGuid } from '../../../util';
import {
    IFormFieldValidationConfig,
    isGreaterThanOrEqualTo,
    isNotBlank,
    runFieldValidation,
    runFormValidation,
    useFailedActionSnackbar,
} from '../../CoreLib/library';
import { IUseImportPriceListFormProps, IInputPriceListFormValues, DEFAULT_PRICE_LIST_LINE_ITEM } from './types';

export function useInputPriceListForm(props: IUseImportPriceListFormProps) {
    const { save, cancel } = props;
    const [
        getPriceListForSite,
        {
            data: initialSitePriceList,
            isLoading: isLoadingInitialSitePriceList,
            isError: isErrorLoadingInitialSitePriceList,
            isSuccess: isInitialSitePriceListLoaded,
        },
    ] = useLazyGetPriceListSiteByIdQuery();
    useFailedActionSnackbar('retrieve', 'existing site material prices', isErrorLoadingInitialSitePriceList);
    const [formSiteId, setFormSiteId] = useState<string>(emptyGuid);
    const [formEffectiveOnDate, setFormEffectiveOnDate] = useState<Date | null | undefined>();
    const [formExistingPriceLineItems, setExistingFormPriceLineItems] = useState<PriceListLineItemDto[]>([]);
    const [formNewPriceLineItems, setNewFormPriceLineItems] = useState<PriceListLineItemDto[]>([]);

    const createNewLineItemErrorsMap = () => {
        return new Map<keyof PriceListLineItemDto, string>([
            ['materialId', ''],
            ['incomeCodeId', ''],
            ['cost', ''],
            ['priceA', ''],
            ['priceB', ''],
            ['priceC', ''],
            ['unitOfMeasure', ''],
        ]);
    };

    useEffect(() => {
        if (formSiteId && formSiteId !== emptyGuid) {
            getPriceListForSite({ siteId: formSiteId });
        }
    }, [formSiteId, getPriceListForSite]);

    useEffect(() => {
        if (initialSitePriceList) {
            setExistingFormPriceLineItems(initialSitePriceList as unknown as PriceListLineItemDto[]);
            var errorMessagePlaceholders = initialSitePriceList.map(() => createNewLineItemErrorsMap());
            setExistingLineItemFieldErrors(errorMessagePlaceholders);
        }
    }, [initialSitePriceList]);

    const [mainFieldErrors, setMainFieldErrors] = useState<Map<keyof IInputPriceListFormValues, string>>(
        new Map([
            ['siteId', ''],
            ['effectiveOn', ''],
        ])
    );

    const mainFormFieldValidators = useMemo(
        () =>
            new Map<keyof IInputPriceListFormValues, IFormFieldValidationConfig>([
                ['siteId', { validators: [isNotBlank], errorMessageEntityName: 'Site' }],
                ['effectiveOn', { validators: [isNotBlank], errorMessageEntityName: 'Effective On' }],
            ]),
        []
    );

    const [existingLineItemFieldErrors, setExistingLineItemFieldErrors] = useState<Map<keyof PriceListLineItemDto, string>[]>([]);
    const [newLineItemFieldErrors, setNewLineItemFieldErrors] = useState<Map<keyof PriceListLineItemDto, string>[]>([]);

    const lineItemFormFieldValidators = new Map<keyof PriceListLineItemDto, IFormFieldValidationConfig>([
        ['materialId', { validators: [isNotBlank], errorMessageEntityName: 'Material' }],
        ['incomeCodeId', { validators: [isNotBlank], errorMessageEntityName: 'Income Code' }],
        ['cost', { validators: [isNotBlank, isGreaterThanOrEqualTo(0)], errorMessageEntityName: 'Cost' }],
        ['priceA', { validators: [isNotBlank, isGreaterThanOrEqualTo(0)], errorMessageEntityName: 'Price A' }],
        ['priceB', { validators: [isNotBlank, isGreaterThanOrEqualTo(0)], errorMessageEntityName: 'Price B' }],
        ['priceC', { validators: [isNotBlank, isGreaterThanOrEqualTo(0)], errorMessageEntityName: 'Price C' }],
        ['unitOfMeasure', { validators: [isNotBlank], errorMessageEntityName: 'Unit Of Measure' }],
    ]);

    const validateForm = () => {
        const mainFormValues = {
            siteId: formSiteId ?? emptyGuid,
            effectiveOn: formEffectiveOnDate,
        };
        const mainFormValidationResult = runFormValidation<Partial<IInputPriceListFormValues>>(mainFormValues, mainFormFieldValidators);
        setMainFieldErrors(mainFormValidationResult.errorMessages);

        const existingLineItemValidationResults = formExistingPriceLineItems.map((lineItem) =>
            lineItem.isSkipped
                ? { isValid: true, errorMessages: createNewLineItemErrorsMap() }
                : runFormValidation<PriceListLineItemDto>(lineItem, lineItemFormFieldValidators)
        );
        const existingLineItemErrorMessages = existingLineItemValidationResults.map((lir) => lir.errorMessages);
        setExistingLineItemFieldErrors(existingLineItemErrorMessages);

        const newLineItemValidationResults = formNewPriceLineItems.map((lineItem) =>
            runFormValidation<PriceListLineItemDto>(lineItem, lineItemFormFieldValidators)
        );
        const newLineItemErrorMessages = newLineItemValidationResults.map((lir) => lir.errorMessages);
        setNewLineItemFieldErrors(newLineItemErrorMessages);

        const areAllExistingLineItemsValid = existingLineItemValidationResults.every((lir) => lir.isValid);
        const areAllNewLineItemsValid = newLineItemValidationResults.every((lir) => lir.isValid);
        return mainFormValidationResult.isValid && areAllExistingLineItemsValid && areAllNewLineItemsValid;
    };

    const validateMainFormField = useCallback(
        (fieldName: keyof IInputPriceListFormValues) => {
            const validationConfig = mainFormFieldValidators.get(fieldName);
            if (validationConfig) {
                const formValues = {
                    siteId: formSiteId,
                    effectiveOn: formEffectiveOnDate,
                };
                const fieldValue = formValues[fieldName];
                const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

                if (errorMessage !== mainFieldErrors.get(fieldName)) {
                    const updatedFieldErrors = _.cloneDeep(mainFieldErrors);
                    updatedFieldErrors.set(fieldName, errorMessage);
                    setMainFieldErrors(updatedFieldErrors);
                }
            }
        },
        [mainFormFieldValidators, formEffectiveOnDate, formSiteId, mainFieldErrors]
    );

    const validateExistingLineItemField = (fieldName: keyof PriceListLineItemDto, idx: number) => {
        const validationConfig = lineItemFormFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = formExistingPriceLineItems[idx];
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== existingLineItemFieldErrors[idx].get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(existingLineItemFieldErrors);
                updatedFieldErrors[idx].set(fieldName, errorMessage);
                setExistingLineItemFieldErrors(updatedFieldErrors);
            }
        }
    };

    const validateNewLineItemField = (fieldName: keyof PriceListLineItemDto, idx: number) => {
        const validationConfig = lineItemFormFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = formNewPriceLineItems[idx];
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== newLineItemFieldErrors[idx].get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(newLineItemFieldErrors);
                updatedFieldErrors[idx].set(fieldName, errorMessage);
                setNewLineItemFieldErrors(updatedFieldErrors);
            }
        }
    };

    const handleSiteChange = (site: SiteDto | undefined) => {
        setFormSiteId(site?.id ?? emptyGuid);
    };

    const handleEffectiveOnChange = (effectiveOn: Date | null | undefined) => {
        setFormEffectiveOnDate(effectiveOn);
    };

    useEffect(() => {
        if (formEffectiveOnDate) {
            validateMainFormField('effectiveOn');
        }
    }, [formEffectiveOnDate, validateMainFormField]);

    const handleExistingMaterialPriceCostsChange = (value: PriceListLineItemDto, idx: number) => {
        var updatedLineItems = _.cloneDeep(formExistingPriceLineItems);
        updatedLineItems[idx] = value;
        setExistingFormPriceLineItems(updatedLineItems);
    };

    const handleNewMaterialPriceCostsChange = (value: PriceListLineItemDto, idx: number) => {
        var updatedLineItems = _.cloneDeep(formNewPriceLineItems);
        updatedLineItems[idx] = value;
        setNewFormPriceLineItems(updatedLineItems);
    };

    const handleAddNewMaterialPrice = () => {
        var updatedLineErrors = _.cloneDeep(newLineItemFieldErrors);
        var defaultErrorMessageMap = new Map<keyof PriceListLineItemDto, string>([
            ['materialId', ''],
            ['incomeCodeId', ''],
            ['cost', ''],
            ['priceA', ''],
            ['priceB', ''],
            ['priceC', ''],
            ['unitOfMeasure', ''],
        ]);
        updatedLineErrors.push(defaultErrorMessageMap);
        setNewLineItemFieldErrors(updatedLineErrors);

        var updatedLineItems = _.cloneDeep(formNewPriceLineItems);
        updatedLineItems.push(DEFAULT_PRICE_LIST_LINE_ITEM);
        setNewFormPriceLineItems(updatedLineItems);
    };

    const handleRemoveNewMaterialPrice = (idx: number) => {
        var updatedLineErrors = _.cloneDeep(newLineItemFieldErrors);
        updatedLineErrors.splice(idx, 1);
        setNewLineItemFieldErrors(updatedLineErrors);

        var updatedLineItems = _.cloneDeep(formNewPriceLineItems);
        updatedLineItems.splice(idx, 1);
        setNewFormPriceLineItems(updatedLineItems);
    };

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const payload: PriceListDto = {
                siteId: formSiteId!,
                effectiveOn: formEffectiveOnDate!,
                updatedPriceLineItems: formExistingPriceLineItems,
                newPriceLineItems: formNewPriceLineItems,
            };
            save(payload);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    return {
        handleSave,
        handleCancel,
        mainFieldErrors,
        existingLineItemFieldErrors,
        newLineItemFieldErrors,
        handleSiteChange,
        handleEffectiveOnChange,
        handleExistingMaterialPriceCostsChange,
        handleNewMaterialPriceCostsChange,
        formSiteId,
        formEffectiveOnDate,
        formExistingPriceLineItems,
        formNewPriceLineItems,
        validateMainFormField,
        validateExistingLineItemField,
        validateNewLineItemField,
        isLoadingInitialSitePriceList,
        isInitialSitePriceListLoaded,
        handleAddNewMaterialPrice,
        handleRemoveNewMaterialPrice,
    };
}
