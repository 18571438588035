import { SelectChangeEvent } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, useEffect, useState } from 'react';
import { DumpRateDto, IncomeCodeDto, SiteDto } from '../../../dtos';
import { UnitOfMeasure, emptyGuid, isValidUnitOfMeasure, unitsOfMeasure } from '../../../util';
import {
    IFormFieldValidationConfig,
    IFormProps,
    isGreaterThanOrEqualTo,
    isNotBlank,
    isShorterThanMaxLength,
    runFieldValidation,
    runFormValidation,
} from '../../CoreLib/library';

export interface IDumpRateFormValues {
    siteId: string;
    type: string;
    cost?: number;
    rate?: number;
    unitOfMeasure: string;
    incomeCodeId: string;
    isActive: boolean;
}

const DEFAULT_DUMP_RATE: DumpRateDto = {
    id: emptyGuid,
    siteId: emptyGuid,
    type: '',
    cost: 0,
    rate: 0,
    unitOfMeasure: unitsOfMeasure[0],
    incomeCodeId: emptyGuid,
    isActive: true,
    createdOn: new Date(),
};

export function useDumpRateForm(props: IFormProps<DumpRateDto>, initialSiteId?: string) {
    const { save, cancel, initValues } = props;
    const [formSiteId, setFormSiteId] = useState(initialSiteId ?? emptyGuid);
    const [formDumpType, setFormDumpType] = useState('');
    const [formCost, setFormCost] = useState<number | undefined>();
    const [formRate, setFormRate] = useState<number | undefined>();
    const [formUnitOfMeasure, setFormUnitOfMeasure] = useState<UnitOfMeasure>('Loads');
    const [formIncomeCodeId, setFormIncomeCodeId] = useState(emptyGuid);
    const [formIsActive, setFormIsActive] = useState(true);
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setFormSiteId(initValues?.siteId ?? initialSiteId ?? emptyGuid);
        setFormDumpType(initValues?.type ?? '');
        setFormCost(initValues?.cost);
        setFormRate(initValues?.rate);
        setFormUnitOfMeasure((initValues?.unitOfMeasure as UnitOfMeasure) ?? 'Loads');
        setFormIncomeCodeId(initValues?.incomeCodeId ?? emptyGuid);
        setFormIsActive(initValues?.isActive ?? true);
    }, [initValues, initialSiteId]);

    const [fieldErrors, setFieldErrors] = useState<Map<keyof IDumpRateFormValues, string>>(
        new Map([
            ['siteId', ''],
            ['type', ''],
            ['cost', ''],
            ['rate', ''],
            ['incomeCodeId', ''],
            ['unitOfMeasure', ''],
        ])
    );

    const formFieldValidators = new Map<keyof IDumpRateFormValues, IFormFieldValidationConfig>([
        [
            'siteId',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Site',
            },
        ],
        [
            'type',
            {
                validators: [isNotBlank, isShorterThanMaxLength(20)],
                errorMessageEntityName: 'Dump Type',
            },
        ],
        [
            'cost',
            {
                validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                errorMessageEntityName: 'Cost',
            },
        ],
        [
            'rate',
            {
                validators: [isNotBlank, isGreaterThanOrEqualTo(0)],
                errorMessageEntityName: 'Rate',
            },
        ],
        [
            'incomeCodeId',
            {
                validators: [isNotBlank],
                errorMessageEntityName: 'Income Code',
            },
        ],
        [
            'unitOfMeasure',
            {
                validators: [isNotBlank, isShorterThanMaxLength(20)],
                errorMessageEntityName: 'Unit Of Measure',
            },
        ],
    ]);

    const validateForm = () => {
        const formValues = getCurrentFormValues();
        const validationResult = runFormValidation<Partial<IDumpRateFormValues>>(formValues, formFieldValidators);
        setFieldErrors(validationResult.errorMessages);
        return validationResult.isValid;
    };

    const getCurrentFormValues = (): IDumpRateFormValues => {
        return {
            siteId: formSiteId,
            type: formDumpType,
            cost: formCost,
            rate: formRate,
            unitOfMeasure: formUnitOfMeasure ?? '',
            incomeCodeId: formIncomeCodeId,
            isActive: formIsActive,
        };
    };

    const validateField = (fieldName: keyof IDumpRateFormValues) => {
        const validationConfig = formFieldValidators.get(fieldName);
        if (validationConfig) {
            const formValues = getCurrentFormValues();
            const fieldValue = formValues[fieldName];
            const { errorMessage } = runFieldValidation(fieldValue, validationConfig);

            if (errorMessage !== fieldErrors.get(fieldName)) {
                const updatedFieldErrors = _.cloneDeep(fieldErrors);
                updatedFieldErrors.set(fieldName, errorMessage);
                setFieldErrors(updatedFieldErrors);
            }
        }
    };

    const isFormDirty = () => {
        return isChanged;
    };

    const handleSiteChange = (newSite: SiteDto | undefined) => {
        setFormSiteId(newSite?.id ?? emptyGuid);
        setIsChanged(true);
    };

    const handleDumpTypeChange = (event: SelectChangeEvent<string>) => {
        setFormDumpType(event.target.value);
        setIsChanged(true);
    };

    const handleCostChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormCost(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    };

    const handleRateChange = (event: ChangeEvent<HTMLInputElement>) => {
        setFormRate(isNaN(event.target.valueAsNumber) ? undefined : event.target.valueAsNumber);
        setIsChanged(true);
    };

    const handleUnitOfMeasureChange = (value?: string) => {
        if (isValidUnitOfMeasure(value ?? '')) {
            setFormUnitOfMeasure(value as UnitOfMeasure);
        } else {
            console.error('Attempted to set unit of measure to invalid value.');
        }
        setIsChanged(true);
    };

    const handleIncomeCodeChange = (newIncomeCode: IncomeCodeDto | undefined) => {
        setFormIncomeCodeId(newIncomeCode?.id ?? emptyGuid);
        setIsChanged(true);
    };

    const handleIsActiveChange = (_: ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        setFormIsActive(isChecked);
        setIsChanged(true);
    };

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            const formValues = getCurrentFormValues();
            const dumpRateRecord = {
                ...(initValues ?? DEFAULT_DUMP_RATE),
                ...formValues,
            };
            save(dumpRateRecord);
            setIsChanged(false);
        }
    };

    const handleCancel = () => {
        cancel();
        setIsChanged(false);
    };

    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        handleSiteChange,
        handleDumpTypeChange,
        handleCostChange,
        handleRateChange,
        handleUnitOfMeasureChange,
        handleIncomeCodeChange,
        handleIsActiveChange,
        formSiteId,
        formDumpType,
        formCost,
        formRate,
        formUnitOfMeasure,
        formIncomeCodeId,
        formIsActive,
        validateField,
    };
}
