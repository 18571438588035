import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MaterialPriceDto } from '../../dtos';
import { useFailedCreateSnackbar, useSuccessfulCreateSnackbar } from '../../Components/CoreLib/library';
import { useCreateMaterialPriceMutation } from '../../store/generated/generatedApi';
import { MaterialPriceForm } from '../../Components/Forms/MaterialPriceForm';

export const AddMaterialPriceView: FC = () => {
    const navigate = useNavigate();
    const [createMaterialPrice, { data, isError, error, isSuccess, reset, isLoading }] = useCreateMaterialPriceMutation();

    useSuccessfulCreateSnackbar('Material Price', isSuccess, () => navigate(`/dataManagement/materialPrice/${data?.id}`));
    useFailedCreateSnackbar('Material Price', isError, reset, error);

    const handleSave = (values: MaterialPriceDto) => {
        if (!isLoading) {
            createMaterialPrice(values);
        }
    };

    const handleCancel = () => {
        navigate('/dataManagement/materialPrices');
    };

    return <MaterialPriceForm save={handleSave} cancel={handleCancel} />;
};
